import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';

import Loader from '../Loader';

const LoaderCenter = ({ sx }: { sx?: SxProps } = { sx: {} }) => {
  return (
    <Box display="flex" width="100%" height="100%" justifyContent="center" alignItems="center" sx={sx}>
      <Loader />
    </Box>
  );
};

export default LoaderCenter;
