import { OrganizationUserRole } from '~/graphql/member/types';

const HIGH_ROLES = [OrganizationUserRole.Owner, OrganizationUserRole.Admin];
const WITHOUT_VIEWER_ROLE = HIGH_ROLES.concat([OrganizationUserRole.Member]);
const ALL_ROLES = WITHOUT_VIEWER_ROLE.concat([OrganizationUserRole.Viewer]);

export const SCREEN_PERMISSION = {
  HOME: {
    VIEW: ALL_ROLES,
  },
  COLLECTIONS: {
    VIEW: ALL_ROLES,
    ADD: WITHOUT_VIEWER_ROLE,
    EDIT: WITHOUT_VIEWER_ROLE,
    DELETE: WITHOUT_VIEWER_ROLE,
    BASIC: {
      VIEW: ALL_ROLES,
    },
    MINTED_NFT: {
      VIEW: ALL_ROLES,
      ADD: WITHOUT_VIEWER_ROLE,
      EDIT: WITHOUT_VIEWER_ROLE,
      DELETE: WITHOUT_VIEWER_ROLE,
    },
    MINT_ON_PURCHASE: {
      VIEW: ALL_ROLES,
      ADD: WITHOUT_VIEWER_ROLE,
      EDIT: WITHOUT_VIEWER_ROLE,
      DELETE: WITHOUT_VIEWER_ROLE,
    },
  },
  SHOPS: {
    VIEW: WITHOUT_VIEWER_ROLE,
    ADD: WITHOUT_VIEWER_ROLE,
    EDIT: WITHOUT_VIEWER_ROLE,
    DELETE: WITHOUT_VIEWER_ROLE,
  },
  MEMBER_SITES: {
    VIEW: WITHOUT_VIEWER_ROLE,
    ADD: WITHOUT_VIEWER_ROLE,
    EDIT: WITHOUT_VIEWER_ROLE,
    DELETE: WITHOUT_VIEWER_ROLE,
  },
  SETTING: {
    VIEW: WITHOUT_VIEWER_ROLE,
    GENERAL: {
      VIEW: HIGH_ROLES,
      EDIT: [OrganizationUserRole.Owner],
    },
    DEPOSIT: {
      VIEW: HIGH_ROLES,
      DEPOSIT: HIGH_ROLES,
      WITHDRAW: [OrganizationUserRole.Owner],
    },
    PAYMENT: {
      VIEW: HIGH_ROLES,
      EDIT: [OrganizationUserRole.Owner, OrganizationUserRole.Admin],
    },
    MEMBER: {
      VIEW: WITHOUT_VIEWER_ROLE,
      ADD: HIGH_ROLES,
      EDIT: HIGH_ROLES,
      DELETE: HIGH_ROLES,
    },
  },
};

export const MEMBER_ACTIONS = {
  ADD: {
    [OrganizationUserRole.Owner]: [
      OrganizationUserRole.Admin,
      OrganizationUserRole.Member,
      OrganizationUserRole.Viewer,
    ],
    [OrganizationUserRole.Admin]: [OrganizationUserRole.Member, OrganizationUserRole.Viewer],
    [OrganizationUserRole.Member]: [] as OrganizationUserRole[],
    [OrganizationUserRole.Viewer]: [] as OrganizationUserRole[],
  },
  EDIT: {
    [OrganizationUserRole.Owner]: [
      OrganizationUserRole.Admin,
      OrganizationUserRole.Member,
      OrganizationUserRole.Viewer,
    ],
    [OrganizationUserRole.Admin]: [OrganizationUserRole.Member, OrganizationUserRole.Viewer],
    [OrganizationUserRole.Member]: [] as OrganizationUserRole[],
    [OrganizationUserRole.Viewer]: [] as OrganizationUserRole[],
  },
  DELETE: {
    [OrganizationUserRole.Owner]: [
      OrganizationUserRole.Admin,
      OrganizationUserRole.Member,
      OrganizationUserRole.Viewer,
    ],
    [OrganizationUserRole.Admin]: [OrganizationUserRole.Member, OrganizationUserRole.Viewer],
    [OrganizationUserRole.Member]: [] as OrganizationUserRole[],
    [OrganizationUserRole.Viewer]: [] as OrganizationUserRole[],
  },
};
