import { FC, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ReactCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import { Crop, PixelCrop } from 'react-image-crop/dist/types';

import CustomDialog from '../custom-dialog';

import { useDebounceEffect } from '~/hooks/useDebounceEffect';
import { renderCroppedCanvas } from '~/utils/renderCroppedCanvas';

import 'react-image-crop/dist/ReactCrop.css';

interface ICropImageDialog {
  open: boolean;
  title: string;
  imgSrc: string;
  aspect?: number;
  onClose: (submitted?: boolean) => void;
  onConfirm: (newImageFile: File[]) => void;
}

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

const CropImageDialog: FC<ICropImageDialog> = ({ open, aspect, title, imgSrc, onConfirm, onClose }) => {
  const { t } = useTranslation();

  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const imgRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const handleClose = () => {
    onClose();
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect || 16 / 9));
  };

  const handleConfirm = async () => {
    const canvasOffset = document.createElement('canvas');
    const image = imgRef.current;
    const canvas = canvasRef.current;
    if (!image || !canvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvasOffset.width = completedCrop.width * scaleX;
    canvasOffset.height = completedCrop.height * scaleY;

    const ctx = canvasOffset.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, canvasOffset.width, canvasOffset.height);

    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    canvasOffset.toBlob((blob) => {
      if (!blob) {
        console.error('Canvas is empty');
        return;
      }
      const newFile = [new File([blob], 'cropped_image')];
      onConfirm(newFile);
      onClose();
    }, 'image/png');
  };

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && canvasRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        renderCroppedCanvas(imgRef.current, canvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (imgSrc) {
      setCrop(undefined);
      setCompletedCrop(undefined);
    }
  }, [imgSrc]);

  return (
    <CustomDialog
      open={open}
      dialogTitle={title}
      onClose={handleClose}
      dialogContent={
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', overflow: 'hidden' }}>
          <ReactCrop
            crop={crop}
            minHeight={0}
            aspect={aspect}
            onComplete={(c) => setCompletedCrop(c)}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
          >
            <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} style={{ maxWidth: '100%' }} />
          </ReactCrop>
          {completedCrop && (
            <canvas
              ref={canvasRef}
              style={{
                display: 'none',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          )}
        </Box>
      }
      actions={[
        <Button key="cancel" variant="outlined" onClick={handleClose}>
          {t('cancel')}
        </Button>,
        <Button key="confirm" variant="contained" onClick={handleConfirm}>
          {t('confirm')}
        </Button>,
      ]}
    />
  );
};

export default CropImageDialog;
