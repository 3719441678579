import { ChangeEventHandler, FC, ForwardedRef, forwardRef, useContext, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { TextField, Tooltip, TooltipProps, Typography, styled, tooltipClasses, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import DialogCloseButton from '~/components/dialog/dialog-close-button';
import { TableImageListShop } from '~/components/shop-collection-detail-lists/ImageNFTList';
import { limitPrice } from '~/constants/common';
import { ShopDetailContext, ShopDetailContextValue } from '~/contexts/ShopDetailWrapper';
import { CurrencyEnum } from '~/types/my-shop';

export type ErrorType = {
  min: number;
  max: number;
} | null;

interface ITextEdit {
  label: string | null | undefined;
  value: string;
  currency: CurrencyEnum;
  onChange: (value: string) => void;
}

interface ITextEditWithError extends ITextEdit {
  error: ErrorType;
}

interface EditImagePriceDialogProps {
  isDialogOpen: boolean;
  rowData?: Partial<TableImageListShop> | undefined;
  isSubmitting?: boolean;
  onCloseDialog: () => void;
  onSavePrice?: (newPrice: number) => void;
}

export interface PriceData {
  id: string;
  price: number;
  currencyUnit: string;
  collectionImageUuid?: string;
}

const useStyles = makeStyles()((theme) => ({
  addImageDialog: {
    '.MuiPaper-root': {
      maxWidth: '615px',
      width: '615px',
    },
    '.image-dialog': {
      '&-title': {
        padding: '16px',
        fontSize: '24px',
        fontWeight: '400',
        fontColor: '#444444',
        lineHeight: '133.4%',
      },
    },
    '.MuiDivider-root': {
      margin: '0 16px',
      borderColor: '#9E9E9E',
    },
    '.MuiDialogContent-root': {
      padding: 16,
      marginTop: '16px',
      '.image-dialog-input-name': {
        width: '100%',
        marginTop: 16,
      },
    },
  },
  priceEdit: {
    '.MuiInputBase-input': {
      padding: '14px',
    },
  },
  actions: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'end',
  },
}));

export const validateLimitPrice = (price: string, currency: string) => {
  const numericPrice = parseFloat(price);

  const min = limitPrice[currency as CurrencyEnum]?.min;
  const max = limitPrice[currency as CurrencyEnum]?.max;

  if (isNaN(numericPrice)) {
    return { min, max };
  }

  if (numericPrice === 0) {
    return null;
  }

  if ((min !== undefined && numericPrice < min) || (max !== undefined && numericPrice > max)) {
    return { min, max };
  }

  return null;
};

const ValidationInput = (params: ITextEditWithError) => {
  const { t } = useTranslation();
  const { error, ...others } = params;
  return (
    <StyledTooltip open={false} title={t('form_validation.limit_price', { min: error?.min, max: error?.max })}>
      <TextEditInputCell {...others} />
    </StyledTooltip>
  );
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: '4px !important',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const TextEditInputCell = forwardRef((props: ITextEdit, ref: ForwardedRef<HTMLInputElement>) => {
  const { value, currency, onChange, ...others } = props;

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = async (event) => {
    const newValue = event.target.value;
    const regexPattern = /^\d*$/;
    if (!regexPattern.test(newValue)) {
      return;
    }
    const removeNegative = newValue.toString().replace(/-/g, '');
    const removeZero = removeNegative.replace(/^0\d+/, removeNegative.slice(1));
    onChange(removeZero);
  };

  return <TextField fullWidth ref={ref} {...others} value={value} variant="outlined" onChange={handleChange} />;
});

const EditImagePriceDialog: FC<EditImagePriceDialogProps> = (props) => {
  const myShopData = useContext(ShopDetailContext) as ShopDetailContextValue;
  const { isSubmitting, rowData, isDialogOpen, onCloseDialog, onSavePrice } = props;

  const [basePrice, setBasePrice] = useState('');
  const [errorInfo, setErrorInfo] = useState<ErrorType>(null);
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null);

  const { classes } = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (rowData && myShopData && isDialogOpen) {
      setBasePrice(rowData.price?.toString() || '');
    }
  }, [isDialogOpen, myShopData, rowData]);

  const handleSavePrice = (callback?: (newPrice: number) => void) => () => {
    if (!!basePrice && !errorMessage) {
      callback?.(parseFloat(basePrice || '0'));
    } else {
      const error = validateLimitPrice(basePrice.toString(), myShopData.data.paymentMethod?.baseCurrency!);
      setErrorMessage(error);
    }
  };

  const handleChange = (newValue: string) => {
    const error = validateLimitPrice(newValue.toString(), myShopData.data.paymentMethod?.baseCurrency!);
    setErrorMessage(error);
    setBasePrice(newValue || '');
  };

  const onClosePriceDialog = () => {
    onCloseDialog();
    setErrorMessage(null);
  };

  return (
    <Dialog open={isDialogOpen} onClose={onClosePriceDialog} className={classes.addImageDialog}>
      <DialogTitle className="image-dialog-title">{t('collection_screen.image.price_setting')}</DialogTitle>
      <DialogCloseButton onClick={onClosePriceDialog} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <Divider />
      <DialogContent sx={{ width: '100%' }}>
        <ValidationInput
          label={myShopData.data.paymentMethod?.baseCurrency}
          error={errorInfo}
          currency={myShopData?.data?.paymentMethod?.baseCurrency! as CurrencyEnum}
          value={basePrice.toString()}
          onChange={handleChange}
        />
        {errorMessage && (
          <Typography
            variant="caption"
            color="error"
            sx={{ display: 'inline-block', fontSize: '14px', marginTop: '8px' }}
          >
            {t('form_validation.limit_price', { min: errorMessage?.min, max: errorMessage?.max?.toLocaleString() })}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          color="primary"
          className="cancelButton"
          onClick={onClosePriceDialog}
          disabled={isSubmitting}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          disabled={rowData?.price?.toString() === basePrice || isSubmitting}
          onClick={handleSavePrice(onSavePrice)}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditImagePriceDialog;
