import { ChangeEventHandler, useEffect, useId, useMemo, useState } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { FormAddNFTValues } from '.';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 'inherit',
  },
  wrap: {
    width: '100%',
    height: 'inherit',
    position: 'relative',
  },
  selectArea: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    textTransform: 'none',
    borderStyle: 'dashed',
    flexDirection: 'column',
    ':hover': {
      borderStyle: 'dashed',
    },
  },
  filePreview: {
    borderRadius: 4,
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    position: 'absolute',
    backgroundColor: 'white',
    margin: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  file: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  changeFile: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'white',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

interface Props {
  control: Control<FormAddNFTValues>;
  name: keyof FormAddNFTValues;
  label?: string;
  type: 'image' | 'video';
  error?: boolean;
  disabled?: boolean;
  helperText?: React.ReactNode;
}

const FileUploadInput: React.FC<Props> = (props) => {
  const { control, error, helperText, name, type, label, disabled } = props;
  const [fileInfo, setFileInfo] = useState<{ url: string; name: string }>();
  const { classes } = useStyles();
  const inputId = useId();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const files = useWatch({
    control,
    name,
  }) as FileList;

  const { onChange, ...inputProps } = useMemo(() => control.register(name), [control, name]);

  useEffect(() => {
    if (files && files.length) {
      const url = URL.createObjectURL(files[0]);
      setFileInfo({ url, name: files[0].name });
      return () => {
        URL.revokeObjectURL(url);
      };
    } else {
      setFileInfo(undefined);
    }
  }, [files]);

  const content = useMemo(() => {
    if (type === 'image') {
      return {
        icon: <PermMediaOutlinedIcon />,
        inputAccept: 'image/*',
        label: `${t('image')}*`,
        preview: <img className={classes.file} src={fileInfo?.url} draggable={false} alt="" />,
      };
    }
    if (type === 'video') {
      return {
        icon: <OndemandVideoIcon />,
        label: t('select_video_file'),
        inputAccept: 'video/*',
        preview: <video className={classes.file} src={fileInfo?.url} controls />,
      };
    }
    return {
      icon: <AttachFileIcon />,
      label: t('select_file'),
      inputAccept: undefined,
      preview: <div className={classes.file}>{fileInfo?.name}</div>,
    };
  }, [classes.file, fileInfo?.name, fileInfo?.url, t, type]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const allowedTypes = [
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/apng',
        'image/avif',
        'image/svg+xml',
        'image/webp',
      ];

      if (!allowedTypes.includes(files[0].type)) {
        enqueueSnackbar(t('file_not_supported'), { variant: 'error' });
        return;
      }
      onChange(event);
    }
  };

  return (
    <FormControl className={classes.root} margin="normal">
      <div className={classes.wrap}>
        <Button variant="outlined" color={error ? 'error' : 'primary'} component="label" className={classes.selectArea}>
          <input
            hidden
            type="file"
            id={inputId}
            disabled={disabled}
            accept={content.inputAccept}
            {...inputProps}
            onChange={handleChange}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {content.icon}
            <Typography sx={{ marginLeft: 1, fontSize: '12px' }}>{content.label}</Typography>
          </Box>
          {!!label && (
            <Box width="100%" textAlign="center">
              <Typography sx={{ marginLeft: 1 }}>({label})</Typography>
            </Box>
          )}
        </Button>
        {!!fileInfo && (
          <div className={classes.filePreview}>
            {!disabled && (
              <Fab size="small" component="label" className={classes.changeFile} htmlFor={inputId}>
                <SwapHorizIcon />
              </Fab>
            )}
            {content.preview}
          </div>
        )}
      </div>
      {!!helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FileUploadInput;
