import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';
import { useDisconnect } from '@gusdk/gu-wallet-connector';
import firebase from 'firebase/app';

export const useLogout = () => {
  const client = useApolloClient();
  const { disconnect } = useDisconnect();
  const logout = useCallback(async () => {
    await firebase.auth().signOut();
    await disconnect();
    client.clearStore();
  }, [client, disconnect]);

  return logout;
};
