import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as HomeIcon } from '../../icons/images/home-icon.svg';

import { AppRouteEnum } from '~/enum/AppRouteEnum';

const HomeBtn = () => {
  const navigate = useNavigate();

  return <HomeIcon style={{ cursor: 'pointer' }} onClick={() => navigate(AppRouteEnum.Home)} width={14} height={14} />;
};

export default HomeBtn;
