import { FC, useContext, useEffect, useState } from 'react';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Divider, IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as CloseIcon } from '../../../../../icons/images/close-icon.svg';

import DialogCloseButton from '~/components/dialog/dialog-close-button';
import EditNameDialog, { IEditNFTNameField } from '~/components/dialog/edit-nft-name-dialog';
import { CURRENCY_ICONS } from '~/constants/common';
import { ShopDetailContext, ShopDetailContextValue } from '~/contexts/ShopDetailWrapper';
import { MemberMyShopCollectionImagesQueryKey } from '~/graphql/member/types';
import { STATUS } from '~/types/my-shop';

interface NFTDialogProps {
  open: boolean;
  nftInfo: any;
  actions?: JSX.Element[];
  isCollectionScreen?: boolean;
  onClose: () => void;
  onOpenPriceDialog?: () => void;
  onEditNFTName?: (data: IEditNFTNameField, nftId: string) => void;
}

interface ImageProps {
  width: number;
  height: number;
}

const useStyles = makeStyles()((theme) => ({
  nftDialog: {
    '.MuiPaper-root': {
      maxWidth: 356,
      width: '100%',
      display: 'flex',
      borderRadius: '4px',
      padding: '16px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 764,
      },
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '.dialogTitle': {
      color: '#444',
      fontSize: '24px',
      fontWeight: '400',
    },
  },
  imgPreview: {
    maxWidth: '100%',
    maxHeight: '100%',
    '.MuiPaper-elevation': {
      overflowY: 'unset',
    },
    '.custom-close-btn': {
      position: 'absolute',
      left: '95%',
      top: '-32px',
      right: '-10px',
      backgroundColor: 'transparent',
      color: 'white',
    },
    '.preview-image': {
      maxWidth: '100%',
      maxHeight: 'calc(100vh - 64px)',
    },
  },
  closeButton: {
    paddingTop: '6px',
  },
  content: {
    display: 'flex',
    paddingBottom: 28,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '.subtitle2': {
      fontWeight: 500,
      fontSize: '10px',
      color: '#00000099',
      letterSpacing: '1.5px',
    },
  },
  leftContent: {
    width: '100%',
    maxWidth: '324px',
    marginRight: 16,
  },
  rightContent: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  wrapperImage: {
    maxWidth: '324px',
    width: '100%',
    aspectRatio: '1 / 1',
    margin: 'auto',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  boxContent: {
    padding: '12px 16px',
    width: '100%',
    wordBreak: 'break-all',
    '.MuiTypography-root': {},
  },
  action: {
    justifyContent: 'end',
  },
  priceBox: {
    display: 'flex',
    justifyContent: 'space-between',
    '.MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
      marginLeft: '8px',
    },
  },
}));

const MintOnPurchaseDialog: FC<NFTDialogProps> = ({
  open,
  nftInfo,
  actions,
  isCollectionScreen,
  onClose,
  onEditNFTName,
  onOpenPriceDialog,
}) => {
  const myShopData = useContext(ShopDetailContext) as ShopDetailContextValue;

  const { t } = useTranslation();
  const { classes } = useStyles();

  const [imageSize, setImageSize] = useState<ImageProps>();
  const [editingLang, setEditingLang] = useState<'en' | 'ja'>('en');
  const [openImgPreview, setOpenImgPreview] = useState<boolean>(false);
  const [openEditNFTNameDialog, setOpenEditNFTNameDialog] = useState<boolean>(false);

  const nftCaption =
    nftInfo?.[MemberMyShopCollectionImagesQueryKey.ShopCollectionImageName] || nftInfo?.originalName || '-';
  const nftCaptionJa =
    nftInfo?.[MemberMyShopCollectionImagesQueryKey.ShopCollectionImageNameJa] || nftInfo?.originalName || '-';
  const nftName = nftInfo?.originalName || nftInfo?.name || '-';
  const notSoldOut = nftInfo?.status !== STATUS.SOLD_OUT;

  useEffect(() => {
    handleImageSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftInfo]);

  const handleImageSize = () => {
    let img = new Image();
    img.src = nftInfo?.url;
    img.onload = () => {
      setImageSize({ width: img.naturalWidth, height: img.naturalHeight });
    };
  };

  // Handle Edit Collection Name Dialog
  const onOpenEditNFTName = (value: 'en' | 'ja') => () => {
    setEditingLang(value);
    setOpenEditNFTNameDialog(true);
  };
  const onCloseEditCollectionName = () => {
    setOpenEditNFTNameDialog(false);
  };

  const onOpenImgPreview = () => {
    setOpenImgPreview(true);
  };

  const onCloseImgPreview = () => {
    setOpenImgPreview(false);
  };

  const handleEditNFTName = async (data: IEditNFTNameField) => {
    if (onEditNFTName) {
      await onEditNFTName(data, nftInfo.uuid);
      setOpenEditNFTNameDialog(false);
    }
  };

  return (
    <Dialog onClose={onClose} open={open} className={classes.nftDialog}>
      <Box className={classes.headerWrapper}>
        <Typography className="dialogTitle">{t('image_details')}</Typography>
        <DialogCloseButton className={classes.closeButton} onClick={onClose} data-testid="close-button">
          <CloseIcon width={24} />
        </DialogCloseButton>
      </Box>
      <Divider sx={{ margin: '16px 0 16px 8px' }} />
      <Box className={classes.content}>
        <Box className={classes.leftContent}>
          <Box className={classes.wrapperImage} onClick={onOpenImgPreview}>
            <img src={nftInfo?.url} alt="Thumbnails" loading="lazy" className={classes.image} />
          </Box>
        </Box>
        <Box className={classes.rightContent}>
          <Box className={classes.boxContent} sx={{ marginTop: '16px' }}>
            <Typography className="subtitle2">{t('my_shop.image_name')}</Typography>
            <Box className={classes.priceBox}>
              <Tooltip
                title={nftName}
                placement="bottom-start"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                }}
              >
                <Typography className="contentDetail">{nftName}</Typography>
              </Tooltip>
            </Box>
          </Box>
          <Divider />
          {!isCollectionScreen && (
            <>
              <Box className={classes.boxContent}>
                <Typography className="subtitle2">{t('my_shop.image_caption_en')}</Typography>
                <Box className={classes.priceBox}>
                  <Tooltip
                    title={nftCaption}
                    placement="bottom-start"
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -10],
                          },
                        },
                      ],
                    }}
                  >
                    <Typography className="contentDetail">{nftCaption}</Typography>
                  </Tooltip>
                  {!isCollectionScreen && notSoldOut && (
                    <ModeEditOutlineOutlinedIcon onClick={onOpenEditNFTName('en')} />
                  )}
                </Box>
              </Box>
              <Divider />
            </>
          )}
          {!isCollectionScreen && (
            <>
              <Box className={classes.boxContent}>
                <Typography className="subtitle2">{t('my_shop.image_caption_ja')}</Typography>
                <Box className={classes.priceBox}>
                  <Tooltip
                    title={nftCaptionJa}
                    placement="bottom-start"
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -10],
                          },
                        },
                      ],
                    }}
                  >
                    <Typography className="contentDetail">{nftCaptionJa}</Typography>
                  </Tooltip>
                  {!isCollectionScreen && notSoldOut && (
                    <ModeEditOutlineOutlinedIcon onClick={onOpenEditNFTName('ja')} />
                  )}
                </Box>
              </Box>
              <Divider />
            </>
          )}
          {!isCollectionScreen && (
            <>
              <Box className={classes.boxContent}>
                <Typography className="subtitle2">{t('price')}</Typography>
                <Box className={classes.priceBox}>
                  <Typography className="contentDetail">
                    {!!nftInfo?.price ? (
                      `${CURRENCY_ICONS[myShopData.data.paymentMethod?.baseCurrency!]} ${nftInfo?.price?.toLocaleString(
                        'en-EN'
                      )}`
                    ) : nftInfo?.price === 0 ? (
                      <Typography color="green">{t('free')}</Typography>
                    ) : (
                      <Typography color="red">{t('no_price_set')}</Typography>
                    )}
                  </Typography>
                  {notSoldOut && <ModeEditOutlineOutlinedIcon onClick={onOpenPriceDialog} />}
                </Box>
              </Box>
              <Divider />
            </>
          )}
          <Box className={classes.boxContent}>
            <Typography className="subtitle2">{t('collection_screen.max_mint')}</Typography>
            <Typography className="contentDetail">{nftInfo?.maxMint}</Typography>
          </Box>
          <Divider />
          <Box className={classes.boxContent}>
            <Typography className="subtitle2">{t('collection_screen.current_mint_count')}</Typography>
            <Typography className="contentDetail">{nftInfo?.currentMint}</Typography>
          </Box>
          <Divider />
          <Box className={classes.boxContent}>
            <Typography className="subtitle2">{t('image_size')}</Typography>
            <Typography className="contentDetail">{`${imageSize?.width}*${imageSize?.height}`}</Typography>
          </Box>
          <Divider />
          <Box className={classes.boxContent}>
            <Typography className="subtitle2">{t('created_at')}</Typography>
            <Typography className="contentDetail">
              {nftInfo?.createAt ? moment(nftInfo.createAt).format(t('date_time_format')) : '-'}
            </Typography>
          </Box>
          <Divider />
        </Box>
      </Box>
      {(!isCollectionScreen && actions) || []}

      <EditNameDialog
        autoFocus={editingLang}
        defaultName={nftCaption}
        open={openEditNFTNameDialog}
        defaultNameJa={nftCaptionJa}
        title={t('my_shop.edit_nft_name')}
        onEdit={handleEditNFTName}
        onClose={onCloseEditCollectionName}
      />
      <Dialog onClose={onCloseImgPreview} open={openImgPreview} className={classes.imgPreview}>
        <IconButton onClick={onCloseImgPreview} data-testid="close-button" className="custom-close-btn">
          <CloseIcon />
        </IconButton>
        <Box component="img" src={nftInfo?.url} alt="NFT image" className="preview-image" />
      </Dialog>
    </Dialog>
  );
};

export default MintOnPurchaseDialog;
