import { useCallback, useEffect, useRef, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import AuthLayout from '../auth-layout';
import CreateOrganizationDialog from '../create-organization-dialog';
import ScreenLoading from '../screen-loading';

import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { GetMeDocument, useCreateOrganizationMutation, User } from '~/graphql/member/types';
import { useLogout } from '~/hooks/use-logout';

const useStyles = makeStyles()((theme) => ({
  wrapButtons: {
    marginTop: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
    flexWrap: 'wrap-reverse',
  },
}));
interface Props {
  user: User;
}

const CreateFirstOrganization: React.FC<Props> = (props) => {
  const { user } = props;
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const isCalled = useRef(false);
  const [openCreateOrganizationDialog, setOpenCreateOrganizationDialog] = useState(false);
  const [createOrganization] = useCreateOrganizationMutation({
    refetchQueries: [GetMeDocument],
  });

  const logout = useLogout();

  const onCloseCreateOrganizationDialog = useCallback(() => {
    setOpenCreateOrganizationDialog(false);
  }, []);

  const onOpenCreateOrganizationDialog = useCallback(async () => {
    setOpenCreateOrganizationDialog(true);
  }, []);

  useEffect(() => {
    if (!isCalled.current) {
      isCalled.current = true;
      createOrganization({
        variables: {
          input: {
            name: `${user.displayName}'s organization`,
            contactEmail: user.email,
          },
        },
      }).catch((e) => enqueueSnackbar(e.message, { variant: 'error' }));
    }
  }, [createOrganization, enqueueSnackbar, user]);

  return <ScreenLoading />;

  // return (
  //   <AuthLayout headerTitle={t('create_your_first_organization')}>
  //     <div>
  //       <img width={100} src="/images/gu-logo.svg" alt="" />
  //     </div>
  //     <Typography variant="body1" color="textPrimary" component="p">
  //       {t('you_need_to_create_organization_before_continue')}
  //     </Typography>
  //     <div className={classes.wrapButtons}>
  //       <Button
  //         component={RouterLink}
  //         to={AppRouteEnum.SignIn}
  //         color="primary"
  //         variant="outlined"
  //         startIcon={<ArrowBackIcon />}
  //         onClick={logout}
  //       >
  //         {t('logout')}
  //       </Button>
  //       <Button variant="contained" onClick={onOpenCreateOrganizationDialog}>
  //         {t('create')}
  //       </Button>
  //       <CreateOrganizationDialog open={openCreateOrganizationDialog} onClose={onCloseCreateOrganizationDialog} />
  //     </div>
  //   </AuthLayout>
  // );
};

export default CreateFirstOrganization;
