import { GridSortDirection } from '@mui/x-data-grid-pro';

import {
  CollectionImageList,
  CollectionList,
  MyShopCollectionImageList,
  MyShopCollectionNFTList,
  SalesCollectionsList,
  ShopAndMemberSiteList,
} from '~/enum/tableFields';
import {
  CollectionImagesQueryKey,
  CollectionQueryKey,
  MemberMyShopCollectionImagesQueryKey,
  MyShopCollectionQueryKey,
  MyShopQueryKey,
  OrderBy,
} from '~/graphql/member/types';

export const OrderByGrid = {
  asc: OrderBy.Asc,
  desc: OrderBy.Desc,
};

export const ReverseOrderByGrid: { [key in OrderBy]: GridSortDirection } = {
  [OrderBy.Asc]: 'asc',
  [OrderBy.Desc]: 'desc',
};
export const CollectionRow = {
  [CollectionList.NAME]: CollectionQueryKey.Name,
  [CollectionList.SYMBOL]: CollectionQueryKey.Symbol,
  [CollectionList.NETWORK]: CollectionQueryKey.Network,
  [CollectionList.CREATED_AT]: CollectionQueryKey.CreatedAt,
  [CollectionList.OWNER_ADDRESS]: CollectionQueryKey.OwnerAddress,
  [CollectionList.CONTRACT_ADDRESS]: CollectionQueryKey.ContractAddress,
};

export const ShopListRow = {
  [ShopAndMemberSiteList.NAME]: MyShopQueryKey.Title,
  [ShopAndMemberSiteList.PUBLISH]: MyShopQueryKey.Publish,
  [ShopAndMemberSiteList.CREATED_AT]: MyShopQueryKey.CreatedAt,
};
export const MembersiteListRow = {
  [ShopAndMemberSiteList.NAME]: MyShopQueryKey.Title,
  [ShopAndMemberSiteList.PUBLISH]: MyShopQueryKey.Publish,
  [ShopAndMemberSiteList.CREATED_AT]: MyShopQueryKey.CreatedAt,
};

export const ReverseCollectionRow = {
  NAME: MyShopCollectionNFTList.NAME,
  PRICE: MyShopCollectionNFTList.PRICE,
  STATUS: MyShopCollectionNFTList.STATUS,
  TOKEN_ID: MyShopCollectionNFTList.TOKEN_ID,
  OWNER: MyShopCollectionNFTList.OWNER_ADDRESS,
  CREATED_AT: MyShopCollectionNFTList.CREATED_AT,
};

export const SalesCollection = {
  [SalesCollectionsList.NAME]: MyShopCollectionQueryKey.Name,
  [SalesCollectionsList.SYMBOL]: MyShopCollectionQueryKey.CollectionSymbol,
  [SalesCollectionsList.NETWORK]: MyShopCollectionQueryKey.CollectionNetwork,
  [SalesCollectionsList.CREATED_AT]: MyShopCollectionQueryKey.CollectionCreatedAt,
  [SalesCollectionsList.OWNER_ADDRESS]: MyShopCollectionQueryKey.CollectionOwnerAddress,
  [SalesCollectionsList.CONTRACT_ADDRESS]: MyShopCollectionQueryKey.CollectionContractAddress,
};

export const ReverseSalesCollection = {
  [MyShopCollectionQueryKey.Name]: SalesCollectionsList.NAME,
  [MyShopCollectionQueryKey.CollectionSymbol]: SalesCollectionsList.SYMBOL,
  [MyShopCollectionQueryKey.CollectionNetwork]: SalesCollectionsList.NETWORK,
  [MyShopCollectionQueryKey.CollectionCreatedAt]: SalesCollectionsList.CREATED_AT,
  [MyShopCollectionQueryKey.CollectionOwnerAddress]: SalesCollectionsList.OWNER_ADDRESS,
  [MyShopCollectionQueryKey.CollectionContractAddress]: SalesCollectionsList.CONTRACT_ADDRESS,
};

export const MyShopCollectionNFT = {
  [MyShopCollectionNFTList.NAME]: 'NAME',
  [MyShopCollectionNFTList.PRICE]: 'PRICE',
  [MyShopCollectionNFTList.STATUS]: 'STATUS',
  [MyShopCollectionNFTList.TOKEN_ID]: 'TOKEN_ID',
  [MyShopCollectionNFTList.OWNER_ADDRESS]: 'OWNER',
  [MyShopCollectionNFTList.CREATED_AT]: 'CREATED_AT',
};

export const ReverseMyShopCollectionNFT = {
  NAME: MyShopCollectionNFTList.NAME,
  PRICE: MyShopCollectionNFTList.PRICE,
  STATUS: MyShopCollectionNFTList.STATUS,
  TOKEN_ID: MyShopCollectionNFTList.TOKEN_ID,
  OWNER: MyShopCollectionNFTList.OWNER_ADDRESS,
  CREATED_AT: MyShopCollectionNFTList.CREATED_AT,
};

export const MyShopCollectionImage = {
  [MyShopCollectionImageList.MAX_MINT]: MemberMyShopCollectionImagesQueryKey.MaxMint,
  [MyShopCollectionImageList.ORIGINAL_NAME]: MemberMyShopCollectionImagesQueryKey.Name,
  [MyShopCollectionImageList.CREATED_AT]: MemberMyShopCollectionImagesQueryKey.CreatedAt,
  [MyShopCollectionImageList.CURRENT_MINT]: MemberMyShopCollectionImagesQueryKey.CurrentMint,
  [MyShopCollectionImageList.NAME]: MemberMyShopCollectionImagesQueryKey.ShopCollectionImageName,
  [MyShopCollectionImageList.PRICE]: MemberMyShopCollectionImagesQueryKey.ShopCollectionImagePrice,
  [MyShopCollectionImageList.STATUS]: MemberMyShopCollectionImagesQueryKey.ShopCollectionImageStatus,
};
export const MyCollectionImage = {
  [CollectionImageList.NAME]: CollectionImagesQueryKey.MaxMint,
  [CollectionImageList.MAX_MINT]: CollectionImagesQueryKey.Name,
  [CollectionImageList.CURRENT_MINT]: CollectionImagesQueryKey.CurrentMint,
};

export const ReverseMyShopCollectionImage = {
  [MemberMyShopCollectionImagesQueryKey.MaxMint]: MyShopCollectionImageList.MAX_MINT,
  [MemberMyShopCollectionImagesQueryKey.Name]: MyShopCollectionImageList.ORIGINAL_NAME,
  [MemberMyShopCollectionImagesQueryKey.CreatedAt]: MyShopCollectionImageList.CREATED_AT,
  [MemberMyShopCollectionImagesQueryKey.CurrentMint]: MyShopCollectionImageList.CURRENT_MINT,
  [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageName]: MyShopCollectionImageList.NAME,
  [MemberMyShopCollectionImagesQueryKey.ShopCollectionImagePrice]: MyShopCollectionImageList.PRICE,
  [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageStatus]: MyShopCollectionImageList.STATUS,
};
