import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { ERC721G__factory } from '@gu-corp/gu-token-studio-contracts';
import { WalletConnectorDialog } from '@gusdk/gu-wallet-connector';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { JsonRpcProvider } from 'ethers';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import ContentCollectionDetail from './ContentCollectionDetail';

import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import HomeBtn from '~/components/home-btn';
import LinkButton from '~/components/link-button';
import LoaderCenter from '~/components/loader-center';
import { ShopDetailContext, ShopDetailContextValue } from '~/contexts/ShopDetailWrapper';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import {
  Collection,
  Exact,
  GetMyShopCollectionByCollectionIdQuery,
  GetMyShopDocument,
  MyShopCollectionFilter,
  useCancelPublishShopMutation,
  useGetAllMyShopCollectionsContainNftLazyQuery,
  useGetAllMyShopCollectionsInPreMintLazyQuery,
  useGetMyShopCollectionByCollectionIdQuery,
  useGetPublishShopQuery,
  usePublishShopMutation,
  useUnAttachMyShopCollectionsMutation,
} from '~/graphql/member/types';
import { _SubgraphErrorPolicy_ } from '~/graphql/subgraph/types';
import { useGrantMintPermission } from '~/hooks/useListingCollection';
import { useNotify } from '~/hooks/useNotify';
import { GRANT_PERMISSION, INftInfo, MEMBER_SITE_NFT_STATUS, ShopDetailTab } from '~/types/my-shop';
import { ERROR_CODE } from '~/utils/not-found';

export interface NFTStatus {
  [key: string]: MEMBER_SITE_NFT_STATUS;
}

export interface ShopCollectionDetailContextValue {
  data: GetMyShopCollectionByCollectionIdQuery[keyof GetMyShopCollectionByCollectionIdQuery];
  loading: boolean;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            input: MyShopCollectionFilter;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetMyShopCollectionByCollectionIdQuery>>;
}

export const ShopCollectionDetailContext = createContext<ShopCollectionDetailContextValue | null>(null);

export interface ICollectionInfo extends Collection {
  status?: GRANT_PERMISSION;
  myShopCollectionName?: string | null;
}

const useStyles = makeStyles()(() => ({
  wrapperAlert: {
    width: 'calc(100% + 48px)',
    margin: '-24px 0 0 -24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '.MuiAlert-root': {
      color: '#00000099',
      backgroundColor: 'white',
      '.MuiAlert-action': {
        padding: 0,
      },
    },
  },
}));

const ShopCollectionDetail = () => {
  const myShopData = useContext(ShopDetailContext) as ShopDetailContextValue;
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { showError } = useNotify();
  const { enqueueSnackbar } = useSnackbar();

  const { id: shopId, collectionId } = useParams();

  const [open, setOpen] = useState(false);
  const [tokenList, setTokenList] = useState<INftInfo[]>();
  const [openWalletConnector, setOpenWalletConnector] = useState(false);
  const [collectionInfo, setCollectionInfo] = useState<ICollectionInfo>();
  const [loadingCollectionInfo, setLoadingCollectionInfo] = useState<boolean>(true);
  const [openRemoveCollectionDialog, setOpenRemoveCollectionDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [publishShop] = usePublishShopMutation();
  const [cancelPublishShop] = useCancelPublishShopMutation();
  const [unattachCollectionShop] = useUnAttachMyShopCollectionsMutation({
    refetchQueries: [GetMyShopDocument],
  });
  // const [handleRemoveApproveTransfer] = useRemoveApproveTransferMutation();
  const [getAllMyShopCollectionsInPreMint] = useGetAllMyShopCollectionsInPreMintLazyQuery();
  const [getAllMyShopCollectionsContainNFT] = useGetAllMyShopCollectionsContainNftLazyQuery();
  const { data: publishShopResponse, refetch: refetchPublishShop } = useGetPublishShopQuery({
    fetchPolicy: 'no-cache',
    variables: {
      myShopUuid: shopId ?? '',
    },
  });

  const [handleGrantPermission] = useGrantMintPermission({
    setOpenWalletConnector,
  });

  const {
    data: myShopCollectionRes,
    loading: loadingMyShopCollection,
    refetch: refetchMyShopCollection,
  } = useGetMyShopCollectionByCollectionIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        collectionUuid: collectionId!,
        shopUuid: shopId!,
      },
    },
    onError: (err) => {
      if (Object.values(err)?.[0]?.[0]?.extensions?.code === ERROR_CODE.NOT_FOUND) {
        navigate(AppRouteEnum.MyShop);
      }
    },
  });

  const myShopCollectionData = useMemo(
    () => myShopCollectionRes?.getMyShopCollectionByCollectionId,
    [myShopCollectionRes?.getMyShopCollectionByCollectionId]
  );

  // Handle Wallet Connector
  const handleCloseWalletConnector = () => {
    setOpenWalletConnector(false);
  };

  // Handle Remove Collection Dialog
  const handleOpenRemoveCollectionDialog = () => {
    setOpenRemoveCollectionDialog(true);
  };
  const onCloseRemoveMyShopDialog = useCallback(async () => {
    setOpenRemoveCollectionDialog(false);
  }, []);

  const onUnattachCollection = useCallback(async () => {
    try {
      // if (myShopData.data.siteSetting?.category === NFT_SHOP_TYPE.PRE_MINT) {
      //   const listMyShopCollectionsInPreMintResponse = await getAllMyShopCollectionsInPreMint({
      //     fetchPolicy: 'no-cache',
      //     variables: {
      //       collectionUuid: collectionInfo?.uuid ?? '',
      //     },
      //   });
      //   const listMyShopCollectionsInPreMint =
      //     listMyShopCollectionsInPreMintResponse?.data?.getAllMyShopCollectionsInPreMint;
      //   if (!!listMyShopCollectionsInPreMintResponse?.error) {
      //     throw new Error(t('my_shop.message.error'));
      //   } else {
      //     if (
      //       listMyShopCollectionsInPreMint?.length === 1 &&
      //       !listMyShopCollectionsInPreMintResponse?.error &&
      //       collectionInfo
      //     ) {
      //       try {
      //         const grantSuccessfully = await handleGrantPermission(collectionInfo, false);
      //         if (!grantSuccessfully) {
      //           return;
      //         }
      //       } catch {
      //         return;
      //       }
      //     }
      //   }
      // } else {
      //   let revokedTokenIdsList: string[] = [];
      //   for (let token of tokenList || []) {
      //     const listMyShopCollectionsContainNFTResponse = await getAllMyShopCollectionsContainNFT({
      //       fetchPolicy: 'no-cache',
      //       variables: {
      //         collectionUuid: collectionInfo?.uuid ?? '',
      //         tokenId: token.tokenId ?? '',
      //       },
      //     });
      //     const listMyShopCollectionsContainNFT =
      //       listMyShopCollectionsContainNFTResponse.data?.getAllMyShopCollectionsContainNFT;
      //     if (listMyShopCollectionsContainNFT?.length === 1) {
      //       revokedTokenIdsList.push(token.tokenId ?? '');
      //     }
      //   }
      //   if (revokedTokenIdsList.length > 0) {
      //     const removeApproveRes = await handleRemoveApproveTransfer({
      //       variables: {
      //         input: {
      //           collectionUuid: collectionInfo?.uuid ?? '',
      //           tokenIds: revokedTokenIdsList,
      //         },
      //       },
      //     });
      //     const uniqueErrorCodes = Array.from(
      //       new Set(
      //         removeApproveRes.data?.removeApproveTransfer.filter((item) => !item.success).map((item) => item.errorCode)
      //       )
      //     );
      //     if (uniqueErrorCodes?.length !== 0) {
      //       uniqueErrorCodes?.forEach((errorCode) => {
      //         enqueueSnackbar(
      //           errorCode === STATUS_CODE.UNPREDICTABLE_GAS_LIMIT
      //             ? t('toast_message.deposit_not_enough')
      //             : t('my_shop.message.error'),
      //           { variant: 'error' }
      //         );
      //       });
      //       return;
      //     }
      //   }
      // }
      await unattachCollectionShop({
        variables: {
          input: {
            shopUuid: shopId || '',
            collectionUuids: [collectionId || ''],
          },
        },
      });
      navigate(AppRouteEnum.ShopDetail.replace(/:id/g, shopId || ''));
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shopId,
    myShopData,
    collectionId,
    collectionInfo,
    myShopCollectionData?.uuid,
    t,
    navigate,
    enqueueSnackbar,
    handleGrantPermission,
    unattachCollectionShop,
    // handleRemoveApproveTransfer,
    getAllMyShopCollectionsInPreMint,
    getAllMyShopCollectionsContainNFT,
  ]);

  const handleOpenRemoveDialog = () => {
    setOpen(true);
  };

  const handleCloseRemoveDialog = async () => {
    setOpen(false);
  };

  const handlePublish = async () => {
    setIsSubmitting(true);
    try {
      await publishShop({ variables: { myShopUuid: shopId ?? '' } });
      await myShopData.refetch({ myShopUuid: shopId ?? '' });
      await refetchPublishShop({ myShopUuid: shopId ?? '' });
      setIsSubmitting(false);
      enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(t('my_shop.message.error'), { variant: 'error' });
    }
  };

  const handleCancel = async () => {
    try {
      await cancelPublishShop({ variables: { myShopUuid: shopId || '' } });
      await myShopData.refetch({ myShopUuid: shopId ?? '' });
      await refetchMyShopCollection({ input: { collectionUuid: collectionId!, shopUuid: shopId! } });
    } catch (err: any) {
      showError(err);
    }
  };

  useEffect(() => {
    return () => {
      myShopData.refetch({ myShopUuid: shopId ?? '' });
    };
  }, [shopId, myShopData]);

  useEffect(() => {
    if (!loadingMyShopCollection && myShopCollectionData) {
      (async () => {
        try {
          setLoadingCollectionInfo(true);
          const collectionInfoRes = myShopCollectionData.collection!;
          const provider = new JsonRpcProvider(supportedNetworks?.[collectionInfoRes.network]?.rpcUrl);
          const contractWithoutSigner = ERC721G__factory.connect(collectionInfoRes.contractAddress, provider as any);
          const owner = await contractWithoutSigner.owner();
          setCollectionInfo({
            ...collectionInfoRes,
            ownerAddress: owner,
            myShopCollectionName: myShopCollectionData.name,
          });
          setLoadingCollectionInfo(false);
        } catch (err: any) {
          showError(err);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMyShopCollection]);

  return (
    <>
      {myShopData.data.hasUpdate && (
        <Box className={classes.wrapperAlert} width="100%">
          <Alert
            icon={false}
            variant="filled"
            action={
              <>
                {!!publishShopResponse?.getPublishShop && (
                  <Button variant="text" color="error" onClick={handleOpenRemoveDialog} disabled={isSubmitting}>
                    {t('cancel')}
                  </Button>
                )}
                <Button
                  variant="text"
                  onClick={handlePublish}
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                >
                  {t('publish')}
                </Button>
              </>
            }
          >
            {t('toast_message.there_is_an_update_shop')}
          </Alert>
        </Box>
      )}
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Link
          component={RouterLink}
          to={AppRouteEnum.MyShop}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t('my_shop.shops')}
        </Link>
        <Link
          component={RouterLink}
          to={AppRouteEnum.ShopDetail.replace(/:id/g, shopId || '')}
          color="text.primary"
          underline="hover"
          style={{ wordBreak: 'break-all' }}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {myShopData?.data?.siteSetting?.title ?? ''}
        </Link>
        <Typography
          style={{ wordBreak: 'break-all' }}
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.secondary"
        >
          {collectionInfo?.myShopCollectionName || collectionInfo?.name || '...'}
        </Typography>
      </Breadcrumbs>
      <LinkButton
        to={`${AppRouteEnum.ShopDetail.replace(/:id/g, shopId || '')}?tab=${ShopDetailTab.COLLECTION}`}
        startIcon={<ArrowBackIcon />}
      >
        {t('back')}
      </LinkButton>
      {loadingCollectionInfo ? (
        <LoaderCenter />
      ) : (
        <>
          <ShopCollectionDetailContext.Provider
            value={{
              data: myShopCollectionData!,
              loading: loadingMyShopCollection,
              refetch: refetchMyShopCollection,
            }}
          >
            <ConfirmationDialog
              open={openRemoveCollectionDialog}
              title={t('my_shop.message.remove_shop_message')}
              content={t('my_shop.message.confirm_remove_shop')}
              confirmTitle={t('remove')}
              onClose={onCloseRemoveMyShopDialog}
              onConfirm={onUnattachCollection}
            />
            <ContentCollectionDetail
              tokenList={tokenList}
              collectionInfo={collectionInfo}
              setTokenList={setTokenList}
              onOpenRemoveCollectionDialog={handleOpenRemoveCollectionDialog}
            />
            <WalletConnectorDialog open={openWalletConnector} onClose={handleCloseWalletConnector} />
            <ConfirmationDialog
              open={open}
              confirmTitle={t('confirm')}
              title={t('cancel_publish_title')}
              content={t('cancel_publish_shop_desc')}
              onConfirm={handleCancel}
              onClose={handleCloseRemoveDialog}
            />
          </ShopCollectionDetailContext.Provider>
        </>
      )}
    </>
  );
};

export default ShopCollectionDetail;
