import { FC, ReactNode } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { makeStyles } from 'tss-react/mui';

interface WrapperWithFabProps {
  children: ReactNode;
  hidden?: Boolean;
  onClick: () => void;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    paddingBottom: '60px',
  },
  fab: {
    position: 'fixed',
    right: '16px',
    bottom: '16px',
  },
}));

const WrapperWithFab: FC<WrapperWithFabProps> = ({ children, onClick, hidden }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper}>
      {children}
      {!hidden && (
        <Fab className={classes.fab} color="primary" onClick={onClick}>
          <AddIcon />
        </Fab>
      )}
    </Box>
  );
};

export default WrapperWithFab;
