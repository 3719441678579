import { FC, useContext } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { Control, Controller, FieldErrorsImpl, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import ImageUploadInput from '../image-upload-input';
import TextEditor from '../TextEditor';

import { FormValues } from '.';

import { MAX_LENGTH } from '~/constants/common';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import { getErrorText } from '~/utils/yup.util';

interface CreateCollectionFormProps {
  control: Control<FormValues, any>;
  isSubmitting: boolean;
  errors: Partial<
    FieldErrorsImpl<{
      name: string;
      desc: string;
      descJa: string;
      symbol: string;
      storage: string;
      network: string;
      images: string[];
      imageFiles: FileList;
      ownerAddress: string;
      contractAddress: string;
      option: NonNullable<NonNullable<'create' | 'import' | undefined>>;
    }>
  >;
  watch: UseFormWatch<FormValues>;
}

const useStyles = makeStyles()(() => ({
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const CreateCollectionForm: FC<CreateCollectionFormProps> = ({ control, isSubmitting, errors, watch }) => {
  const supportedNetworksContext = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;
  const supportedNetworks = supportedNetworksContext?.supportedNetworks;

  const { classes } = useStyles();
  const { t } = useTranslation();

  const option = watch('option');

  return (
    <>
      <Controller
        name="option"
        control={control}
        render={({ field }) => (
          <FormControl disabled={isSubmitting} fullWidth>
            <RadioGroup row {...field} className={classes.radioGroup}>
              <FormControlLabel value="create" control={<Radio />} label={t('create_new')} />
              <FormControlLabel value="import" control={<Radio />} label={t('import_exist_address')} />
            </RadioGroup>
          </FormControl>
        )}
      />
      {option === 'import' ? (
        <>
          <Controller
            key="contractAddress"
            name="contractAddress"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                required
                label={t('contract_address')}
                disabled={isSubmitting}
                variant="outlined"
                margin="normal"
                error={!!errors.contractAddress?.message}
                helperText={t(errors.contractAddress?.message as any)}
                {...field}
              />
            )}
          />
          <TextEditor
            name="desc"
            control={control}
            heightEditor={47}
            maxLength={MAX_LENGTH}
            readOnly={isSubmitting}
            label={t('description_en')}
            error={errors.desc?.message}
            sx={{
              marginTop: '23px',
            }}
          />
          <TextEditor
            name="descJa"
            control={control}
            heightEditor={47}
            maxLength={MAX_LENGTH}
            readOnly={isSubmitting}
            label={t('description_ja')}
            error={errors.descJa?.message}
            sx={{
              marginTop: '23px',
            }}
          />
        </>
      ) : (
        <>
          <Controller
            key="name"
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                required
                fullWidth
                margin="normal"
                variant="outlined"
                label={t('collection_name')}
                disabled={isSubmitting}
                error={!!errors.name?.message}
                helperText={getErrorText(errors.name?.message, t)}
                {...field}
              />
            )}
          />
          <Controller
            key="ownerAddress"
            name="ownerAddress"
            control={control}
            render={({ field }) => (
              <TextField
                required
                fullWidth
                margin="normal"
                variant="outlined"
                disabled={isSubmitting}
                label={t('owner_address')}
                error={!!errors.ownerAddress?.message}
                helperText={getErrorText(errors.ownerAddress?.message, t)}
                {...field}
              />
            )}
          />
          <Controller
            key="symbol"
            name="symbol"
            control={control}
            render={({ field }) => (
              <TextField
                required
                fullWidth
                label={t('symbol')}
                variant="outlined"
                margin="normal"
                disabled={isSubmitting}
                error={!!errors.symbol?.message}
                helperText={getErrorText(errors.symbol?.message, t)}
                {...field}
              />
            )}
          />
          <TextEditor
            name="desc"
            control={control}
            heightEditor={47}
            maxLength={MAX_LENGTH}
            readOnly={isSubmitting}
            label={t('description_en')}
            error={errors.desc?.message}
            sx={{
              marginTop: '23px',
            }}
          />
          <TextEditor
            name="descJa"
            control={control}
            heightEditor={47}
            maxLength={MAX_LENGTH}
            readOnly={isSubmitting}
            label={t('description_ja')}
            error={errors.descJa?.message}
            sx={{
              marginTop: '23px',
            }}
          />
          <Controller
            key="storage"
            name="storage"
            control={control}
            render={({ field }) => (
              <TextField
                select
                label={t('storage_for_data')}
                fullWidth
                required
                margin="normal"
                disabled={isSubmitting}
                error={!!errors.storage?.message}
                helperText={t(errors.storage?.message as any)}
                {...field}
              >
                {storages.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </>
      )}
      <Controller
        key="network"
        name="network"
        control={control}
        render={({ field }) => (
          <TextField
            select
            required
            label={t('network')}
            fullWidth
            margin="normal"
            disabled={isSubmitting}
            error={!!errors.network?.message}
            helperText={t(errors.network?.message as any)}
            {...field}
          >
            {supportedNetworks
              ? Object.values(supportedNetworks).map((option) => (
                  <MenuItem key={option?.chainId} value={option?.chainId}>
                    {option?.name}
                  </MenuItem>
                ))
              : []}
          </TextField>
        )}
      />
      <Box marginTop="16px">
        <ImageUploadInput<FormValues>
          required
          type="image"
          height="156px"
          name="imageFiles"
          control={control}
          label={t('image')}
          sx={{ margin: 0 }}
          disabled={isSubmitting}
          defaultImageUrlName="imageMock"
          error={!!errors.imageFiles?.message}
          helperText={t(errors.imageFiles?.message as any, {
            size: 10,
          })}
        />
      </Box>
    </>
  );
};

const storages = [
  {
    value: 'ipfs',
    label: 'Ipfs',
  },
];

export default CreateCollectionForm;
