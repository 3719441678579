import { FC, useContext, useEffect, useState } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { JsonRpcProvider, formatEther } from 'ethers';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import DialogCloseButton from '~/components/dialog/dialog-close-button';
import { MIN_DEPOSIT } from '~/constants/common';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import {
  MyShopCollectionQueryKey,
  QueryOperator,
  useGetMyShopLazyQuery,
  useListMyShopCollectionsLazyQuery,
} from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';

interface ConditionPublishProps extends DialogProps {
  onClose: () => void;
  onPassed: (newValue: boolean) => void;
}

export enum ConditionPublishEnum {
  STRIPE_NOT_ENTERED = 'STRIPE_NOT_ENTERED',
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  NOT_ATTACH_COLLECTION = 'NOT_ATTACH_COLLECTION',
}

const errorsList = (t: TFunction): Record<ConditionPublishEnum, { title: string; value: ConditionPublishEnum }> => ({
  [ConditionPublishEnum.STRIPE_NOT_ENTERED]: {
    title: t('my_shop.message.fincode_or_other_information_not_entered'),
    value: ConditionPublishEnum.STRIPE_NOT_ENTERED,
  },
  [ConditionPublishEnum.NOT_ATTACH_COLLECTION]: {
    title: t('my_shop.message.not_attach_any_collection'),
    value: ConditionPublishEnum.NOT_ATTACH_COLLECTION,
  },
  [ConditionPublishEnum.INSUFFICIENT_BALANCE]: {
    title: t('my_shop.message.deposit_is_sufficient'),
    value: ConditionPublishEnum.INSUFFICIENT_BALANCE,
  },
});

enum STATUS_CHECK {
  LOADING = 'LOADING',
  PASSED = 'PASSED',
  ERROR = 'ERROR',
}

const initCondition = {
  [ConditionPublishEnum.STRIPE_NOT_ENTERED]: STATUS_CHECK.LOADING,
  [ConditionPublishEnum.NOT_ATTACH_COLLECTION]: STATUS_CHECK.LOADING,
  [ConditionPublishEnum.INSUFFICIENT_BALANCE]: STATUS_CHECK.LOADING,
};

const ConditionPublish: FC<ConditionPublishProps> = ({ onClose, onPassed, ...others }) => {
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;

  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedOrganization } = useAccount();

  const [status, setStatus] = useState(initCondition);

  const [getMyShop, { refetch: refetchMyShop }] = useGetMyShopLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      myShopUuid: id || '',
    },
  });
  const [listMyShopCollections, { refetch: refetchListCollections }] = useListMyShopCollectionsLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        fields: [
          {
            value: [id ?? ''],
            operator: QueryOperator.Contains,
            key: MyShopCollectionQueryKey.ShopUuid,
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (others.open && id) {
      (async () => {
        try {
          let isError = false;
          const listMyShopCollectionRes = await refetchListCollections({
            where: {
              fields: [
                {
                  value: [id ?? ''],
                  operator: QueryOperator.Contains,
                  key: MyShopCollectionQueryKey.ShopUuid,
                },
              ],
            },
          });
          const myShopCollections = listMyShopCollectionRes.data?.listMyShopCollections.items;
          const myShopCollectionsLength = myShopCollections?.length || 0;
          isError = isError || !(myShopCollectionsLength > 0);
          setStatus((newState) => ({
            ...newState,
            [ConditionPublishEnum.NOT_ATTACH_COLLECTION]:
              myShopCollectionsLength > 0 ? STATUS_CHECK.PASSED : STATUS_CHECK.ERROR,
          }));
          const paymentSetting = selectedOrganization.paymentSetting;
          isError = isError || !(!!paymentSetting?.publicKey && !!paymentSetting?.secretKey);
          setStatus((newState) => ({
            ...newState,
            [ConditionPublishEnum.STRIPE_NOT_ENTERED]:
              !!paymentSetting?.publicKey && !!paymentSetting?.secretKey ? STATUS_CHECK.PASSED : STATUS_CHECK.ERROR,
          }));
          const networksList = Array.from(new Set(myShopCollections.map((item) => item.collection?.network!)));
          const balanceAvailabilityStatus = await Promise.all(
            networksList.map(async (item) => {
              const provider = new JsonRpcProvider(supportedNetworks?.[item]?.rpcUrl);
              const balanceBigNumber = await provider.getBalance(selectedOrganization.masterWalletAddress!);
              const balance = formatEther(balanceBigNumber);
              return Number(balance) >= MIN_DEPOSIT;
            })
          );
          const depositCondition = balanceAvailabilityStatus.every((item) => item) && myShopCollectionsLength > 0;
          isError = isError || !depositCondition;
          setStatus((newState) => ({
            ...newState,
            [ConditionPublishEnum.INSUFFICIENT_BALANCE]: depositCondition ? STATUS_CHECK.PASSED : STATUS_CHECK.ERROR,
          }));
          await refetchMyShop({
            myShopUuid: id,
          });
          if (!isError) {
            onPassed(true);
          } else {
            enqueueSnackbar(t('toast_message.open_shop_unsuccessfully'), { variant: 'error' });
          }
        } catch (err: any) {}
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [others.open, id, selectedOrganization, t, onPassed, refetchMyShop, enqueueSnackbar, refetchListCollections]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setStatus(initCondition);
  };

  const disableClose = Object.values(status).includes(STATUS_CHECK.LOADING);

  return (
    <Dialog {...others} maxWidth="sm" fullWidth>
      {!disableClose && (
        <DialogCloseButton onClick={handleClose} data-testid="close-button">
          <CloseIcon />
        </DialogCloseButton>
      )}
      <DialogTitle>
        <Typography variant="h4" color="inherit" component="p" className="title">
          {t('my_shop.publish_condition')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {(Object.keys(status) as ConditionPublishEnum[]).map((condition) => (
          <Box key={condition} display="flex" gap="8px" marginBottom="8px">
            {status[condition] === STATUS_CHECK.LOADING ? (
              <CircularProgress size="20px" sx={{ marginRight: '4px' }} color="primary" />
            ) : status[condition] === STATUS_CHECK.PASSED ? (
              <CheckCircleOutlineIcon color="primary" />
            ) : status[condition] === STATUS_CHECK.ERROR ? (
              <ErrorOutlineIcon color="error" />
            ) : null}
            <Typography variant="subtitle2" color={status[condition] === STATUS_CHECK.ERROR ? 'error' : 'primary'}>
              {errorsList(t)[condition].title}
            </Typography>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default ConditionPublish;
