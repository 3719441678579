import { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { VIEW_MODE } from '../custom-grid-toolbar-search-by-api';
import EditNameDialog, { IEditNFTNameField } from '../dialog/edit-nft-name-dialog';
import { GalleryData } from '../gallery-list';
import { IconBtnCopy } from '../IconBtnCopy';
import ListTable from '../list-table/v2';
import SquareImage from '../SquareImage';

import { CHIP_COLOR, CURRENCY_ICONS } from '~/constants/common';
import { ShopDetailContext, ShopDetailContextValue } from '~/contexts/ShopDetailWrapper';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import { env } from '~/env';
import {
  GetMyShopDocument,
  ListMyShopCollectionTokensDocument,
  MyShopCollectionsTokensQueryKey,
  QueryOperator,
  useListMyShopCollectionTokensQuery,
  useSetMyShopCollectionTokenMutation,
  useUpdateMyShopCollectionMutation,
} from '~/graphql/member/types';
import { _SubgraphErrorPolicy_, TokenQuery, useTokensLazyQuery } from '~/graphql/subgraph/types';
import { useNotify } from '~/hooks/useNotify';
import NFTDialog from '~/pages/my-shop/shop-detail/components/CollectionDetail/NFTDialog';
import {
  ICollectionInfo,
  ShopCollectionDetailContext,
  ShopCollectionDetailContextValue,
} from '~/pages/my-shop/shop-detail/components/CollectionDetail/ShopCollectionDetail';
import EditImagePriceDialog from '~/pages/my-shop/shop-detail/components/EditImagePriceDialog';
import {
  collectionStatus,
  renderSelectEditInputCell,
} from '~/pages/my-shop/shop-detail/components/SalesCollection/SalesCollectionTab';
import { INftInfo, MetadataJson, NFT_STATUS, STATUS } from '~/types/my-shop';
import { verifyOrderKey, verifySortKey } from '~/utils/common';
import { getNFTMetadata } from '~/utils/getNFTMetadata';
import { truncateEthAddress } from '~/utils/string.utils';

interface ExistingNFTListProps {
  tokenList: INftInfo[] | undefined;
  collectionInfo: ICollectionInfo | undefined;
  onOpenAddNFT: () => void;
  setTokenList: Dispatch<SetStateAction<INftInfo[] | undefined>>;
}

export interface TableExistingNFT {
  id: string;
  url?: string;
  name?: string;
  uuid?: string;
  price?: number;
  nameJa?: string;
  tokenId?: string;
  createdAt?: string;
  description?: string;
  metadataUrl?: string;
  ownerAddress?: string;
  collectionUuid: string;
  metadataContent?: MetadataJson;
  [MyShopCollectionsTokensQueryKey.Name]: string;
  [MyShopCollectionsTokensQueryKey.Order]: number;
  [MyShopCollectionsTokensQueryKey.NameJa]: string;
  [MyShopCollectionsTokensQueryKey.Status]: string;
  [MyShopCollectionsTokensQueryKey.TokenId]: string;
  [MyShopCollectionsTokensQueryKey.TokenName]: string;
  [MyShopCollectionsTokensQueryKey.Price]: number | null;
  [MyShopCollectionsTokensQueryKey.TokenMintTime]: string;
}

interface IInitInfo {
  [key: string]: TableExistingNFT;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    '.MuiSelect-standard': {
      backgroundColor: 'transparent!important',
    },
    '.disabled': {
      opacity: 0.5,
    },
  },
  noPriceSet: {
    cursor: 'pointer',
    textDecoration: 'underline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const ExistingNFTList: FC<ExistingNFTListProps> = (props) => {
  const { collectionInfo, onOpenAddNFT } = props;
  const myShopData = useContext(ShopDetailContext) as ShopDetailContextValue;
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;
  const myShopCollectionData = useContext(ShopCollectionDetailContext) as ShopCollectionDetailContextValue;

  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showError } = useNotify();
  const { id: shopId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<TableExistingNFT[]>([]);

  const [isOpenNFTDialog, setIsOpenNFTDialog] = useState<boolean>(false);
  const [selectedToken, setSelectedToken] = useState<TableExistingNFT>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openEditCurrency, setOpenEditCurrency] = useState<boolean>(false);
  const [loadingTokensList, setLoadingTokensList] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [openEditNFTNameDialog, setOpenEditNFTNameDialog] = useState<boolean>(false);

  const [tokensQuery, setTokensQuery] = useState({
    searchText: '',
    sortBy: verifySortKey(
      MyShopCollectionsTokensQueryKey,
      myShopCollectionData?.data?.sortBy,
      MyShopCollectionsTokensQueryKey.TokenMintTime
    ),
    orderBy: verifyOrderKey(myShopCollectionData?.data?.orderBy),
    where: {
      fields: [
        {
          operator: QueryOperator.Equals,
          value: [myShopCollectionData?.data?.uuid ?? ''],
          key: MyShopCollectionsTokensQueryKey.MyshopCollectionUuid,
        },
      ],
    },
  });

  const updateTokensQuery = (newValue: any) => {
    setLoadingTokensList(true);
    setTokensQuery((value: any) => ({ ...value, ...newValue }));
  };

  const [getTokens, { loading: fetchingTokens }] = useTokensLazyQuery();
  const [updateMyShopCollection] = useUpdateMyShopCollectionMutation({
    refetchQueries: [GetMyShopDocument],
  });
  const [setMyShopCollectionToken] = useSetMyShopCollectionTokenMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionTokensDocument],
  });
  useListMyShopCollectionTokensQuery({
    fetchPolicy: 'cache-and-network',
    skip: !myShopCollectionData?.data?.uuid,
    variables: tokensQuery,
    onCompleted: async (data) => {
      setLoadingTokensList(true);
      const myShopCollectionTokens = data.listMyShopCollectionTokens.items;
      if (!myShopCollectionTokens) {
        setLoadingTokensList(false);
        return;
      }
      const initInfos: IInitInfo = {};
      const contractAddress = collectionInfo?.contractAddress;
      const tokenIdsList =
        myShopCollectionTokens?.reduce((result, token) => {
          if (token?.tokenId && contractAddress) {
            const idOfToken = `${contractAddress.toLowerCase()}_${token.tokenId}`;
            initInfos[token.tokenId] = {
              id: token.uuid,
              price: token.price!,
              collectionUuid: collectionInfo.uuid,
              name: token.name || token.tokenName!,
              nameJa: token.nameJa || token.tokenName!,
              [MyShopCollectionsTokensQueryKey.Order]: token.order!,
              [MyShopCollectionsTokensQueryKey.TokenId]: token.tokenId!,
              [MyShopCollectionsTokensQueryKey.TokenName]: token.tokenName!,
              [MyShopCollectionsTokensQueryKey.TokenMintTime]: token.tokenMintTime!,
              [MyShopCollectionsTokensQueryKey.Name]: token.name || token.tokenName!,
              [MyShopCollectionsTokensQueryKey.NameJa]: token.nameJa || token.tokenName!,
              [MyShopCollectionsTokensQueryKey.Price]: token.price!,
              [MyShopCollectionsTokensQueryKey.Status]:
                isNaN(Number(token.price ?? undefined)) && token.status === STATUS.NOW_ON_SALE
                  ? STATUS.SUSPENSION
                  : token?.status || STATUS.SUSPENSION,
            };
            result.push(idOfToken);
          }
          return result;
        }, [] as string[]) || [];
      const tokensList = await getTokens({
        fetchPolicy: 'no-cache',
        variables: {
          first: 1000,
          where: { id_in: tokenIdsList },
          subgraphError: _SubgraphErrorPolicy_.Deny,
        },
        context: {
          blockchain: 'subgraph',
          subgraphUrl: supportedNetworks[collectionInfo?.network || ''].subgraphUrl,
        },
      });
      const extraInfos = tokensList.data?.tokens.reduce((result, token) => {
        result[token.tokenID] = token;
        return result;
      }, {} as { [key: string]: TokenQuery['token'] });

      const newRows: TableExistingNFT[] = await Promise.all(
        myShopCollectionTokens?.map(async (token) => {
          const metadata = await getNFTMetadata(collectionInfo?.uuid!, extraInfos?.[token.tokenId!]);
          return {
            ...metadata,
            ...initInfos[token.tokenId!],
            contractAddress: metadata.ownerAddress,
            url: metadata.metadataContent.image.replace('ipfs://', env.REACT_APP_IPFS_GATEWAY_URL),
          };
        }) || []
      );
      setRows(newRows);
      setLoadingTokensList(false);
    },
  });

  // Handle Edit Price Dialog
  const onOpenPriceDialog = () => {
    setOpenEditCurrency(true);
  };
  const onClosePriceDialog = () => {
    setOpenEditCurrency(false);
  };

  // Handle Edit Collection Name Dialog
  const onOpenEditNFTName = () => {
    setAnchorEl(null);
    setOpenEditNFTNameDialog(true);
  };

  const onCloseEditCollectionName = () => {
    setOpenEditNFTNameDialog(false);
  };

  const initializeTokensPrice = useCallback(
    (id: string, callback?: (currentToken: TableExistingNFT) => void, newTokenList?: TableExistingNFT[]) => {
      const currentToken = (newTokenList || rows || []).find((item) => item.id === id);

      if (currentToken) {
        if (callback) {
          callback(currentToken);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, myShopCollectionData.loading]
  );

  const handleClickAction = useCallback(
    (row: TableExistingNFT): MouseEventHandler<HTMLButtonElement> =>
      (event) => {
        if (row[MyShopCollectionsTokensQueryKey.Status] !== STATUS.SOLD_OUT) {
          setAnchorEl(event.currentTarget);
          setSelectedToken(row);
        } else {
          enqueueSnackbar(t('my_shop.message.already_sold_out'), { variant: 'error' });
        }
      },
    [t, enqueueSnackbar]
  );

  const checkStatusNft = useCallback(
    (status: string) => {
      if (status === NFT_STATUS.ON_SALE) {
        return <Chip label={t('on_sale')} color="success" />;
      } else {
        return <Chip label={t('unsold')} color="error" />;
      }
    },
    [t]
  );

  const handleStatusRow = useCallback(
    async (tokenId: string, isOnSale: boolean = false) => {
      try {
        const newStatus = isOnSale ? STATUS.NOW_ON_SALE : STATUS.SUSPENSION;
        setLoadingTokensList(true);
        await setMyShopCollectionToken({
          variables: {
            input: {
              tokenId,
              myShopCollectionUuid: myShopCollectionData.data.uuid!,
              status: newStatus,
            },
          },
        });
        setSelectedToken((prevState) =>
          prevState
            ? {
                ...prevState,
                status: newStatus,
              }
            : undefined
        );
        onCloseNFTDialog();
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
      } catch (e: any) {
        showError(e);
        setLoadingTokensList(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shopId, myShopCollectionData?.data.uuid, t, enqueueSnackbar]
  );

  const onChangeStatusNft = useCallback(
    (item: TableExistingNFT) => {
      if (!item[MyShopCollectionsTokensQueryKey.Price] && item[MyShopCollectionsTokensQueryKey.Price] !== 0) {
        enqueueSnackbar(t('my_shop.message.need_set_price'), { variant: 'error' });
      } else if (item[MyShopCollectionsTokensQueryKey.Status] === STATUS.SOLD_OUT) {
        enqueueSnackbar(t('my_shop.message.already_sold_out'), { variant: 'error' });
      } else {
        handleStatusRow(
          item[MyShopCollectionsTokensQueryKey.TokenId],
          item[MyShopCollectionsTokensQueryKey.Status] === STATUS.SUSPENSION
        );
      }
    },
    [t, enqueueSnackbar, handleStatusRow]
  );

  const handleClickPrice = useCallback(
    (row: TableExistingNFT) => () => {
      if (row[MyShopCollectionsTokensQueryKey.Status] !== STATUS.SOLD_OUT) {
        initializeTokensPrice(row?.id || '', (currentToken) => {
          setSelectedToken(currentToken);
        });
        onOpenPriceDialog();
      } else {
        enqueueSnackbar(t('my_shop.message.already_sold_out'), { variant: 'error' });
      }
    },
    [t, enqueueSnackbar, initializeTokensPrice]
  );

  const existingColumns: GridColDef<TableExistingNFT>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value, row }) => {
          return (
            <Box width="64px">
              <SquareImage src={value} onClick={() => openNFTDialog(row.id)} />
            </Box>
          );
        },
      },
      {
        width: 100,
        editable: true,
        sortable: false,
        resizable: false,
        headerName: t('my_shop.order_index'),
        field: MyShopCollectionsTokensQueryKey.Order,
        renderEditCell: renderSelectEditInputCell,
      },
      {
        headerName: t('status'),
        width: JSON.parse(columnsSize).status || 125,
        field: MyShopCollectionsTokensQueryKey.Status,
        renderCell: ({ row }) => (
          <>
            {row[MyShopCollectionsTokensQueryKey.Status] === STATUS.SOLD_OUT ? (
              <Chip
                label={`${collectionStatus(t)[row[MyShopCollectionsTokensQueryKey.Status] as STATUS]?.title || ''}`}
                color={CHIP_COLOR[row[MyShopCollectionsTokensQueryKey.Status]]}
              />
            ) : (
              <Select
                variant="standard"
                disableUnderline
                fullWidth
                value={
                  row[MyShopCollectionsTokensQueryKey.Status] === STATUS.NOW_ON_SALE
                    ? NFT_STATUS.ON_SALE
                    : NFT_STATUS.UNSOLD
                }
                onChange={() => onChangeStatusNft(row)}
              >
                {Object.values(NFT_STATUS).map((status) => (
                  <MenuItem key={status} value={status}>
                    {checkStatusNft(status)}
                  </MenuItem>
                ))}
              </Select>
            )}
          </>
        ),
      },
      {
        align: 'left',
        headerAlign: 'left',
        headerName: t('price'),
        width: JSON.parse(columnsSize).price || 100,
        field: MyShopCollectionsTokensQueryKey.Price,
        renderCell({ row }: { row: TableExistingNFT }) {
          const baseCurrency = myShopData.data.paymentMethod?.baseCurrency;
          const basePrice = row[MyShopCollectionsTokensQueryKey.Price];
          return (
            <>
              {!!basePrice ? (
                `${baseCurrency ? CURRENCY_ICONS[baseCurrency] : ''} ${basePrice.toLocaleString('en-EN')}`
              ) : row[MyShopCollectionsTokensQueryKey.Price] === 0 ? (
                <Chip variant="filled" color="success" label={t('free')} />
              ) : (
                <Typography className={classes.noPriceSet} color="red" onClick={handleClickPrice(row)}>
                  {t('no_price_set')}
                </Typography>
              )}
            </>
          );
        },
      },
      {
        field: MyShopCollectionsTokensQueryKey.Name,
        headerName: t('my_shop.nft_caption_en'),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        field: MyShopCollectionsTokensQueryKey.NameJa,
        headerName: t('my_shop.nft_caption_ja'),
        width: JSON.parse(columnsSize).nameJa || 150,
      },
      {
        headerName: t('my_shop.nft_name'),
        field: MyShopCollectionsTokensQueryKey.TokenName,
        width: JSON.parse(columnsSize).originalName || 150,
      },
      {
        headerName: t('token_id'),
        width: JSON.parse(columnsSize).tokenId || 150,
        field: MyShopCollectionsTokensQueryKey.TokenId,
      },
      {
        sortable: false,
        field: 'ownerAddress',
        headerName: t('owner_address'),
        renderCell: ({ formattedValue }) => (
          <Box display="flex" alignItems="center">
            {formattedValue ? (
              <>
                {truncateEthAddress(formattedValue)}
                <IconBtnCopy text={formattedValue} />
              </>
            ) : (
              '-'
            )}
          </Box>
        ),
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).ownerAddress || 150,
      },
      {
        type: 'date',
        headerName: t('created_at'),
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).createdAt || 115,
        field: MyShopCollectionsTokensQueryKey.TokenMintTime,
        valueFormatter: ({ value }) => (value ? moment.unix(value).format(t('date_format')) : '-'),
      },
      {
        width: 70,
        headerName: '',
        sortable: false,
        type: 'actions',
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getActions: ({ row }) => [
          <>
            <IconButton onClick={handleClickAction(row)}>
              <MoreVertIcon />
            </IconButton>
          </>,
        ],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myShopData.data.paymentMethod?.baseCurrency, t, handleClickPrice, handleClickAction]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenEditPriceDialog = (item?: GalleryData) => {
    handleCloseMenu();
    onOpenPriceDialog();
  };

  const handleSort = async (value: any) => {
    const sortBy = value.sortBy;
    const orderBy = value.orderBy;
    updateTokensQuery({ sortBy, orderBy });
    if (sortBy !== myShopCollectionData?.data?.sortBy || orderBy !== myShopCollectionData?.data?.orderBy) {
      await updateMyShopCollection({
        variables: {
          input: { uuid: myShopCollectionData?.data?.uuid || '', sortBy, orderBy },
        },
      });
    }
  };

  // Handle NFT Dialog
  const openNFTDialog = useCallback(
    (id: string) => {
      setIsOpenNFTDialog(true);
      initializeTokensPrice(id, (currentToken) => {
        setSelectedToken(currentToken);
      });
    },
    [initializeTokensPrice]
  );

  const onCloseNFTDialog = () => {
    setIsOpenNFTDialog(false);
  };

  const handleEditNFTName = async (data: IEditNFTNameField) => {
    try {
      if (selectedToken) {
        setLoadingTokensList(true);
        await setMyShopCollectionToken({
          variables: {
            input: {
              ...data,
              myShopCollectionUuid: myShopCollectionData.data.uuid!,
              tokenId: selectedToken[MyShopCollectionsTokensQueryKey.TokenId],
            },
          },
        });
        setSelectedToken((newState) =>
          newState ? { ...newState, name: data.name || '', nameJa: data.nameJa || '' } : undefined
        );
        enqueueSnackbar(t('toast_message.update_caption_successfully'), { variant: 'success' });
        setOpenEditNFTNameDialog(false);
      }
    } catch (err: any) {
      showError(err);
      setLoadingTokensList(false);
    }
  };

  const onSavePriceNFT = useCallback(
    async (newPrice: number) => {
      setIsSubmitting(true);
      try {
        setLoadingTokensList(true);
        await setMyShopCollectionToken({
          variables: {
            input: {
              price: newPrice,
              myShopCollectionUuid: myShopCollectionData.data.uuid!,
              tokenId: selectedToken?.[MyShopCollectionsTokensQueryKey.TokenId]!,
            },
          },
        });
        onClosePriceDialog();
        setSelectedToken((prevState) => {
          if (!prevState) {
            return prevState;
          }
          return {
            ...prevState,
            price: newPrice,
            [MyShopCollectionsTokensQueryKey.Price]: newPrice,
          };
        });
        setIsSubmitting(false);
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
      } catch (e: any) {
        setIsSubmitting(false);
        setLoadingTokensList(false);
        showError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shopId, selectedToken, myShopCollectionData?.data.uuid, t]
  );

  const handleStatusInDialog = () => {
    if (!isNaN(Number(selectedToken?.[MyShopCollectionsTokensQueryKey.Price] ?? undefined))) {
      handleStatusRow(
        selectedToken?.[MyShopCollectionsTokensQueryKey.TokenId] || '',
        selectedToken?.[MyShopCollectionsTokensQueryKey.Status] === STATUS.SUSPENSION
      );
    } else if (selectedToken?.[MyShopCollectionsTokensQueryKey.Status] === STATUS.SUSPENSION) {
      enqueueSnackbar(t('my_shop.message.need_set_price'), { variant: 'error' });
    }
  };

  const handleStatus = () => {
    if (
      !!selectedToken?.[MyShopCollectionsTokensQueryKey.Price] ||
      selectedToken?.[MyShopCollectionsTokensQueryKey.Price] === 0
    ) {
      handleStatusRow(
        selectedToken?.[MyShopCollectionsTokensQueryKey.TokenId] || '',
        selectedToken?.[MyShopCollectionsTokensQueryKey.Status] === STATUS.SUSPENSION
      );
    } else if (selectedToken?.[MyShopCollectionsTokensQueryKey.Status] === STATUS.SUSPENSION) {
      enqueueSnackbar(t('my_shop.message.need_set_price'), { variant: 'error' });
    }
    setAnchorEl(null);
  };

  const handleProcessRowUpdate = async (newRow: TableExistingNFT, oldRow: TableExistingNFT) => {
    if (oldRow[MyShopCollectionsTokensQueryKey.Status] === STATUS.SOLD_OUT) {
      enqueueSnackbar(t('my_shop.message.already_sold_out'), { variant: 'error' });
      return oldRow;
    }
    if (newRow[MyShopCollectionsTokensQueryKey.Order] === oldRow[MyShopCollectionsTokensQueryKey.Order]) {
      return newRow;
    }
    setLoadingTokensList(true);
    await setMyShopCollectionToken({
      variables: {
        input: {
          myShopCollectionUuid: myShopCollectionData.data.uuid!,
          tokenId: newRow[MyShopCollectionsTokensQueryKey.TokenId],
          order: newRow[MyShopCollectionsTokensQueryKey.Order]
            ? parseFloat(newRow[MyShopCollectionsTokensQueryKey.Order]?.toString())
            : null,
        },
      },
    });
    onClosePriceDialog();
    return newRow;
  };

  const isLoading = fetchingTokens || loadingTokensList;

  return (
    <Box className={classes.wrapper}>
      <ListTable
        isMenu
        noBorder
        rows={rows}
        isShopScreen
        pagination={false}
        isLoading={isLoading}
        search={tokensQuery.searchText}
        tableName="shop_collection_existing_nft"
        onlyMode={VIEW_MODE.LIST}
        columns={existingColumns}
        disableRowSelectionOnClick
        noDataProps={{
          title: t('create_new_nft'),
          description: t('add_nft_to_minted_shop'),
          buttonTitle: t('add_nft'),
          onClick: onOpenAddNFT,
        }}
        onRowUpdate={handleProcessRowUpdate}
        searchLabel={t('my_shop.nft_name')}
        noRowsMessage={t('my_shop.message.no_nft')}
        onSearch={(v) => updateTokensQuery({ page: 1, searchText: v || '' })}
        getRowClassName={(params) =>
          params.row[MyShopCollectionsTokensQueryKey.Status] === STATUS.SOLD_OUT ? 'disabled' : ''
        }
        sort={{
          sortBy: tokensQuery.sortBy,
          orderBy: tokensQuery.orderBy,
        }}
        onSort={handleSort}
      />
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleCloseMenu}>
        <MenuItem
          onClick={() => {
            openNFTDialog(selectedToken?.id || '');
            setAnchorEl(null);
          }}
        >
          {t('my_shop.show_detail')}
        </MenuItem>
        <MenuItem onClick={() => handleOpenEditPriceDialog()}>{t('edit_price')}</MenuItem>
        <MenuItem onClick={onOpenEditNFTName}>{t('my_shop.edit_nft_name')}</MenuItem>
        <MenuItem onClick={handleStatus}>
          {selectedToken?.[MyShopCollectionsTokensQueryKey.Status] !== STATUS.SUSPENSION ? t('unsold') : t('on_sale')}
        </MenuItem>
      </Menu>
      <EditNameDialog
        open={openEditNFTNameDialog}
        title={t('my_shop.edit_nft_name')}
        defaultName={selectedToken?.[MyShopCollectionsTokensQueryKey.Name]!}
        defaultNameJa={selectedToken?.[MyShopCollectionsTokensQueryKey.NameJa]!}
        onEdit={handleEditNFTName}
        onClose={onCloseEditCollectionName}
      />
      <NFTDialog
        open={isOpenNFTDialog}
        isShopCollectionScreen
        nftInfo={selectedToken!}
        collectionInfo={collectionInfo!}
        onEditNFTName={handleEditNFTName}
        onOpenPriceDialog={onOpenPriceDialog}
        actions={[
          <Box sx={{ alignSelf: 'flex-end' }} key="1">
            <Button
              variant="outlined"
              disabled={
                isNaN(Number(selectedToken?.[MyShopCollectionsTokensQueryKey.Price] ?? undefined)) ||
                selectedToken?.[MyShopCollectionsTokensQueryKey.Status] === STATUS.SOLD_OUT
              }
              color={
                selectedToken?.[MyShopCollectionsTokensQueryKey.Status] === STATUS.SUSPENSION ? 'primary' : 'error'
              }
              onClick={handleStatusInDialog}
            >
              {selectedToken?.[MyShopCollectionsTokensQueryKey.Status] === STATUS.SOLD_OUT
                ? t('my_shop.sold_out')
                : selectedToken?.[MyShopCollectionsTokensQueryKey.Status] === STATUS.SUSPENSION
                ? t('on_sale')
                : t('unsold')}
            </Button>
          </Box>,
        ]}
        onClose={onCloseNFTDialog}
      />
      <EditImagePriceDialog
        rowData={selectedToken}
        isSubmitting={isSubmitting}
        isDialogOpen={openEditCurrency}
        onSavePrice={onSavePriceNFT}
        onCloseDialog={onClosePriceDialog}
      />
    </Box>
  );
};

export default ExistingNFTList;
