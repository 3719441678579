import { memo, useContext, useEffect, useMemo, useState } from 'react';

import { WalletConnectorDialog } from '@gusdk/gu-wallet-connector';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import {
  GridColDef,
  GridColumnResizeParams,
  GridInputRowSelectionModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import { VIEW_MODE } from '~/components/custom-grid-toolbar-search-by-api';
import ListTable, { ListTablePagination, SortItem } from '~/components/list-table/v2';
import SquareImage from '~/components/SquareImage';
import { ShopDetailContext, ShopDetailContextValue } from '~/contexts/ShopDetailWrapper';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import {
  CollectionQueryKey,
  ListCollectionIdsInShopQuery,
  ListCollectionsFilter,
  ListCollectionsQuery,
  ListMyShopCollectionsDocument,
  OrderBy,
  QueryOperator,
  useAttachMyShopCollectionsMutation,
  useListCollectionsLazyQuery,
  useUnAttachMyShopCollectionsMutation,
} from '~/graphql/member/types';
import { useGrantMintPermission } from '~/hooks/useListingCollection';
import { CollectionData } from '~/pages/collection';
import { GRANT_PERMISSION, MEMBER_SITE_NFT_STATUS, NFT_SHOP_TYPE, SalesMethodEnum, STATUS } from '~/types/my-shop';
import { truncateEthAddress } from '~/utils/string.utils';

interface AddCollectionDialogProps {
  open: boolean;
  rows: CollectionData[];
  loadingData: boolean;
  selectedCollections?: ListCollectionIdsInShopQuery['listMyShopCollections']['items'];
  onClose: () => void;

  pagination: ListTablePagination;
  queryData: {
    page?: number;
    limit?: number;
    orderBy?: OrderBy;
    searchText?: string;
    sortBy?: CollectionQueryKey;
    where?: ListCollectionsFilter;
  };
  updateQuery: (params: any) => void;
}

export interface ShopCollection {
  type?: string;
  collectionUuid: string;
}

const useStyles = makeStyles()(() => ({
  dialog: {
    '.MuiPaper-root': {
      width: '100%',
      height: '80vh',
      maxWidth: 1180,
      position: 'relative',
    },
    '.guide-text': {
      marginLeft: '24px',
      color: '#333',
    },
    '.MuiDialogTitle-root': {
      padding: '16px',
      '.MuiTypography-h2': {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '32px',
      },
      '.MuiTypography-caption': {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px',
      },
    },
    '.MuiDialogContent-root': {
      padding: '0 16px',
      '& > .MuiBox-root': {
        height: '100%',
      },
    },
    '.MuiDialogActions-root': {
      padding: '16px',
    },
  },
  layer: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
    opacity: 0.3,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'white',
  },
  table: {
    '.MuiOutlinedInput-root .MuiSelect-outlined': {
      paddingTop: '14px',
      paddingBottom: '14px',
    },
  },
}));

export const statusList = (t: TFunction) => ({
  [GRANT_PERMISSION.GRANTED]: {
    color: 'success',
    label: t('granted'),
    value: GRANT_PERMISSION.GRANTED,
  },
  [GRANT_PERMISSION.NOT_GRANTED]: {
    color: 'error',
    label: t('not_granted'),
    value: GRANT_PERMISSION.NOT_GRANTED,
  },
  [GRANT_PERMISSION.NOT_PERMISSION]: {
    color: 'warning',
    label: t('not_permission'),
    value: GRANT_PERMISSION.NOT_PERMISSION,
  },
  [GRANT_PERMISSION.UNAVAILABLE]: {
    color: 'error',
    label: t('unavailable'),
    value: GRANT_PERMISSION.UNAVAILABLE,
  },
});

const AddCollectionDialog = (props: AddCollectionDialogProps) => {
  const { rows, open, selectedCollections, loadingData, onClose, queryData, updateQuery, pagination } = props;

  const myShopData = useContext(ShopDetailContext) as ShopDetailContextValue;
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;

  const { id } = useParams();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [openWalletConnector, setOpenWalletConnector] = useState(false);
  const [isAttachingCollection, setIsAttachingCollection] = useState(false);
  const [selectedCollectionIds, setSelectedCollectionIds] = useState<GridRowSelectionModel | undefined>([]);
  const [tempSelectedCollectionIds, setTempSelectedCollectionIds] = useState<GridRowSelectionModel | undefined>([]);

  const isPreMintType = myShopData.data.siteSetting?.category === NFT_SHOP_TYPE.PRE_MINT;

  const [handleGrantPermission] = useGrantMintPermission({
    setOpenWalletConnector,
  });
  const [getListCollections] = useListCollectionsLazyQuery();
  const [attachCollectionToShop] = useAttachMyShopCollectionsMutation({
    refetchQueries: [ListMyShopCollectionsDocument],
    onCompleted: () => updateQuery({ page: 1 }),
  });
  const [unAttachCollectionShop] = useUnAttachMyShopCollectionsMutation({
    refetchQueries: [ListMyShopCollectionsDocument],
    onCompleted: () => updateQuery({ page: 1 }),
  });
  // const [handleRemoveApproveTransfer] = useRemoveApproveTransferMutation();
  // const [getAllMyShopCollectionsInPreMint] = useGetAllMyShopCollectionsInPreMintLazyQuery();
  // const [getAllMyShopCollectionsContainNFT] = useGetAllMyShopCollectionsContainNftLazyQuery();

  const onColumnWidthChange = (data: GridColumnResizeParams) => {
    const columnsSize = localStorage.getItem('columnsSize');
    if (columnsSize) {
      const parsed = JSON.parse(columnsSize);
      if (parsed) {
        parsed[data.colDef.field] = data.width;
        localStorage.setItem('columnsSize', JSON.stringify(parsed));
      }
    } else {
      localStorage.setItem('columnsSize', JSON.stringify({ [data.colDef.field]: data.width }));
    }
  };

  const columns: GridColDef<CollectionData>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value }) => {
          return (
            <Box width="64px">
              <SquareImage src={value} />
            </Box>
          );
        },
      },
      {
        field: CollectionQueryKey.Name,
        headerName: t('my_shop.collection_name'),
        width: JSON.parse(columnsSize).name || 150,
      },
      ...(isPreMintType
        ? [
            {
              field: 'status',
              sortable: false,
              headerName: t('status'),
              width: JSON.parse(columnsSize).status || 150,
              renderCell: ({ row }: { row: any }) => {
                const info = statusList(t)[row?.status as GRANT_PERMISSION];
                return <Chip label={`${info.label || ''}`} color={info.color as 'error' | 'success' | 'warning'} />;
              },
            },
          ]
        : []),
      {
        field: CollectionQueryKey.Symbol,
        headerName: t('symbol'),
        width: JSON.parse(columnsSize).symbol || 150,
      },
      {
        field: CollectionQueryKey.ContractAddress,
        headerName: t('contract_address'),
        valueFormatter: ({ value }) => truncateEthAddress(value),
        width: JSON.parse(columnsSize).contractAddress || 150,
      },
      {
        field: CollectionQueryKey.Network,
        headerName: t('network'),
        valueFormatter: ({ value }) => (value ? supportedNetworks?.[value]?.name : '-'),
        width: JSON.parse(columnsSize).network || 150,
      },
      {
        field: CollectionQueryKey.OwnerAddress,
        headerName: t('owner_address'),
        width: JSON.parse(columnsSize).ownerAddress || 150,
        valueFormatter: ({ value }) => (value ? truncateEthAddress(value) : '-'),
      },
      {
        type: 'date',
        headerName: t('created_at'),
        field: CollectionQueryKey.CreatedAt,
        width: JSON.parse(columnsSize).createdAt || 115,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_format')) : '-'),
      },
    ];
  }, [t, isPreMintType, supportedNetworks]);

  const onAttachCollection = async () => {
    try {
      if (!isAttachingCollection) {
        return;
      }
      const attachedCollectionIds =
        tempSelectedCollectionIds?.filter((id) => !selectedCollectionIds?.includes(id)) || [];
      const unattachedCollectionIds =
        selectedCollectionIds?.filter((id) => !tempSelectedCollectionIds?.includes(id)) || [];
      let attachableCollections: ShopCollection[] = [];

      const {
        data: {
          listCollections: { items: affectedCollectionsList } = {
            items: [] as ListCollectionsQuery['listCollections']['items'],
          },
        } = {},
      } = await getListCollections({
        fetchPolicy: 'cache-and-network',
        variables: {
          where: {
            fields: [
              {
                value: attachedCollectionIds.concat(unattachedCollectionIds) as string[],
                operator: QueryOperator.Anyof,
                key: CollectionQueryKey.Uuid,
              },
            ],
          },
        },
      });
      const successfullyDetachedCollectionIds: string[] = [];
      for (let affectedCollection of affectedCollectionsList) {
        const isAttachedCollection = attachedCollectionIds?.includes(affectedCollection.uuid);
        const isDetachedCollection = unattachedCollectionIds?.includes(affectedCollection.uuid);
        if (isPreMintType) {
          if (isAttachedCollection) {
            try {
              const grantSuccessfully = await handleGrantPermission(affectedCollection, true);
              if (!grantSuccessfully) {
                continue;
              }
            } catch {
              continue;
            }
          }
        }
        if (isAttachedCollection) {
          const newCollection: ShopCollection = {
            type: SalesMethodEnum.NFT_LIST_AND_ADVANCED,
            collectionUuid: affectedCollection.uuid,
          };
          attachableCollections.push(newCollection);
        }
        if (isDetachedCollection) {
          successfullyDetachedCollectionIds.push(affectedCollection.uuid);
        }
      }
      let hasChange = false;
      if (attachableCollections?.length > 0) {
        hasChange = true;
        await attachCollectionToShop({
          variables: {
            input: {
              shopUuid: myShopData?.data?.uuid,
              collections: attachableCollections || [],
            },
          },
        });
      }
      if (successfullyDetachedCollectionIds && successfullyDetachedCollectionIds.length > 0) {
        hasChange = true;
        await unAttachCollectionShop({
          variables: {
            input: {
              shopUuid: myShopData?.data?.uuid,
              collectionUuids: successfullyDetachedCollectionIds as string[],
            },
          },
        });
      }
      if (hasChange) {
        await myShopData.refetch({ myShopUuid: id || '' });
      }

      onClose();
      if (!!attachableCollections?.length || !!successfullyDetachedCollectionIds?.length) {
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
      }
      setIsAttachingCollection(false);
    } catch (error: any) {
      setIsAttachingCollection(false);
      enqueueSnackbar(error?.error?.message || error.message || t('my_shop.message.error'), { variant: 'error' });
    }
  };

  const checkConnectionStatus = () => {
    setIsAttachingCollection(true);
  };

  const handleRowSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
    setTempSelectedCollectionIds(rowSelectionModel);
  };

  const handleCloseWalletConnector = () => {
    setOpenWalletConnector(false);
  };

  useEffect(() => {
    if (isAttachingCollection) {
      onAttachCollection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAttachingCollection]);

  useEffect(() => {
    const selectedIds = selectedCollections?.reduce((output, current) => {
      const newId = current.collectionUuid;
      if (newId) {
        output.push(newId);
      }
      return output;
    }, [] as string[]);
    setSelectedCollectionIds(selectedIds);
    setTempSelectedCollectionIds(selectedIds);
  }, [selectedCollections]);

  return (
    <Dialog onClose={() => onClose()} open={open} className={classes.dialog}>
      <DialogTitle component="div">
        <Typography variant="h2">{t('my_shop.attach_new_collection')}</Typography>
        <Typography variant="caption">{t('my_shop.select_attach_collection')}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Divider sx={{ borderColor: '#9E9E9E', width: 'calc(100% - 48px)', margin: '0 16px 16px 16px' }} />
      <DialogContent>
        <ListTable
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          checkboxSelection
          rowSelectionModel={tempSelectedCollectionIds as GridInputRowSelectionModel}
          onRowSelectionModelChange={handleRowSelectionChange}
          onColumnWidthChange={onColumnWidthChange}
          isRowSelectable={(params) =>
            ![GRANT_PERMISSION.NOT_PERMISSION, GRANT_PERMISSION.UNAVAILABLE].includes(
              params.row.status as GRANT_PERMISSION
            )
          }
          // new
          rows={rows}
          columns={columns}
          autoHeight={false}
          isLoading={loadingData}
          onlyMode={VIEW_MODE.LIST}
          search={queryData.searchText}
          tableName="attach_collection_list"
          searchLabel={t('my_shop.collection_name')}
          onSearch={(v) => updateQuery({ page: 1, searchText: v || '' })}
          noRowsMessage={t('my_shop.message.no_collection')}
          paginationData={pagination}
          onPagination={updateQuery}
          sort={{
            sortBy: queryData.sortBy,
            orderBy: queryData.orderBy,
          }}
          onSort={(value = {}) => updateQuery(value as SortItem)}
        />
      </DialogContent>
      <DialogActions>
        <Box>
          <Button variant="outlined" color="primary" onClick={onClose} sx={{ marginRight: '16px' }}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={checkConnectionStatus}
            disabled={isAttachingCollection}
            endIcon={isAttachingCollection && <CircularProgress size={20} color="inherit" />}
          >
            {t('my_shop.attach')}
          </Button>
        </Box>
      </DialogActions>
      {isAttachingCollection && <Box className={classes.layer}></Box>}
      <WalletConnectorDialog open={openWalletConnector} onClose={handleCloseWalletConnector} />
    </Dialog>
  );
};

export default memo(AddCollectionDialog);
