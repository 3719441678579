import React, { useContext } from 'react';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import PhoneIphoneRoundedIcon from '@mui/icons-material/PhoneIphoneRounded';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import LinkButton from '~/components/link-button';
import { EDIT_SHOP_HEADER_HEIGHT, EDIT_SHOP_HEADER_HEIGHT_MOBILE } from '~/constants/layout';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { EditShopContext, EditShopContextValue } from '~/pages/edit-shop';
import { StyledComponentProps } from '~/types/material-ui';
import { DEVICE, TEMPLATE_OPTIONS } from '~/types/my-shop';

const useStyles = makeStyles()((theme) => ({
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: theme.palette.text.secondary,
    boxShadow: 'unset',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  toolbar: {
    height: EDIT_SHOP_HEADER_HEIGHT_MOBILE,
    [theme.breakpoints.up('sm')]: {
      height: EDIT_SHOP_HEADER_HEIGHT,
    },
  },
  toolbarContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '.MuiButton-root .MuiButton-startIcon svg': {
      fontSize: 12,
    },
  },
  backBtn: {
    display: 'flex',
  },
  modeBtn: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  headerBtn: {
    display: 'flex',
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  device: DEVICE;
  isSubmitting: boolean;
  onSave: () => void;
  handleDevice: (newDevice: DEVICE) => void;
}

const initDevices = [
  { value: DEVICE.DESKTOP, icon: <ComputerOutlinedIcon /> },
  { value: DEVICE.MOBILE, icon: <PhoneIphoneRoundedIcon /> },
];

const HeaderBarEditDesign: React.FC<Props> = ({ device, isSubmitting, onSave, handleDevice }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { pathname } = useLocation();

  const config = useContext(EditShopContext) as EditShopContextValue;

  const [currentThema, currentNavi, currentStyle, currentShowLabelSample, currentPositionLabelSample] = useWatch({
    control: config.control,
    name: ['thema', 'navi', 'style', 'showLabelSample', 'positionLabelSample'],
  });

  const isMemberSite = pathname.startsWith(AppRouteEnum.MemberSite);

  const {
    thema: themaDefault,
    navi: naviDefault,
    style: styleDefault,
    showLabelSample,
    positionLabelSample,
  } = config.control._defaultValues;

  const isThemaChanged = () => {
    if (themaDefault?.template === currentThema?.template) {
      switch (themaDefault?.template) {
        case TEMPLATE_OPTIONS.HEADER_IMAGE_STYLE_1:
          return (
            JSON.stringify(themaDefault?.bannerImageFile) === JSON.stringify(currentThema?.bannerImageFile) ||
            (!themaDefault?.bannerImageFile?.length && !currentThema?.bannerImageFile?.length)
          );
        case TEMPLATE_OPTIONS.HEADER_IMAGE_STYLE_2:
          return (
            JSON.stringify(themaDefault?.ratioBannerImageFile) === JSON.stringify(currentThema?.ratioBannerImageFile) ||
            (!themaDefault?.ratioBannerImageFile?.length && !currentThema?.ratioBannerImageFile?.length)
          );
        default:
          return true;
      }
    } else {
      return false;
    }
  };

  const disabled =
    isSubmitting ||
    (isThemaChanged() &&
      JSON.stringify(naviDefault) === JSON.stringify(currentNavi) &&
      JSON.stringify(styleDefault) === JSON.stringify(currentStyle) &&
      !!currentShowLabelSample === !!showLabelSample &&
      JSON.stringify(positionLabelSample) === JSON.stringify(currentPositionLabelSample));

  return (
    <>
      <AppBar position="static" className={classes.appBar} color="inherit">
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarContent}>
            <LinkButton
              className={classes.backBtn}
              to={(isMemberSite ? AppRouteEnum.MemberSiteDetail : AppRouteEnum.ShopDetail).replace(/:id/, id || '')}
              variant="outlined"
              startIcon={<ArrowBackIosNewIcon />}
            >
              {t('back')}
            </LinkButton>
            <Box className={classes.modeBtn}>
              {initDevices.map((item) => (
                <Button
                  key={item.value}
                  sx={{ backgroundColor: device === item.value ? '#1976d244' : 'transparent' }}
                  onClick={() => handleDevice(item.value)}
                >
                  {item.icon}
                </Button>
              ))}
            </Box>
            <Box className={classes.headerBtn}>
              <Button variant="contained" disabled={disabled} onClick={onSave}>
                {t('save')}
              </Button>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default HeaderBarEditDesign;
