import { FC } from 'react';

import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

import NFTQRCode from './NFTQRCode';

const useStyles = makeStyles()(() => ({
  wrapperItem: {
    maxWidth: 980,
    margin: '0 auto',
    '.MuiCard-root': {
      boxShadow: 'none',
      borderRadius: '12px',
      '.MuiCardMedia-root': {
        borderRadius: '12px',
      },
    },
    '.MuiTypography-subtitle1': {
      fontSize: '24px',
      fontWeight: 700,
    },
  },
}));

const mockToken = {
  id: '0',
  network: '10081',
  tokenId: '000',
  name: 'NFT Image',
  image: 'https://dummyimage.com/285x285/000/fff',
};

const MemberSiteScreen: FC = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapperItem}>
      <Box maxWidth="360px" margin="auto" display="flex" flexDirection="column" gap="40px">
        {[mockToken].map((nftInfo) => (
          <Box key={nftInfo.id}>
            <NFTQRCode nftInfo={nftInfo} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MemberSiteScreen;
