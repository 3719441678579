import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import DialogCloseButton from '~/components/dialog/dialog-close-button';
import TextEditor from '~/components/TextEditor';
import { MAX_LENGTH } from '~/constants/common';
import { getErrorText } from '~/utils/yup.util';

export interface IEditCollectionFromShopField {
  name: string;
  desc?: string;
  nameJa: string;
  descJa?: string;
}

interface EditNameDialogProps extends DialogProps {
  title: string;
  defaultData: {
    name: string;
    desc: string;
    nameJa: string;
    descJa: string;
  };
  autoFocus?: 'en' | 'ja';
  onClose: () => void;
  onEdit: (data: IEditCollectionFromShopField) => Promise<void>;
}

const useStyles = makeStyles()((theme) => ({
  addImageDialog: {
    '.MuiPaper-root': {
      minWidth: '480px',
      maxWidth: '480px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '360px',
      },
    },
    '.dialog': {
      '&-title': {
        fontColor: '#444444',
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '133.4%',
        padding: '16px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '20px',
          lineHeight: 'unset',
        },
      },
    },
    '.MuiDivider-root': {
      margin: '0 16px',
      borderColor: '#9E9E9E',
    },
    '.MuiDialogContent-root': {
      padding: '16px',
      '.dialog-input-name': {
        width: '100%',
        marginTop: 16,
      },
    },
    '.MuiDialogActions-root': {
      padding: '16px',
      '.MuiButtonBase-root:not(:first-of-type)': {
        marginLeft: '16px',
      },
    },
  },
}));

const schema = yup.object({
  desc: yup.string(),
  descJa: yup.string(),
  name: yup.string().max(100).required(),
  nameJa: yup.string().max(100).required(),
});

export interface FormValuesImageNFT extends yup.InferType<typeof schema> {}

const EditCollectionFromShopDialog: FC<EditNameDialogProps> = (props) => {
  const { open, title, autoFocus, defaultData, onEdit, onClose } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAction =
    (callback: (data: IEditCollectionFromShopField) => Promise<void>): SubmitHandler<IEditCollectionFromShopField> =>
    async (data) => {
      setIsSubmitting(true);
      await callback({
        name: data.name.trim(),
        nameJa: data.nameJa.trim(),
        desc: (data.desc || '').trim(),
        descJa: (data.descJa || '').trim(),
      });
      setIsSubmitting(false);
    };

  const {
    control,
    formState: { dirtyFields, errors },
    reset,
    handleSubmit,
  } = useForm<FormValuesImageNFT>({
    defaultValues: {
      name: '',
      desc: '',
      nameJa: '',
      descJa: '',
    },
    resolver: yupResolver(schema),
  });

  const isDirty = !!Object.keys(dirtyFields).length;

  useEffect(() => {
    if (open) {
      reset({
        name: defaultData.name,
        desc: defaultData.desc,
        nameJa: defaultData.nameJa,
        descJa: defaultData.descJa,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const autoFocusInput = autoFocus || 'en';

  return (
    <Dialog open={open} onClose={onClose} className={classes.addImageDialog}>
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      <DialogCloseButton onClick={onClose} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <Divider />
      <DialogContent>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              required
              variant="outlined"
              spellCheck={false}
              id="outlined-basic"
              disabled={isSubmitting}
              className="dialog-input-name"
              label={t('my_shop.captionEn')}
              error={!!errors.name?.message}
              autoFocus={autoFocusInput === 'en'}
              helperText={getErrorText(errors.name?.message, t)}
              {...field}
            />
          )}
        />
        <Controller
          name="nameJa"
          control={control}
          render={({ field }) => (
            <TextField
              required
              variant="outlined"
              spellCheck={false}
              id="outlined-basic"
              disabled={isSubmitting}
              className="dialog-input-name"
              label={t('my_shop.captionJa')}
              error={!!errors.nameJa?.message}
              autoFocus={autoFocusInput === 'ja'}
              helperText={getErrorText(errors.nameJa?.message, t)}
              {...field}
            />
          )}
        />
        <TextEditor
          name="desc"
          control={control}
          heightEditor={47}
          maxLength={MAX_LENGTH}
          readOnly={isSubmitting}
          label={t('description_en')}
          error={errors.desc?.message}
          sx={{
            marginTop: '23px',
          }}
        />
        <TextEditor
          name="descJa"
          control={control}
          heightEditor={47}
          maxLength={MAX_LENGTH}
          readOnly={isSubmitting}
          label={t('description_ja')}
          error={errors.descJa?.message}
          sx={{
            marginTop: '23px',
          }}
        />
      </DialogContent>
      <DialogActions>
        <Box>
          <Button variant="outlined" color="primary" onClick={onClose} disabled={isSubmitting}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!isDirty || isSubmitting}
            endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
            onClick={handleSubmit(handleAction(onEdit!))}
          >
            {t('save')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditCollectionFromShopDialog;
