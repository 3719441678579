import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ERC721G__factory } from '@gu-corp/gu-token-studio-contracts';
import { useAccount, useSigner, useSwitchNetwork, WalletRequired } from '@gusdk/gu-wallet-connector';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import DialogCloseButton from '../dialog/dialog-close-button';

import FileUploadInput from './FileUploadInput';

import { CODE } from '~/constants/code';
import { MAX_FILE_SIZE, MAX_LENGTH } from '~/constants/common';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import { env } from '~/env';
import {
  Storage,
  useUploadNftMutation,
  useUploadNftWithApiKeyMutation,
  GetMeDocument,
  useGetCollectionLazyQuery,
} from '~/graphql/member/types';
import { useAccount as useMe } from '~/hooks/with-account';
import { StyledComponentProps } from '~/types/material-ui';
import { getErrorText } from '~/utils/yup.util';

const useStyles = makeStyles()(() => ({
  dialog: {
    '.MuiDivider-root': {
      margin: '0 16px',
      borderColor: '#9E9E9E',
    },
    '.MuiDialogContent-root': {
      padding: '16px',
      marginTop: '16px',
      '.MuiFormControl-root': {
        margin: 0,
        ':not(:first-of-type)': {
          marginTop: '23px',
        },
        '.MuiFormHelperText-root.Mui-error': {
          whiteSpace: 'pre-wrap',
        },
      },
    },
    '.MuiDialogActions-root': {
      padding: '16px',
      '.MuiButtonBase-root:not(:first-of-type)': {
        marginLeft: '16px',
      },
    },
    '.MuiFormLabel-root': {
      '.MuiFormLabel-asterisk': {
        color: '#da0202de',
      },
    },
  },
}));

interface Props extends DialogProps {
  collectionId: string;
  classes?: StyledComponentProps<typeof useStyles>['classes'] & DialogProps['classes'];
  onClose: (params?: { isCreated?: boolean }) => void;
}

const AddNFTDialog: React.FC<Props> = (props) => {
  const { open, onClose, collectionId, ...others } = props;
  const { classes } = useStyles();

  const handleCloseDialog: DialogProps['onClose'] = (event, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return;
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} {...others} maxWidth="sm" className={classes.dialog} fullWidth>
      <Content {...props} />
    </Dialog>
  );
};

enum MethodEnum {
  NoAPIKey = 'noAPIKey',
  WithAPIKey = 'withAPIKey',
  MetadataUri = 'metadataUri',
}

enum TemplateEnum {
  Image = 'image',
  Video = 'video',
}

const schema = yup.object({
  method: yup.mixed<MethodEnum>().oneOf(Object.values(MethodEnum)).required(),
  storage: yup
    .mixed<Storage>()
    .oneOf(Object.values(Storage))
    .when('method', {
      is: (value: string) => value === MethodEnum.WithAPIKey,
      then: (schema) => schema.required(),
    }),
  apiKey: yup.string().when('method', {
    is: (value: string) => value === MethodEnum.WithAPIKey,
    then: (schema) => schema.required(),
  }),
  isSave: yup.boolean().when('method', {
    is: (value: string) => value !== MethodEnum.WithAPIKey,
    then: (schema) => schema.required(),
  }),
  template: yup
    .mixed<TemplateEnum>()
    .oneOf(Object.values(TemplateEnum))
    .when('method', {
      is: (value: string) => value !== MethodEnum.MetadataUri,
      then: (schema) => schema.required(),
    }),
  name: yup
    .string()
    .max(100)
    .when('method', {
      is: (value: string) => value !== MethodEnum.MetadataUri,
      then: (schema) => schema.required(),
    }),
  description: yup
    .string()
    .max(255)
    .when('method', {
      is: (value: string) => value !== MethodEnum.MetadataUri,
      then: (schema) => schema.required(),
    }),
  image: yup
    .mixed<FileList>()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value?.length) {
          return true;
        }
        return value[0].size < MAX_FILE_SIZE;
      },
    })
    .when('method', {
      is: (value: string) => value !== MethodEnum.MetadataUri,
      then: (schema) => schema.required(),
    }),
  mediaAttachment: yup
    .mixed<FileList>()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value) {
          return true;
        }
        return value[0].size < 10000000;
      },
    })
    .when('template', {
      is: TemplateEnum.Video,
      then: (schema) => schema.required(),
    }),
  metadataUri: yup
    .string()
    .test({
      message: 'form_validation.invalid_ipfs_uri',
      test: async (value) => {
        if (!value) {
          return true;
        }
        const url = value.replace('ipfs://', env.REACT_APP_IPFS_GATEWAY_URL);
        try {
          const result = await fetch(url);
          const metadata = await result.json();

          if (!metadata.image || !metadata.name) {
            return false;
          }
          return true;
        } catch (error) {
          return false;
        }
      },
    })
    .when('method', {
      is: MethodEnum.MetadataUri,
      then: (schema) => schema.required(),
    }),
});

export interface FormAddNFTValues extends yup.InferType<typeof schema> {}

const Content: React.FC<Props> = (props) => {
  const supportedNetworksContext = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;
  const supportedNetworks = supportedNetworksContext?.supportedNetworks;
  const { onClose, collectionId } = props;

  const signer = useSigner();
  const { t } = useTranslation();
  const { selectedOrganization } = useMe();
  const { switchNetwork } = useSwitchNetwork();
  const { account: accountAddress } = useAccount();

  const [step, setStep] = useState(-1);

  const refSigner = useRef(signer);
  refSigner.current = signer;

  const web3ApiKey = useMemo(() => {
    const web3Storage = selectedOrganization?.ipfsStorageApiKeys?.find((el) => el.storage === Storage.Web3Storage);
    return web3Storage?.apiKey;
  }, [selectedOrganization]);

  const nftApiKey = useMemo(() => {
    const nftStorage = selectedOrganization?.ipfsStorageApiKeys?.find((el) => el.storage === Storage.NftStorage);
    return nftStorage?.apiKey;
  }, [selectedOrganization]);

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm<FormAddNFTValues>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    defaultValues: {
      method: web3ApiKey || nftApiKey ? MethodEnum.WithAPIKey : MethodEnum.NoAPIKey,
      template: TemplateEnum.Image,
      storage: nftApiKey && !web3ApiKey ? Storage.NftStorage : Storage.Web3Storage,
      apiKey: '',
      isSave: false,
      name: '',
      description: '',
    },
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const [uploadNft] = useUploadNftMutation();
  const [getCollection, { loading: loadingCollection }] = useGetCollectionLazyQuery();
  const [uploadNFTWithApiKey] = useUploadNftWithApiKeyMutation({
    refetchQueries: [GetMeDocument],
  });

  const method = useWatch({
    control,
    name: 'method',
  });
  const template = useWatch({
    control,
    name: 'template',
  });
  const storage = useWatch({
    control,
    name: 'storage',
  });

  useEffect(() => {
    if (storage === Storage.Web3Storage && web3ApiKey) {
      setValue('apiKey', web3ApiKey);
    } else if (storage === Storage.NftStorage && nftApiKey) {
      setValue('apiKey', nftApiKey);
    } else {
      setValue('apiKey', '');
    }
  }, [storage, web3ApiKey, nftApiKey, setValue]);

  const errorMessage = (err: any) => {
    if (err?.error?.code === CODE.INTERNAL_ERROR && err?.error?.data?.code === CODE.NOT_OWNER_COLLECTION) {
      return t('you_are_not_the_owner_of_this_collection');
    } else if (err?.code === CODE.CALL_EXCEPTION || err.code === CODE.BAD_DATA) {
      return t('toast_message.network_not_match');
    } else if (err?.code === CODE.ACTION_REJECTED) {
      return t('form_validation.user_denied_signature');
    } else if (err?.graphQLErrors?.[0]?.extensions?.exception?.action === 'estimateGas') {
      return t('toast_message.insufficient_funds_for_gas');
    }
    return err?.error?.message || err.shortMessage || err.message || t('my_shop.message.error');
  };

  const onSubmit = useCallback(
    async (data: FormAddNFTValues) => {
      try {
        setStep(0);
        const collectionResponse = await getCollection({
          fetchPolicy: 'cache-and-network',
          variables: {
            collectionUuid: collectionId,
          },
        });
        const collection = collectionResponse?.data?.getCollection;
        if (!collection) {
          throw new Error(t('collection_screen.collection_not_found'));
        }
        await switchNetwork(Number(supportedNetworks?.[collection.network]?.chainId));
        await new Promise((done) => setTimeout(done, 1000)); // Wait for update chainId in refSigner
        if (!refSigner.current) {
          throw new Error(t('you_are_not_the_owner_of_this_collection'));
        }
        const contract = ERC721G__factory.connect(collection.contractAddress, refSigner.current);
        const owner = await contract.owner();
        const ownerLowerCase = owner.toLowerCase();
        const currentWalletLowerCase = accountAddress.toLowerCase();
        const masterWalletLowerCase = selectedOrganization.masterWalletAddress?.toLowerCase();
        if (![currentWalletLowerCase, masterWalletLowerCase].includes(ownerLowerCase)) {
          throw new Error(t('you_are_not_the_owner_of_this_collection'));
        }

        let metadataUrl: string = data.metadataUri ?? '';
        if (data.method === MethodEnum.NoAPIKey) {
          const { data: nftResource } = await uploadNft({
            variables: {
              input: {
                name: data.name ?? '',
                description: data.description ?? '',
                image: data.image?.[0] ?? null,
                mediaAttachment: data.mediaAttachment?.[0],
              },
            },
          });
          metadataUrl = nftResource?.uploadNFT?.metadataUrl ?? '';
        } else if (data.method === MethodEnum.WithAPIKey) {
          const { data: nftResource } = await uploadNFTWithApiKey({
            variables: {
              input: {
                name: data.name ?? '',
                description: data.description ?? '',
                image: data.image?.[0] ?? null,
                mediaAttachment: data.mediaAttachment?.[0],
                storage: data.storage ?? Storage.Web3Storage,
                apiKey: data.apiKey ?? '',
                isSave: data.isSave ?? false,
                organizationUuid: selectedOrganization.uuid,
              },
            },
          });
          metadataUrl = nftResource?.uploadNFTWithApiKey?.metadataUrl ?? '';
        }
        if (!metadataUrl) return;

        setStep(1);
        await (await contract['safeMint(address,string)'](accountAddress, metadataUrl)).wait();
        setStep(2);
      } catch (e: any) {
        enqueueSnackbar(errorMessage(e), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      collectionId,
      accountAddress,
      supportedNetworks,
      selectedOrganization.uuid,
      selectedOrganization.masterWalletAddress,
      t,
      uploadNft,
      switchNetwork,
      getCollection,
      enqueueSnackbar,
      uploadNFTWithApiKey,
    ]
  );

  const methodOptions = useMemo(
    () =>
      [
        {
          value: MethodEnum.NoAPIKey,
          label: t('method_options.no_api_key'),
        },
        {
          value: MethodEnum.MetadataUri,
          label: t('method_options.metadata_uri'),
        },
      ].map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )),
    [t]
  );

  const ipfsStorageOptions = useMemo(
    () =>
      [
        {
          value: Storage.Web3Storage,
          label: t('ipfs_storage_options.web3_storage'),
        },
        {
          value: Storage.NftStorage,
          label: t('ipfs_storage_options.nft_storage'),
        },
      ].map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )),
    [t]
  );

  const templateOptions = useMemo(
    () =>
      [
        {
          value: TemplateEnum.Image,
          label: t('template_options.image_template'),
        },
      ].map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )),
    [t]
  );

  if (loadingCollection) {
    return (
      <>
        <DialogCloseButton disabled={isSubmitting} onClick={() => onClose()} data-testid="close-button">
          <CloseIcon />
        </DialogCloseButton>
        <DialogTitle>
          <Typography variant="h5" component="p">
            {t('add_nft_dialog.title')}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        </DialogContent>
      </>
    );
  }

  return (
    <>
      <DialogCloseButton disabled={isSubmitting} onClick={() => onClose()} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle>
        <Typography variant="h5" component="p">
          {t('add_nft_dialog.title')}
        </Typography>
      </DialogTitle>
      <Divider sx={{ margin: '0 16px' }} />
      <DialogContent>
        {step >= 0 ? (
          <>
            <Stepper activeStep={step} orientation="vertical">
              {method !== MethodEnum.MetadataUri && (
                <Step>
                  <StepLabel error={!isSubmitting && step === 0} optional={t('update_nft_step.upload.description')}>
                    {t('update_nft_step.upload.title')}
                  </StepLabel>
                </Step>
              )}
              <Step>
                <StepLabel error={!isSubmitting && step === 1} optional={t('update_nft_step.mint.description')}>
                  {t('update_nft_step.mint.title')}
                </StepLabel>
              </Step>
            </Stepper>
          </>
        ) : (
          <>
            {method !== MethodEnum.MetadataUri && (
              <>
                <Box height="156px" margin="16px 0 8px">
                  <FileUploadInput
                    type="image"
                    name="image"
                    control={control}
                    label={template === TemplateEnum.Video ? 'Cover' : undefined}
                    error={!!errors.image?.message}
                    helperText={t(errors.image?.message as any, {
                      size: 10,
                    })}
                  />
                </Box>
                {template === TemplateEnum.Video && (
                  <FileUploadInput
                    type="video"
                    control={control}
                    name="mediaAttachment"
                    disabled={isSubmitting}
                    error={!!errors.mediaAttachment?.message}
                    helperText={t(errors.mediaAttachment?.message as any, {
                      size: 10,
                    })}
                  />
                )}
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      fullWidth
                      label={t('image_name')}
                      variant="outlined"
                      margin="normal"
                      disabled={isSubmitting}
                      error={!!errors.name?.message}
                      helperText={getErrorText(errors.name?.message, t)}
                      {...field}
                    />
                  )}
                />
              </>
            )}
            <Controller
              name="method"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  label={t('method')}
                  fullWidth
                  margin="normal"
                  error={!!errors.method?.message}
                  helperText={t(errors.method?.message as any)}
                  required
                  disabled={isSubmitting}
                  {...field}
                  onChange={(e) => {
                    resetField('storage');
                    resetField('apiKey');
                    resetField('isSave');
                    field.onChange(e);
                  }}
                >
                  {methodOptions}
                </TextField>
              )}
            />
            {method === MethodEnum.WithAPIKey && (
              <>
                <Controller
                  name="storage"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      label={t('ipfs_storage')}
                      fullWidth
                      margin="normal"
                      error={!!errors.template?.message}
                      helperText={t(errors.template?.message as any)}
                      required
                      {...field}
                      onChange={(e) => {
                        resetField('apiKey');
                        resetField('isSave');
                        field.onChange(e);
                      }}
                    >
                      {ipfsStorageOptions}
                    </TextField>
                  )}
                />
                {((storage === Storage.Web3Storage && !web3ApiKey) ||
                  (storage === Storage.NftStorage && !nftApiKey)) && (
                  <>
                    <Controller
                      name="apiKey"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label={t('api_key')}
                          variant="outlined"
                          margin="normal"
                          error={!!errors.name?.message}
                          helperText={t(errors.name?.message as any)}
                          required
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="isSave"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Checkbox checked={getValues('isSave')} {...field} />}
                          label="Remember Api Key"
                        />
                      )}
                    />
                  </>
                )}
              </>
            )}
            {method !== MethodEnum.MetadataUri && (
              <>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      rows={3}
                      fullWidth
                      multiline
                      margin="normal"
                      variant="outlined"
                      disabled={isSubmitting}
                      error={!!errors.description?.message}
                      helperText={getErrorText(errors.description?.message, t)}
                      label={`${t('description')} (${field?.value?.length || 0}/${MAX_LENGTH})`}
                      required
                      {...field}
                    />
                  )}
                />
              </>
            )}
            {method === MethodEnum.MetadataUri && (
              <Controller
                name="metadataUri"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={t('metadataUri')}
                    variant="outlined"
                    margin="normal"
                    error={!!errors.metadataUri?.message}
                    helperText={t(errors.metadataUri?.message as any)}
                    required
                    {...field}
                  />
                )}
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {isSubmitting || step < 0 ? (
          <>
            <Button color="primary" variant="outlined" onClick={() => onClose()} disabled={isSubmitting}>
              {t('cancel')}
            </Button>
            <WalletRequired>
              <Button
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                onClick={handleSubmit(onSubmit)}
              >
                {t('create')}
              </Button>
            </WalletRequired>
          </>
        ) : step === 2 ? (
          <Button
            disabled={isSubmitting}
            color="primary"
            variant="contained"
            onClick={() => onClose({ isCreated: true })}
          >
            {t('done')}
          </Button>
        ) : (
          <Button disabled={isSubmitting} color="primary" variant="contained" onClick={() => setStep(-1)}>
            {t('back')}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default AddNFTDialog;
