import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { jaJP, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as AddIcon } from '../../icons/images/add-icon.svg';

import UserLayout from '~/components/app-layout/user-layout';
import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import { VIEW_MODE } from '~/components/custom-grid-toolbar-search-by-api';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import { GalleryData } from '~/components/gallery-list';
import HomeBtn from '~/components/home-btn';
import ListTable, { ListTablePagination } from '~/components/list-table/v2';
import SquareImage from '~/components/SquareImage';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { MyShopContext, MyShopContextValue } from '~/contexts/MyShopWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { env } from '~/env';
import {
  ListMyShopsDocument,
  MyShopQueryKey,
  QueryOperator,
  useDuplicateMyShopMutation,
  useListMyShopsQuery,
  useRemoveMyShopMutation,
} from '~/graphql/member/types';
import { SHOP_TYPE } from '~/types/my-shop';
import { getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';

export interface DisplayedMyShopData {
  id: string;
  [MyShopQueryKey.CreatedAt]: Date;
  url: string | null | undefined;
  [MyShopQueryKey.Title]: string | null | undefined;
  domain: string | null | undefined;
  category: string | null | undefined;
  description: string | null | undefined;
  [MyShopQueryKey.Publish]: boolean | null | undefined;
}

const getInitQuery = (shopType: string) => {
  return {
    page: 1,
    limit: ITEMS_PER_PAGE.LIST,
    searchText: '',
    sortBy: verifySortKey(MyShopQueryKey, getLocalStorage(shopType + '_list_sort')),
    orderBy: verifyOrderKey(getLocalStorage(shopType + '_list_order')),
    where: {
      fields: [
        {
          value: [shopType],
          operator: QueryOperator.Contains,
          key: MyShopQueryKey.ShopType,
        },
      ],
    },
  };
};

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    paddingBottom: '60px',
  },
  wrapperTable: {
    '.image-list-thumbnail': {
      height: '64px',
      width: '64px',
      borderRadius: '4px',
      objectFit: 'cover',
      cursor: 'pointer',
    },
  },
  wrapperTopTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '16px 8px 8px',
  },
  shopURL: {
    color: '#000000DE',
    textDecoration: 'none',
    '&:hover': {
      color: '#1976d2',
      textDecoration: 'underline',
    },
  },
  addBtn: {
    right: '16px',
    bottom: '16px',
    position: 'fixed',
    '.add-btn': {
      height: '56px',
      minWidth: '56px!important',
      borderRadius: '50%',
    },
  },
}));

const MyShop = () => {
  const myShopState = useContext(MyShopContext) as MyShopContextValue;

  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const [selectedItem, setSelectedItem] = useState<DisplayedMyShopData>();
  const [openRemoveMyShopDialog, setOpenRemoveMyShopDialog] = useState(false);

  const shopType = useMemo(
    () => (location.pathname.startsWith(AppRouteEnum.MemberSite) ? SHOP_TYPE.MEMBER : SHOP_TYPE.SHOP),
    [location]
  );

  useEffect(() => {
    document.title = myShopState?.isMemberSite ? t('member_site.member_site') : t('my_shop.shops');
  }, [t, myShopState?.isMemberSite]);

  const [shopQuery, setShopQuery] = useState(getInitQuery(shopType));

  useEffect(() => {
    setLocalStorageItems({
      [shopType + '_list_order']: shopQuery?.orderBy,
      [shopType + '_list_sort']: shopQuery?.sortBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopQuery?.orderBy, shopQuery?.sortBy]);

  useEffect(() => {
    if (shopQuery?.where?.fields?.[0]?.value?.[0] !== shopType) {
      setShopQuery(getInitQuery(shopType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopType]);

  const updateShopQuery = (newValue: any) => setShopQuery((value: any) => ({ ...value, ...newValue }));

  const [removeMyShop] = useRemoveMyShopMutation({
    refetchQueries: [ListMyShopsDocument],
    onCompleted: () => updateShopQuery({ page: 1 }),
  });
  const [duplicateMyShop] = useDuplicateMyShopMutation({
    refetchQueries: [ListMyShopsDocument],
    onCompleted: () => updateShopQuery({ page: 1 }),
  });

  const { data: dataShops, loading: loadingListMyShops } = useListMyShopsQuery({
    fetchPolicy: 'cache-and-network',
    variables: shopQuery,
  });

  const { items, pagination } = useMemo(() => {
    const items = dataShops?.listMyShops?.items || [];
    const pagination: ListTablePagination = dataShops?.listMyShops?.pagination || {};

    const _items: DisplayedMyShopData[] = items.map((item) => {
      return {
        id: item.uuid,
        domain:
          env.REACT_APP_CLIENT_URL +
          (myShopState?.isMemberSite
            ? AppRouteEnum.BuyerMemberSite.replace(/:shopName/g, item.domain?.name as string)
            : AppRouteEnum.Shop.replace(/:shopName/g, item.domain?.name as string)),
        [MyShopQueryKey.CreatedAt]: item.createdAt,
        [MyShopQueryKey.Title]: item.siteSetting?.title,
        category: item.siteSetting?.category,
        description: item.siteSetting?.description,
        [MyShopQueryKey.Publish]: item.publish,
        url: item.siteSetting?.ogp
          ? env.REACT_APP_API_MEDIA + '/' + item.siteSetting?.ogp
          : myShopState.isMemberSite
          ? 'default/ogp-member-site.png'
          : '/default/ogp-shop.png',
      } as DisplayedMyShopData;
    });
    return { items: _items, pagination };
  }, [dataShops?.listMyShops?.items, dataShops?.listMyShops?.pagination, myShopState.isMemberSite]);

  // Handle Menu
  const onOpenMenu = (myShop: DisplayedMyShopData) => (e: any) => {
    setSelectedItem(myShop);
    setMenuAnchorEl(e.currentTarget);
  };
  const onCloseMenu = () => {
    setMenuAnchorEl(undefined);
  };

  // Handle Remove Shop Dialog
  const onOpenRemoveMyShopDialog = () => {
    setOpenRemoveMyShopDialog(true);
    onCloseMenu();
  };
  const onCloseRemoveMyShopDialog = useCallback(async () => {
    setOpenRemoveMyShopDialog(false);
  }, []);

  const handleDuplicated = async () => {
    try {
      const duplicatedMyShop = await duplicateMyShop({ variables: { myShopUuid: selectedItem?.id || '' } });
      if (duplicatedMyShop) {
        navigate(
          (myShopState?.isMemberSite ? AppRouteEnum.MemberSiteDetail : AppRouteEnum.ShopDetail).replace(
            /:id/g,
            duplicatedMyShop.data?.duplicateMyShop?.uuid || ''
          )
        );
        enqueueSnackbar(
          t(myShopState?.isMemberSite ? 'toast_message.member_site_duplicated' : 'toast_message.shop_duplicated'),
          { variant: 'success' }
        );
      }
    } catch (err: any) {
      enqueueSnackbar(err.message || t('my_shop.message.error'), { variant: 'error' });
    }
  };

  const handleRemoveShop = async () => {
    try {
      if (selectedItem) {
        await removeMyShop({ variables: { myShopUuid: selectedItem?.id || '' } });
        enqueueSnackbar(
          t(myShopState?.isMemberSite ? 'toast_message.member_site_deleted' : 'toast_message.shop_deleted'),
          { variant: 'success' }
        );
      }
    } catch (err: any) {
      enqueueSnackbar(err.message || t('my_shop.message.error'), { variant: 'error' });
    }
  };

  useEffect(() => {
    return () => {
      setMenuAnchorEl(undefined);
      setSelectedItem(undefined);
      setOpenRemoveMyShopDialog(false);
    };
  }, [shopType, i18n.language]);

  const redirectToSettingShop = useCallback(
    (myShopSelected: DisplayedMyShopData) => {
      navigate((myShopState.isMemberSite ? AppRouteEnum.MemberSite : AppRouteEnum.MyShop) + `/${myShopSelected?.id}`);
    },
    [myShopState.isMemberSite, navigate]
  );

  const redirectToSettingMemberSite = useCallback(
    (myShopSelected: DisplayedMyShopData) => {
      navigate(AppRouteEnum.MemberSite + `/${myShopSelected?.id}`);
    },
    [navigate]
  );

  const goToDetailScreen = () => {
    if (selectedItem) {
      myShopState.shopType === SHOP_TYPE.SHOP
        ? redirectToSettingShop(selectedItem)
        : redirectToSettingMemberSite(selectedItem);
    }
  };
  const redirectToDetail = useCallback(
    (id: string) => {
      navigate((myShopState.isMemberSite ? AppRouteEnum.MemberSite : AppRouteEnum.MyShop) + `/${id}`);
    },
    [myShopState.isMemberSite, navigate]
  );

  const columns: GridColDef<DisplayedMyShopData>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value, row }) => {
          return (
            <Box width="64px">
              <SquareImage src={value || '/default/ogp-shop.png'} onClick={() => redirectToDetail(row.id)} />
            </Box>
          );
        },
      },
      {
        field: MyShopQueryKey.Title,
        headerName: t(myShopState?.isMemberSite ? 'site_name' : 'my_shop.shop_name'),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        field: MyShopQueryKey.Publish,
        headerName: t('status'),
        width: JSON.parse(columnsSize).name || 150,
        renderCell: ({ row }) => (
          <Chip
            label={row[MyShopQueryKey.Publish] ? t('open') : t('close')}
            color={row[MyShopQueryKey.Publish] ? 'success' : 'error'}
          />
        ),
      },
      {
        field: 'domain',
        sortable: false,
        headerName: t('url'),
        width: JSON.parse(columnsSize).domain || 400,
        renderCell: ({ value }) => {
          return (
            <Link target="_blank" className={classes.shopURL} href={value}>
              {value}
            </Link>
          );
        },
      },
      {
        type: 'date',
        headerName: t('created_at'),
        field: MyShopQueryKey.CreatedAt,
        width: JSON.parse(columnsSize).createdAt || 115,
        valueFormatter: ({ value }) => {
          return value ? moment(value).format(t('date_format')) : '-';
        },
      },
      {
        width: 70,
        headerName: '',
        type: 'actions',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getActions: (params) => {
          return [
            <IconButton onClick={onOpenMenu(params.row)}>
              <MoreVertIcon sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
            </IconButton>,
          ];
        },
      },
    ];
  }, [t, classes.shopURL, myShopState?.isMemberSite, redirectToDetail]);

  const redirectToCreateShop = useCallback(() => {
    navigate(myShopState?.isMemberSite ? AppRouteEnum.CreateMemberSite : AppRouteEnum.CreateShop);
  }, [myShopState?.isMemberSite, navigate]);

  const handleClickGalleryCard = (item: GalleryData) => {
    navigate((myShopState?.isMemberSite ? AppRouteEnum.MemberSite : AppRouteEnum.MyShop) + `/${item?.id}`);
  };

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Typography color="text.secondary">
          {myShopState.shopType === SHOP_TYPE.SHOP ? t('my_shop.shops') : t('member_site.member_site')}
        </Typography>
      </Breadcrumbs>
      <Menu anchorEl={menuAnchorEl} open={!!menuAnchorEl} onClose={onCloseMenu}>
        <MenuItem onClick={goToDetailScreen}>{t('my_shop.show_detail')}</MenuItem>
        <MenuItem onClick={handleDuplicated}>{t('my_shop.duplicate')}</MenuItem>
        <MenuItem onClick={onOpenRemoveMyShopDialog}>{t('delete')}</MenuItem>
      </Menu>
      <Box className={classes.wrapper}>
        <CustomCardTable
          cardTitle={myShopState.shopType === SHOP_TYPE.SHOP ? t('my_shop.shops') : t('member_site.member_site')}
          headerAction={
            <HeaderAction
              menus={[
                {
                  title: myShopState?.isMemberSite ? t('member_site.create_new_member_site') : t('my_shop.create_shop'),
                  onClick: redirectToCreateShop,
                },
              ]}
            />
          }
          cardContent={
            <Box className={classes.wrapperTable}>
              <ListTable
                notSquare
                noBorder
                rows={items}
                columns={columns}
                isLoading={loadingListMyShops}
                tableName={myShopState.isMemberSite ? 'membersite' : 'shop'}
                searchLabel={t(myShopState?.isMemberSite ? 'site_name' : 'my_shop.shop_name')}
                noDataProps={{
                  title: myShopState.isMemberSite ? t('create_new_membersite') : t('create_new_shop'),
                  description: myShopState.isMemberSite ? t('no_data.member_site') : t('no_data.shop'),
                  buttonTitle: myShopState?.isMemberSite
                    ? t('member_site.create_new_member_site')
                    : t('my_shop.create_shop'),
                  onClick: redirectToCreateShop,
                }}
                onlyMode={VIEW_MODE.LIST}
                localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
                noRowsMessage={myShopState?.isMemberSite ? t('no_data.member_site') : t('no_data.shop')}
                onClickImage={handleClickGalleryCard}
                search={shopQuery.searchText}
                onSearch={(v) => updateShopQuery({ page: 1, searchText: v || '' })}
                paginationData={pagination}
                onPagination={updateShopQuery}
                sort={{
                  sortBy: shopQuery.sortBy,
                  orderBy: shopQuery.orderBy,
                }}
                onSort={updateShopQuery}
              />
            </Box>
          }
        />
        <Box className={classes.addBtn}>
          <Button className="add-btn" color="primary" variant="contained" onClick={redirectToCreateShop}>
            <AddIcon />
          </Button>
        </Box>
      </Box>
      <ConfirmationDialog
        open={openRemoveMyShopDialog}
        title={
          myShopState.isMemberSite ? t('member_site.delete_member_site') : t('my_shop.message.delete_shop_message')
        }
        content={
          myShopState.isMemberSite ? t('member_site.remove_membersite') : t('my_shop.message.confirm_delete_shop')
        }
        onConfirm={handleRemoveShop}
        onClose={onCloseRemoveMyShopDialog}
      />
    </UserLayout>
  );
};

export default memo(MyShop);
