import { ChangeEvent, useContext } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import ImageUploadInput from '~/components/image-upload-input';
import { ShopDetailContext, ShopDetailContextValue } from '~/contexts/ShopDetailWrapper';
import { MyShop, Theme as ShopTheme } from '~/graphql/member/types';
import { EditShopContext, EditShopContextValue } from '~/pages/edit-shop';
import { StyledComponentProps } from '~/types/material-ui';
import { NFT_SHOP_TYPE, SHOP_TYPE, TEMPLATE_OPTIONS } from '~/types/my-shop';

interface Props extends StyledComponentProps<typeof useStyles> {
  shopData?: MyShop;
  theme?: ShopTheme;
  isMobile?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    '.MuiTypography-subtitle1': {
      fontSize: 14,
      fontWeight: 500,
      marginBottom: '8px',
    },
  },
  themaRadio: {
    marginLeft: 11,
  },
  styleSection: {
    marginTop: '8px',
    lineHeight: '16px',
    '.MuiTypography-caption': {
      fontSize: '10px',
      marginTop: '4px',
      lineHeight: '16px',
      display: 'inline-block',
    },
  },
  styleSectionColor: {
    marginTop: '24px',
    paddingBottom: '8px',
    marginBottom: '16px',
    borderBottom: '1px solid #d7d7d7',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      '&:not(:first-of-type)': {
        marginTop: '16px',
      },
    },
  },
}));

const EditTheme: React.FC<React.PropsWithChildren<Props>> = () => {
  const config = useContext(EditShopContext) as EditShopContextValue;
  const myShopData = useContext(ShopDetailContext) as ShopDetailContextValue;

  const { t } = useTranslation();
  const { classes } = useStyles();

  const [template, showLabelSample, positionLabelSample] = useWatch({
    control: config.control,
    name: ['thema.template', 'showLabelSample', 'positionLabelSample'],
  });

  const handleChangeTemplate = (event: ChangeEvent<HTMLInputElement>) => {
    if (config) {
      config.setValue('thema.template', event.target.value as TEMPLATE_OPTIONS);
    }
  };

  const handleChangeShowLabel = (e: any) => {
    if (config) {
      config.setValue('showLabelSample', e.target.checked);
    }
  };

  const handlePosition = (type: 'top' | 'left') => (_: Event, value: number | number[]) => {
    if (config) {
      let newPosition = positionLabelSample || [50, 50];
      switch (type) {
        case 'top':
          newPosition[0] = value as number;
          break;
        case 'left':
          newPosition[1] = value as number;
          break;
      }
      config.setValue('positionLabelSample', newPosition);
    }
  };

  const handleCropBanner = (croppedImage: File[]) => {
    config.setValue(`thema.bannerImageFile`, croppedImage);
  };

  const handleCropRatioBanner = (croppedImage: File[]) => {
    config.setValue(`thema.ratioBannerImageFile`, croppedImage);
  };

  return (
    <Box className={classes.wrapper}>
      {myShopData?.data?.shopType === SHOP_TYPE.SHOP &&
        myShopData?.data?.siteSetting?.category === NFT_SHOP_TYPE.PRE_MINT && (
          <>
            <FormControlLabel
              control={<Switch />}
              checked={!!showLabelSample}
              sx={{ marginLeft: '-2px', marginRight: 0 }}
              label={t('my_shop.edit_design.show_label_sample')}
              onChange={handleChangeShowLabel}
            />
            {showLabelSample && (
              <Box marginTop="8px" padding="0 8px">
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.primary">
                    {t('my_shop.edit_design.top')} (%):
                  </Typography>
                  <Typography variant="subtitle2" color="primary">
                    {positionLabelSample?.[0] || 50}%
                  </Typography>
                </Box>
                <Slider
                  min={20}
                  max={80}
                  step={10}
                  valueLabelDisplay="off"
                  value={positionLabelSample?.[0] || 50}
                  onChange={handlePosition('top')}
                />
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="text.primary">
                    {t('my_shop.edit_design.left')} (%):
                  </Typography>
                  <Typography variant="subtitle2" color="primary">
                    {positionLabelSample?.[1] || 50}%
                  </Typography>
                </Box>
                <Slider
                  min={30}
                  max={70}
                  step={10}
                  valueLabelDisplay="off"
                  value={positionLabelSample?.[1] || 50}
                  onChange={handlePosition('left')}
                />
              </Box>
            )}
            <Divider sx={{ margin: '16px 0' }} />
          </>
        )}
      <Typography variant="subtitle1">{t('template')}</Typography>
      <Box className={classes.themaRadio}>
        <FormControlLabel
          value={TEMPLATE_OPTIONS.SIMPLE_SITE}
          control={<Radio onChange={handleChangeTemplate} />}
          checked={template === TEMPLATE_OPTIONS.SIMPLE_SITE}
          label={t('my_shop.edit_design.option_simple_site')}
        />
        <FormControlLabel
          value={TEMPLATE_OPTIONS.HEADER_IMAGE_STYLE_1}
          control={<Radio onChange={handleChangeTemplate} />}
          label={t('my_shop.edit_design.header_image_style_1')}
          checked={template === TEMPLATE_OPTIONS.HEADER_IMAGE_STYLE_1}
        />
        <FormControlLabel
          value={TEMPLATE_OPTIONS.HEADER_IMAGE_STYLE_2}
          control={<Radio onChange={handleChangeTemplate} />}
          label={t('my_shop.edit_design.header_image_style_2')}
          checked={template === TEMPLATE_OPTIONS.HEADER_IMAGE_STYLE_2}
        />
      </Box>
      {template === TEMPLATE_OPTIONS.HEADER_IMAGE_STYLE_1 && (
        <Box className={classes.styleSection}>
          <ImageUploadInput
            type="image"
            height="140px"
            control={config.control}
            name="thema.bannerImageFile"
            label={t('my_shop.edit_design.import_header_image')}
            defaultImageUrlName="thema.bannerImage"
            error={!!config.errors.thema?.bannerImageFile?.message}
            helperText={t(config.errors.thema?.bannerImageFile?.message as any, { size: 10 })}
            onCropChange={handleCropBanner}
            cropTitle={t('my_shop.edit_design.header_image_style_1')}
          />
        </Box>
      )}
      {template === TEMPLATE_OPTIONS.HEADER_IMAGE_STYLE_2 && (
        <Box className={classes.styleSection}>
          <ImageUploadInput
            type="image"
            height="140px"
            control={config.control}
            name="thema.ratioBannerImageFile"
            label={t('my_shop.edit_design.import_header_image')}
            defaultImageUrlName="thema.ratioBannerImage"
            error={!!config.errors.thema?.ratioBannerImageFile?.message}
            helperText={t(config.errors.thema?.ratioBannerImageFile?.message as any, { size: 10 })}
            aspect={4 / 1}
            onCropChange={handleCropRatioBanner}
            cropTitle={t('my_shop.edit_design.header_image_style_2')}
          />
          <Typography variant="caption">
            {t('my_shop.edit_design.recommended_size', { width: 1200, height: 300, ratio: '4:1' })}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EditTheme;
