import { BaseSessionManager } from "@toruslabs/base-session-manager";
import { generatePrivate, getPublic, sign } from "@toruslabs/eccrypto";
import { decryptData, encryptData, keccak256 } from "@toruslabs/metadata-helpers";

import { OpenloginSessionManagerOptions, SessionApiResponse, SessionRequestBody } from "./interfaces";

const DEFAULT_SESSION_TIMEOUT = 86400;
export class OpenloginSessionManager<T> extends BaseSessionManager<T> {
  sessionServerBaseUrl = "https://broadcast-server.tor.us";

  sessionNamespace: string;

  sessionTime: number = DEFAULT_SESSION_TIMEOUT;

  public sessionId: string = "";

  constructor({ sessionServerBaseUrl, sessionNamespace, sessionTime, sessionId }: OpenloginSessionManagerOptions = {}) {
    super();

    if (sessionServerBaseUrl) {
      this.sessionServerBaseUrl = sessionServerBaseUrl;
    }
    if (sessionNamespace) this.sessionNamespace = sessionNamespace;
    if (sessionTime) this.sessionTime = sessionTime;
    if (sessionId) this.sessionId = sessionId.padStart(64, "0");
  }

  static generateRandomSessionKey(): string {
    return generatePrivate().toString("hex").padStart(64, "0");
  }

  async createSession(data: T): Promise<string> {
    super.checkSessionParams();
    const privKey = Buffer.from(this.sessionId, "hex");
    const pubKey = getPublic(privKey).toString("hex");
    const encData = await encryptData(this.sessionId, data);
    const signature = (await sign(privKey, keccak256(Buffer.from(encData, "utf8")))).toString("hex");

    const body: SessionRequestBody = {
      key: pubKey,
      data: encData,
      signature,
      namespace: this.sessionNamespace,
      timeout: this.sessionTime,
    };

    await super.request({ method: "POST", url: `${this.sessionServerBaseUrl}/store/set`, data: body });

    return this.sessionId;
  }

  async authorizeSession(): Promise<T> {
    super.checkSessionParams();
    const pubkey = getPublic(Buffer.from(this.sessionId, "hex")).toString("hex");
    const url = new URL(`${this.sessionServerBaseUrl}/store/get`);
    url.searchParams.append("key", pubkey);
    if (this.sessionNamespace) url.searchParams.append("namespace", this.sessionNamespace);

    const result = await super.request<SessionApiResponse>({ url: url.toString() });
    if (!result.message) {
      throw new Error("Session Expired or Invalid public key");
    }

    const response = await decryptData<T & { error?: string }>(this.sessionId, result.message);
    if (response.error) {
      throw new Error("There was an error decrypting data.");
    }

    return response;
  }

  async updateSession(data: Partial<T>): Promise<void> {
    super.checkSessionParams();
    const privKey = Buffer.from(this.sessionId, "hex");
    const pubKey = getPublic(privKey).toString("hex");
    const encData = await encryptData(this.sessionId, data);
    const signature = (await sign(privKey, keccak256(Buffer.from(encData, "utf8")))).toString("hex");

    const body: SessionRequestBody = {
      key: pubKey,
      data: encData,
      signature,
      namespace: this.sessionNamespace,
    };

    await super.request({ method: "PUT", url: `${this.sessionServerBaseUrl}/store/update`, data: body });
  }

  async invalidateSession(): Promise<boolean> {
    super.checkSessionParams();
    const privKey = Buffer.from(this.sessionId, "hex");
    const pubKey = getPublic(privKey).toString("hex");
    const encData = await encryptData(this.sessionId, {});
    const signature = (await sign(privKey, keccak256(Buffer.from(encData, "utf8")))).toString("hex");

    const data: SessionRequestBody = {
      key: pubKey,
      data: encData,
      signature,
      namespace: this.sessionNamespace,
      timeout: 1,
    };

    await super.request({ method: "POST", url: `${this.sessionServerBaseUrl}/store/set`, data });
    this.sessionId = "";
    return true;
  }
}
