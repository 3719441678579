import { FC, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ openInstruction: boolean }>()((theme, { openInstruction }) => ({
  container: {
    width: '100%',
    marginBottom: '24px',
  },
  setupHeader: {
    display: 'flex',
    flexDirection: 'column',
    margin: openInstruction ? '24px 0 16px' : '24px 0 0',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'unset',
    },
    alignItems: 'start',
    justifyContent: 'space-between',
    '.MuiTypography-h2': {
      margin: 0,
      fontWeight: 700,
      color: '#111',
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  btnOpen: {
    margin: openInstruction ? '16px 0 0' : '0px',
    [theme.breakpoints.up('sm')]: {
      margin: 'unset',
    },
  },
  note: {
    marginBottom: '16px',
    '.MuiTypography-caption': {
      width: '100%',
      fontSize: '16px',
      borderRadius: '6px',
      padding: '12px 16px',
      alignItems: 'center',
      letterSpacing: '0.4px',
      display: 'inline-block',
      backgroundColor: '#E8F0F9',
    },
  },
  step: {
    fontSize: 16,
    fontWeight: 700,
    minWidth: '63px',
    marginRight: '8px',
  },
  content: {
    display: 'flex',
    '&:not(:last-of-type)': {
      marginBottom: '16px',
    },
    h4: {
      margin: '0',
      color: '#111',
      fontSize: '16px',
    },
  },
  instruction: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#363636',
  },
}));

const FincodeSetupGuide: FC = () => {
  const { t } = useTranslation();
  const [openInstruction, setOpenInstruction] = useState<boolean>(false);
  const { classes } = useStyles({ openInstruction });

  const instruction = [
    {
      step: t('my_shop.payment_methods.step1.label'),
      label: t('my_shop.payment_methods.step1.method'),
      content: t('my_shop.payment_methods.step1.detail'),
      link: 'https://dashboard.fincode.jp/',
    },
    {
      step: t('my_shop.payment_methods.step2.label'),
      label: t('my_shop.payment_methods.step2.method'),
      content: t('my_shop.payment_methods.step2.detail'),
      link: '',
    },
    {
      step: t('my_shop.payment_methods.step3.label'),
      label: t('my_shop.payment_methods.step3.method'),
      content: t('my_shop.payment_methods.step3.detail'),
      link: '',
    },
  ];

  const handleDisplayInstruction = () => {
    setOpenInstruction(!openInstruction);
  };
  return (
    <Box className={classes.container}>
      <Box className={classes.setupHeader}>
        {!!openInstruction && (
          <Box sx={{ display: 'flex' }}>
            <Typography variant="h2">{t('my_shop.payment_methods.fincode_setup')}</Typography>
          </Box>
        )}
        <Button variant="outlined" color="primary" className={classes.btnOpen} onClick={handleDisplayInstruction}>
          {openInstruction
            ? `${t('my_shop.payment_methods.hide_setup_desc')}`
            : `${t('my_shop.payment_methods.show_setup_decs')}`}
        </Button>
      </Box>
      {!!openInstruction && (
        <>
          <Box className={classes.note}>
            <Typography variant="caption">{t('my_shop.payment_methods.detailed_look')}</Typography>
          </Box>
          {instruction.map((item) => (
            <Box className={classes.content} key={item.label}>
              <Typography color="primary" className={classes.step}>
                {item.step}
              </Typography>
              <Box>
                <h4>{item.label}</h4>
                <Typography variant="caption" className={classes.instruction}>
                  <Trans
                    i18nKey={item.content}
                    components={{
                      lnk: <Link sx={{ color: '#1098FC' }} href="https://dashboard.fincode.jp/" target="_blank" />,
                    }}
                  />
                </Typography>
              </Box>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default FincodeSetupGuide;
