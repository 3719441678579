import { RefObject, forwardRef, useContext, useMemo } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { SelectChangeEvent } from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import ColorPalette from '~/components/color-palette';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { useResizeObserver } from '~/hooks/useResizeObserver';
import { EditShopContext, EditShopContextValue } from '~/pages/edit-shop';
import { FONT, OBJECT_FIT } from '~/types/my-shop';

enum StyleField {
  font = 'font',
  textColor = 'textColor',
  titleColor = 'titleColor',
  buttonColor = 'buttonColor',
  headerColor = 'headerColor',
  headerTextColor = 'headerTextColor',
  backgroundColor = 'backgroundColor',
  descriptionColor = 'descriptionColor',
  collectionTitleColor = 'collectionTitleColor',
  nftCardTextColor = 'nftCardTextColor',
  nftCardBackgroundColor = 'nftCardBackgroundColor',
  collectionNftCardImageMode = 'collectionNftCardImageMode',
  collectionBorderColor = 'collectionBorderColor',
}

const useStyles = makeStyles()(() => ({
  wrapperColors: {
    '.MuiAccordion-root.Mui-expanded': {
      margin: 0,
      '&:before': {
        opacity: 1,
      },
    },
    '.MuiAccordion-root': {
      boxShadow: 'none',
      background: 'transparent',
    },
    '.MuiAccordionSummary-root': {
      padding: 0,
      minHeight: 0,
    },
    '.MuiAccordionSummary-content': {
      fontWeight: 500,
      fontSize: '14px',
      margin: '15px 0',
    },
    '.MuiAccordionSummary-content.Mui-expanded': {
      margin: '15px 0',
      minHeight: 0,
    },
    '.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 0,
    },
    '.MuiAccordionDetails-root': {
      padding: '0 8px 8px',
    },
  },
  btnCheck: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const MOBILE_SIZE = 600;

const EditStyle = forwardRef<HTMLDivElement>((_, ref) => {
  const config = useContext(EditShopContext) as EditShopContextValue;

  const { width } = useResizeObserver({ ref: ref as RefObject<HTMLDivElement> });

  const isMobile = width ? width <= MOBILE_SIZE : false;

  const { t } = useTranslation();
  const { classes } = useStyles();
  const { pathname } = useLocation();

  const isMemberSite = useMemo(() => pathname.startsWith(AppRouteEnum.MemberSite), [pathname]);

  const [
    fontFamily,
    spacing,
    spacingSm,
    textColor,
    titleColor,
    buttonColor,
    headerColor,
    headerTextColor,
    backgroundColor,
    descriptionColor,
    collectionTitleColor,
    collectionBorderColor,
    nftCardTextColor,
    nftCardBackgroundColor,
    collectionNftCardImageMode,
  ] = useWatch({
    control: config.control,
    name: [
      'style.font',
      'style.spacing',
      'style.spacingSm',
      'style.textColor',
      'style.titleColor',
      'style.buttonColor',
      'style.headerColor',
      'style.headerTextColor',
      'style.backgroundColor',
      'style.descriptionColor',
      'style.collectionTitleColor',
      'style.collectionBorderColor',
      'style.nftCardTextColor',
      'style.nftCardBackgroundColor',
      'style.collectionNftCardImageMode',
    ],
  });

  const styleOptions = useMemo(
    () => ({
      [StyleField.headerColor]: {
        label: t('my_shop.edit_design.header_color'),
        field: StyleField.headerColor,
        value: headerColor,
      },
      [StyleField.headerTextColor]: {
        label: t('my_shop.edit_design.header_text_color'),
        field: StyleField.headerTextColor,
        value: headerTextColor,
      },
      [StyleField.titleColor]: {
        label: isMemberSite ? t('my_shop.edit_design.site_name_color') : t('my_shop.edit_design.shop_name_color'),
        field: StyleField.titleColor,
        value: titleColor,
      },
      [StyleField.descriptionColor]: {
        label: isMemberSite
          ? t('my_shop.edit_design.member_site_description_color')
          : t('my_shop.edit_design.shop_description_color'),
        field: StyleField.descriptionColor,
        value: descriptionColor,
      },
      [StyleField.collectionTitleColor]: {
        label: t('my_shop.edit_design.collection_title_color'),
        field: StyleField.collectionTitleColor,
        value: collectionTitleColor,
      },
      [StyleField.nftCardTextColor]: {
        label: t('my_shop.edit_design.nft_card_text_color'),
        field: StyleField.nftCardTextColor,
        value: nftCardTextColor,
      },
      [StyleField.nftCardBackgroundColor]: {
        label: t('my_shop.edit_design.nft_card_background_color'),
        field: StyleField.nftCardBackgroundColor,
        value: nftCardBackgroundColor,
      },
      [StyleField.collectionBorderColor]: {
        label: t('my_shop.edit_design.collection_border_color'),
        field: StyleField.collectionBorderColor,
        value: collectionBorderColor,
      },
      [StyleField.textColor]: {
        label: t('my_shop.edit_design.title_text_color'),
        field: StyleField.textColor,
        value: textColor,
      },
      [StyleField.backgroundColor]: {
        label: t('my_shop.edit_design.title_background_color'),
        field: StyleField.backgroundColor,
        value: backgroundColor,
      },
      [StyleField.buttonColor]: {
        label: t('my_shop.edit_design.title_button_color'),
        field: StyleField.buttonColor,
        value: buttonColor,
      },
    }),
    [
      t,
      textColor,
      titleColor,
      buttonColor,
      headerColor,
      headerTextColor,
      isMemberSite,
      backgroundColor,
      descriptionColor,
      collectionTitleColor,
      nftCardTextColor,
      nftCardBackgroundColor,
      collectionBorderColor,
    ]
  );

  const handleChangeFont = (event: SelectChangeEvent<FONT>) => {
    if (config) {
      config.setValue('style.font', event.target.value as FONT);
    }
  };

  const handleChangeSpacing = (_: Event, value: number | number[]) => {
    if (config) {
      config.setValue('style.spacing', value as number);
    }
  };

  const handleChangeSpacingSm = (_: Event, value: number | number[]) => {
    if (config) {
      config.setValue('style.spacingSm', value as number);
    }
  };

  const handleChangeImageMode = (event: SelectChangeEvent<OBJECT_FIT>) => {
    if (config) {
      config.setValue('style.collectionNftCardImageMode', event.target.value as OBJECT_FIT);
    }
  };

  const handleChangeStyle = (field: StyleField) => (newColor: string) => {
    if (config) {
      config.setValue(`style.${field}`, newColor);
    }
  };

  return (
    <Box className={classes.wrapperColors}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('my_shop.edit_design.title_font')}</AccordionSummary>
        <AccordionDetails>
          <RadioGroup value={fontFamily} onChange={handleChangeFont}>
            {Object.values(FONT).map((font) => (
              <FormControlLabel key={font} value={font} control={<Radio />} label={font} />
            ))}
          </RadioGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('my_shop.edit_design.card_image_mode')}</AccordionSummary>
        <AccordionDetails>
          <RadioGroup value={collectionNftCardImageMode} onChange={handleChangeImageMode}>
            {Object.values(OBJECT_FIT).map((mode) => (
              <FormControlLabel key={mode} value={mode} control={<Radio />} label={t('image_view.' + mode)} />
            ))}
          </RadioGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{t('my_shop.edit_design.spacing')}</AccordionSummary>
        <AccordionDetails>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color={isMobile ? 'text.disabled' : 'text.primary'}>
              {t('my_shop.edit_design.spacing_on_web')}
            </Typography>
            <Typography variant="subtitle2" color={isMobile ? 'text.disabled' : 'primary'}>
              {spacing}px
            </Typography>
          </Box>
          <Slider
            min={4}
            max={60}
            step={4}
            value={spacing}
            disabled={isMobile}
            valueLabelDisplay="off"
            onChange={handleChangeSpacing}
            marks={[
              { label: '4', value: 4 },
              { label: '60', value: 60 },
            ]}
          />
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color={!isMobile ? 'text.disabled' : 'text.primary'}>
              {t('my_shop.edit_design.spacing_on_mobile')}
            </Typography>
            <Typography variant="subtitle2" color={!isMobile ? 'text.disabled' : 'primary'}>
              {spacingSm}px
            </Typography>
          </Box>
          <Slider
            min={4}
            max={60}
            step={4}
            value={spacingSm}
            disabled={!isMobile}
            valueLabelDisplay="off"
            onChange={handleChangeSpacingSm}
            marks={[
              { label: '4', value: 4 },
              { label: '60', value: 60 },
            ]}
          />
        </AccordionDetails>
      </Accordion>

      {Object.values(styleOptions).map((option) => (
        <Accordion key={option.field}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>{option.label}</AccordionSummary>
          <AccordionDetails>
            <ColorPalette defaultColor={option.value} onChange={handleChangeStyle(option.field)} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
});

export default EditStyle;
