import { ChangeEventHandler, FC, useContext, useMemo, useRef } from 'react';

import {
  WalletRequired,
  useSigner,
  useSwitchNetwork,
  useAccount as useWalletAccount,
} from '@gusdk/gu-wallet-connector';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { JsonRpcProvider, parseEther, formatEther } from 'ethers';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CustomDialog from '../custom-dialog';

import { CODE } from '~/constants/code';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import { useWithdrawMutation } from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';

interface IDepositDialog {
  open: boolean;
  isWithdraw?: boolean;
  onClose: (submitted?: boolean) => void;
}

const schema = yup.object({
  amount: yup.string().required(),
  network: yup.string().required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const DepositDialog: FC<IDepositDialog> = ({ open, isWithdraw, onClose }) => {
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;

  const signer = useSigner();
  const { t } = useTranslation();
  const { account } = useWalletAccount();
  const { enqueueSnackbar } = useSnackbar();
  const { switchNetwork } = useSwitchNetwork();
  const { selectedOrganization } = useAccount();

  const refSigner = useRef(signer);
  refSigner.current = signer;

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      amount: '',
      network: Object.values(supportedNetworks)?.[0]?.chainId,
    },
    resolver: yupResolver(schema),
  });

  const [withdraw] = useWithdrawMutation();

  const handleChangeAmount =
    (callback: (value: string) => void): ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
    (event) => {
      const newValue = event.target.value;
      const regexPattern = /^-?\d*\.?\d{0,18}$|^0$/;
      if (!regexPattern.test(newValue)) {
        return;
      }
      const removeNegative = newValue.toString().replace(/-/g, '');
      const removeZero = removeNegative.replace(/^0\d+/, removeNegative.slice(1));
      callback(removeZero);
    };

  const validationDeposit = async (data: FormValues) => {
    if (account && selectedOrganization.masterWalletAddress) {
      const provider = new JsonRpcProvider(supportedNetworks[data.network]?.rpcUrl);
      const balanceBigNumber = await provider.getBalance(
        isWithdraw ? selectedOrganization.masterWalletAddress : account
      );
      const balance = formatEther(balanceBigNumber);
      return Number(data.amount) < Number(balance);
    }
  };

  const onSubmit = async (data: FormValues) => {
    try {
      await switchNetwork(Number(supportedNetworks[data.network].chainId));
      await new Promise((done) => setTimeout(done, 1000)); // Wait for update chainId in refSigner
      if (refSigner.current && selectedOrganization.masterWalletAddress) {
        const validation = await validationDeposit(data);
        if (!validation) {
          enqueueSnackbar(t('toast_message.insufficient_funds_for_gas'), { variant: 'error' });
          return;
        }
        if (isWithdraw) {
          const withdrawRes = await withdraw({
            variables: {
              input: {
                network: data.network,
                walletAddress: account,
                amount: data.amount,
              },
            },
          });
          if (!withdrawRes) {
            enqueueSnackbar(t('my_shop.message.error'), { variant: 'error' });
            return;
          }
        } else {
          const transaction = await refSigner.current.sendTransaction({
            to: selectedOrganization.masterWalletAddress,
            value: parseEther(data.amount),
          });
          await transaction.wait();
        }
        reset();
        onClose(true);
        enqueueSnackbar(
          isWithdraw ? t('toast_message.withdraw_successfully') : t('toast_message.deposit_successfully'),
          { variant: 'success' }
        );
      }
    } catch (err: any) {
      enqueueSnackbar(
        err.code === CODE.ACTION_REJECTED
          ? err.reason
          : err.code === CODE.CALL_EXCEPTION
          ? t('toast_message.network_not_match')
          : err?.graphQLErrors?.[0]?.extensions?.exception?.code === CODE.INSUFFICIENT_FUNDS
          ? t('toast_message.insufficient_funds_for_gas')
          : err?.error?.message || err?.shortMessage || err.message || t('my_shop.message.error'),
        { variant: 'error' }
      );
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const renderMenus = useMemo(
    () =>
      Object.values(supportedNetworks).map((network) => (
        <MenuItem key={network.chainId} value={network.chainId}>
          {network.name}
        </MenuItem>
      )),
    [supportedNetworks]
  );

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      dialogTitle={isWithdraw ? t('settings.withdraw') : t('settings.deposit')}
      dialogContent={
        <>
          <Controller
            name="network"
            control={control}
            render={({ field }) => (
              <TextField
                select
                fullWidth
                margin="dense"
                variant="outlined"
                label={t('network')}
                disabled={isSubmitting}
                error={!!errors.network?.message}
                helperText={t(errors.network?.message as any)}
                {...field}
              >
                {renderMenus}
              </TextField>
            )}
          />
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                disabled={isSubmitting}
                sx={{ marginTop: '16px' }}
                label={t('settings.amount')}
                error={!!errors.amount?.message}
                helperText={t(errors.amount?.message as any)}
                {...field}
                onChange={handleChangeAmount(field.onChange)}
              />
            )}
          />
        </>
      }
      actions={[
        <Button variant="outlined" disabled={isSubmitting} onClick={handleClose}>
          {t('cancel')}
        </Button>,
        <WalletRequired key={0}>
          <Button
            variant="contained"
            disabled={isSubmitting}
            endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
            onClick={handleSubmit(onSubmit)}
          >
            {isWithdraw ? t('settings.withdraw') : t('settings.deposit')}
          </Button>
        </WalletRequired>,
      ]}
    />
  );
};

export default DepositDialog;
