import { ChangeEventHandler, FC, forwardRef, useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import * as colors from '@mui/material/colors';
import Input from '@mui/material/Input';
import Radio, { RadioProps } from '@mui/material/Radio';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

interface IColorPalette {
  defaultColor?: string;
  onChange: (newColor: string) => void;
}

const basicColors = ['#000000', '#545454', '#d9d9d9', '#ffffff'];

const muiColors = colors as any;
const defaultColorInit = '#2196f3';
const hues = [
  'red',
  'pink',
  'purple',
  'deepPurple',
  'indigo',
  'blue',
  'lightBlue',
  'cyan',
  'teal',
  'green',
  'lightGreen',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deepOrange',
];

const shades = [900, 800, 700, 600, 500, 400, 300, 200, 100, 50, 'A700', 'A400', 'A200', 'A100'];

const detectHuesShades = hues.reduce((result, hue) => {
  const shadesObj = muiColors[hue];
  shades.forEach((shade, idx) => {
    result[shadesObj[shade]] = { hue, shadeIdx: idx };
  });
  return result;
}, {} as { [key: string]: { hue: string; shadeIdx: number } });

const TooltipRadio = forwardRef<HTMLButtonElement, RadioProps>((props, ref) => {
  return <Radio ref={ref} {...props} />;
});

const useStyles = makeStyles()(() => ({
  wrapperColorPalette: {
    maxWidth: '232px',
    margin: 'auto',
    '.MuiInputBase-root': {
      fontSize: '0.875rem',
    },
  },
  wrapperPalette: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: '8px',
  },
}));

const ColorPalette: FC<IColorPalette> = ({ defaultColor, onChange }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [color, setColor] = useState(defaultColorInit);
  const [input, setInput] = useState(defaultColorInit);
  const [selectedHue, setSelectedHue] = useState('blue');
  const [selectedShadeIdx, setSelectedShadeIdx] = useState(4);

  const handleChangeColor: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const isRgb = (string: string) => /rgb\([0-9]{1,3}\s*,\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}\)/i.test(string);

    const isHex = (string: string) => /^#?([0-9a-f]{3})$|^#?([0-9a-f]){6}$/i.test(string);

    let {
      target: { value: color },
    } = event;

    setInput(color);

    let isValidColor = false;

    if (isRgb(color)) {
      isValidColor = true;
    } else if (isHex(color)) {
      isValidColor = true;
      if (color.indexOf('#') === -1) {
        color = `#${color}`;
      }
    }

    if (isValidColor) {
      setColor(color);
      onChange(color);
    }
  };

  const handleChangeHue: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const hue = event.target.value;
    const color = muiColors[hue][shades[selectedShadeIdx]];

    setColor(color);
    setInput(color);
    onChange(color);
    setSelectedHue(hue);
  };

  const handleChangeValue: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const color = event.target.value;
    setColor(color);
    setInput(color);
    onChange(color);
  };

  const handleChangeShade = (_: Event, shade: number | number[]) => {
    const color = muiColors[selectedHue][shades[shade as number]];
    setColor(color);
    setInput(color);
    onChange(color);
    setSelectedShadeIdx(shade as number);
  };

  useEffect(() => {
    if (defaultColor) {
      setInput(defaultColor);
      setColor(defaultColor);
      if (detectHuesShades[defaultColor]) {
        setSelectedHue(detectHuesShades[defaultColor].hue);
        setSelectedShadeIdx(detectHuesShades[defaultColor].shadeIdx);
      }
    }
  }, [defaultColor]);

  return (
    <Box className={classes.wrapperColorPalette}>
      <Input value={input} onChange={handleChangeColor} fullWidth />
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
        <Typography variant="body2">{t('my_shop.shade')}:</Typography>
        <Slider
          min={0}
          max={13}
          step={1}
          value={selectedShadeIdx}
          aria-labelledby="ShadeSliderLabel"
          sx={{ flex: 1, ml: 2, mr: 2 }}
          onChange={handleChangeShade}
        />
        <Typography width="40px" textAlign="right">
          {shades[selectedShadeIdx]}
        </Typography>
      </Box>
      <Box className={classes.wrapperPalette}>
        {basicColors.map((backgroundColor, index) => (
          <TooltipRadio
            key={index}
            sx={{ p: 0 }}
            color="default"
            value={backgroundColor}
            onChange={handleChangeValue}
            checked={color.toLowerCase() === backgroundColor}
            style={{
              border: `1px solid ${backgroundColor === '#ffffff' ? '#D9D9D9' : backgroundColor}`,
              borderRadius: '4px',
              backgroundColor,
            }}
            icon={<Box sx={{ width: '100%', paddingTop: '100%' }} />}
            checkedIcon={
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  color: backgroundColor === '#ffffff' ? '#000000' : 'common.white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CheckIcon style={{ fontSize: 30 }} />
              </Box>
            }
          />
        ))}

        {hues.map((hue, index) => {
          const backgroundColor = muiColors[hue][shades[selectedShadeIdx]];

          return (
            <TooltipRadio
              key={index}
              value={hue}
              sx={{ p: 0 }}
              color="default"
              onChange={handleChangeHue}
              checked={color.toLowerCase() === backgroundColor}
              icon={<Box sx={{ width: '100%', paddingTop: '100%' }} style={{ backgroundColor, borderRadius: '4px' }} />}
              checkedIcon={
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    border: 1,
                    borderColor: 'white',
                    color: 'common.white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '4px',
                  }}
                  style={{ backgroundColor }}
                >
                  <CheckIcon style={{ fontSize: 30 }} />
                </Box>
              }
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ColorPalette;
