import { useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer } from '@mui/x-data-grid';
import { Control, UseFieldArrayAppend, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { FormRegistrationConditionValues, initInfoType } from './RegistrationCondition';

import { AcquisitionInformationType } from '~/types/my-shop';

interface AcquisitionToolbarProps {
  control: Control<FormRegistrationConditionValues, any>;
  append: UseFieldArrayAppend<FormRegistrationConditionValues, 'acquisitionInformation'>;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

const AcquisitionToolbar = (props: AcquisitionToolbarProps) => {
  const { control, append, setRowModesModel } = props;
  const { t } = useTranslation();

  const acquisitionInformation = useWatch({ control, name: 'acquisitionInformation' });

  const typeNotExisting = useMemo(() => {
    const currentInfoTypes = acquisitionInformation?.map((info) => info?.type);
    return Object.values(AcquisitionInformationType).find((type) => !currentInfoTypes?.includes(type));
  }, [acquisitionInformation]);

  const handleClick = () => {
    const newId = uuidv4();
    if (typeNotExisting) {
      append({
        id: newId,
        displayName: initInfoType(t)[typeNotExisting!].label,
        required: false,
        type: typeNotExisting!,
      });
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [newId]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    }
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} disabled={!typeNotExisting}>
        {t('member_site.add_acquisition_information')}
      </Button>
    </GridToolbarContainer>
  );
};

export default AcquisitionToolbar;
