import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import DialogCloseButton from '../dialog/dialog-close-button';

import { GetMeDocument, useCreateOrganizationMutation } from '~/graphql/member/types';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({}));

interface Props extends DialogProps {
  onClose: () => void;
  classes?: StyledComponentProps<typeof useStyles>['classes'] & DialogProps['classes'];
}

const CreateOrganizationDialog: React.FC<Props> = (props) => {
  const { open, onClose, ...others } = props;

  return (
    <Dialog open={open} onClose={onClose} {...others} maxWidth="sm" fullWidth>
      <Content {...props} />
    </Dialog>
  );
};

const schema = yup.object({
  name: yup.string().trim().max(100).required(),
  contactEmail: yup.string().trim().email('form_validation.invalid_email').required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const Content: React.FC<Props> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const [createOrganization] = useCreateOrganizationMutation({
    refetchQueries: [GetMeDocument],
  });

  const MAX_LENGTH = 100;

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      contactEmail: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: FormValues) => {
      try {
        await createOrganization({
          variables: {
            input: {
              name: data.name.trim(),
              contactEmail: data.contactEmail.trim(),
            },
          },
        });
        onClose();
      } catch (error: any) {
        if (errors.name) {
          setError('name', { message: 'my_shop.message.errors.max' });
        } else if (errors.contactEmail) {
          setError('contactEmail', { message: 'my_shop.message.errors.max' });
        }
      }
    },
    [errors, setError, createOrganization, onClose]
  );

  return (
    <>
      <DialogCloseButton onClick={onClose} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle>
        <Typography variant="h5" component="p">
          {t('create_organization_dialog.title')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              label={t('organization_name')}
              variant="outlined"
              margin="normal"
              sx={{ marginTop: 0 }}
              error={!!errors.name?.message}
              helperText={!!errors.name?.message && t('my_shop.message.errors.max', { max: MAX_LENGTH })}
              {...field}
            />
          )}
        />
        <Controller
          name="contactEmail"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              label={t('contact_email')}
              variant="outlined"
              margin="normal"
              error={!!errors.contactEmail?.message}
              helperText={t(errors.contactEmail?.message as any)}
              {...field}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t('create')}
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateOrganizationDialog;
