import { FC } from 'react';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { ViewMode, ViewModeProps } from '../custom-grid-toolbar-search-by-api';

const useStyles = makeStyles()((theme) => ({
  wrapperToolbar: {
    display: 'flex',
    justifyContent: 'end',
    '.viewMode': {
      display: 'flex',
    },
  },
  searchInput: {
    marginRight: '16px',
    '& .MuiInputBase-root.MuiInput-root': {
      lineHeight: '36.5px',
      '& .MuiInputBase-input': {
        height: '36.5px',
        boxSizing: 'border-box',
      },
    },
  },
}));

const CustomHeaderGallery: FC<ViewModeProps> = (props) => {
  const { search, handleSearch } = props;

  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapperToolbar}>
      <TextField
        id="standard-basic"
        variant="standard"
        placeholder={t('search')}
        value={search}
        className={classes.searchInput}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box className="viewMode">
        <ViewMode {...props} />
      </Box>
    </Box>
  );
};

export default CustomHeaderGallery;
