export const prefectures = {
  en: {
    Aichi: 'Aichi',
    Akita: 'Akita',
    Aomori: 'Aomori',
    Chiba: 'Chiba',
    Ehime: 'Ehime',
    Fukui: 'Fukui',
    Fukuoka: 'Fukuoka',
    Fukushima: 'Fukushima',
    Gifu: 'Gifu',
    Gunma: 'Gunma',
    Hiroshima: 'Hiroshima',
    Hokkaidō: 'Hokkaidō',
    Hyōgo: 'Hyōgo',
    Ibaraki: 'Ibaraki',
    Ishikawa: 'Ishikawa',
    Iwate: 'Iwate',
    Kagawa: 'Kagawa',
    Kagoshima: 'Kagoshima',
    Kanagawa: 'Kanagawa',
    Kōchi: 'Kōchi',
    Kumamoto: 'Kumamoto',
    Kyoto: 'Kyoto',
    Mie: 'Mie',
    Miyagi: 'Miyagi',
    Miyazaki: 'Miyazaki',
    Nagano: 'Nagano',
    Nagasaki: 'Nagasaki',
    Nara: 'Nara',
    Niigata: 'Niigata',
    Ōita: 'Ōita',
    Okayama: 'Okayama',
    Okinawa: 'Okinawa',
    Osaka: 'Osaka',
    Saga: 'Saga',
    Saitama: 'Saitama',
    Shiga: 'Shiga',
    Shimane: 'Shimane',
    Shizuoka: 'Shizuoka',
    Tochigi: 'Tochigi',
    Tokushima: 'Tokushima',
    Tokyo: 'Tokyo',
    Tottori: 'Tottori',
    Toyama: 'Toyama',
    Wakayama: 'Wakayama',
    Yamagata: 'Yamagata',
    Yamaguchi: 'Yamaguchi',
    Yamanashi: 'Yamanashi',
  },
  ja: {
    Aichi: '愛知県',
    Akita: '秋田県',
    Aomori: '青森県',
    Chiba: '千葉県',
    Ehime: '愛媛県',
    Fukui: '福井県',
    Fukuoka: '福岡県',
    Fukushima: '福島県',
    Gifu: '岐阜県',
    Gunma: '群馬県',
    Hiroshima: '広島県',
    Hokkaidō: '北海道',
    Hyōgo: '兵庫県',
    Ibaraki: '茨城県',
    Ishikawa: '石川県',
    Iwate: '岩手県',
    Kagawa: '香川県',
    Kagoshima: '鹿児島県',
    Kanagawa: '神奈川県',
    Kōchi: '高知県',
    Kumamoto: '熊本県',
    Kyoto: '京都府',
    Mie: '三重県',
    Miyagi: '宮城県',
    Miyazaki: '宮崎県',
    Nagano: '長野県',
    Nagasaki: '長崎県',
    Nara: '奈良県',
    Niigata: '新潟県',
    Ōita: '大分県',
    Okayama: '岡山県',
    Okinawa: '沖縄県',
    Osaka: '大阪府',
    Saga: '佐賀県',
    Saitama: '埼玉県',
    Shiga: '滋賀県',
    Shimane: '島根県',
    Shizuoka: '静岡県',
    Tochigi: '栃木県',
    Tokushima: '徳島県',
    Tokyo: '東京都',
    Tottori: '鳥取県',
    Toyama: '富山県',
    Wakayama: '和歌山県',
    Yamagata: '山形県',
    Yamaguchi: '山口県',
    Yamanashi: '山梨県',
  },
};
