import { ReactNode, useCallback, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import DialogCloseButton from '../dialog/dialog-close-button';

import { MEMBER_ACTIONS } from '~/config/roleConfig';
import { CODE } from '~/constants/code';
import {
  OrganizationUserRole,
  ListOrganizationUsersDocument,
  useAddOrganizationUserMutation,
} from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()(() => ({
  dialog: {
    '.MuiDialog-paper': {
      '& > .MuiIconButton-root': {
        top: '14px',
      },
      '.dialog-title': {
        padding: '16px',
        fontColor: '#444444',
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '133.4%',
      },
      '.MuiDivider-root': {
        margin: '0 16px',
        borderColor: '#9E9E9E',
      },
      '.MuiDialogContent-root': {
        padding: '16px',
        marginTop: '16px',
        '.MuiFormControl-root': {
          margin: 0,
          ':not(:first-of-type)': {
            marginTop: '23px',
          },
          '.MuiFormHelperText-root.Mui-error': {
            whiteSpace: 'pre-wrap',
          },
        },
      },
      '.MuiDialogActions-root': {
        padding: '16px',
        '.MuiButtonBase-root:not(:first-of-type)': {
          marginLeft: '16px',
        },
      },
    },
  },
}));

export const initRoleOptions = (t: TFunction) => [
  {
    value: OrganizationUserRole.Admin,
    label: t('role_options.admin'),
  },
  {
    value: OrganizationUserRole.Member,
    label: t('role_options.member'),
  },
  {
    value: OrganizationUserRole.Viewer,
    label: t('role_options.viewer'),
  },
];

interface Props extends DialogProps {
  onClose: () => void;
  classes?: StyledComponentProps<typeof useStyles>['classes'] & DialogProps['classes'];
}

const AddUserDialog: React.FC<Props> = (props) => {
  const { open, onClose, ...others } = props;
  const { classes } = useStyles();

  return (
    <Dialog open={open} onClose={onClose} {...others} className={classes.dialog} maxWidth="sm" fullWidth>
      <Content {...props} />
    </Dialog>
  );
};

const schema = yup.object({
  email: yup.string().email('form_validation.invalid_email').required(),
  role: yup.mixed<OrganizationUserRole>().oneOf(Object.values(OrganizationUserRole)).required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const Content: React.FC<Props> = (props) => {
  const { onClose } = props;

  const { t } = useTranslation();
  const { selectedOrganization } = useAccount();

  const [addOrganizationUser] = useAddOrganizationUserMutation({
    refetchQueries: [ListOrganizationUsersDocument],
  });

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      role: MEMBER_ACTIONS.ADD[selectedOrganization.role][0] || OrganizationUserRole.Member,
    },
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (data: FormValues) => {
      try {
        await addOrganizationUser({
          variables: {
            input: {
              email: data.email,
              role: data.role,
            },
          },
        });
        enqueueSnackbar(t('toast_message.add_member_successfully'), { variant: 'success' });
        onClose();
      } catch (error: any) {
        if (error.message === CODE.USER_NEED_REGISTER_FIREBASE) {
          setError('email', { message: 'form_validation.email_not_registered_gu_account' });
        } else {
          enqueueSnackbar(
            error.message === CODE.USER_ALREADY_ADDED ? t('toast_message.this_member_added') : error.message,
            { variant: 'error' }
          );
        }
      }
    },
    [addOrganizationUser, onClose, enqueueSnackbar, t, setError]
  );

  const roleOptions = useMemo(
    () =>
      initRoleOptions(t).reduce((result, option) => {
        if (MEMBER_ACTIONS.ADD[selectedOrganization.role]?.includes(option.value)) {
          result.push(
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        }
        return result;
      }, [] as ReactNode[]),
    [selectedOrganization.role, t]
  );

  return (
    <>
      <DialogCloseButton onClick={onClose} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle className="dialog-title">{t('add_member_dialog.title')}</DialogTitle>
      <Divider />
      <DialogContent>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              label={t('email_address')}
              variant="outlined"
              margin="normal"
              sx={{ marginTop: 0 }}
              InputProps={{
                type: 'email',
              }}
              error={!!errors.email?.message}
              helperText={
                errors.email?.message ? (
                  <Trans
                    i18nKey={t(errors.email?.message)}
                    components={{
                      lnk: <Link sx={{ color: '#1098FC' }} href="https://account.gu.net/" target="_blank" />,
                    }}
                  />
                ) : (
                  ''
                )
              }
              disabled={isSubmitting}
              {...field}
            />
          )}
        />
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              label={t('role')}
              variant="outlined"
              margin="normal"
              error={!!errors.role?.message}
              helperText={t(errors.role?.message as any)}
              {...field}
              select
              disabled={isSubmitting}
            >
              {roleOptions}
            </TextField>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="primary" variant="outlined" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </>
  );
};

export default AddUserDialog;
