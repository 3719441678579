import React from 'react';

import { Divider } from '@mui/material';
import Button, { ButtonTypeMap } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { DialogProps } from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomDialog from '../custom-dialog';

import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      '& > .MuiIconButton-root': {
        top: '20px',
        padding: 0,
      },
      '& > .MuiDialogTitle-root': {
        padding: 0,
        lineHeight: '32px',
      },
      '& > .MuiDialogContent-root': {
        padding: 0,
        marginTop: '4px',
        p: {
          margin: 0,
          color: '#444',
          lineHeight: '28px',
        },
      },
    },
  },
}));

export type ICloseDialog = (params?: { isSubmitted?: boolean }) => Promise<void> | void;

export interface ConfirmationDialogProps extends DialogProps {
  title: string;
  content?: string;
  confirmTitle?: string;
  colorSubmit?: ButtonTypeMap['props']['color'];
  onClose: ICloseDialog;
  onlyConfirm?: boolean;
  onConfirm: () => Promise<void>;
  classes?: StyledComponentProps<typeof useStyles>['classes'] & DialogProps['classes'];
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  content,
  colorSubmit,
  onlyConfirm,
  confirmTitle,
  onClose,
  onConfirm,
  ...others
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({});

  const onSubmit = async () => {
    try {
      await onConfirm();
      onClose({ isSubmitted: true });
    } catch (err) {}
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <CustomDialog
      {...others}
      hideTitleDivider
      dialogTitle={title}
      className={classes.dialog}
      dialogContent={
        <>
          <Typography color="inherit" component="p">
            {content || t('my_shop.message.confirm_delete')}
          </Typography>
          <Divider />
        </>
      }
      actions={[
        <>
          {!onlyConfirm && (
            <Button key={0} color="inherit" variant="outlined" onClick={handleClose} disabled={isSubmitting}>
              {t('cancel')}
            </Button>
          )}
          <Button
            key={1}
            variant="contained"
            disabled={isSubmitting}
            color={colorSubmit || 'error'}
            endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
            onClick={handleSubmit(onSubmit)}
          >
            {confirmTitle || t('delete')}
          </Button>
        </>,
      ]}
      onClose={handleClose}
    />
  );
};

export default ConfirmationDialog;
