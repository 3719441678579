import { FC, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ERC721G__factory } from '@gu-corp/gu-token-studio-contracts';
import { useAccount as useWalletAccount, WalletConnectorDialog } from '@gusdk/gu-wallet-connector';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  GridColDef,
  GridColumnResizeParams,
  GridInputRowSelectionModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { JsonRpcProvider } from 'ethers';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { VIEW_MODE } from '../custom-grid-toolbar-search-by-api';
import ConfirmationDialog from '../dialog/confirmation-dialog';
import ListTable, { IHandleListTable } from '../list-table/v2';
import SquareImage from '../SquareImage';

import { CODE } from '~/constants/code';
import { ShopDetailContext, ShopDetailContextValue } from '~/contexts/ShopDetailWrapper';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import {
  CreateMyShopCollectionTokenInput,
  MyShopCollectionsTokensQueryKey,
  QueryOperator,
  useAttachMyShopCollectionTokensMutation,
  useListMyShopCollectionTokensQuery,
  useUnAttachMyShopCollectionTokensMutation,
} from '~/graphql/member/types';
import {
  _SubgraphErrorPolicy_,
  OrderDirection,
  Token_OrderBy,
  TokensQuery,
  useTokensQuery,
} from '~/graphql/subgraph/types';
import { useGrantTransferPermission } from '~/hooks/useListingCollection';
import { useAccount } from '~/hooks/with-account';
import {
  ShopCollectionDetailContext,
  ShopCollectionDetailContextValue,
} from '~/pages/my-shop/shop-detail/components/CollectionDetail/ShopCollectionDetail';
import { GRANT_PERMISSION, STATUS } from '~/types/my-shop';
import { ITokensSubgraphQuery } from '~/types/token';
import { CancelPromiseResponse, cancelPromise } from '~/utils/cancelPromise';
import { convertOrderToAPI, convertOrderToSubgraph, getLocalStorage, setLocalStorageItems } from '~/utils/common';
import { getNFTMetadata } from '~/utils/getNFTMetadata';
import { truncateEthAddress } from '~/utils/string.utils';

let getCollectionPromise: CancelPromiseResponse;

const useStyles = makeStyles()(() => ({
  dialog: {
    '.MuiPaper-root': {
      width: '100%',
      height: '80vh',
      maxWidth: 1180,
      position: 'relative',
    },
    '.guide-text': {
      marginLeft: '24px',
      color: '#333',
    },
    '.MuiDialogTitle-root': {
      paddingBottom: '0',
      '.MuiTypography-h2': {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '32px',
      },
      '.MuiTypography-caption': {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px',
      },
    },
    '.MuiDialogContent-root': {
      padding: '0 24px',
      '& > .MuiBox-root': {
        height: '100%',
      },
    },
    '.MuiDialogActions-root': {
      padding: '16px 24px',
    },
  },
  layer: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
    opacity: 0.3,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'white',
  },
  table: {
    '.MuiOutlinedInput-root .MuiSelect-outlined': {
      paddingTop: '14px',
      paddingBottom: '14px',
    },
  },
}));

interface AddMyShopCollectionNFTProps {
  open: boolean;
  onClose: () => void;
}

interface NFTInfoInSelectList {
  id: string;
  url: string;
  name: string;
  description: string;
  status: GRANT_PERMISSION;
  [Token_OrderBy.Owner]: string;
  [Token_OrderBy.TokenId]: string;
  [Token_OrderBy.MintTime]: string;
}

export const statusList = (t: TFunction) => ({
  [GRANT_PERMISSION.GRANTED]: {
    color: 'success',
    label: t('granted'),
    value: GRANT_PERMISSION.GRANTED,
  },
  [GRANT_PERMISSION.NOT_GRANTED]: {
    color: 'error',
    label: t('not_granted'),
    value: GRANT_PERMISSION.NOT_GRANTED,
  },
  [GRANT_PERMISSION.NOT_PERMISSION]: {
    color: 'warning',
    label: t('not_permission'),
    value: GRANT_PERMISSION.NOT_PERMISSION,
  },
  [GRANT_PERMISSION.UNAVAILABLE]: {
    color: 'error',
    label: t('unavailable'),
    value: GRANT_PERMISSION.UNAVAILABLE,
  },
});

const AddMyShopCollectionNFT: FC<AddMyShopCollectionNFTProps> = (props) => {
  const { open, onClose } = props;

  const myShopData = useContext(ShopDetailContext) as ShopDetailContextValue;
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;
  const shopCollectionDetailData = useContext(ShopCollectionDetailContext) as ShopCollectionDetailContextValue;

  const { id, collectionId } = useParams();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { account } = useWalletAccount();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedOrganization } = useAccount();

  const [isAddingNFT, setIsAddingNFT] = useState(false);
  const [rows, setRows] = useState<NFTInfoInSelectList[]>([]);
  const [openWalletConnector, setOpenWalletConnector] = useState(false);
  const [isLoadingCollections, setIsLoadingCollections] = useState(true);
  const [openExplainationDialog, setOpenExplainationDialog] = useState(false);
  const [selectedNFTIds, setSelectedNFTIds] = useState<GridRowSelectionModel | undefined>([]);
  const [tempSelectedNFTIds, setTempSelectedNFTIds] = useState<GridRowSelectionModel | undefined>([]);

  const refMasterWallet = useRef(selectedOrganization?.masterWalletAddress);
  refMasterWallet.current = selectedOrganization?.masterWalletAddress;
  const [handleGrantTransferPermission] = useGrantTransferPermission({
    setOpenWalletConnector,
  });

  const [tokensQuery, setTokensQuery] = useState<ITokensSubgraphQuery>({
    first: 1000,
    subgraphError: _SubgraphErrorPolicy_.Deny,
    where: {
      tokenID: undefined,
      owner_in: [account.toLowerCase() ?? ''],
      contract_: {
        id: shopCollectionDetailData?.data?.collection?.contractAddress?.toLowerCase() ?? '',
      },
    },
    orderBy: (getLocalStorage('shop_attach_nft_list_sort') as Token_OrderBy) || Token_OrderBy.MintTime,
    orderDirection: (getLocalStorage('shop_attach_nft_list_order') as OrderDirection) || OrderDirection.Desc,
  });

  const updateTokensQuery = (newValue: IHandleListTable) => {
    const orderBy = newValue.sortBy ? { orderBy: newValue.sortBy as Token_OrderBy } : {};
    const orderDirection = newValue.orderBy ? { orderDirection: convertOrderToSubgraph(newValue.orderBy) } : {};
    const searchText = (value: ITokensSubgraphQuery) =>
      typeof newValue.searchText === 'string'
        ? { where: { ...value.where, tokenID: newValue.searchText || undefined } }
        : {};
    return setTokensQuery((value) => ({
      ...value,
      ...searchText(value),
      ...orderBy,
      ...orderDirection,
    }));
  };

  useEffect(() => {
    setLocalStorageItems({
      shop_attach_nft_list_sort: tokensQuery?.orderBy,
      shop_attach_nft_list_order: tokensQuery?.orderDirection,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokensQuery?.orderBy, tokensQuery?.orderDirection]);

  const setDataGridRows = async (data: TokensQuery) => {
    try {
      if (getCollectionPromise?.cancelCallback) {
        getCollectionPromise.cancelCallback();
      }
      getCollectionPromise = cancelPromise(async () => {
        let rejectSwitch = false;
        try {
          setIsLoadingCollections(true);
          let newRows: NFTInfoInSelectList[] = [];
          const collection = shopCollectionDetailData?.data?.collection;
          if (account && open && collection?.contractAddress) {
            if (!data) {
              setIsLoadingCollections(false);
              return [];
            }
            const network = supportedNetworks?.[collection?.network || ''];
            const provider = new JsonRpcProvider(network?.rpcUrl);
            const contract = ERC721G__factory.connect(collection?.contractAddress!, provider as any);
            newRows = await Promise.all(
              await (data?.tokens || []).reduce(async (resultPromise, item) => {
                let status;
                const newResult = await resultPromise;
                try {
                  const ownerLowerCase = item.owner.id.toLowerCase();
                  const approvedAddress = await contract.getApproved(item.tokenID);
                  const approvedAddressLowerCase = approvedAddress.toLowerCase();
                  const masterWalletLowerCase = refMasterWallet.current?.toLowerCase() || '';
                  status = network?.testMode
                    ? GRANT_PERMISSION.UNAVAILABLE
                    : [ownerLowerCase, approvedAddressLowerCase].includes(masterWalletLowerCase)
                    ? GRANT_PERMISSION.GRANTED
                    : GRANT_PERMISSION.NOT_GRANTED;
                } catch (err: any) {
                  if (
                    !rejectSwitch &&
                    err?.error?.code === CODE.INTERNAL_ERROR &&
                    err?.error?.data?.code === CODE.NOT_SUPPORT_COLLECTION
                  ) {
                    rejectSwitch = false;
                    status = GRANT_PERMISSION.UNAVAILABLE;
                    enqueueSnackbar(t('contract_is_not_support'), { variant: 'error' });
                  }
                }
                let metadata = await getNFTMetadata(collection.uuid, item);
                newResult.push({
                  status,
                  id: item.tokenID,
                  url: metadata.metadataContent.image,
                  name: metadata.metadataContent.name,
                  [Token_OrderBy.TokenId]: item.tokenID,
                  [Token_OrderBy.MintTime]: item.mintTime,
                  description: metadata.metadataContent.description,
                  [Token_OrderBy.Owner]: item.owner.id || undefined,
                } as NFTInfoInSelectList);
                return newResult;
              }, Promise.resolve([] as NFTInfoInSelectList[]))
            );
          }
          setIsLoadingCollections(false);
          return newRows;
        } catch (err: any) {
          setIsLoadingCollections(false);
          enqueueSnackbar(err?.error?.message || err.message || t('my_shop.message.error'), { variant: 'error' });
          return [];
        }
      });
      const newRows = await getCollectionPromise.awaitCallback;
      setRows(newRows as NFTInfoInSelectList[]);
    } catch (err: any) {}
  };

  const { loading: loadingTokens } = useTokensQuery({
    fetchPolicy: 'no-cache',
    variables: tokensQuery,
    skip: shopCollectionDetailData.loading,
    context: {
      blockchain: 'subgraph',
      subgraphUrl: supportedNetworks[shopCollectionDetailData?.data?.collection?.network || ''].subgraphUrl,
    },
    onCompleted: setDataGridRows,
    onError: () => setRows([]),
  });
  useListMyShopCollectionTokensQuery({
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        fields: [
          {
            operator: QueryOperator.Equals,
            value: [shopCollectionDetailData.data.uuid || ''],
            key: MyShopCollectionsTokensQueryKey.MyshopCollectionUuid,
          },
        ],
      },
    },
    onCompleted: (data) => {
      const selectedIds = data.listMyShopCollectionTokens.items?.reduce((output, current) => {
        const newId = current.tokenId;
        if (newId) {
          output.push(newId);
        }
        return output;
      }, [] as string[]);
      setSelectedNFTIds(selectedIds);
      setTempSelectedNFTIds(selectedIds);
    },
  });
  const [attachMyShopCollectionTokens] = useAttachMyShopCollectionTokensMutation();
  const [unAttachMyShopCollectionTokens] = useUnAttachMyShopCollectionTokensMutation();
  // const [getAllMyShopCollectionsContainNFT] = useGetAllMyShopCollectionsContainNftLazyQuery();

  const columns: GridColDef<NFTInfoInSelectList>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value }) => {
          return (
            <Box width="64px">
              <SquareImage src={value} />
            </Box>
          );
        },
      },
      {
        field: 'name',
        sortable: false,
        headerName: t('my_shop.nft_name'),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        field: 'status',
        sortable: false,
        headerName: t('status'),
        width: JSON.parse(columnsSize).status || 150,
        renderCell: ({ row }) => {
          const info = statusList(t)[row?.status as GRANT_PERMISSION];
          return <Chip label={`${info?.label || ''}`} color={info?.color as 'error' | 'success' | 'warning'} />;
        },
      },
      {
        headerName: t('token_id'),
        field: Token_OrderBy.TokenId,
        width: JSON.parse(columnsSize).symbol || 150,
      },
      {
        sortable: false,
        field: Token_OrderBy.Owner,
        headerName: t('owner_address'),
        width: JSON.parse(columnsSize).ownerAddress || 150,
        valueFormatter: ({ value }) => (value ? truncateEthAddress(value) : '-'),
      },
      {
        type: 'date',
        headerName: t('created_at'),
        field: Token_OrderBy.MintTime,
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).createdAt || 115,
        valueFormatter: ({ value }) => (value ? moment.unix(value).format(t('date_format')) : '-'),
      },
    ];
  }, [t]);

  const handleCloseWalletConnector = () => {
    setOpenWalletConnector(false);
  };

  const checkConnectionStatus = () => {
    setIsAddingNFT(true);
  };

  const handleRowSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
    setTempSelectedNFTIds(rowSelectionModel);
  };

  const onOpenExplainDialog = () => {
    setOpenExplainationDialog(true);
  };

  const onCloseExplainDialog = useCallback(async () => {
    setOpenExplainationDialog(false);
  }, []);

  const onColumnWidthChange = (data: GridColumnResizeParams) => {
    const columnsSize = localStorage.getItem('columnsSize');
    if (columnsSize) {
      const parsed = JSON.parse(columnsSize);
      if (parsed) {
        parsed[data.colDef.field] = data.width;
        localStorage.setItem('columnsSize', JSON.stringify(parsed));
      }
    } else {
      localStorage.setItem('columnsSize', JSON.stringify({ [data.colDef.field]: data.width }));
    }
  };

  const onAttachCollection = useCallback(async () => {
    try {
      if (!isAddingNFT) {
        return;
      }
      const addedTokenIds = tempSelectedNFTIds?.filter((id) => !selectedNFTIds?.includes(id)) || [];
      const removedTokenIds = selectedNFTIds?.filter((id) => !tempSelectedNFTIds?.includes(id)) || [];
      const successfulTokenList: CreateMyShopCollectionTokenInput[] = [];
      const provider = new JsonRpcProvider(
        supportedNetworks?.[shopCollectionDetailData?.data?.collection?.network || '']?.rpcUrl
      );
      const contract = ERC721G__factory.connect(
        shopCollectionDetailData.data.collection?.contractAddress!,
        provider as any
      );
      if (addedTokenIds.length > 0) {
        const newAddNFTs = rows.reduce((result, current) => {
          if (addedTokenIds?.includes(current[Token_OrderBy.TokenId])) {
            result[current[Token_OrderBy.TokenId]] = current;
          }
          return result;
        }, {} as { [key: string]: NFTInfoInSelectList });
        for (let addedTokenId of addedTokenIds) {
          const isAddedNFT = addedTokenIds?.includes(addedTokenId);
          if (isAddedNFT) {
            const newAddNFT = newAddNFTs[addedTokenId];
            const hasNotGrantedNFT = newAddNFT.status === GRANT_PERMISSION.NOT_GRANTED;
            if (hasNotGrantedNFT) {
              onOpenExplainDialog();
            }
            const owner = await contract.ownerOf(addedTokenId);
            const ownerLowerCase = owner.toLowerCase();
            const approvedAddress = await contract.getApproved(addedTokenId);
            const approvedAddressLowerCase = approvedAddress.toLowerCase();
            const masterWalletLowerCase = refMasterWallet.current?.toLowerCase() || '';
            if (![ownerLowerCase, approvedAddressLowerCase].includes(masterWalletLowerCase)) {
              try {
                const grantSuccessfully = await handleGrantTransferPermission(
                  shopCollectionDetailData.data.collection!,
                  addedTokenId.toString(),
                  true
                );
                onCloseExplainDialog();
                if (!grantSuccessfully) {
                  continue;
                }
              } catch {
                continue;
              }
            }
            successfulTokenList.push({
              myShopCollectionUuid: shopCollectionDetailData.data.uuid!,
              tokenId: newAddNFT[Token_OrderBy.TokenId],
              tokenName: newAddNFT.name,
              tokenDesc: newAddNFT.description,
              tokenMintTime: newAddNFT[Token_OrderBy.MintTime],
              status: STATUS.SUSPENSION,
              name: newAddNFT.name,
              nameJa: newAddNFT.name,
              price: null,
              order: null,
            });
          }
        }
      }
      let hasChange = false;
      if (successfulTokenList.length > 0) {
        hasChange = true;
        await attachMyShopCollectionTokens({
          variables: {
            input: {
              myShopCollectionUuid: shopCollectionDetailData.data.uuid!,
              tokens: successfulTokenList,
            },
          },
        });
      }
      if (removedTokenIds.length > 0) {
        hasChange = true;
        await unAttachMyShopCollectionTokens({
          variables: {
            input: {
              myShopCollectionUuid: shopCollectionDetailData.data.uuid!,
              tokenIds: removedTokenIds as string[],
            },
          },
        });
      }

      if (hasChange) {
        await myShopData.refetch({ myShopUuid: id || '' });
        await shopCollectionDetailData?.refetch({
          input: {
            shopUuid: id ?? '',
            collectionUuid: collectionId ?? '',
          },
        });
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
      } else {
        setTempSelectedNFTIds(selectedNFTIds);
      }
      setIsAddingNFT(false);
    } catch (err: any) {
      setIsAddingNFT(false);
      enqueueSnackbar(err.message || t('my_shop.message.error'), { variant: 'error' });
    }
  }, [
    id,
    rows,
    myShopData,
    isAddingNFT,
    collectionId,
    selectedNFTIds,
    supportedNetworks,
    tempSelectedNFTIds,
    shopCollectionDetailData,
    t,
    enqueueSnackbar,
    onCloseExplainDialog,
    attachMyShopCollectionTokens,
    handleGrantTransferPermission,
    unAttachMyShopCollectionTokens,
  ]);

  useEffect(() => {
    if (isAddingNFT) {
      onAttachCollection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingNFT]);

  return (
    <Dialog onClose={onClose} open={open} className={classes.dialog}>
      <DialogTitle component="div">
        <Typography variant="h2">{t('add_nft')}</Typography>
        <Typography variant="caption">{t('my_shop.add_nft_description')}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Divider sx={{ borderColor: '#9E9E9E', width: 'calc(100% - 48px)', margin: '16px auto' }} />
      <DialogContent>
        <ListTable
          rows={rows}
          rowHeight={84}
          columns={columns}
          disableColumnMenu
          checkboxSelection
          autoHeight={false}
          pagination={false}
          onlyMode={VIEW_MODE.LIST}
          className={classes.table}
          disableRowSelectionOnClick
          searchLabel={t('token_id')}
          keepNonExistentRowsSelected
          search={tokensQuery.where.tokenID}
          noRowsMessage={t('my_shop.message.no_nft')}
          isLoading={isLoadingCollections || loadingTokens}
          rowSelectionModel={tempSelectedNFTIds as GridInputRowSelectionModel}
          isRowSelectable={({ row }) => row.status !== GRANT_PERMISSION.UNAVAILABLE}
          sort={{
            sortBy: tokensQuery.orderBy,
            orderBy: convertOrderToAPI(tokensQuery.orderDirection),
          }}
          onSort={updateTokensQuery}
          onColumnWidthChange={onColumnWidthChange}
          onRowSelectionModelChange={handleRowSelectionChange}
          onSearch={(v) => updateTokensQuery({ searchText: v || '' })}
        />
      </DialogContent>
      <DialogActions>
        <Box>
          <Button variant="outlined" color="primary" onClick={onClose} sx={{ marginRight: '16px' }}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={checkConnectionStatus}
            disabled={isAddingNFT}
            endIcon={isAddingNFT && <CircularProgress size={20} color="inherit" />}
          >
            {t('add')}
          </Button>
        </Box>
      </DialogActions>
      {isAddingNFT && <Box className={classes.layer}></Box>}
      <ConfirmationDialog
        onlyConfirm
        confirmTitle="Ok"
        colorSubmit="primary"
        open={openExplainationDialog}
        onClose={onCloseExplainDialog}
        title={t('my_shop.confirm_add_nft_title')}
        content={t('my_shop.confirm_add_nft')}
        onConfirm={onCloseExplainDialog}
      />

      <WalletConnectorDialog open={openWalletConnector} onClose={handleCloseWalletConnector} />
    </Dialog>
  );
};

export default memo(AddMyShopCollectionNFT);
