import { useEffect, memo } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import UserLayout from '~/components/app-layout/user-layout';
import CustomCardTable from '~/components/custom-card-table';
import PermissionsView from '~/components/PermissionsView';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';

interface IHeaderAction {
  buttonName?: string;
  onClick?: () => void;
}

const useStyles = makeStyles()((theme) => ({
  content: {
    height: '100%',
    width: '100%',
  },
  welcomeBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  welcome: {
    fontSize: 24,
    color: '#333333',
  },
  description: {
    marginTop: 8,
    color: '#0000008A',
    fontSize: 16,
  },
  lastLogin: {
    fontSize: 14,
    color: '#0000008A',
    alignSelf: 'end',
    margin: '8px 0 16px 0',
  },
  cardContent: {
    padding: 24,
  },
  contentHeader: {
    fontSize: 20,
    fontWeight: 500,
    color: '#333333',
  },
  contentText: {
    fontSize: 16,
    color: '#0000008A',
    fontWeight: 400,
    marginTop: '8px',
  },
}));

const Home: React.FC = () => {
  const { classes } = useStyles(undefined, { props: {} });
  const { t } = useTranslation();
  const firebaseUser = useFirebaseUser();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t('home_page.home');
  }, [t]);

  const HeaderAction = (props: IHeaderAction) => {
    return (
      <>
        <Button variant="text" color="primary" style={{ textTransform: 'none' }} onClick={props.onClick}>
          <Typography sx={{ fontSize: 16 }}>{props.buttonName || t('go')}</Typography>
          <ArrowForwardIosIcon sx={{ fontSize: '12px', marginLeft: '8px' }} />
        </Button>
      </>
    );
  };

  return (
    <UserLayout>
      <Box className={classes.content}>
        <Box className={classes.welcomeBox}>
          <Typography className={classes.welcome}>
            {t('home_page.welcome', { name: firebaseUser?.displayName })}
          </Typography>
          <Typography className={classes.description}>{t('home_page.description')}</Typography>
          <Typography className={classes.lastLogin}>
            {t('home_page.last_login')}: {moment(firebaseUser?.metadata.lastSignInTime).format(t('date_format'))}
          </Typography>
        </Box>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <CustomCardTable
              reverse
              headerAction={
                <HeaderAction
                  onClick={() => {
                    navigate(AppRouteEnum.Collection);
                  }}
                />
              }
              cardContent={
                <Box className={classes.cardContent}>
                  <Typography className={classes.contentHeader}>{t('home_page.create_new_collection')}</Typography>
                  <Typography className={classes.contentText}>{t('home_page.collection_explain_text')}</Typography>
                </Box>
              }
            />
          </Grid>
          <PermissionsView roles={SCREEN_PERMISSION.HOME.VIEW}>
            <Grid item xs={12} md={6}>
              <CustomCardTable
                reverse
                headerAction={<HeaderAction onClick={() => navigate(AppRouteEnum.MyShop)} />}
                cardContent={
                  <Box className={classes.cardContent}>
                    <Typography className={classes.contentHeader}>{t('home_page.create_shop')}</Typography>
                    <Typography className={classes.contentText}>{t('home_page.shop_explain_text')}</Typography>
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomCardTable
                reverse
                headerAction={<HeaderAction onClick={() => navigate(AppRouteEnum.MemberSite)} />}
                cardContent={
                  <Box className={classes.cardContent}>
                    <Typography className={classes.contentHeader}>{t('home_page.membersite')}</Typography>
                    <Typography className={classes.contentText}>{t('home_page.membersite_explain')}</Typography>
                  </Box>
                }
              />
            </Grid>
          </PermissionsView>
        </Grid>
      </Box>
    </UserLayout>
  );
};

export default memo(Home);
