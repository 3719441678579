import { Fragment, MouseEventHandler, ReactNode } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { makeStyles } from 'tss-react/mui';

import DialogCloseButton from '../dialog-close-button';

const useStyles = makeStyles()(() => ({
  initDialog: {
    '.MuiPaper-root': {
      padding: '16px',
      '& > .MuiDivider-root': {
        marginBottom: '16px',
        borderColor: '#9E9E9E',
      },
    },
    '.MuiDialogTitle-root': {
      padding: '0 40px 0 0',
      fontWeight: 400,
      fontSize: '24px',
    },
    '.MuiDialogContent-root': {
      padding: 0,
      marginBottom: '16px',
    },
    '.MuiDialogActions-root': {
      padding: 0,
      '& > :not(:first-of-type)': {
        marginLeft: '16px',
      },
    },
    '.MuiDivider-root': {
      marginTop: '16px',
      borderColor: '#9E9E9E',
    },
  },
}));

interface Props extends DialogProps {
  dialogTitle: ReactNode;
  actions?: JSX.Element[];
  dialogContent: ReactNode;
  hideTitleDivider?: boolean;
  onClose: MouseEventHandler<HTMLButtonElement> | undefined;
}

const CustomDialog: React.FC<Props> = (props) => {
  const { className, actions: _, dialogTitle: __, dialogContent: ___, hideTitleDivider: ____, ...others } = props;

  const { classes, cx } = useStyles();

  return (
    <Dialog {...others} className={cx(className, classes.initDialog)} maxWidth="sm" fullWidth>
      <Content {...props} />
    </Dialog>
  );
};

const Content: React.FC<Props> = (props) => {
  const { dialogTitle, dialogContent, hideTitleDivider, onClose, actions } = props;

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <DialogTitle sx={{ flex: 1 }}>{dialogTitle}</DialogTitle>
        <DialogCloseButton onClick={onClose} data-testid="close-button">
          <CloseIcon />
        </DialogCloseButton>
      </Box>
      {!hideTitleDivider && <Divider />}
      <DialogContent>{dialogContent}</DialogContent>
      {!!actions?.length && (
        <DialogActions>
          {actions.map((action, idx) => (
            <Fragment key={idx}>{action}</Fragment>
          ))}
        </DialogActions>
      )}
    </>
  );
};

export default CustomDialog;
