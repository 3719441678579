import { FC, useCallback, useContext, useEffect, useState } from 'react';

import { WalletRequired } from '@gusdk/gu-wallet-connector';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { JsonRpcProvider, formatEther } from 'ethers';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomCardTable from '~/components/custom-card-table';
import DepositDialog from '~/components/dialog/deposit-dialog';
import LoaderCenter from '~/components/loader-center';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import { useAccount, useCheckPermissions } from '~/hooks/with-account';

const { DEPOSIT, WITHDRAW } = SCREEN_PERMISSION.SETTING.DEPOSIT;

const useStyles = makeStyles<{ refetching: boolean }>()((_, { refetching }) => ({
  wrapper: {
    gap: '16px',
    display: 'flex',
    minHeight: '150px',
    paddingBottom: '16px',
    flexDirection: 'column',
    justifyContent: 'center',
    '.wrapperContent': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      '.content': {
        gap: '32px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        '.MuiTypography-body1': {
          fontSize: '16px',
          strong: {
            fontSize: '32px',
          },
        },
      },
      '.MuiIconButton-root .MuiSvgIcon-root': refetching
        ? {
            animation: 'spin 1s linear infinite',
            '@keyframes spin': {
              '0%': {
                transform: 'rotate(0deg)',
              },
              '100%': {
                transform: 'rotate(360deg)',
              },
            },
          }
        : {},
    },
    '.actions': {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}));

interface IBalanceList {
  symbol: string;
  balance: number;
}

const Balance: FC = () => {
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;
  const { t } = useTranslation();
  const { selectedOrganization } = useAccount();

  const [loading, setLoading] = useState(true);
  const [refetching, setRefetching] = useState(false);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [balanceList, setBalanceList] = useState<IBalanceList[]>([]);
  const [updatedDate, setUpdatedDate] = useState<Moment | null>(null);

  const { classes } = useStyles({ refetching });

  const [depositable, withdrawable] = useCheckPermissions([DEPOSIT, WITHDRAW]);

  const handleOpenDeposit = async () => {
    setOpenDeposit(true);
  };

  const handleOpenWithdraw = async () => {
    setOpenWithdraw(true);
  };

  const handleCloseDeposit = (submitted = false) => {
    if (submitted) {
      loadBalance();
    }
    setOpenDeposit(false);
  };

  const handleCloseWithdraw = (submitted = false) => {
    if (submitted) {
      loadBalance();
    }
    setOpenWithdraw(false);
  };

  const loadBalance = useCallback(
    async (isRefetching: boolean = false) => {
      if (selectedOrganization?.masterWalletAddress) {
        if (!isRefetching) {
          setLoading(true);
        }
        const wallet = selectedOrganization.masterWalletAddress;
        const newBalanceList = await Promise.all(
          Object.values(supportedNetworks).map(async (network) => {
            const provider = new JsonRpcProvider(network.rpcUrl);
            const balanceBigNumber = await provider.getBalance(wallet);
            const balance = formatEther(balanceBigNumber);
            return {
              balance: isNaN(Number(balance)) ? 0 : Number(balance),
              symbol: network.tokenSymbol || '',
            };
          }) || []
        );
        setLoading(false);
        setRefetching(false);
        setUpdatedDate(moment());
        setBalanceList(newBalanceList);
      }
    },
    [supportedNetworks, selectedOrganization?.masterWalletAddress]
  );

  const handleReload = () => {
    setRefetching(true);
    loadBalance(true);
  };

  useEffect(() => {
    loadBalance();
  }, [loadBalance]);

  return (
    <Box gap="24px" display="flex" flexDirection="column">
      <CustomCardTable
        cardTitle={t('settings.balance')}
        headerAction={
          <Typography marginRight="8px">
            {t('my_shop.last_published_date')}:{' '}
            {updatedDate ? moment(updatedDate).format(t('date_time_format')) : '...'}
          </Typography>
        }
        cardContent={
          <Box className={classes.wrapper}>
            {loading ? (
              <LoaderCenter />
            ) : (
              <>
                <Box className="wrapperContent">
                  <Box className="content">
                    {balanceList.map((balance) => (
                      <Typography key={balance.symbol} variant="body1">
                        <strong>{balance.balance.toFixed(4)}</strong>
                        {balance.symbol}
                      </Typography>
                    ))}
                  </Box>
                  <IconButton onClick={handleReload}>
                    <RefreshIcon sx={{ fontSize: '20px' }} />
                  </IconButton>
                </Box>
                <Box className="actions">
                  {withdrawable && (
                    <WalletRequired>
                      <Button variant="outlined" sx={{ marginRight: '16px' }} onClick={handleOpenWithdraw}>
                        {t('settings.withdraw')}
                      </Button>
                    </WalletRequired>
                  )}
                  {depositable && (
                    <WalletRequired>
                      <Button variant="contained" onClick={handleOpenDeposit}>
                        {t('settings.deposit')}
                      </Button>
                    </WalletRequired>
                  )}
                </Box>
              </>
            )}
          </Box>
        }
      />
      <DepositDialog isWithdraw open={openWithdraw} onClose={handleCloseWithdraw} />
      <DepositDialog open={openDeposit} onClose={handleCloseDeposit} />
    </Box>
  );
};

export default Balance;
