import { NavigateFunction } from 'react-router';

import { AppRouteEnum } from '~/enum/AppRouteEnum';

export enum ERROR_CODE {
  NOT_FOUND = '404',
}

export const notFound = (code: ERROR_CODE, navigate: NavigateFunction) => {
  if (code === ERROR_CODE.NOT_FOUND) {
    navigate(AppRouteEnum.NotFound);
  }
};
