import { ChangeEventHandler, FC, KeyboardEventHandler, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { Control, Controller, FieldErrorsImpl, SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import DialogCloseButton from '~/components/dialog/dialog-close-button';
import ImageUploadInput from '~/components/image-upload-input';
import { FormValuesImageNFT } from '~/pages/collection/CollectionDetail/details/LazyMintCollectionDetail';
import { TYPE_IMAGE } from '~/types/my-shop';
import filteringNumericString from '~/utils/filteringNumericString';
import { getErrorText } from '~/utils/yup.util';

interface ImageNFTDialogProps {
  isDirty: boolean;
  control: Control<FormValuesImageNFT, any>;
  errors: Partial<
    FieldErrorsImpl<{
      imageName: string;
      maxMint: number;
      imageFiles: NonNullable<
        | FileList
        | {
            file: File;
            type: TYPE_IMAGE;
            url: string;
          }[]
        | undefined
      >;
      imageMock: string;
    }>
  >;
  isDialogOpen: boolean;
  isEdit?: boolean;
  onAddImage?: (data: FormValuesImageNFT) => Promise<void>;
  onEditImage?: (data: FormValuesImageNFT) => Promise<void>;
  onCloseDialog: () => void;
  handleSubmit: UseFormHandleSubmit<FormValuesImageNFT>;
}

const useStyles = makeStyles()((theme) => ({
  addImageDialog: {
    '.MuiPaper-root': {
      minWidth: '480px',
      maxWidth: '480px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '360px',
      },
    },
    '.image-dialog': {
      '&-title': {
        padding: '16px',
        fontColor: '#444444',
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '133.4%',
        [theme.breakpoints.down('sm')]: {
          fontSize: '20px',
          lineHeight: 'unset',
        },
      },
    },
    '.MuiDialogContent-root': {
      padding: '16px',
      marginTop: '16px',
      '.image-dialog-input-name': {
        width: '100%',
        marginTop: 16,
      },
    },
    '.MuiDivider-root': {
      margin: '0 16px',
      borderColor: '#9E9E9E',
    },
    '.MuiDialogActions-root': {
      padding: '16px',
      '.MuiButtonBase-root:not(:first-of-type)': {
        marginLeft: '16px',
      },
    },
    '.MuiFormLabel-asterisk': {
      color: '#da0202de',
    },
  },
}));

const ImageNFTDialog: FC<ImageNFTDialogProps> = (props) => {
  const {
    control,
    errors,
    isDirty,
    isDialogOpen,
    isEdit = false,
    onAddImage,
    onEditImage,
    onCloseDialog,
    handleSubmit,
  } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const blockInvalidChar: KeyboardEventHandler<HTMLDivElement> | undefined = (e) =>
    ['e', 'E', '+', '-', ',', '.'].includes(e.key) && e.preventDefault();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAction =
    (callback: (data: FormValuesImageNFT) => Promise<void>): SubmitHandler<FormValuesImageNFT> =>
    async (data) => {
      setIsSubmitting(true);
      await callback(data);
      setIsSubmitting(false);
    };

  const handleChangeMaxMint =
    (onChange: (...event: any[]) => void): ChangeEventHandler<HTMLInputElement> =>
    (event) => {
      const newValue = event.target.value;
      const filtered = filteringNumericString(newValue);
      if (!filtered && newValue !== '') {
        return;
      }
      event.target.value = filtered || '';
      onChange(event);
    };

  return (
    <Dialog open={isDialogOpen} onClose={onCloseDialog} className={classes.addImageDialog}>
      <DialogTitle className="image-dialog-title">
        {isEdit ? t('collection_screen.edit_lazy_mint_image') : t('collection_screen.add_new_issue_nft_image')}
      </DialogTitle>
      <DialogCloseButton onClick={onCloseDialog} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <Divider />
      <DialogContent>
        <ImageUploadInput<FormValuesImageNFT>
          required
          type="image"
          height="156px"
          name="imageFiles"
          control={control}
          label={t('image')}
          className="imageUpload"
          disabled={isSubmitting}
          defaultImageUrlName="imageMock"
          error={!!errors.imageFiles?.message}
          helperText={t(errors.imageFiles?.message as any, {
            size: 10,
          })}
        />
        <Controller
          name="imageName"
          control={control}
          render={({ field }) => (
            <TextField
              className="image-dialog-input-name"
              id="outlined-basic"
              label={t('name.thing')}
              variant="outlined"
              required
              disabled={isSubmitting}
              error={!!errors.imageName?.message}
              helperText={getErrorText(errors.imageName?.message, t)}
              {...field}
            />
          )}
        />
        <Controller
          name="maxMint"
          control={control}
          render={({ field }) => (
            <TextField
              required
              variant="outlined"
              id="outlined-basic"
              className="image-dialog-input-name"
              label={t('collection_screen.max_mint')}
              disabled={isSubmitting}
              error={!!errors.maxMint?.message}
              helperText={t(errors.maxMint?.message as any, {
                min: 1,
                max: 999999999999999,
                label: t('collection_screen.max_mint'),
              })}
              {...field}
              onKeyDown={blockInvalidChar}
              onChange={handleChangeMaxMint(field.onChange)}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Box>
          <Button variant="outlined" color="primary" onClick={onCloseDialog}>
            {t('cancel')}
          </Button>
          {isEdit ? (
            <Button
              variant="contained"
              onClick={handleSubmit(handleAction(onEditImage!))}
              disabled={!isDirty || isSubmitting}
              endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
            >
              {t('save')}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleSubmit(handleAction(onAddImage!))}
              disabled={isSubmitting}
              endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
            >
              {t('add')}
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ImageNFTDialog;
