import { FC } from 'react';

import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

import { colors } from '~/constants/colors';

interface SquareImageProps {
  src: string;
  borderRadius?: number;
  onClick?: () => void;
}

const useStyles = makeStyles<{ borderRadius: number }>()((_, { borderRadius }) => ({
  wrapperImage: {
    width: '100%',
    cursor: 'pointer',
    paddingTop: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: `${borderRadius}px`,
  },
  image: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    backgroundColor: colors.black,
  },
}));

const SquareImage: FC<SquareImageProps> = ({ src, borderRadius = 0, onClick }) => {
  const { classes } = useStyles({ borderRadius });

  const handleClick = () => {
    if (!!onClick) {
      onClick();
    }
  };

  return (
    <Box className={classes.wrapperImage} onClick={handleClick}>
      <img src={src || ''} alt="Thumbnails" loading="lazy" crossOrigin="anonymous" className={classes.image} />
    </Box>
  );
};

export default SquareImage;
