import { useCallback, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { env } from '~/env';
import { GetMeDocument, useAcceptTermOfUseMutation } from '~/graphql/member/types';
import { useLogout } from '~/hooks/use-logout';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    maxWidth: theme.breakpoints.values.md,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(8, 8, 2, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  checkbox: {
    display: 'block',
    margin: theme.spacing(2, 0),
  },
  wrapCheckBox: {
    textAlign: 'center',
    padding: theme.spacing(0, 4, 4, 4),
  },
  wrapButtons: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
    flexWrap: 'wrap-reverse',
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {}

const TermsOfUse = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const [agree, setAgree] = useState(false);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const logout = useLogout();
  const [loadingTerms, setLoadingTerms] = useState(true);
  const [acceptTermOfUse, { loading: loadingAcceptTerm }] = useAcceptTermOfUseMutation({
    refetchQueries: [GetMeDocument],
  });

  const onTermsLoaded = useCallback(() => {
    setLoadingTerms(false);
  }, []);

  const onAccept = useCallback(async () => {
    try {
      await acceptTermOfUse();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [enqueueSnackbar, acceptTermOfUse]);

  const onCheck = useCallback((event: React.SyntheticEvent, checked: boolean) => {
    setAgree(checked);
  }, []);

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant="h5" color="white">
            {t('terms_of_use.title')}
          </Typography>
        </div>
        <div className={classes.content}>
          <div>
            <iframe
              title="terms of use"
              src={i18n.language === 'ja' ? env.REACT_APP_TERMS_OF_USE_JA : env.REACT_APP_TERMS_OF_USE_EN}
              width="100%"
              height={400}
              frameBorder="0"
              hidden={loadingTerms}
              onLoad={onTermsLoaded}
            />
            {loadingTerms && <Skeleton variant="rectangular" width="100%" height={400} />}
          </div>
        </div>
        <div className={classes.wrapCheckBox}>
          <Link
            marginTop={3}
            display="block"
            target="_blank"
            href={i18n.language === 'ja' ? 'https://www.gu-tech.com/ja/privacy' : 'https://www.gu-tech.com/privacy'}
          >
            {t('terms_of_use.privacy_policy')}
          </Link>
          <div className={classes.checkbox}>
            <FormControlLabel
              control={<Checkbox />}
              checked={agree}
              label={t('terms_of_use.agree_the_terms_of_use_and_and_the_management_of_personal_information')}
              onChange={onCheck}
            />
          </div>
          <div className={classes.wrapButtons}>
            <Button
              LinkComponent={RouterLink}
              href={AppRouteEnum.SignIn}
              color="primary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={logout}
            >
              {t('do_not_agree')}
            </Button>
            <Button
              variant="contained"
              onClick={onAccept}
              disabled={loadingAcceptTerm || !agree}
              endIcon={loadingAcceptTerm && <CircularProgress size={20} color="inherit" />}
            >
              {t('agree_and_start')}
            </Button>
          </div>
        </div>
      </Paper>
    </Container>
  );
};

export default TermsOfUse;
