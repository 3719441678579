import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  noRowContainer: {
    zIndex: 1,
    height: '100%',
    padding: '16px',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  msgNodata: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '32px',
    textAlign: 'center',
    marginBottom: '14px',
  },
}));

const CustomNodata: FC<{ message?: string }> = ({ message }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.noRowContainer}>
      <div className={classes.msgNodata}>{message || t('no_data_available')}</div>
    </div>
  );
};

export default CustomNodata;
