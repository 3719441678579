import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  memo,
  useContext,
  useEffect,
  useState,
  SyntheticEvent,
} from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import SalesCollection from './components/SalesCollection';
import AdvancedSetting from './components/shop-detail-tabs/AdvancedSetting';
import BasicSiteSetting from './components/shop-detail-tabs/BasicSiteSetting';
import MemberTerms from './components/shop-detail-tabs/MemberTerms';
import RegistrationCondition from './components/shop-detail-tabs/RegistrationCondition';

import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import HomeBtn from '~/components/home-btn';
import LinkButton from '~/components/link-button';
import LoaderCenter from '~/components/loader-center';
import { MyShopContext, MyShopContextValue } from '~/contexts/MyShopWrapper';
import { ShopDetailContext, ShopDetailContextValue } from '~/contexts/ShopDetailWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import {
  AcquisitionInformation,
  usePublishShopMutation,
  useCancelPublishShopMutation,
  useGetPublishShopQuery,
  GetMyShopDocument,
  ListMyShopCollectionsDocument,
} from '~/graphql/member/types';
import { ExpireDateMethod, MemberSiteDetailTab, SHOP_TYPE, Unit } from '~/types/my-shop';

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    width: '100%',
  },
  wrapperAlert: {
    width: 'calc(100% + 48px)',
    margin: '-24px 0 0 -24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '.MuiAlert-root': {
      color: '#00000099',
      backgroundColor: 'white',
      '.MuiAlert-action': {
        padding: 0,
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: '8px',
    },
    h4: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 400,
      margin: '30px 0 20px',
    },
    h5: {
      fontSize: '14px',
      fontWeight: 400,
      marginRight: 40,
    },
  },
  ogpBox: {
    height: 212,
    padding: 16,
    display: 'flex',
    borderRadius: '8px',
    backgroundColor: theme.palette.common.white,
    border: '1px solid rgba(21, 21, 21, 0.15)',
    '.ogp-image': {
      height: 180,
      width: 340,
      borderRadius: 8,
      marginRight: 16,
      '.ogp-title': {
        height: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FFF',
        textAlign: 'center',
        fontSize: '36px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0.46px',
      },
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      height: 'unset',
      '.ogp-image': {
        width: '100%',
        marginBottom: '8px',
      },
    },
  },
  infoBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '.wrapperButton': {
      [theme.breakpoints.down('md')]: {
        marginTop: '8px',
      },
      [theme.breakpoints.up('md')]: {
        alignSelf: 'flex-end',
      },
    },
    [theme.breakpoints.down('md')]: {
      '.MuiButton-root': {
        overflow: 'hidden',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        width: '100% !important',
        textOverflow: 'ellipsis',
        '&:first-of-type': {
          marginBottom: '8px',
        },
      },
    },
  },
  info: {
    display: 'flex',
    height: '269px',
    fontWeight: 400,
    h6: {
      fontSize: 16,
      lineHeight: '20px',
    },
    span: {
      fontSize: 12,
      marginTop: 8,
      lineHeight: '133.4%',
    },
    '& > div': {
      flex: 1,
      borderRadius: '8px',
      border: '1px solid rgba(21, 21, 21, 0.15)',
      overflow: 'hidden',
      '&:not(:last-of-type)': {
        marginRight: 16,
        padding: 16,
        '& > div': {
          marginBottom: 16,
        },
      },
      '&:last-of-type': {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        '& .banner': {
          width: '100%',
          height: '150px',
          backgroundSize: 'cover',
        },
        '& .customizeAction': {
          flex: 1,
          padding: 16,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          '& > div': {
            alignSelf: 'end',
          },
        },
      },
    },
  },
  tabs: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > .MuiBox-root': {
      flex: 1,
      minWidth: 0,
      [theme.breakpoints.up('md')]: {
        marginRight: '24px',
      },
    },
  },
  editorBtn: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  editorBtnMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

type OrUndefined<T> = T | undefined;

export enum MemberSiteFormEnum {
  TERMS = 'TERMS',
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED',
  COLLECTION = 'COLLECTION',
  REGISTRATION = 'REGISTRATION',
}

export interface FormMemberSiteContextValue {
  publishedDate: string;
  editingAt: MemberSiteFormEnum | undefined;
  setEditingAt: Dispatch<SetStateAction<MemberSiteFormEnum | undefined>>;
}

export const FormMemberSiteContext = createContext<FormMemberSiteContextValue | null>(null);

const ShopDetail: FC = () => {
  const myShopState = useContext(MyShopContext) as MyShopContextValue;
  const myShopData = useContext(ShopDetailContext) as ShopDetailContextValue;

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [areDataUpdated, setAreDataUpdated] = useState(false);
  const [editingAt, setEditingAt] = useState<MemberSiteFormEnum | undefined>();
  const [tab, setTab] = useState(MemberSiteDetailTab.BASIC);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const isMemberSite = myShopData?.data?.shopType === SHOP_TYPE.MEMBER;

  const [publishShop] = usePublishShopMutation();
  const [cancelPublishShop] = useCancelPublishShopMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionsDocument],
  });
  const { data: publishShopResponse, refetch: refetchPublishShop } = useGetPublishShopQuery({
    fetchPolicy: 'no-cache',
    variables: {
      myShopUuid: id ?? '',
    },
  });

  const steps = {
    [MemberSiteDetailTab.BASIC]: {
      value: MemberSiteDetailTab.BASIC,
      stepTitle: t('my_shop.basic'),
      component: <BasicSiteSetting />,
    },
    [MemberSiteDetailTab.COLLECTION]: {
      value: MemberSiteDetailTab.COLLECTION,
      stepTitle: t('collection'),
      component: <SalesCollection />,
    },
    [MemberSiteDetailTab.TERMS]: {
      value: MemberSiteDetailTab.TERMS,
      stepTitle: t('member_site.member_terms'),
      component: <MemberTerms />,
    },
    [MemberSiteDetailTab.REGISTRATION]: {
      value: MemberSiteDetailTab.REGISTRATION,
      stepTitle: t('member_site.registration_condition'),
      component: <RegistrationCondition isSubmitting={isSubmitting} />,
    },
    [MemberSiteDetailTab.ADVANCED]: {
      value: MemberSiteDetailTab.ADVANCED,
      stepTitle: t('my_shop.advanced_setting'),
      component: <AdvancedSetting />,
    },
  };

  useEffect(() => {
    (async () => {
      try {
        if (!myShopData?.loading) {
          if (myShopData?.data) {
            const { siteSetting, shopInformation, domain, nftActivationSettings, paymentMethod } = myShopData.data;
            myShopState?.setFormData({
              siteSetting: {
                ogpFile: undefined,
                logoFile: undefined,
                faviconFile: undefined,
                domainName: domain?.name!,
                theme: siteSetting?.theme,
                ogp: siteSetting?.ogp ?? '',
                logo: siteSetting?.logo ?? '',
                name: siteSetting?.name ?? '',
                meta: siteSetting?.meta ?? '',
                siteName: siteSetting?.title!,
                favicon: siteSetting?.favicon ?? '',
                metaDescription: siteSetting?.description!,
              },
              shopInformation: {
                policy: shopInformation?.policy as OrUndefined<string>,
              },
              paymentMethod: {
                baseCurrency: paymentMethod?.baseCurrency as string,
              },
              nftActivationSettings: {
                acquisitionInformation: nftActivationSettings?.acquisitionInformation.filter(
                  (info) => !['LAST_NAME', 'FIRST_NAME'].includes(info.type!)
                ) as AcquisitionInformation[],
                expireMethod: nftActivationSettings?.expireMethod as ExpireDateMethod,
                expireDate: dayjs(nftActivationSettings?.expireDate),
                expireUnit: nftActivationSettings?.expireUnit as Unit,
                expireValue: nftActivationSettings?.expireValue as OrUndefined<number>,
              },
            });
            setAreDataUpdated(true);
          } else {
            if (
              !(
                (window.location.pathname.startsWith(AppRouteEnum.MyShop) &&
                  myShopData?.data?.shopType === SHOP_TYPE.SHOP) ||
                (window.location.pathname.startsWith(AppRouteEnum.MemberSite) &&
                  myShopData?.data?.shopType === SHOP_TYPE.MEMBER)
              )
            ) {
              navigate(AppRouteEnum.NotFound);
            }
          }
        }
      } catch (err: any) {
        enqueueSnackbar(t('my_shop.message.error'), { variant: 'error' });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myShopData.data, myShopData.loading, t, enqueueSnackbar]);

  const handlePublish = async () => {
    setIsSubmitting(true);
    try {
      await publishShop({
        variables: {
          myShopUuid: id ?? '',
        },
      });
      await myShopData.refetch({
        myShopUuid: id ?? '',
      });
      await refetchPublishShop({
        myShopUuid: id ?? '',
      });
      setIsSubmitting(false);
      enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(t('my_shop.message.error'), { variant: 'error' });
    }
  };

  const handleCancel = async () => {
    try {
      await cancelPublishShop({
        variables: {
          myShopUuid: id || '',
        },
      });
    } catch (err: any) {
      enqueueSnackbar(t('my_shop.message.error'), { variant: 'error' });
    }
  };

  const handleChangeTab = (_: SyntheticEvent<Element, Event>, value: string) => {
    setTab(value as SetStateAction<MemberSiteDetailTab>);
    navigate(`${location.pathname}?tab=${value}`);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tabFromUrl = urlParams.get('tab');
    if (tabFromUrl) {
      setTab(tabFromUrl as SetStateAction<MemberSiteDetailTab>);
    }
  }, [location.search]);

  const handleOpenRemoveDialog = () => {
    setOpen(true);
  };

  const handleCloseRemoveDialog = async () => {
    setOpen(false);
  };

  return (
    <FormMemberSiteContext.Provider
      value={{
        editingAt,
        publishedDate: publishShopResponse?.getPublishShop.updatedAt,
        setEditingAt,
      }}
    >
      {myShopData.data.hasUpdate && (
        <Box className={classes.wrapperAlert}>
          <Alert
            icon={false}
            variant="filled"
            action={
              <>
                {!!publishShopResponse?.getPublishShop && (
                  <Button
                    variant="text"
                    color="error"
                    onClick={handleOpenRemoveDialog}
                    disabled={!!editingAt || isSubmitting}
                  >
                    {t('cancel')}
                  </Button>
                )}
                <Button
                  variant="text"
                  onClick={handlePublish}
                  disabled={!!editingAt || isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                >
                  {t('publish')}
                </Button>
              </>
            }
          >
            {t('toast_message.there_is_an_update_member_site')}
          </Alert>
        </Box>
      )}
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Link
          component={RouterLink}
          to={isMemberSite ? AppRouteEnum.MemberSite : AppRouteEnum.MyShop}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t(isMemberSite ? 'member_site.member_site' : 'my_shop.shops')}
        </Link>
        <Typography style={{ wordBreak: 'break-all' }} color="text.secondary">
          {myShopData?.data?.siteSetting?.title || ''}
        </Typography>
      </Breadcrumbs>
      <LinkButton
        variant="outlined"
        endIcon={<ChevronRightIcon />}
        to={AppRouteEnum.EditMemberSite.replace(/:id/g, id || '')}
        className={classes.editorBtnMobile}
      >
        {t('design_editor')}
      </LinkButton>
      <Box className={classes.tabs}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="scrollable" scrollButtons={false} value={tab} onChange={handleChangeTab}>
            {Object.values(steps).map((tab) => (
              <Tab key={tab.stepTitle} value={tab.value} label={tab.stepTitle} />
            ))}
          </Tabs>
        </Box>
        <LinkButton
          variant="outlined"
          className={classes.editorBtn}
          endIcon={<ChevronRightIcon />}
          to={AppRouteEnum.EditMemberSite.replace(/:id/g, id || '')}
        >
          {t('design_editor')}
        </LinkButton>
      </Box>
      {areDataUpdated ? <Box className={classes.wrapper}>{steps[tab].component}</Box> : <LoaderCenter />}
      <ConfirmationDialog
        open={open}
        confirmTitle={t('confirm')}
        title={t('cancel_publish_title')}
        content={t('cancel_publish_member_site_desc')}
        onConfirm={handleCancel}
        onClose={handleCloseRemoveDialog}
      />
    </FormMemberSiteContext.Provider>
  );
};

export default memo(ShopDetail);
