import { FC, memo, useCallback, useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, TextField, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import { MyShopCollectionResponse } from '../../..';

import i18nMain from 'src/i18n';
import CardsList from '~/components/CardList';
import { API_MEDIA, MAX_ORDER } from '~/constants/common';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import {
  useUpdateMyShopCollectionMutation,
  useSetMyShopCollectionImageMutation,
  OrderBy,
  MemberMyShopCollectionImagesQueryKey,
  QueryOperator,
  GetMyShopDocument,
  useListMyShopCollectionImagesQuery,
} from '~/graphql/member/types';
import { _SubgraphErrorPolicy_ } from '~/graphql/subgraph/types';
import useTextLanguage from '~/hooks/useTextLanguage';
import { EditShopContext, EditShopContextValue } from '~/pages/edit-shop';
import { CardData, STATUS } from '~/types/my-shop';
import { getErrorText } from '~/utils/yup.util';

interface CollectionSectionProps {
  collectionSection: MyShopCollectionResponse;
}

const ITEM_PER_PAGE = 12;

const useStyles = makeStyles<{ isEditCollectionCaption: boolean }>()((theme, { isEditCollectionCaption }) => {
  const sm = theme.breakpoints.up('sm').replace('@media', '@container');
  const md = theme.breakpoints.up('md').replace('@media', '@container');
  const smDown = theme.breakpoints.down('sm').replace('@media', '@container');

  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      '.nft-wrapper': {
        gap: 16,
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'auto',
        marginBottom: 24,
        [md]: {
          flexWrap: 'nowrap',
        },
      },
      '.purchase-btn': {
        maxWidth: 113,
        margin: '0 auto',
      },
    },
    titleSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '.MuiFormControl-root': {
        margin: 0,
        '.MuiInputBase-input': {
          padding: 0,
          fontWeight: 400,
          fontSize: '24px',
          lineHeight: '32px',
          textAlign: 'center',
        },
      },
      '.MuiTypography-subtitle1': {
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '32px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '.MuiIconButton-root': {
        verticalAlign: 'bottom',
      },
    },
    wrapperItem: {
      display: 'flex',
      borderRadius: '8px',
      padding: '16px 15px',
      [sm]: {
        padding: '24px',
      },
      flexDirection: 'column',
      '.MuiGrid-container': {
        minHeight: '10px',
      },
      '.collection-info': {
        maxWidth: 'min(580px, 80%)',
        margin: '0 auto 32px',
        width: isEditCollectionCaption ? '80%' : 'initial',
      },
      '.collection-name': {
        ...(isEditCollectionCaption
          ? {
              width: '100%',
            }
          : { maxWidth: '100%' }),
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        '.MuiIconButton-root': {
          top: '50%',
          padding: '4px',
          position: 'absolute',
          left: 'calc(100% + 8px)',
          transform: 'translateY(-50%)',
          '.MuiSvgIcon-root': {
            fontSize: '24px',
          },
        },
      },
      '.description': {
        width: '100%',
        fontWeight: 400,
        display: 'block',
        fontSize: '14px',
        marginTop: '12px',
        lineHeight: '20px',
        textAlign: 'center',
        wordBreak: 'break-word',
        letterSpacing: '0.15px',
        a: {
          '&:any-link': {
            color: 'inherit',
          },
        },
        p: {
          margin: 0,
        },
      },
      '.no-nft': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        img: {
          [smDown]: {
            width: '138px',
            height: '88px',
          },
        },
        '.MuiTypography-caption': {
          fontWeight: 400,
          fontSize: '16px',
          letterSpacing: '0.15px',
          marginTop: '12px',
          [sm]: {
            marginTop: '32px',
          },
        },
      },
    },
  };
});

const schema = yup.object({
  caption: yup.string().max(100).required(),
});

interface CollectionCaptionValue extends yup.InferType<typeof schema> {}

const CollectionSection: FC<CollectionSectionProps> = ({ collectionSection }) => {
  const config = useContext(EditShopContext) as EditShopContextValue;
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;

  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useTranslation('translation');
  const { t: tMain } = useTranslation('translation', { i18n: i18nMain });

  const [isLoadingNFTs, setIsLoadingNFTs] = useState(true);
  const [imagesList, setImagesList] = useState<CardData[]>([]);
  const [collectionName, setCollectionName] = useState(collectionSection.name);
  const [isEditCollectionCaption, setIsEditCollectionCaption] = useState(false);
  const [collectionNameJa, setCollectionNameJa] = useState(collectionSection.nameJa);
  const [newChangedNFTNames, setNewChangedNFTNames] = useState<{ [key: string]: string }>();

  const { classes } = useStyles({ isEditCollectionCaption });

  const isEnglish = i18n.language === 'en';

  const collectionBorderColor = useWatch({
    control: config.control,
    name: 'style.collectionBorderColor',
  });

  const defaultCaption =
    (isEnglish ? collectionName || collectionSection?.name! : collectionNameJa || collectionSection?.nameJa!) ||
    collectionSection?.collection?.name!;

  const [updateMyShopCollection] = useUpdateMyShopCollectionMutation({
    refetchQueries: [GetMyShopDocument],
  });
  const [setMyShopCollectionImage] = useSetMyShopCollectionImageMutation({
    refetchQueries: [GetMyShopDocument],
  });

  const {
    control,
    reset,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm<CollectionCaptionValue>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    defaultValues: {
      caption: defaultCaption,
    },
    resolver: yupResolver(schema),
  });

  useListMyShopCollectionImagesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      page: 1,
      limit: ITEM_PER_PAGE,
      orderBy: (collectionSection.orderBy as OrderBy) ?? undefined,
      sortBy: (collectionSection.sortBy as MemberMyShopCollectionImagesQueryKey) ?? undefined,
      where: {
        myShopCollectionUuid: collectionSection.uuid ?? '',
        fields: [
          {
            value: [STATUS.NOW_ON_SALE],
            operator: QueryOperator.Equals,
            key: MemberMyShopCollectionImagesQueryKey.ShopCollectionImageStatus,
          },
          {
            value: [],
            operator: QueryOperator.Exists,
            key: MemberMyShopCollectionImagesQueryKey.ShopCollectionImagePrice,
          },
        ],
      },
    },
    onCompleted: (data) => {
      setIsLoadingNFTs(true);
      const collection = collectionSection.collection;
      const myShopCollectionImages = data?.listMyShopCollectionImages.items;
      if (!collection || !myShopCollectionImages) {
        setIsLoadingNFTs(false);
        return;
      }
      const newImagesList: CardData[] = myShopCollectionImages.map((collectionImage) => ({
        price: collectionImage.price!,
        status: collectionImage.status!,
        collectionUuid: collection?.uuid,
        maxMint: collectionImage.maxMint!,
        createdAt: collectionImage?.createdAt,
        currentMint: collectionImage.currentMint!,
        collectionImageUuid: collectionImage.uuid,
        order: collectionImage?.order ?? MAX_ORDER,
        originalName: collectionImage?.name ?? '...',
        myShopCollectionUuid: collectionSection.uuid!,
        url: API_MEDIA + collectionImage?.image || '',
        id: collectionImage?.myShopCollectionImageUuid!,
        networkSymbol: supportedNetworks?.[collection?.network!].icon ?? '',
        name: collectionImage?.myShopCollectionImageName ?? collectionImage?.name ?? '...',
        nameJa: collectionImage?.myShopCollectionImageNameJa ?? collectionImage?.name ?? '...',
      }));

      setImagesList((prevState) => prevState?.concat(newImagesList));
      setIsLoadingNFTs(false);
    },
  });

  const handleEditNFTCaption = useCallback(
    (info: CardData) => async (data: { caption: string }) => {
      try {
        if (isEditCollectionCaption) {
          setIsEditCollectionCaption(false);
        }
        const newName = data.caption.trim();
        const payload = isEnglish
          ? {
              name: newName,
            }
          : {
              nameJa: newName,
            };
        await setMyShopCollectionImage({
          variables: {
            input: {
              collectionImageUuid: info?.collectionImageUuid || '',
              myShopCollectionUuid: info?.myShopCollectionUuid || '',
              ...payload,
            },
          },
        });
        setNewChangedNFTNames((prevState) => ({
          ...prevState,
          [`${info.id}-${i18n.language}`]: newName,
        }));
        enqueueSnackbar(tMain('toast_message.update_caption_successfully'), { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(err.message || tMain('my_shop.message.error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tMain, i18n.language, newChangedNFTNames, isEditCollectionCaption, collectionSection?.uuid]
  );

  const handleEditCollectionCaption = async (data: { caption: string }) => {
    try {
      const newName = data.caption.trim();
      let payload;
      if (isEnglish) {
        payload = {
          name: newName,
        };
        setCollectionName(newName);
      } else {
        payload = {
          nameJa: newName,
        };
        setCollectionNameJa(newName);
      }
      await updateMyShopCollection({
        variables: {
          input: {
            uuid: collectionSection.uuid!,
            ...payload,
          },
        },
      });
      resetField('caption', { defaultValue: newName });
      enqueueSnackbar(tMain('toast_message.update_caption_successfully'), { variant: 'success' });
      setIsEditCollectionCaption(false);
    } catch (err: any) {
      enqueueSnackbar(err.message || tMain('my_shop.message.error'), { variant: 'error' });
    }
  };

  useEffect(() => {
    setIsEditCollectionCaption(false);
    reset({
      caption: defaultCaption,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const collectionDescription = useTextLanguage(
    collectionSection?.desc || collectionSection?.collection?.desc,
    collectionSection?.descJa || collectionSection?.collection?.descJa
  );

  const collectionCaption = isEnglish
    ? collectionName || collectionSection?.name || collectionSection.collection?.name! || '...'
    : collectionNameJa || collectionSection?.nameJa || collectionSection.collection?.name! || '...';

  return collectionSection ? (
    <Box className={classes.wrapperItem} border={`1px solid ${collectionBorderColor}`}>
      <Box className="collection-info">
        <Box className={classes.titleSection}>
          <Box className="collection-name">
            {isEditCollectionCaption ? (
              <>
                <Controller
                  name="caption"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      fullWidth
                      autoFocus
                      margin="normal"
                      variant="standard"
                      spellCheck={false}
                      error={!!errors.caption?.message}
                      helperText={getErrorText(errors.caption?.message, tMain)}
                      {...field}
                    />
                  )}
                />
                <IconButton onClick={handleSubmit(handleEditCollectionCaption)}>
                  <CheckCircleIcon color="info" />
                </IconButton>
              </>
            ) : (
              <>
                <Tooltip
                  title={collectionCaption}
                  placement="bottom-start"
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -10],
                        },
                      },
                    ],
                  }}
                >
                  <Typography variant="subtitle1" color="collectionTitleColor">
                    {collectionCaption}
                  </Typography>
                </Tooltip>
                <IconButton onClick={() => setIsEditCollectionCaption(true)}>
                  <EditOutlinedIcon />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
        {collectionDescription && (
          <Box
            color="textColor"
            className="quillPreview description"
            dangerouslySetInnerHTML={{ __html: collectionDescription || '...' }}
          />
        )}
      </Box>
      <CardsList
        data={imagesList!}
        isLoading={isLoadingNFTs}
        newChangedNFTNames={newChangedNFTNames}
        onSaveEditCaption={handleEditNFTCaption}
      />
    </Box>
  ) : null;
};

export default memo(CollectionSection);
