import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const useNotify = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const showError = (err: any, defaultMessage?: string) => {
    const errorCode = err?.graphQLErrors?.[0]?.extensions?.response?.error;
    const errorMessage = t(`api_messages.${errorCode}`, defaultMessage || err.message).toString();

    enqueueSnackbar(errorMessage, { variant: 'error' });
  };
  const showErrorByKey = (messageKey: string) => {
    const message = t(messageKey);

    enqueueSnackbar(message, { variant: 'error' });
  };

  const showSuccess = (messageKey: string) => {
    const message = t(messageKey);
    enqueueSnackbar(message, { variant: 'success' });
  };

  return { showError, showSuccess, showErrorByKey };
};
