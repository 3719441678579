import { Dispatch, SetStateAction, createContext, useCallback, useEffect, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { AcquisitionInformationInput } from '~/graphql/member/types';
import { ITheme, initFormEditShop } from '~/pages/edit-shop';
import { CurrencyEnum, ExpireDateMethod, NFT_SHOP_TYPE, SHOP_TYPE, Unit } from '~/types/my-shop';

export interface IFormData {
  siteSetting?: {
    ogp?: string | null;
    theme?: ITheme | null;
    logo?: string | null;
    meta?: string | null;
    name?: string | null;
    category?: NFT_SHOP_TYPE;
    favicon?: string | null;
    siteName?: string | null;
    ogpFile?: FileList | null;
    logoFile?: FileList | null;
    domainName?: string | null;
    faviconFile?: FileList | null;
    metaDescription?: string | null;
  } | null;
  shopInformation?: {
    policy?: string | null;
    terms?: string | null;
    information?: string | null;
  } | null;
  paymentMethod?: {
    baseCurrency?: string | null;
  } | null;
  nftActivationSettings?: {
    expireUnit?: Unit;
    expireDate?: Dayjs;
    expireValue?: number;
    expireMethod?: ExpireDateMethod;
    acquisitionInformation?: AcquisitionInformationInput[];
  } | null;
}

export interface MyShopContextValue {
  step: number;
  shopType: SHOP_TYPE;
  formData: IFormData;
  isMemberSite: boolean;
  handleClear: () => void;
  handleNextStep: (length: number) => void;
  handlePreviousStep: () => void;
  setFormData: Dispatch<SetStateAction<IFormData>>;
}

export const MyShopContext = createContext<MyShopContextValue | undefined>(undefined);

export const initData = (isMemberSite: boolean): IFormData => ({
  siteSetting: {
    ogp: '',
    logo: '',
    meta: '',
    name: '',
    favicon: '',
    siteName: '',
    domainName: '',
    ogpFile: undefined,
    logoFile: undefined,
    metaDescription: '',
    faviconFile: undefined,
    category: NFT_SHOP_TYPE.PRE_MINT,
    theme: initFormEditShop(isMemberSite),
  },
  shopInformation: {
    policy: '',
    terms: '',
    information: '',
  },
  paymentMethod: {
    baseCurrency: CurrencyEnum.JPY,
  },
  nftActivationSettings: {
    acquisitionInformation: [],
    expireMethod: ExpireDateMethod.SPECIFY_DATE,
    expireDate: dayjs(),
    expireValue: 1,
    expireUnit: Unit.MONTH,
  },
});

const MyShopWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const shopType = location.pathname.startsWith(AppRouteEnum.MemberSite) ? SHOP_TYPE.MEMBER : SHOP_TYPE.SHOP;
  const isMemberSite = shopType === SHOP_TYPE.MEMBER;

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<IFormData>(initData(isMemberSite));

  useEffect(() => {
    return () => {
      handleClear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleNextStep = useCallback(
    (length: number) => {
      if (step < length - 1) {
        setStep(step + 1);
      }
    },
    [step]
  );

  const handlePreviousStep = useCallback(() => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate(AppRouteEnum.MyShop);
    }
  }, [step, navigate]);

  const handleClear = () => {
    setStep(0);
    setFormData(initData(isMemberSite));
  };

  return (
    <MyShopContext.Provider
      value={{
        step,
        shopType,
        formData,
        isMemberSite,
        setFormData,
        handleClear,
        handleNextStep,
        handlePreviousStep,
      }}
    >
      <Outlet />
    </MyShopContext.Provider>
  );
};

export default MyShopWrapper;
