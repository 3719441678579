import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';

import ScreenLoading from '~/components/screen-loading';
import { ListNetworksQuery, useListNetworksQuery } from '~/graphql/member/types';

export interface SupportedNetworksContextValue {
  supportedNetworks: { [key: string]: ListNetworksQuery['listNetworks'][0] };
}

export const SupportedNetworksContext = createContext<SupportedNetworksContextValue>({} as any);

export const useSupportedNetworks = () => useContext(SupportedNetworksContext);

const SupportedNetworksProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: listNetworks, loading } = useListNetworksQuery({
    fetchPolicy: 'cache-and-network',
  });

  const items = useMemo(
    () => Object.fromEntries(listNetworks?.listNetworks.map((network) => [network.chainId, network]) || []),
    [listNetworks?.listNetworks]
  );

  return (
    <SupportedNetworksContext.Provider
      value={{
        supportedNetworks: items,
      }}
    >
      {loading ? <ScreenLoading /> : children}
    </SupportedNetworksContext.Provider>
  );
};

export default SupportedNetworksProvider;
