import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from 'tss-react/mui';

type MenuItem<T> = {
  value: T;
  label: string;
  sublabel?: string;
  icon: JSX.Element;
};

type MenuListProps<T> = {
  currMenu: T | '';
  menuList: MenuItem<T>[];
  onChange: (menu: T) => void;
};

const useStyles = makeStyles()(({ breakpoints }) => ({
  listItem: {
    padding: 0,
    height: '66px',
    paddingTop: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    [breakpoints.up('sm')]: {
      gap: '4px',
      height: '80px',
    },
    '.MuiListItemText-root': {
      margin: 0,
      flex: '0 1 auto',
      '.MuiTypography-root': {
        margin: 0,
        fontSize: '12px',
        lineHeight: '266%',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
    },
  },
  listItemIcon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const MenuList = <T,>({ currMenu, menuList, onChange }: MenuListProps<T>) => {
  const { classes } = useStyles();
  return (
    <List>
      {menuList.map((menu) => (
        <ListItemButton
          key={menu.label}
          className={classes.listItem}
          selected={menu.value === currMenu}
          onClick={() => onChange(menu.value)}
        >
          {!!menu.icon && <ListItemIcon className={classes.listItemIcon}>{menu.icon}</ListItemIcon>}
          <ListItemText primary={menu.label} />
        </ListItemButton>
      ))}
    </List>
  );
};

export default MenuList;
