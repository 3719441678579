import { generateLicense, LicenseInfo } from '@mui/x-license-pro';
import { createRoot } from 'react-dom/client';

import App from './App';
import './index.css';

LicenseInfo.setLicenseKey(
  generateLicense({
    expiryDate: new Date(4000, 10, 10),
    orderNumber: '1',
    licensingModel: 'subscription',
    scope: 'premium',
  })
);

const postal_code_init = require('japan-postal-code');

function importAll(r: __WebpackModuleApi.RequireContext) {
  r.keys().forEach(r);
}
importAll(require.context('japan-postal-code/zipdata', true, /\.js$/));

postal_code_init.setJsonDataUrl('/japan-postal-code/zipdata/zip-');

export const postal_code = postal_code_init;

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
