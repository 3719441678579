const filteringNumericString = (numericString: string) => {
  const regexPattern = /^\d*$/;
  if (!regexPattern.test(numericString)) {
    return null;
  }
  const removeNegative = numericString.toString().replace(/-/g, '');
  const removeZero = removeNegative.replace(/^0\d+/, removeNegative.slice(1));
  return removeZero;
};

export default filteringNumericString;
