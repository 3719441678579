import { useMemo } from 'react';

import {
  DataGridPro,
  DataGridProProps,
  GridColumnResizeParams,
  GridValidRowModel,
  GridColumnOrderChangeParams,
} from '@mui/x-data-grid-pro';
import { makeStyles } from 'tss-react/mui';
interface DataGridProComponent {
  <R extends GridValidRowModel = any>(
    props: DataGridProProps<R> & React.RefAttributes<HTMLDivElement> & { tableName?: string }
  ): JSX.Element;
  propTypes?: any;
}

const useStyles = makeStyles<{ emptyRows: boolean }>()((theme, { emptyRows }) => ({
  dataGrid: {
    '.MuiDataGrid-footerContainer': {
      '.MuiTablePagination-root': {
        padding: '0 8px',
        [theme.breakpoints.up('md')]: {
          padding: '0 16px',
        },
      },
    },
  },
}));

const CustomDataGrid: DataGridProComponent = ({ className, ...props }) => {
  const { loading, rows } = props;

  const { classes, cx } = useStyles({ emptyRows: !rows.length || !!loading });

  const onColumnWidthChange = (data: GridColumnResizeParams) => {
    const columnsSize = localStorage.getItem('columnsSize');
    if (columnsSize) {
      const parsed = JSON.parse(columnsSize);
      if (parsed) {
        parsed[data.colDef.field] = data.width;
        localStorage.setItem('columnsSize', JSON.stringify(parsed));
      }
    } else {
      localStorage.setItem('columnsSize', JSON.stringify({ [data.colDef.field]: data.width }));
    }
  };

  const onColumnOrderChange = (_: GridColumnOrderChangeParams, __: any, detail: any) => {
    const newArray = detail.api
      .getAllColumns()
      .filter((item: any) => !item.disableReorder)
      .map((item: any) => item.field);
    localStorage.setItem(`columnsOrder_${props.tableName}`, JSON.stringify(newArray));
  };

  const columns = useMemo(() => {
    const data = localStorage.getItem(`columnsOrder_${props.tableName}`);
    if (!data) return props.columns;
    try {
      const newArr = JSON.parse(data);
      if (newArr.length) {
        return props.columns.sort((a, b) => (a.disableReorder ? 0 : newArr.indexOf(a.field) - newArr.indexOf(b.field)));
      }
    } catch (error) {}
    return props.columns;
  }, [props]);

  return (
    <DataGridPro
      autoHeight
      {...props}
      columns={columns}
      className={cx(classes.dataGrid, className)}
      onColumnWidthChange={onColumnWidthChange}
      onColumnOrderChange={onColumnOrderChange}
    />
  );
};

export default CustomDataGrid;
