import moment from 'moment';

import { ExpireDateMethod, Unit } from '~/types/my-shop';

interface IExpireData {
  expireDate?: string | undefined | null;
  expireUnit?: string | undefined | null;
  expireValue?: string | undefined | null;
  expireMethod?: string | undefined | null;
}

export const getExpireDate = (expireData: IExpireData, startDate: string): number | string | null => {
  if (expireData.expireMethod) {
    switch (expireData?.expireMethod) {
      case ExpireDateMethod.NOT_EXPIRE:
        return Infinity;
      case ExpireDateMethod.SPECIFY_DATE:
        return expireData?.expireDate || null;
      case ExpireDateMethod.SPECIFY_HOW_LONG:
        if (!(startDate && expireData.expireValue)) {
          return null;
        }
        const getTime = (unit: moment.unitOfTime.DurationConstructor) =>
          moment(startDate).add(expireData.expireValue, unit).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        switch (expireData.expireUnit) {
          case Unit.DAY:
            return getTime('days');
          case Unit.MONTH:
            return getTime('months');
          case Unit.YEAR:
            return getTime('years');
        }
    }
  }
  return null;
};
