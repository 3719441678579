import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import { FormShopContext, FormShopContextValue, ShopFormEnum } from '../..';
import WrapperTextField from '../../../../../components/WrapperTextField';

import HeaderAction from './HeaderAction';

import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import ImageUploadInput from '~/components/image-upload-input';
import { IFormData, MyShopContext, MyShopContextValue } from '~/contexts/MyShopWrapper';
import { ShopDetailContext, ShopDetailContextValue } from '~/contexts/ShopDetailWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { env } from '~/env';
import { useCheckExistDomainLazyQuery, useUpdateMyShopMutation } from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';
import { steps } from '~/pages/my-shop/create-shop';
import { CurrencyEnum, NFT_SHOP_TYPE } from '~/types/my-shop';
import { getErrorText } from '~/utils/yup.util';

const schema = yup.object({
  siteName: yup.string().nullable().max(100).required(),
  metaDescription: yup.string().nullable().max(1000),
  ogpFile: yup
    .mixed<FileList>()
    .nullable()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value) {
          return true;
        }
        return value[0].size < 10000000;
      },
    }),
  logoFile: yup
    .mixed<FileList>()
    .nullable()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value) {
          return true;
        }
        return value[0].size < 10000000;
      },
    }),
  faviconFile: yup
    .mixed<FileList>()
    .nullable()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value) {
          return true;
        }
        return value[0].size < 10000000;
      },
    }),
  ogp: yup.string().nullable(),
  logo: yup.string().nullable(),
  favicon: yup.string().nullable(),
  domainName: yup
    .string()
    .nullable()
    .max(100)
    .matches(/^[a-zA-Z0-9_-]*$/, { message: 'form_validation.shop_url_validation', excludeEmptyString: true })
    .required(),
  baseCurrency: yup.string().nullable().required(),
  category: yup.string().nullable().required(),
});

const useStyles = makeStyles<{ isCreate?: boolean }>()((theme, { isCreate }) => ({
  wrapper: {
    '.MuiPaper-root': {
      boxShadow: 'none',
      overflow: 'hidden',
      borderRadius: '8px',
      border: '1px solid #D7D7D7',
    },
    '& > .MuiCard-root': {
      marginBottom: '24px',
      '& > .MuiCardHeader-root': {
        padding: '16px',
        minHeight: '52px',
        borderBottom: isCreate ? 'none' : '1px solid #D7D7D7',
        [theme.breakpoints.up('sm')]: {
          padding: '0px 16px',
        },
        '.MuiCardHeader-action': {
          alignSelf: 'center',
        },
      },
      '.MuiCardHeader-title': {
        display: isCreate ? '' : 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'unset',
        },
      },
    },
    '.MuiCardContent-root': {
      gap: '16px',
      padding: '16px 16px 0',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      '.MuiTypography-h5': {
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '16px',
        color: '#00000099',
        letterSpacing: '1.5px',
      },
      '.cardContent': {
        '&__left': {
          gap: '16px',
          width: '408px',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
          '&-wrapperSiteImage': {
            '&--siteImage': {
              aspectRatio: '408 / 235',
              '.MuiFormControl-root': {
                height: '100%',
              },
            },
          },
          '&-wrapperLogo': {
            gap: '16px',
            display: 'flex',
            '&--item': {
              flex: 1,
              '& > .MuiBox-root': {
                aspectRatio: '1 / 1',
                '.MuiFormControl-root': {
                  height: '100%',
                },
              },
            },
          },
        },
        '&__right': {
          flex: 1,
          minWidth: 0,
          display: 'flex',
          flexDirection: 'column',
          '&-wrapperInput': {
            '.MuiTextField-root': {
              '.MuiInputAdornment-root': {
                '.MuiTypography-root': {
                  fontSize: '16px',
                },
                '.MuiTypography-caption': {
                  fontSize: '12px',
                  [theme.breakpoints.up('sm')]: {
                    fontSize: '18px',
                  },
                },
              },
            },
          },
        },
      },
    },
    '.MuiCardActions-root': {
      padding: '16px',
      justifyContent: isCreate ? 'space-between' : 'flex-end',
    },
    '.MuiCardHeader-action': {
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  cardHeader: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
}));

export interface FormBasicSiteSettingValues extends yup.InferType<typeof schema> {}

interface BasicSiteSettingProps {
  isCreate?: boolean;
}

const getShopType = (t: TFunction) => ({
  [NFT_SHOP_TYPE.MINTED]: {
    value: NFT_SHOP_TYPE.MINTED,
    label: t('my_shop.minted_type'),
  },
  [NFT_SHOP_TYPE.PRE_MINT]: {
    value: NFT_SHOP_TYPE.PRE_MINT,
    label: t('my_shop.pre_mint_type'),
  },
});

const BasicSiteSetting: FC<BasicSiteSettingProps> = ({ isCreate }) => {
  const myShopState = useContext(MyShopContext) as MyShopContextValue;
  const formShopState = useContext(FormShopContext) as FormShopContextValue;
  const myShopData = useContext(ShopDetailContext) as ShopDetailContextValue;

  const isEditing = formShopState?.editingAt === ShopFormEnum.BASIC;

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles({ isCreate });
  const { enqueueSnackbar } = useSnackbar();
  const { selectedOrganization } = useAccount();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const [updateMyShop] = useUpdateMyShopMutation();
  const [checkExistDomain] = useCheckExistDomainLazyQuery();

  const defaultValues: FormBasicSiteSettingValues = useMemo(
    () => ({
      ogp: myShopState?.formData?.siteSetting?.ogp!,
      logo: myShopState?.formData?.siteSetting?.logo!,
      favicon: myShopState?.formData?.siteSetting?.favicon!,
      category: myShopState.formData.siteSetting?.category!,
      logoFile: myShopState.formData.siteSetting?.logoFile!,
      ogpFile: myShopState?.formData?.siteSetting?.ogpFile!,
      siteName: myShopState?.formData?.siteSetting?.siteName!,
      faviconFile: myShopState.formData.siteSetting?.faviconFile!,
      domainName: myShopState?.formData?.siteSetting?.domainName!,
      baseCurrency: isCreate
        ? selectedOrganization.currencySetting?.baseCurrency!
        : myShopState?.formData?.paymentMethod?.baseCurrency!,
      metaDescription: myShopState?.formData?.siteSetting?.metaDescription!,
    }),
    [isCreate, myShopState?.formData, selectedOrganization.currencySetting?.baseCurrency]
  );

  const {
    control,
    reset,
    setError,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm<FormBasicSiteSettingValues>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    resolver: yupResolver(schema),
  });
  const isDirty = !!Object.keys(dirtyFields).length;

  const [baseCurrency, category] = useWatch({
    control,
    name: ['baseCurrency', 'category'],
  });

  const currencyOptions = useMemo(
    () =>
      Object.keys(CurrencyEnum).map((option: string) => (
        <MenuItem key={option} value={option} disabled={baseCurrency === option}>
          {selectedOrganization.currencySetting?.baseCurrency !== option
            ? option
            : `${option} (${t('my_shop.payment_methods.base_currency')})`}
        </MenuItem>
      )),
    [baseCurrency, t, selectedOrganization]
  );

  const shopTypeOptions = useMemo(() => {
    const shopTypeWithLang = getShopType(t);
    return Object.keys(shopTypeWithLang).map((option: string) => (
      <MenuItem key={option} value={option} disabled={category === option}>
        {t(shopTypeWithLang[option as NFT_SHOP_TYPE].label)}
      </MenuItem>
    ));
  }, [category, t]);

  const handleChangeToEdit = () => {
    formShopState?.setEditingAt(ShopFormEnum.BASIC);
  };
  const handleCancel = () => {
    if (isCreate) {
      navigate(AppRouteEnum.MyShop);
    } else {
      reset(defaultValues);
      formShopState?.setEditingAt(undefined);
    }
  };

  const onOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const onCloseConfimDialog = async () => {
    setOpenConfirmDialog(false);
  };

  const onSubmit = async (data: FormBasicSiteSettingValues) => {
    setIsSubmitting(true);
    const { baseCurrency, ...newSiteSetting } = data;
    if (myShopState?.formData?.siteSetting?.domainName?.trim() !== data.domainName.trim()) {
      const isExistDomain = await checkExistDomain({
        fetchPolicy: 'cache-and-network',
        variables: {
          domain: data.domainName.trim(),
          shopType: myShopState.shopType,
        },
      });
      if (isExistDomain?.data?.checkExistDomain) {
        setError('domainName', { message: t('my_shop.message.errors.domain_is_existing') });
        setIsSubmitting(false);
        return;
      }
    }
    myShopState.setFormData(
      (prevState) =>
        ({
          ...prevState,
          siteSetting: {
            ...prevState.siteSetting,
            ...newSiteSetting,
          },
          paymentMethod: {
            baseCurrency,
          },
        } as IFormData)
    );
    if (isCreate) {
      myShopState.handleNextStep(steps(t).length);
      setIsSubmitting(false);
      return;
    }
    try {
      await updateMyShop({
        variables: {
          input: {
            uuid: id!,
            siteSetting: {
              title: data.siteName,
              description: data.metaDescription,
            },
            paymentMethod: {
              baseCurrency,
            },
            domain: {
              name: data.domainName.trim(),
            },
            ogpFile: data.ogpFile?.[0],
            logoFile: data.logoFile?.[0],
            faviconFile: data.faviconFile?.[0],
          },
        },
      });
      await myShopData?.refetch({
        myShopUuid: id!,
      });
      formShopState?.setEditingAt(undefined);
      setIsSubmitting(false);
      enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const handlePreSubmit = async (data: FormBasicSiteSettingValues) => {
    const newBaseCurrency = data.baseCurrency;
    const currentBaseCurrency = myShopData?.data?.paymentMethod?.baseCurrency;
    if (!isCreate && currentBaseCurrency !== newBaseCurrency) {
      onOpenConfirmDialog();
    } else {
      await onSubmit(data);
    }
  };

  useEffect(() => {
    return () => {
      formShopState?.setEditingAt(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Box className={classes.wrapper}>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          title={t('my_shop.basic_site_setting')}
          action={!isCreate && <HeaderAction publishedDate={formShopState?.publishedDate} />}
          titleTypographyProps={{ fontSize: '16px', fontWeight: 700, letterSpacing: '0.1px', lineHeight: '20px' }}
        />
        <CardContent>
          <Box className="cardContent__left">
            <Box className="cardContent__left-wrapperSiteImage">
              <Typography variant="h5">{t('my_shop.site_image')}</Typography>
              <Box className="cardContent__left-wrapperSiteImage--siteImage">
                <ImageUploadInput<FormBasicSiteSettingValues>
                  required
                  type="image"
                  height="100%"
                  name="ogpFile"
                  control={control}
                  label={t('image')}
                  defaultImageUrlName="ogp"
                  error={!!errors.ogpFile?.message}
                  defaultImagePath={'/default/ogp-shop.png'}
                  disabled={!(isCreate || isEditing) || isSubmitting}
                  helperText={t(errors.ogpFile?.message as any, {
                    size: 10,
                  })}
                />
              </Box>
            </Box>
            <Box className="cardContent__left-wrapperLogo">
              <Box className="cardContent__left-wrapperLogo--item">
                <Typography variant="h5">{t('logo')}</Typography>
                <Box>
                  <ImageUploadInput<FormBasicSiteSettingValues>
                    required
                    type="image"
                    height="100%"
                    name="logoFile"
                    control={control}
                    label={t('image')}
                    defaultImageUrlName="logo"
                    error={!!errors.logoFile?.message}
                    defaultImagePath="/default/favicon-shop.png"
                    disabled={!(isCreate || isEditing) || isSubmitting}
                    helperText={t(errors.logoFile?.message as any, {
                      size: 10,
                    })}
                  />
                </Box>
              </Box>
              <Box className="cardContent__left-wrapperLogo--item">
                <Typography variant="h5">{t('favicon')}</Typography>
                <Box>
                  <ImageUploadInput<FormBasicSiteSettingValues>
                    required
                    type="image"
                    height="100%"
                    name="faviconFile"
                    control={control}
                    label={t('image')}
                    defaultImageUrlName="favicon"
                    error={!!errors.faviconFile?.message}
                    defaultImagePath="/default/favicon-shop.png"
                    disabled={!(isCreate || isEditing) || isSubmitting}
                    helperText={t(errors.faviconFile?.message as any, {
                      size: 10,
                    })}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="cardContent__right">
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                name="siteName"
                control={control}
                isEditing={isCreate || isEditing}
                label={t('my_shop.shop_name')}
                render={(field) => (
                  <TextField
                    fullWidth
                    margin="normal"
                    disabled={isSubmitting}
                    label={t('my_shop.shop_name')}
                    error={!!errors.siteName?.message}
                    helperText={getErrorText(errors.siteName?.message, t)}
                    required
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                name="domainName"
                control={control}
                label={t('my_shop.site_setting.shop_url')}
                readOnlyBodyStyle={{ color: '#1976d2 !important' }}
                isEditing={isCreate || isEditing}
                formatValue={(field) =>
                  env.REACT_APP_CLIENT_URL + AppRouteEnum.Shop.replace(/:shopName/g, field.value as string)
                }
                render={(field) => (
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    disabled={isSubmitting}
                    error={!!errors.domainName?.message}
                    helperText={getErrorText(errors.domainName?.message, t)}
                    label={t('my_shop.site_setting.shop_url') + ' (tokenstudio.gu.net/shop/)'}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                name="metaDescription"
                control={control}
                isEditing={isCreate || isEditing}
                label={t('my_shop.site_setting.meta_description')}
                readOnlyBodyStyle={{
                  height: '120px',
                }}
                render={(field) => (
                  <TextField
                    rows={4}
                    fullWidth
                    multiline
                    margin="normal"
                    disabled={isSubmitting}
                    placeholder={t('my_shop.shop_description')}
                    label={t('my_shop.site_setting.shop_description')}
                    error={!!errors.metaDescription?.message}
                    helperText={getErrorText(errors.metaDescription?.message, t)}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                control={control}
                name="baseCurrency"
                isEditing={isCreate || isEditing}
                label={t('my_shop.shop_currency')}
                render={(field) => (
                  <TextField
                    select
                    fullWidth
                    margin="normal"
                    disabled={isSubmitting}
                    label={t('my_shop.shop_currency')}
                    error={!!errors.baseCurrency?.message}
                    helperText={t(errors.baseCurrency?.message as any)}
                    {...field}
                  >
                    {currencyOptions}
                  </TextField>
                )}
              />
            </Box>
            <Box className="cardContent__right-wrapperInput">
              <WrapperTextField
                name="category"
                control={control}
                isEditing={!!isCreate}
                label={t('my_shop.shop_type')}
                formatValue={(field) => getShopType(t)[field.value as NFT_SHOP_TYPE]?.label}
                render={(field) => (
                  <TextField
                    select
                    fullWidth
                    margin="normal"
                    disabled={isSubmitting}
                    label={t('my_shop.shop_type')}
                    error={!!errors.category?.message}
                    helperText={t(errors.category?.message as any)}
                    {...field}
                  >
                    {shopTypeOptions}
                  </TextField>
                )}
              />
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          {isCreate || isEditing ? (
            <>
              <Button variant="outlined" color="primary" onClick={handleCancel} disabled={isSubmitting}>
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                disabled={(!isCreate && !isDirty) || isSubmitting}
                endIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : undefined}
                onClick={handleSubmit(handlePreSubmit)}
              >
                {t(isCreate ? 'next' : 'save')}
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={handleChangeToEdit}>
              {t('edit')}
            </Button>
          )}
        </CardActions>
      </Card>
      <ConfirmationDialog
        open={openConfirmDialog}
        title={t('my_shop.confirm_change_currency')}
        content={t('my_shop.confirm_change_currency_content')}
        confirmTitle={t('update')}
        onClose={onCloseConfimDialog}
        onConfirm={handleSubmit(onSubmit)}
      />
    </Box>
  );
};

export default BasicSiteSetting;
