import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    fontSize: 14,
    fontWeight: 500,
    color: '#000000DE',
  },
}));

const NoResultsOverlay = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.content}>{t('no_data_available')}</Typography>
    </Box>
  );
};

export default NoResultsOverlay;
