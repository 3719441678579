import { useCallback } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

interface Props {
  text: string;
  size?: 'small' | 'medium' | 'large';
}
export const IconBtnCopy = ({ text, size = 'small' }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onCopyAddress = useCallback(() => {
    enqueueSnackbar(t('copied'), {
      variant: 'info',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  }, [enqueueSnackbar, t]);
  return (
    <CopyToClipboard text={text} onCopy={onCopyAddress}>
      <IconButton size={size}>
        <ContentCopyIcon fontSize="inherit" />
      </IconButton>
    </CopyToClipboard>
  );
};
