export enum AppRouteEnum {
  Home = '/',
  Collection = '/collection',
  CollectionDetail = '/collection/:collectionId',
  Shop = '/shop/:shopName',
  MyShop = '/my-shop',
  CreateShop = '/my-shop/create',
  ShopSetting = '/my-shop/:id/setting',
  EditShop = '/my-shop/:id/edit-shop',
  ShopDetail = '/my-shop/:id',
  ShopCollectionDetail = '/my-shop/:id/collection/:collectionId',
  LazyMintCollectionDetail = '/my-shop/:id/lazy-collection/:collectionId',
  MemberSite = '/member-site',
  BuyerMemberSite = '/member/:shopName',
  CreateMemberSite = '/member-site/create',
  MemberSiteSetting = '/member-site/:id/setting',
  MemberSiteCollections = '/member-site/:id/collections',
  MemberSiteNFTs = '/member-site/:id/nfts',
  EditMemberSite = '/member-site/:id/edit-member-site',
  MemberSiteDetail = '/member-site/:id',
  MemberSiteCollectionDetail = '/member-site/:id/collection/:collectionId',
  SignIn = '/sign-in',
  Setting = '/setting',
  NotFound = '/404',
}
