import { FC, ReactNode } from 'react';

import { OrganizationUserRole } from '~/graphql/member/types';
import { useCheckPermissions } from '~/hooks/with-account';

interface Props {
  children: ReactNode;
  children403?: any | null;
  roles: OrganizationUserRole[];
}

const PermissionsView: FC<Props> = ({ children, roles, children403 }) => {
  const [isCanView] = useCheckPermissions([roles]);
  if (!isCanView) {
    return children403 || null;
  }
  return <>{children}</>;
};

export default PermissionsView;
