export enum SalesCollectionsList {
  URL = 'url',
  NAME = 'name',
  ORDER = 'order',
  SYMBOL = 'symbol',
  CONTRACT_ADDRESS = 'contractAddress',
  OWNER_ADDRESS = 'ownerAddress',
  NETWORK = 'network',
  CREATED_AT = 'createdAt',
}

export enum MyShopCollectionNFTList {
  URL = 'url',
  NAME = 'name',
  ORDER = 'order',
  PRICE = 'price',
  STATUS = 'status',
  NAME_JA = 'nameJa',
  TOKEN_ID = 'tokenId',
  CREATED_AT = 'createdAt',
  ORIGINAL_NAME = 'originalName',
  OWNER_ADDRESS = 'ownerAddress',
}

export enum MyShopCollectionImageList {
  URL = 'url',
  NAME = 'name',
  ORDER = 'order',
  PRICE = 'price',
  STATUS = 'status',
  MAX_MINT = 'maxMint',
  CREATED_AT = 'createdAt',
  CURRENT_MINT = 'currentMint',
  ORIGINAL_NAME = 'originalName',
}

export enum CollectionList {
  NAME = 'name',
  SYMBOL = 'symbol',
  CONTRACT_ADDRESS = 'contractAddress',
  OWNER_ADDRESS = 'ownerAddress',
  NETWORK = 'network',
  CREATED_AT = 'createdAt',
}

export enum ShopAndMemberSiteList {
  NAME = 'name',
  DOMAIN = 'domain',
  PUBLISH = 'publish',
  CREATED_AT = 'createdAt',
}

export enum CollectionImageList {
  NAME = 'name',
  MAX_MINT = 'maxMint',
  CURRENT_MINT = 'currentMint',
}
