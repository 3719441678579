type EnvType = {
  REACT_APP_FIREBASE_PUBLIC_API_KEY: string;
  REACT_APP_FIREBASE_AUTH_DOMAIN: string;
  REACT_APP_FIREBASE_DATABASE_URL: string;
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_FIREBASE_STORAGE_BUCKET: string;
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string;
  REACT_APP_FIREBASE_APP_ID: string;
  REACT_APP_TERMS_OF_USE_JA: string;
  REACT_APP_TERMS_OF_USE_EN: string;
  REACT_APP_PROJECT_ID_WALLET_CONNECT: string;
  REACT_APP_SUPPORTED_NETWORK: string;
  REACT_APP_CLIENT_URL: string;
  REACT_APP_API_MEMBER_SERVER: string;
  REACT_APP_API_CLIENT_SERVER: string;
  REACT_APP_API_MEDIA: string;
  REACT_APP_MASTER_WALLET_ADDRESS: string;
  REACT_APP_IPFS_GATEWAY_URL: string;
  REACT_APP_HUBSPOT_URL: string;
};
export const env: EnvType = process.env.NODE_ENV === 'production' ? (window as any)['env'] : process.env;
