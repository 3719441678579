import moment from 'moment';

import { NFTToken } from './fetch-nfts';

import { env } from '~/env';
import { TokenQuery } from '~/graphql/subgraph/types';
import { MetadataJson } from '~/types/my-shop';

export const getNFTMetadata = async (collectionUuid: string, tokenInfo: TokenQuery['token']) => {
  const key = `${collectionUuid}-${parseInt(tokenInfo?.tokenID, 10) - 1}`;
  let metadata: NFTToken = {
    collectionUuid: collectionUuid!,
    createdAt: moment.unix(tokenInfo?.mintTime).toString(),
    description: '',
    metadataContent: {
      image: '',
      name: '',
      animation_url: '',
      description: '',
    },
    metadataUrl: tokenInfo?.tokenURI ?? '',
    name: '',
    ownerAddress: tokenInfo?.owner.id ?? '',
    tokenId: tokenInfo?.tokenID,
    uuid: key,
  };
  let cachedToken: NFTToken | undefined;
  try {
    cachedToken = JSON.parse(localStorage.getItem(key) || '');
  } catch (err: any) {}
  if (cachedToken) {
    metadata.metadataContent = {
      animation_url: '',
      image: cachedToken.metadataContent.image,
      name: cachedToken.metadataContent?.name || '',
      description: cachedToken.metadataContent?.description || '',
    };
    cachedToken.ownerAddress = tokenInfo?.owner.id ?? cachedToken.ownerAddress;
    localStorage.setItem(key, JSON.stringify(cachedToken));
  } else {
    const url = tokenInfo?.tokenURI.replace('ipfs://', env.REACT_APP_IPFS_GATEWAY_URL) || '';
    const data = await fetch(url);
    const metadataRes: MetadataJson = await data.json();
    metadata.metadataContent = {
      animation_url: '',
      image: metadataRes?.image
        ? metadataRes.image.replace('ipfs://', env.REACT_APP_IPFS_GATEWAY_URL)
        : '/images/gu-logo.svg',
      name: metadataRes?.name || '',
      description: metadataRes?.description || '',
    };
    localStorage.setItem(key, JSON.stringify(metadata));
  }
  return metadata;
};
