import { useCallback, useContext, useState } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StepConnector, stepConnectorClasses, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as HomeIcon } from '../../../icons/images/home-icon.svg';
import BasicSiteSetting from '../member-site-detail/components/shop-detail-tabs/BasicSiteSetting';
import MemberTerms from '../member-site-detail/components/shop-detail-tabs/MemberTerms';
import RegistrationCondition from '../member-site-detail/components/shop-detail-tabs/RegistrationCondition';

import UserLayout from '~/components/app-layout/user-layout';
import HomeBtn from '~/components/home-btn';
import { IFormData, MyShopContext, MyShopContextValue } from '~/contexts/MyShopWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { ListMyShopsDocument, ThemeInput, useCreateMyShopMutation } from '~/graphql/member/types';
import { initFormEditShop } from '~/pages/edit-shop';

const useStyles = makeStyles()(() => ({
  wrapperForm: {
    width: '100%',
    marginTop: '16px',
  },
  form: {},
}));

export const steps = (t: TFunction, isSubmitting: boolean, handleCreateMyPage?: (data: IFormData) => Promise<void>) => {
  return [
    { stepTitle: t('member_site.basic_settings'), component: <BasicSiteSetting isCreate /> },
    { stepTitle: t('member_site.member_terms'), component: <MemberTerms isCreate /> },
    {
      stepTitle: t('member_site.registration_condition'),
      component: <RegistrationCondition isCreate isSubmitting={isSubmitting} onCreate={handleCreateMyPage} />,
    },
  ];
};

const CreateMemberSite = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const myShopState = useContext(MyShopContext) as MyShopContextValue;
  const [createMyShop] = useCreateMyShopMutation({
    refetchQueries: [ListMyShopsDocument],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCreateMyPage = useCallback(
    async (data: IFormData) => {
      setIsSubmitting(true);
      try {
        const { siteSetting, shopInformation, nftActivationSettings, paymentMethod } = data;
        const newShop = await createMyShop({
          variables: {
            input: {
              shopType: myShopState.shopType,
              siteSetting: {
                name: siteSetting?.siteName,
                title: siteSetting?.siteName,
                meta: siteSetting?.metaDescription,
                description: siteSetting?.metaDescription,
                theme: {
                  ...(initFormEditShop(myShopState.isMemberSite) as ThemeInput),
                  navi: {
                    pageTitle: siteSetting?.siteName,
                    pageTitleJa: siteSetting?.siteName,
                    description: siteSetting?.metaDescription,
                    descriptionJa: siteSetting?.metaDescription,
                  },
                },
              },
              shopInformation: {
                policy: shopInformation?.policy,
              },
              paymentMethod: {
                baseCurrency: paymentMethod?.baseCurrency,
              },
              nftActivationSettings: {
                ...nftActivationSettings,
                expireValue: nftActivationSettings?.expireValue?.toString(),
                acquisitionInformation: nftActivationSettings?.acquisitionInformation,
              },
              domain: {
                name: siteSetting?.domainName,
              },
              ogpFile: siteSetting?.ogpFile?.[0],
              logoFile: siteSetting?.logoFile?.[0],
              faviconFile: siteSetting?.faviconFile?.[0],
            },
          },
        });
        setIsSubmitting(false);
        enqueueSnackbar(
          t(
            myShopState?.isMemberSite
              ? 'toast_message.create_member_site_successful'
              : 'toast_message.create_shop_successful'
          ),
          { variant: 'success' }
        );
        navigate(
          (myShopState.isMemberSite ? AppRouteEnum.MemberSiteDetail : AppRouteEnum.ShopDetail).replace(
            /:id/,
            newShop.data?.createMyShop.uuid || ''
          )
        );
      } catch (err: any) {
        enqueueSnackbar(err?.message ?? t('my_shop.message.error'), { variant: 'error' });
      }
    },
    [t, myShopState.shopType, myShopState.isMemberSite, createMyShop, navigate, enqueueSnackbar]
  );

  const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#0367DF',
      },
    },
  }));

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Link
          component={RouterLink}
          to={myShopState.isMemberSite ? AppRouteEnum.MemberSite : AppRouteEnum.MyShop}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t(myShopState.isMemberSite ? 'member_site.member_site' : 'my_shop.shops')}
        </Link>
        <Typography color="text.secondary">
          {t(myShopState.isMemberSite ? 'member_site.create_new_member_site' : 'my_shop.create_new_shop')}
        </Typography>
      </Breadcrumbs>
      <Box className={classes.wrapperForm}>
        <Box margin="24px 0">
          <Stepper alternativeLabel activeStep={myShopState.step} connector={<StyledStepConnector />}>
            {steps(t, isSubmitting).map((label) => (
              <Step key={label.stepTitle}>
                <StepLabel>{label.stepTitle}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box className={classes.form}>{steps(t, isSubmitting, handleCreateMyPage)[myShopState.step].component}</Box>
      </Box>
    </UserLayout>
  );
};

export default CreateMemberSite;
