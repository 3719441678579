import { useTranslation } from 'react-i18next';

const useTextLanguage = (en: string | undefined | null, ja: string | undefined | null) => {
  const { i18n } = useTranslation();

  if (i18n.language === 'en') {
    return en || ja || '';
  }
  return ja || en || '';
};

export default useTextLanguage;
