export interface CancelPromiseResponse {
  cancelCallback: (() => void) | undefined;
  awaitCallback: Promise<any>;
}

export const cancelPromise = (callback: () => any): CancelPromiseResponse => {
  let cancelCallback: CancelPromiseResponse['cancelCallback'];
  const awaitCallback: CancelPromiseResponse['awaitCallback'] = new Promise((resolve, reject) => {
    cancelCallback = () => reject();
    callback().then((data: any) => {
      resolve(data);
    });
  });
  return { cancelCallback, awaitCallback };
};
