import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import AccessDenied from '~/components/access-denied';
import CreateFirstOrganization from '~/components/create-first-organization';
import ScreenLoading from '~/components/screen-loading';
import TermsOfUse from '~/components/terms-of-use';
import {
  useGetMeQuery,
  OrganizationStatus,
  OrganizationRoleUser,
  UserOrs,
  OrganizationUserRole,
} from '~/graphql/member/types';

const AccountContext = createContext<{
  account: UserOrs;
  isApproved: boolean;
  selectedOrganization: OrganizationRoleUser;
  setSelectedOrganization: (uuid: string) => void;
}>({} as any);

export const useAccount = () => useContext(AccountContext);

export const useCheckPermissions = (
  rolesGroups: ((role: OrganizationUserRole) => Array<OrganizationUserRole[]>) | Array<OrganizationUserRole[]>
) => {
  const { selectedOrganization } = useAccount();
  const role = selectedOrganization.role;
  const result = [];
  if (typeof rolesGroups === 'function') {
    rolesGroups = rolesGroups(role);
  }
  for (let roles of rolesGroups) {
    result.push((roles || []).includes(role));
  }
  return result;
};

export const WithAccount: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const [selectedOrganizationUuid, setSelectedOrganizationUuid] = useState(() => {
    return localStorage.getItem('selectedOrganization') || '';
  });
  const { data: getMeData, error } = useGetMeQuery({
    fetchPolicy: 'cache-and-network',
  });

  const onSelectedOrganization = useCallback((uuid: string) => {
    localStorage.setItem('selectedOrganization', uuid || '');
    setSelectedOrganizationUuid(uuid);
  }, []);

  const organizationsObject = useMemo(() => {
    if (!getMeData?.getMe?.organizations) {
      return undefined;
    }
    return new Map(getMeData.getMe.organizations.map((org) => [org.uuid, org]));
  }, [getMeData]);

  const correctUuid = useMemo(() => {
    if (!getMeData?.getMe) {
      return undefined;
    }
    const isNumber = !isNaN(Number(selectedOrganizationUuid));
    const isNotExist = organizationsObject?.get(selectedOrganizationUuid);
    if ((isNumber || !isNotExist) && getMeData?.getMe?.organizations) {
      const firstOrganization = getMeData?.getMe?.organizations[0];
      onSelectedOrganization(firstOrganization?.uuid);
      return firstOrganization?.uuid;
    }
    return selectedOrganizationUuid;
  }, [getMeData?.getMe, organizationsObject, selectedOrganizationUuid, onSelectedOrganization]);

  const { selectedOrganization, isApproved } = useMemo(() => {
    const data = organizationsObject?.get(correctUuid!);
    return { selectedOrganization: data, isApproved: data?.status === OrganizationStatus.Approved };
  }, [organizationsObject, correctUuid]);

  useEffect(() => {
    if (getMeData?.getMe && selectedOrganization) {
      localStorage.setItem('organizationUuid', selectedOrganization.uuid);
    }
  }, [getMeData, selectedOrganization]);

  const isForbidden = (error?.graphQLErrors?.[0]?.extensions?.response as any)?.error === 'us_403_0';

  if (!getMeData && !isForbidden) {
    return <ScreenLoading />;
  }

  if (isForbidden) {
    return <AccessDenied />;
  }

  if (!getMeData?.getMe) {
    return <TermsOfUse />;
  }

  if (!getMeData.getMe.organizations.length) {
    return <CreateFirstOrganization user={getMeData.getMe} />;
  }

  if (!selectedOrganization) {
    return <ScreenLoading />;
  }

  // if (selectedOrganization.status !== OrganizationStatus.Approved && location.pathname !== AppRouteEnum.Home) {
  //   return <Navigate to={AppRouteEnum.Home} />;
  // }

  return (
    <AccountContext.Provider
      value={{
        isApproved,
        selectedOrganization,
        account: getMeData.getMe,
        setSelectedOrganization: onSelectedOrganization,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
