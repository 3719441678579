import { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { WalletConnectorDialog, useAccount } from '@gusdk/gu-wallet-connector';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import SalesCollectionTab from './SalesCollectionTab';
import WrapperAddCollectionDialog from './WrapperAddCollectionDialog';

import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import { ListTablePagination } from '~/components/list-table/v2';
import WrapperWithFab from '~/components/WrapperWithFab';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { ShopDetailContext, ShopDetailContextValue } from '~/contexts/ShopDetailWrapper';
import { env } from '~/env';
import { MyShopCollectionQueryKey, QueryOperator, useListMyShopCollectionsQuery } from '~/graphql/member/types';
import { COLLECTION_TYPE, GRANT_PERMISSION, SalesMethodEnum } from '~/types/my-shop';
import { verifyOrderKey, verifySortKey } from '~/utils/common';

export enum TABS {
  LAZY_MINT = 0,
  EXISTING = 1,
  MEMBER_SITE = 2,
}

export const TYPES = {
  [TABS.LAZY_MINT]: [COLLECTION_TYPE.MEMBERSHIP, COLLECTION_TYPE.PROMOTION],
  [TABS.EXISTING]: [COLLECTION_TYPE.DIGITAL_COLLECTIVE],
  [TABS.MEMBER_SITE]: [COLLECTION_TYPE.MEMBERSHIP, COLLECTION_TYPE.PROMOTION, COLLECTION_TYPE.DIGITAL_COLLECTIVE],
};

export interface DisplayedCollectionsList {
  id: string;
  url?: string;
  order?: number;
  status?: GRANT_PERMISSION;
  salesMethod?: SalesMethodEnum;

  [MyShopCollectionQueryKey.Name]?: string;
  [MyShopCollectionQueryKey.Desc]?: string;
  [MyShopCollectionQueryKey.NameJa]?: string;
  [MyShopCollectionQueryKey.DescJa]?: string;
  [MyShopCollectionQueryKey.CollectionUuid]?: string;
  [MyShopCollectionQueryKey.CollectionSymbol]?: string;
  [MyShopCollectionQueryKey.CollectionNetwork]: string;
  [MyShopCollectionQueryKey.CollectionCreatedAt]?: string;
  [MyShopCollectionQueryKey.CollectionOwnerAddress]: string;
  [MyShopCollectionQueryKey.CollectionContractAddress]?: string;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    height: '100%',
  },
}));

const SalesCollection: FC = () => {
  const myShopData = useContext(ShopDetailContext) as ShopDetailContextValue;

  const { id } = useParams();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { account } = useAccount();
  const { enqueueSnackbar } = useSnackbar();

  const [openWalletDialog, setOpenWalletDialog] = useState(false);
  const [isOpenAddCollection, setIsOpenAddCollection] = useState<boolean>(false);

  const openAttachCollectionDialog = useCallback(() => {
    setIsOpenAddCollection(true);
  }, []);

  const closeAttachCollectionDialog = useCallback(() => {
    setIsOpenAddCollection(false);
  }, []);
  const handleCloseWalletDialog = () => {
    setOpenWalletDialog(false);
  };

  const handleOpenAttachDialog = () => {
    if (account) {
      openAttachCollectionDialog();
    } else {
      setOpenWalletDialog(true);
    }
  };

  const [myShopCollectionsQuery, setMyShopCollectionsQuery] = useState({
    page: 1,
    limit: ITEMS_PER_PAGE.LIST,
    searchText: '',
    sortBy: verifySortKey(
      MyShopCollectionQueryKey,
      myShopData.data.shopInformation?.sortBy,
      MyShopCollectionQueryKey.CollectionCreatedAt
    ),
    orderBy: verifyOrderKey(myShopData.data.shopInformation?.orderBy),
    where: {
      fields: [
        {
          value: [id ?? ''],
          operator: QueryOperator.Contains,
          key: MyShopCollectionQueryKey.ShopUuid,
        },
      ],
    },
  });

  const updateMyShopCollectionsQuery = (newValue: any) => {
    setMyShopCollectionsQuery((value: any) => {
      return { ...value, ...newValue };
    });
  };

  const { data: dataShopCollections, loading: loadingListMyShopCollections } = useListMyShopCollectionsQuery({
    variables: myShopCollectionsQuery,
    fetchPolicy: 'cache-and-network',
    onError(error) {
      enqueueSnackbar(error.message || t('my_shop.message.error'), { variant: 'error' });
    },
  });

  const { items, pagination } = useMemo(() => {
    const items = dataShopCollections?.listMyShopCollections?.items || [];
    const pagination: ListTablePagination = dataShopCollections?.listMyShopCollections?.pagination || {};

    const _items: DisplayedCollectionsList[] = items.map((item) => {
      const { collection } = item;
      return {
        id: item.uuid,
        order: item.order,
        [MyShopCollectionQueryKey.CollectionName]: collection?.name,
        [MyShopCollectionQueryKey.CollectionUuid]: collection?.uuid,
        url: env.REACT_APP_API_MEDIA + '/' + collection?.images?.[0],
        [MyShopCollectionQueryKey.Name]: item.name || collection?.name,
        [MyShopCollectionQueryKey.CollectionSymbol]: collection?.symbol,
        [MyShopCollectionQueryKey.CollectionNetwork]: collection?.network,
        [MyShopCollectionQueryKey.NameJa]: item.nameJa || collection?.name,
        [MyShopCollectionQueryKey.Desc]: item.desc || collection?.desc || '',
        [MyShopCollectionQueryKey.CollectionCreatedAt]: collection?.createdAt,
        [MyShopCollectionQueryKey.DescJa]: item.descJa || collection?.descJa || '',
        [MyShopCollectionQueryKey.CollectionOwnerAddress]: collection?.ownerAddress,
        [MyShopCollectionQueryKey.CollectionContractAddress]: collection?.contractAddress,
      } as DisplayedCollectionsList;
    });
    return { items: _items, pagination };
  }, [dataShopCollections]);

  useEffect(() => {
    const newSortBy = verifySortKey(
      MyShopCollectionQueryKey,
      myShopData.data.shopInformation?.sortBy,
      MyShopCollectionQueryKey.CollectionCreatedAt
    );
    const newOrderBy = verifyOrderKey(myShopData.data.shopInformation?.orderBy);

    if (myShopCollectionsQuery.sortBy !== newSortBy || myShopCollectionsQuery.orderBy !== newOrderBy) {
      updateMyShopCollectionsQuery({ sortBy: newSortBy, orderBy: newOrderBy });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myShopData.data.shopInformation?.sortBy, myShopData.data.shopInformation?.orderBy]);

  return (
    <Box className={classes.wrapper}>
      <WrapperWithFab onClick={handleOpenAttachDialog}>
        <CustomCardTable
          cardTitle={t('collections')}
          headerAction={
            <HeaderAction
              menus={[
                {
                  title: t('my_shop.attach_new_collection'),
                  onClick: handleOpenAttachDialog,
                },
              ]}
            />
          }
          cardContent={
            <SalesCollectionTab
              rows={items}
              pagination={pagination}
              loadingData={loadingListMyShopCollections}
              queryData={myShopCollectionsQuery}
              updateQuery={updateMyShopCollectionsQuery}
              openAttachCollectionDialog={openAttachCollectionDialog}
            />
          }
        />
      </WrapperWithFab>
      <WalletConnectorDialog
        open={openWalletDialog}
        onClose={handleCloseWalletDialog}
        onNext={openAttachCollectionDialog}
      />
      <WrapperAddCollectionDialog
        open={isOpenAddCollection}
        onClose={closeAttachCollectionDialog}
        refetchMyShopCollections={() => updateMyShopCollectionsQuery({ page: 1, searchText: '' })}
      />
    </Box>
  );
};

export default memo(SalesCollection);
