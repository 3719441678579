import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import Box from '@mui/material/Box';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';

import UserLayout from '~/components/app-layout/user-layout';
import LoaderCenter from '~/components/loader-center';
import { Exact, GetMyShopQuery, useGetMyShopQuery } from '~/graphql/member/types';
import { notFound } from '~/utils/not-found';

export type MyShopResponse = GetMyShopQuery[keyof GetMyShopQuery];

export interface ShopDetailContextValue {
  data: GetMyShopQuery[keyof GetMyShopQuery];
  loading: boolean;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            myShopUuid: string;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetMyShopQuery>>;
}

export const ShopDetailContext = createContext<ShopDetailContextValue>({} as any);
export const useShopDetailWrapper = () => useContext(ShopDetailContext);

const ShopDetailWrapper = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: myShopDataRes,
    loading: loadingMyShopRes,
    refetch: refetchMyShop,
  } = useGetMyShopQuery({
    fetchPolicy: 'no-cache',
    variables: {
      myShopUuid: id ?? '',
    },
    onError: (err) => {
      notFound(Object.values(err)?.[0]?.[0]?.extensions?.code, navigate);
    },
  });

  const myShopData = useMemo(() => myShopDataRes?.getMyShop, [myShopDataRes?.getMyShop]);

  return (
    <Wrapper>
      <ShopDetailContext.Provider
        value={{
          data: myShopData!,
          loading: loadingMyShopRes,
          refetch: refetchMyShop,
        }}
      >
        {loadingMyShopRes ? (
          <Box width="100%" height="100%">
            <LoaderCenter />
          </Box>
        ) : (
          <Outlet />
        )}
      </ShopDetailContext.Provider>
    </Wrapper>
  );
};

const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const isEditShop = location.pathname.endsWith('edit-shop') || location.pathname.endsWith('edit-member-site');

  if (isEditShop && !!children) {
    return <>{children}</>;
  }

  return <UserLayout>{children}</UserLayout>;
};

export default ShopDetailWrapper;
