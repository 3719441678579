export const truncateEthAddress = (address: string) => {
  const match = address.match(/^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const truncateIpfsUrl = (url: string) => {
  const urlEnd = url.slice(url.length - 7);
  const urlStart = url.substring(0, 10);
  const shortened = urlStart + '...' + urlEnd;
  return shortened;
};
