import { FC, MouseEventHandler, useCallback, useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

type MenuHook = (params: {
  menus: {
    label: string;
    onClick: (event: any) => void;
  }[];
}) => { MenuComponent: FC; onOpenMenu: MouseEventHandler<HTMLButtonElement> };

const useMenu: MenuHook = (params) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

  const handleCloseMenu = () => {
    setAnchorEl(undefined);
  };

  const handleClick =
    (callback: MouseEventHandler<HTMLLIElement>): MouseEventHandler<HTMLLIElement> =>
    (event) => {
      handleCloseMenu();
      callback(event);
    };

  const onOpenMenu: MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const MenuComponent: FC = () => (
    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleCloseMenu}>
      {params.menus.map((menu) => (
        <MenuItem key={menu.label} onClick={handleClick(menu.onClick)}>
          {menu.label}
        </MenuItem>
      ))}
    </Menu>
  );

  return { MenuComponent, onOpenMenu };
};

export default useMenu;
