import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = { context: { apiName: 'subgraph' } } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  Int8: any;
};

export type All = {
  id: Scalars['ID'];
  numOwners: Scalars['BigInt'];
  numTokenContracts: Scalars['BigInt'];
  numTokens: Scalars['BigInt'];
};

export type All_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<All_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  numOwners?: InputMaybe<Scalars['BigInt']>;
  numOwners_gt?: InputMaybe<Scalars['BigInt']>;
  numOwners_gte?: InputMaybe<Scalars['BigInt']>;
  numOwners_in?: InputMaybe<Array<Scalars['BigInt']>>;
  numOwners_lt?: InputMaybe<Scalars['BigInt']>;
  numOwners_lte?: InputMaybe<Scalars['BigInt']>;
  numOwners_not?: InputMaybe<Scalars['BigInt']>;
  numOwners_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  numTokenContracts?: InputMaybe<Scalars['BigInt']>;
  numTokenContracts_gt?: InputMaybe<Scalars['BigInt']>;
  numTokenContracts_gte?: InputMaybe<Scalars['BigInt']>;
  numTokenContracts_in?: InputMaybe<Array<Scalars['BigInt']>>;
  numTokenContracts_lt?: InputMaybe<Scalars['BigInt']>;
  numTokenContracts_lte?: InputMaybe<Scalars['BigInt']>;
  numTokenContracts_not?: InputMaybe<Scalars['BigInt']>;
  numTokenContracts_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  numTokens?: InputMaybe<Scalars['BigInt']>;
  numTokens_gt?: InputMaybe<Scalars['BigInt']>;
  numTokens_gte?: InputMaybe<Scalars['BigInt']>;
  numTokens_in?: InputMaybe<Array<Scalars['BigInt']>>;
  numTokens_lt?: InputMaybe<Scalars['BigInt']>;
  numTokens_lte?: InputMaybe<Scalars['BigInt']>;
  numTokens_not?: InputMaybe<Scalars['BigInt']>;
  numTokens_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<All_Filter>>>;
};

export enum All_OrderBy {
  Id = 'id',
  NumOwners = 'numOwners',
  NumTokenContracts = 'numTokenContracts',
  NumTokens = 'numTokens',
}

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

/** Defines the order direction, either ascending or descending */
export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type Owner = {
  id: Scalars['ID'];
  numTokens: Scalars['BigInt'];
  tokens: Array<Token>;
};

export type OwnerTokensArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Token_Filter>;
};

export type OwnerPerTokenContract = {
  contract: TokenContract;
  id: Scalars['ID'];
  numTokens: Scalars['BigInt'];
  owner: Owner;
};

export type OwnerPerTokenContract_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<OwnerPerTokenContract_Filter>>>;
  contract?: InputMaybe<Scalars['String']>;
  contract_?: InputMaybe<TokenContract_Filter>;
  contract_contains?: InputMaybe<Scalars['String']>;
  contract_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_ends_with?: InputMaybe<Scalars['String']>;
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_gt?: InputMaybe<Scalars['String']>;
  contract_gte?: InputMaybe<Scalars['String']>;
  contract_in?: InputMaybe<Array<Scalars['String']>>;
  contract_lt?: InputMaybe<Scalars['String']>;
  contract_lte?: InputMaybe<Scalars['String']>;
  contract_not?: InputMaybe<Scalars['String']>;
  contract_not_contains?: InputMaybe<Scalars['String']>;
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_starts_with?: InputMaybe<Scalars['String']>;
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  numTokens?: InputMaybe<Scalars['BigInt']>;
  numTokens_gt?: InputMaybe<Scalars['BigInt']>;
  numTokens_gte?: InputMaybe<Scalars['BigInt']>;
  numTokens_in?: InputMaybe<Array<Scalars['BigInt']>>;
  numTokens_lt?: InputMaybe<Scalars['BigInt']>;
  numTokens_lte?: InputMaybe<Scalars['BigInt']>;
  numTokens_not?: InputMaybe<Scalars['BigInt']>;
  numTokens_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<OwnerPerTokenContract_Filter>>>;
  owner?: InputMaybe<Scalars['String']>;
  owner_?: InputMaybe<Owner_Filter>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum OwnerPerTokenContract_OrderBy {
  Contract = 'contract',
  ContractDoAllAddressesOwnTheirIdByDefault = 'contract__doAllAddressesOwnTheirIdByDefault',
  ContractId = 'contract__id',
  ContractName = 'contract__name',
  ContractNumOwners = 'contract__numOwners',
  ContractNumTokens = 'contract__numTokens',
  ContractSupportsEip721Metadata = 'contract__supportsEIP721Metadata',
  ContractSymbol = 'contract__symbol',
  Id = 'id',
  NumTokens = 'numTokens',
  Owner = 'owner',
  OwnerId = 'owner__id',
  OwnerNumTokens = 'owner__numTokens',
}

export type Owner_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Owner_Filter>>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  numTokens?: InputMaybe<Scalars['BigInt']>;
  numTokens_gt?: InputMaybe<Scalars['BigInt']>;
  numTokens_gte?: InputMaybe<Scalars['BigInt']>;
  numTokens_in?: InputMaybe<Array<Scalars['BigInt']>>;
  numTokens_lt?: InputMaybe<Scalars['BigInt']>;
  numTokens_lte?: InputMaybe<Scalars['BigInt']>;
  numTokens_not?: InputMaybe<Scalars['BigInt']>;
  numTokens_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<Owner_Filter>>>;
  tokens_?: InputMaybe<Token_Filter>;
};

export enum Owner_OrderBy {
  Id = 'id',
  NumTokens = 'numTokens',
  Tokens = 'tokens',
}

export type Query = {
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  all?: Maybe<All>;
  alls: Array<All>;
  owner?: Maybe<Owner>;
  ownerPerTokenContract?: Maybe<OwnerPerTokenContract>;
  ownerPerTokenContracts: Array<OwnerPerTokenContract>;
  owners: Array<Owner>;
  token?: Maybe<Token>;
  tokenContract?: Maybe<TokenContract>;
  tokenContracts: Array<TokenContract>;
  tokens: Array<Token>;
};

export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type QueryAllArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryAllsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<All_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<All_Filter>;
};

export type QueryOwnerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryOwnerPerTokenContractArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryOwnerPerTokenContractsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OwnerPerTokenContract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OwnerPerTokenContract_Filter>;
};

export type QueryOwnersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Owner_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Owner_Filter>;
};

export type QueryTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryTokenContractArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryTokenContractsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenContract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TokenContract_Filter>;
};

export type QueryTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};

export type Subscription = {
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  all?: Maybe<All>;
  alls: Array<All>;
  owner?: Maybe<Owner>;
  ownerPerTokenContract?: Maybe<OwnerPerTokenContract>;
  ownerPerTokenContracts: Array<OwnerPerTokenContract>;
  owners: Array<Owner>;
  token?: Maybe<Token>;
  tokenContract?: Maybe<TokenContract>;
  tokenContracts: Array<TokenContract>;
  tokens: Array<Token>;
};

export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type SubscriptionAllArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionAllsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<All_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<All_Filter>;
};

export type SubscriptionOwnerArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionOwnerPerTokenContractArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionOwnerPerTokenContractsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OwnerPerTokenContract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<OwnerPerTokenContract_Filter>;
};

export type SubscriptionOwnersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Owner_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Owner_Filter>;
};

export type SubscriptionTokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionTokenContractArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionTokenContractsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenContract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TokenContract_Filter>;
};

export type SubscriptionTokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
};

export type Token = {
  contract: TokenContract;
  id: Scalars['ID'];
  mintTime: Scalars['BigInt'];
  owner: Owner;
  tokenID: Scalars['BigInt'];
  tokenURI: Scalars['String'];
};

export type TokenContract = {
  doAllAddressesOwnTheirIdByDefault: Scalars['Boolean'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  numOwners: Scalars['BigInt'];
  numTokens: Scalars['BigInt'];
  supportsEIP721Metadata: Scalars['Boolean'];
  symbol?: Maybe<Scalars['String']>;
  tokens: Array<Token>;
};

export type TokenContractTokensArgs = {
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Token_Filter>;
};

export type TokenContract_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TokenContract_Filter>>>;
  doAllAddressesOwnTheirIdByDefault?: InputMaybe<Scalars['Boolean']>;
  doAllAddressesOwnTheirIdByDefault_in?: InputMaybe<Array<Scalars['Boolean']>>;
  doAllAddressesOwnTheirIdByDefault_not?: InputMaybe<Scalars['Boolean']>;
  doAllAddressesOwnTheirIdByDefault_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  numOwners?: InputMaybe<Scalars['BigInt']>;
  numOwners_gt?: InputMaybe<Scalars['BigInt']>;
  numOwners_gte?: InputMaybe<Scalars['BigInt']>;
  numOwners_in?: InputMaybe<Array<Scalars['BigInt']>>;
  numOwners_lt?: InputMaybe<Scalars['BigInt']>;
  numOwners_lte?: InputMaybe<Scalars['BigInt']>;
  numOwners_not?: InputMaybe<Scalars['BigInt']>;
  numOwners_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  numTokens?: InputMaybe<Scalars['BigInt']>;
  numTokens_gt?: InputMaybe<Scalars['BigInt']>;
  numTokens_gte?: InputMaybe<Scalars['BigInt']>;
  numTokens_in?: InputMaybe<Array<Scalars['BigInt']>>;
  numTokens_lt?: InputMaybe<Scalars['BigInt']>;
  numTokens_lte?: InputMaybe<Scalars['BigInt']>;
  numTokens_not?: InputMaybe<Scalars['BigInt']>;
  numTokens_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<TokenContract_Filter>>>;
  supportsEIP721Metadata?: InputMaybe<Scalars['Boolean']>;
  supportsEIP721Metadata_in?: InputMaybe<Array<Scalars['Boolean']>>;
  supportsEIP721Metadata_not?: InputMaybe<Scalars['Boolean']>;
  supportsEIP721Metadata_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  tokens_?: InputMaybe<Token_Filter>;
};

export enum TokenContract_OrderBy {
  DoAllAddressesOwnTheirIdByDefault = 'doAllAddressesOwnTheirIdByDefault',
  Id = 'id',
  Name = 'name',
  NumOwners = 'numOwners',
  NumTokens = 'numTokens',
  SupportsEip721Metadata = 'supportsEIP721Metadata',
  Symbol = 'symbol',
  Tokens = 'tokens',
}

export type Token_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  contract?: InputMaybe<Scalars['String']>;
  contract_?: InputMaybe<TokenContract_Filter>;
  contract_contains?: InputMaybe<Scalars['String']>;
  contract_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_ends_with?: InputMaybe<Scalars['String']>;
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_gt?: InputMaybe<Scalars['String']>;
  contract_gte?: InputMaybe<Scalars['String']>;
  contract_in?: InputMaybe<Array<Scalars['String']>>;
  contract_lt?: InputMaybe<Scalars['String']>;
  contract_lte?: InputMaybe<Scalars['String']>;
  contract_not?: InputMaybe<Scalars['String']>;
  contract_not_contains?: InputMaybe<Scalars['String']>;
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_starts_with?: InputMaybe<Scalars['String']>;
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  mintTime?: InputMaybe<Scalars['BigInt']>;
  mintTime_gt?: InputMaybe<Scalars['BigInt']>;
  mintTime_gte?: InputMaybe<Scalars['BigInt']>;
  mintTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  mintTime_lt?: InputMaybe<Scalars['BigInt']>;
  mintTime_lte?: InputMaybe<Scalars['BigInt']>;
  mintTime_not?: InputMaybe<Scalars['BigInt']>;
  mintTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  or?: InputMaybe<Array<InputMaybe<Token_Filter>>>;
  owner?: InputMaybe<Scalars['String']>;
  owner_?: InputMaybe<Owner_Filter>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  tokenID?: InputMaybe<Scalars['BigInt']>;
  tokenID_gt?: InputMaybe<Scalars['BigInt']>;
  tokenID_gte?: InputMaybe<Scalars['BigInt']>;
  tokenID_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenID_lt?: InputMaybe<Scalars['BigInt']>;
  tokenID_lte?: InputMaybe<Scalars['BigInt']>;
  tokenID_not?: InputMaybe<Scalars['BigInt']>;
  tokenID_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenURI?: InputMaybe<Scalars['String']>;
  tokenURI_contains?: InputMaybe<Scalars['String']>;
  tokenURI_contains_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_ends_with?: InputMaybe<Scalars['String']>;
  tokenURI_ends_with_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_gt?: InputMaybe<Scalars['String']>;
  tokenURI_gte?: InputMaybe<Scalars['String']>;
  tokenURI_in?: InputMaybe<Array<Scalars['String']>>;
  tokenURI_lt?: InputMaybe<Scalars['String']>;
  tokenURI_lte?: InputMaybe<Scalars['String']>;
  tokenURI_not?: InputMaybe<Scalars['String']>;
  tokenURI_not_contains?: InputMaybe<Scalars['String']>;
  tokenURI_not_contains_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_not_ends_with?: InputMaybe<Scalars['String']>;
  tokenURI_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_not_in?: InputMaybe<Array<Scalars['String']>>;
  tokenURI_not_starts_with?: InputMaybe<Scalars['String']>;
  tokenURI_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  tokenURI_starts_with?: InputMaybe<Scalars['String']>;
  tokenURI_starts_with_nocase?: InputMaybe<Scalars['String']>;
};

export enum Token_OrderBy {
  Contract = 'contract',
  ContractDoAllAddressesOwnTheirIdByDefault = 'contract__doAllAddressesOwnTheirIdByDefault',
  ContractId = 'contract__id',
  ContractName = 'contract__name',
  ContractNumOwners = 'contract__numOwners',
  ContractNumTokens = 'contract__numTokens',
  ContractSupportsEip721Metadata = 'contract__supportsEIP721Metadata',
  ContractSymbol = 'contract__symbol',
  Id = 'id',
  MintTime = 'mintTime',
  Owner = 'owner',
  OwnerId = 'owner__id',
  OwnerNumTokens = 'owner__numTokens',
  TokenId = 'tokenID',
  TokenUri = 'tokenURI',
}

export type _Block_ = {
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = 'allow',
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = 'deny',
}

export type OwnerPerTokenContractQueryVariables = Exact<{
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError: _SubgraphErrorPolicy_;
}>;

export type OwnerPerTokenContractQuery = {
  ownerPerTokenContract?: {
    id: string;
    numTokens: any;
    owner: { id: string; numTokens: any };
    contract: {
      id: string;
      name?: string | null;
      symbol?: string | null;
      doAllAddressesOwnTheirIdByDefault: boolean;
      supportsEIP721Metadata: boolean;
      numTokens: any;
      tokens: Array<{ id: string; tokenID: any; mintTime: any; tokenURI: string; owner: { id: string } }>;
    };
  } | null;
};

export type OwnerPerTokenContractsQueryVariables = Exact<{
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OwnerPerTokenContract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError: _SubgraphErrorPolicy_;
  where?: InputMaybe<OwnerPerTokenContract_Filter>;
}>;

export type OwnerPerTokenContractsQuery = {
  ownerPerTokenContracts: Array<{
    id: string;
    numTokens: any;
    owner: { id: string; numTokens: any };
    contract: {
      id: string;
      name?: string | null;
      symbol?: string | null;
      doAllAddressesOwnTheirIdByDefault: boolean;
      supportsEIP721Metadata: boolean;
      numTokens: any;
      tokens: Array<{ id: string; tokenID: any; mintTime: any; tokenURI: string; owner: { id: string } }>;
    };
  }>;
};

export type TokenContractQueryVariables = Exact<{
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError: _SubgraphErrorPolicy_;
}>;

export type TokenContractQuery = {
  tokenContract?: {
    id: string;
    name?: string | null;
    symbol?: string | null;
    doAllAddressesOwnTheirIdByDefault: boolean;
    supportsEIP721Metadata: boolean;
    numTokens: any;
    tokens: Array<{ id: string; tokenID: any; mintTime: any; tokenURI: string; owner: { id: string } }>;
  } | null;
};

export type TokenContractsQueryVariables = Exact<{
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenContract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError: _SubgraphErrorPolicy_;
  where?: InputMaybe<TokenContract_Filter>;
}>;

export type TokenContractsQuery = {
  tokenContracts: Array<{
    id: string;
    name?: string | null;
    symbol?: string | null;
    doAllAddressesOwnTheirIdByDefault: boolean;
    supportsEIP721Metadata: boolean;
    numTokens: any;
    tokens: Array<{ id: string; tokenID: any; mintTime: any; tokenURI: string; owner: { id: string } }>;
  }>;
};

export type TokenQueryVariables = Exact<{
  block?: InputMaybe<Block_Height>;
  id: Scalars['ID'];
  subgraphError: _SubgraphErrorPolicy_;
}>;

export type TokenQuery = {
  token?: { id: string; tokenID: any; mintTime: any; tokenURI: string; owner: { id: string } } | null;
};

export type TokensQueryVariables = Exact<{
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars['Int']>;
  subgraphError: _SubgraphErrorPolicy_;
  where?: InputMaybe<Token_Filter>;
}>;

export type TokensQuery = {
  tokens: Array<{
    id: string;
    tokenID: any;
    mintTime: any;
    tokenURI: string;
    owner: { id: string };
    contract: { id: string; numTokens: any };
  }>;
};

export const OwnerPerTokenContractDocument = gql`
  query ownerPerTokenContract($block: Block_height, $id: ID!, $subgraphError: _SubgraphErrorPolicy_!) {
    ownerPerTokenContract(block: $block, id: $id, subgraphError: $subgraphError) {
      id
      owner {
        id
        numTokens
      }
      contract {
        id
        name
        symbol
        doAllAddressesOwnTheirIdByDefault
        supportsEIP721Metadata
        tokens {
          id
          tokenID
          owner {
            id
          }
          mintTime
          tokenURI
        }
        numTokens
        numTokens
      }
      numTokens
    }
  }
`;

/**
 * __useOwnerPerTokenContractQuery__
 *
 * To run a query within a React component, call `useOwnerPerTokenContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerPerTokenContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerPerTokenContractQuery({
 *   variables: {
 *      block: // value for 'block'
 *      id: // value for 'id'
 *      subgraphError: // value for 'subgraphError'
 *   },
 * });
 */
export function useOwnerPerTokenContractQuery(
  baseOptions: Apollo.QueryHookOptions<OwnerPerTokenContractQuery, OwnerPerTokenContractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OwnerPerTokenContractQuery, OwnerPerTokenContractQueryVariables>(
    OwnerPerTokenContractDocument,
    options
  );
}
export function useOwnerPerTokenContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OwnerPerTokenContractQuery, OwnerPerTokenContractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OwnerPerTokenContractQuery, OwnerPerTokenContractQueryVariables>(
    OwnerPerTokenContractDocument,
    options
  );
}
export type OwnerPerTokenContractQueryHookResult = ReturnType<typeof useOwnerPerTokenContractQuery>;
export type OwnerPerTokenContractLazyQueryHookResult = ReturnType<typeof useOwnerPerTokenContractLazyQuery>;
export type OwnerPerTokenContractQueryResult = Apollo.QueryResult<
  OwnerPerTokenContractQuery,
  OwnerPerTokenContractQueryVariables
>;
export const OwnerPerTokenContractsDocument = gql`
  query ownerPerTokenContracts(
    $block: Block_height
    $first: Int
    $orderBy: OwnerPerTokenContract_orderBy
    $orderDirection: OrderDirection
    $skip: Int
    $subgraphError: _SubgraphErrorPolicy_!
    $where: OwnerPerTokenContract_filter
  ) {
    ownerPerTokenContracts(
      block: $block
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      subgraphError: $subgraphError
      where: $where
    ) {
      id
      owner {
        id
        numTokens
      }
      contract {
        id
        name
        symbol
        doAllAddressesOwnTheirIdByDefault
        supportsEIP721Metadata
        tokens {
          id
          tokenID
          owner {
            id
          }
          mintTime
          tokenURI
        }
        numTokens
        numTokens
      }
      numTokens
    }
  }
`;

/**
 * __useOwnerPerTokenContractsQuery__
 *
 * To run a query within a React component, call `useOwnerPerTokenContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerPerTokenContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerPerTokenContractsQuery({
 *   variables: {
 *      block: // value for 'block'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      skip: // value for 'skip'
 *      subgraphError: // value for 'subgraphError'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOwnerPerTokenContractsQuery(
  baseOptions: Apollo.QueryHookOptions<OwnerPerTokenContractsQuery, OwnerPerTokenContractsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OwnerPerTokenContractsQuery, OwnerPerTokenContractsQueryVariables>(
    OwnerPerTokenContractsDocument,
    options
  );
}
export function useOwnerPerTokenContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OwnerPerTokenContractsQuery, OwnerPerTokenContractsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OwnerPerTokenContractsQuery, OwnerPerTokenContractsQueryVariables>(
    OwnerPerTokenContractsDocument,
    options
  );
}
export type OwnerPerTokenContractsQueryHookResult = ReturnType<typeof useOwnerPerTokenContractsQuery>;
export type OwnerPerTokenContractsLazyQueryHookResult = ReturnType<typeof useOwnerPerTokenContractsLazyQuery>;
export type OwnerPerTokenContractsQueryResult = Apollo.QueryResult<
  OwnerPerTokenContractsQuery,
  OwnerPerTokenContractsQueryVariables
>;
export const TokenContractDocument = gql`
  query tokenContract($block: Block_height, $id: ID!, $subgraphError: _SubgraphErrorPolicy_!) {
    tokenContract(block: $block, id: $id, subgraphError: $subgraphError) {
      id
      name
      symbol
      doAllAddressesOwnTheirIdByDefault
      supportsEIP721Metadata
      tokens {
        id
        tokenID
        owner {
          id
        }
        mintTime
        tokenURI
      }
      numTokens
      numTokens
    }
  }
`;

/**
 * __useTokenContractQuery__
 *
 * To run a query within a React component, call `useTokenContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenContractQuery({
 *   variables: {
 *      block: // value for 'block'
 *      id: // value for 'id'
 *      subgraphError: // value for 'subgraphError'
 *   },
 * });
 */
export function useTokenContractQuery(
  baseOptions: Apollo.QueryHookOptions<TokenContractQuery, TokenContractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TokenContractQuery, TokenContractQueryVariables>(TokenContractDocument, options);
}
export function useTokenContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TokenContractQuery, TokenContractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TokenContractQuery, TokenContractQueryVariables>(TokenContractDocument, options);
}
export type TokenContractQueryHookResult = ReturnType<typeof useTokenContractQuery>;
export type TokenContractLazyQueryHookResult = ReturnType<typeof useTokenContractLazyQuery>;
export type TokenContractQueryResult = Apollo.QueryResult<TokenContractQuery, TokenContractQueryVariables>;
export const TokenContractsDocument = gql`
  query tokenContracts(
    $block: Block_height
    $first: Int
    $orderBy: TokenContract_orderBy
    $orderDirection: OrderDirection
    $skip: Int
    $subgraphError: _SubgraphErrorPolicy_!
    $where: TokenContract_filter
  ) {
    tokenContracts(
      block: $block
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      subgraphError: $subgraphError
      where: $where
    ) {
      id
      name
      symbol
      doAllAddressesOwnTheirIdByDefault
      supportsEIP721Metadata
      tokens {
        id
        tokenID
        owner {
          id
        }
        mintTime
        tokenURI
      }
      numTokens
      numTokens
    }
  }
`;

/**
 * __useTokenContractsQuery__
 *
 * To run a query within a React component, call `useTokenContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenContractsQuery({
 *   variables: {
 *      block: // value for 'block'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      skip: // value for 'skip'
 *      subgraphError: // value for 'subgraphError'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTokenContractsQuery(
  baseOptions: Apollo.QueryHookOptions<TokenContractsQuery, TokenContractsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TokenContractsQuery, TokenContractsQueryVariables>(TokenContractsDocument, options);
}
export function useTokenContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TokenContractsQuery, TokenContractsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TokenContractsQuery, TokenContractsQueryVariables>(TokenContractsDocument, options);
}
export type TokenContractsQueryHookResult = ReturnType<typeof useTokenContractsQuery>;
export type TokenContractsLazyQueryHookResult = ReturnType<typeof useTokenContractsLazyQuery>;
export type TokenContractsQueryResult = Apollo.QueryResult<TokenContractsQuery, TokenContractsQueryVariables>;
export const TokenDocument = gql`
  query token($block: Block_height, $id: ID!, $subgraphError: _SubgraphErrorPolicy_!) {
    token(block: $block, id: $id, subgraphError: $subgraphError) {
      id
      tokenID
      owner {
        id
      }
      mintTime
      tokenURI
    }
  }
`;

/**
 * __useTokenQuery__
 *
 * To run a query within a React component, call `useTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenQuery({
 *   variables: {
 *      block: // value for 'block'
 *      id: // value for 'id'
 *      subgraphError: // value for 'subgraphError'
 *   },
 * });
 */
export function useTokenQuery(baseOptions: Apollo.QueryHookOptions<TokenQuery, TokenQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TokenQuery, TokenQueryVariables>(TokenDocument, options);
}
export function useTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenQuery, TokenQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TokenQuery, TokenQueryVariables>(TokenDocument, options);
}
export type TokenQueryHookResult = ReturnType<typeof useTokenQuery>;
export type TokenLazyQueryHookResult = ReturnType<typeof useTokenLazyQuery>;
export type TokenQueryResult = Apollo.QueryResult<TokenQuery, TokenQueryVariables>;
export const TokensDocument = gql`
  query tokens(
    $block: Block_height
    $first: Int
    $orderBy: Token_orderBy
    $orderDirection: OrderDirection
    $skip: Int
    $subgraphError: _SubgraphErrorPolicy_!
    $where: Token_filter
  ) {
    tokens(
      block: $block
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      subgraphError: $subgraphError
      where: $where
    ) {
      id
      tokenID
      owner {
        id
      }
      contract {
        id
        numTokens
      }
      mintTime
      tokenURI
    }
  }
`;

/**
 * __useTokensQuery__
 *
 * To run a query within a React component, call `useTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokensQuery({
 *   variables: {
 *      block: // value for 'block'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      skip: // value for 'skip'
 *      subgraphError: // value for 'subgraphError'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTokensQuery(baseOptions: Apollo.QueryHookOptions<TokensQuery, TokensQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TokensQuery, TokensQueryVariables>(TokensDocument, options);
}
export function useTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokensQuery, TokensQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TokensQuery, TokensQueryVariables>(TokensDocument, options);
}
export type TokensQueryHookResult = ReturnType<typeof useTokensQuery>;
export type TokensLazyQueryHookResult = ReturnType<typeof useTokensLazyQuery>;
export type TokensQueryResult = Apollo.QueryResult<TokensQuery, TokensQueryVariables>;
