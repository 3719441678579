import { OrderByGrid } from '~/constants/tableKeys';
import { OrderBy } from '~/graphql/member/types';
import { OrderDirection } from '~/graphql/subgraph/types';

interface SortConfig {
  key: string;
  order?: 'asc' | 'desc' | null;
}

interface localStorageItems {}

export const multiSort = <T>(array: T[], sortConfigs: SortConfig[]): T[] => {
  return array.sort((a, b) => {
    for (const config of sortConfigs) {
      const { key, order = 'asc' } = config;

      const aValue = (a as any)[key];
      const bValue = (b as any)[key];

      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      } else if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      }
    }

    return 0;
  });
};

export const isNumberic = (value: string) => {
  return /^\d+$/.test(value);
};

export const setLocalStorageItems = (items: localStorageItems) => {
  for (const [key, value] of Object.entries(items)) {
    if (value !== null && value !== undefined) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }
};
export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const verifySortKey = (keys: any, value: any, defaultValue?: any) => {
  return Object.values(keys).includes(value) ? value : defaultValue || keys.CreatedAt;
};

export const verifyOrderKey = (orderKey?: any) => {
  return orderKey === OrderBy.Asc ? OrderBy.Asc : OrderBy.Desc;
};

export const verifySubgraphOrderKey = (orderKey?: any) => {
  return orderKey === OrderDirection.Asc ? OrderDirection.Asc : OrderDirection.Desc;
};

export const convertOrderToAPI = (value?: string) => {
  return !!value ? (value === OrderDirection.Asc ? OrderByGrid.asc : OrderByGrid.desc) : undefined;
};

export const convertOrderToSubgraph = (value?: string) => {
  return !!value ? (value === OrderByGrid.asc ? OrderDirection.Asc : OrderDirection.Desc) : undefined;
};
