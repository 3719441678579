import { FC, useEffect, useRef, useState } from 'react';

import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { makeStyles } from 'tss-react/mui';

import { useNotify } from '~/hooks/useNotify';
import { getErrorText } from '~/utils/yup.util';

const formats = ['link'];

const toolbarOptions = [['link']];

const countSlashN = (str: string) => str.split('\n').length - 1;

const useStyles = makeStyles<{ t: TFunction; heightEditor: number }>()((_, { t, heightEditor }) => ({
  textEditor: {
    width: '100%',
    position: 'relative',
    p: {
      margin: 0,
      padding: 0,
    },
    '.MuiTypography-subtitle2': {
      zIndex: 1,
      top: '-8px',
      left: '9px',
      fontWeight: 400,
      padding: '0 5px',
      fontSize: '12px',
      lineHeight: '16px',
      position: 'absolute',
      display: 'inline-block',
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '.quill': {
      display: 'flex',
      flexDirection: 'column-reverse',
      '.ql-toolbar.ql-snow': {
        borderRadius: '0 0 4px 4px',
      },
      '.ql-container.ql-snow': {
        fontSize: '1rem',
        borderRadius: '4px 4px 0 0',
        borderTop: '1px solid #ccc',
        borderWidth: '1px 1px 0px 1px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        '.ql-editor': {
          minHeight: `${heightEditor}px`,
          maxHeight: `${heightEditor}px`,
        },
        '.ql-tooltip': {
          zIndex: 2,
          '&:before': {
            content: `"${t('link')}"`,
          },
          '.ql-action:after': {
            marginLeft: 0,
          },
          ':not(.ql-editing)': {
            '.ql-preview': {
              display: 'block',
              maxWidth: '238px',
            },
            '.ql-action:after': {
              content: `"${t('edit')}"`,
            },
            '.ql-remove:before': {
              content: `"${t('remove')}"`,
            },
          },
          '&.ql-editing': {
            input: {
              width: '238px',
              display: 'block',
            },
            '.ql-action:after': {
              content: `"${t('save')}"`,
            },
          },
        },
      },
    },
  },
}));

export const useValidLinkQuill = (ref: any) => {
  const { showErrorByKey } = useNotify();

  useEffect(() => {
    const editorInstance = ref?.current;

    try {
      if (!editorInstance?.editor) return;
      editorInstance.editor.theme.modules.toolbar.handlers.link = function (value: any) {
        if (value) {
          let range = editorInstance.editor.getSelection();
          if (range == null || range.length === 0) return;
          let preview = editorInstance.editor.getText(range);
          const tooltip = editorInstance.editor.theme.tooltip;
          tooltip.save = function () {
            const val = tooltip.textbox.value;
            const urlRegex = /http(s)?:\/\/(www.)?[A-Za-z0-9]+(\.[A-Za-z]{2,}){1,3}\/?[^\s]*$/;
            if (val.match(urlRegex)) {
              if (tooltip.linkRange) {
                tooltip.quill.formatText(tooltip.linkRange, 'link', val);
                tooltip.hide();
              } else {
                editorInstance.editor.format('link', val);
              }
            } else {
              showErrorByKey('form_validation.invalid_value_entered');
            }
          };
          tooltip.edit('link', preview);
        }
      };
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showErrorByKey]);
};

const TextEditor: FC<{
  name: string;
  label: string;
  error?: string;
  maxLength: number;
  readOnly?: boolean;
  sx?: SxProps<Theme>;
  heightEditor: number;
  control: Control<any, any>;
}> = ({ sx, name, label, error, control, readOnly, maxLength, heightEditor }) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles({ t, heightEditor });

  const [length, setLength] = useState(0);

  const ref = useRef<ReactQuill>(null);

  useValidLinkQuill(ref);

  const getLength = () => {
    if (ref.current) {
      const text = ref.current.getEditor().getText();
      const removedSlashN = text.replace(/\n/g, '');
      return removedSlashN.length;
    } else {
      return 0;
    }
  };

  const handleChange = (callback: (value: string) => void) => () => {
    const editor = ref.current?.getEditor();
    if (!editor) {
      return;
    }
    const currentLength = getLength();
    if (currentLength > maxLength) {
      const numberSlashN = countSlashN(editor.getText());
      const startIdx = maxLength + numberSlashN - 1;
      editor?.deleteText(startIdx, currentLength);
    }
    setLength(getLength());
    let newContents = editor.root.innerHTML;
    if (newContents.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      newContents = '';
    }
    callback(newContents || '');
  };

  useEffect(() => {
    setLength(getLength());
  }, []);

  return (
    <Box sx={sx} className={cx('wrapperTextEditor', classes.textEditor)}>
      <Typography variant="subtitle2">{`${label} (${length}/${maxLength})`}</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ReactQuill
            ref={ref}
            theme="snow"
            formats={formats}
            readOnly={readOnly}
            placeholder={label}
            value={field.value || ''}
            bounds=".wrapperTextEditor"
            modules={{
              toolbar: toolbarOptions,
            }}
            onChange={handleChange(field.onChange)}
          />
        )}
      />
      {!!error && (
        <Typography variant="caption" color="error">
          {getErrorText(error, t)}
        </Typography>
      )}
    </Box>
  );
};

export default TextEditor;
