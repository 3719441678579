import { FC, memo, useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import { CURRENCY_ICONS } from '~/constants/common';
import { useShopDetailWrapper } from '~/contexts/ShopDetailWrapper';
import { env } from '~/env';
import i18nMain from '~/i18n';
import { EditShopContext, EditShopContextValue } from '~/pages/edit-shop';
import { CardData, NFT_SHOP_TYPE, OBJECT_FIT } from '~/types/my-shop';
import { getNFTMetadata } from '~/utils/getNFTMetadata';
import { getErrorText } from '~/utils/yup.util';

interface ProductCardProps {
  data: CardData;
  customName: string | undefined;
  onSaveEditCaption?: (info: CardData) => (data: { caption: string }) => Promise<void>;
}

const useStyles = makeStyles<{
  nftCardTextColor: string;
  nftCardBackgroundColor: string;
  collectionNftCardImageMode?: OBJECT_FIT;
  positionLabelSample?: (number | undefined)[] | null;
}>()(
  ({ breakpoints }, { nftCardTextColor, positionLabelSample, nftCardBackgroundColor, collectionNftCardImageMode }) => {
    const sm = breakpoints.up('sm').replace('@media', '@container');
    return {
      card: {
        boxShadow: 'none',
        overflow: 'visible',
        borderRadius: '8px',
        backgroundColor: nftCardBackgroundColor || 'transparent',
        '& > .MuiCardContent-root': {
          padding: '8px 10px',
          paddingBottom: '8px !important',
          [sm]: {
            padding: '12px 16px',
            paddingBottom: '12px !important',
          },
        },
      },
      wrapperImage: {
        width: '100%',
        cursor: 'pointer',
        paddingTop: '100%',
        overflow: 'hidden',
        position: 'relative',
        containerType: 'size',
        borderRadius: '8px 8px 0 0',
        '.MuiSkeleton-root': {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
        },
        '.sample': {
          zIndex: 2,
          position: 'absolute',
          top: `${positionLabelSample?.[0] || 50}%`,
          left: `${positionLabelSample?.[1] || 50}%`,
          transform: 'translate(-50%, -50%) rotate(-15deg)',
          '.MuiTypography-subtitle2': {
            margin: 0,
            fontWeight: 300,
            fontSize: '14cqw',
            color: '#686465',
            whiteSpace: 'nowrap',
            letterSpacing: '2px',
            textTransform: 'uppercase',
            textShadow:
              '0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff, 0px 0px 5px #fff',
          },
        },
      },
      image: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        position: 'absolute',
        objectFit: collectionNftCardImageMode || OBJECT_FIT.COVER,
      },
      titleBox: {
        gap: '4px',
        display: 'flex',
        marginBottom: '4px',
        alignItems: 'center',
        justifyContent: 'space-between',
        '.network': {
          width: '20px',
          aspectRatio: '1 / 1',
          position: 'relative',
          [sm]: {
            width: '28px',
          },
        },
      },
      title: {
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        '.MuiFormControl-root': {
          margin: 0,
          '.MuiInputBase-input': {
            padding: 0,
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '15px',
            [sm]: {
              fontSize: '16px',
              lineHeight: '24px',
            },
          },
        },
        '.MuiIconButton-root': {
          padding: '4px',
          marginLeft: '4px',
          '.MuiSvgIcon-root': {
            fontSize: '12px',
          },
          [sm]: {
            padding: '5px',
            '.MuiSvgIcon-root': {
              fontSize: '14px',
            },
          },
        },
      },
      nftName: {
        fontWeight: 400,
        fontSize: '12px',
        overflow: 'hidden',
        lineHeight: '15px',
        whiteSpace: 'nowrap',
        color: nftCardTextColor,
        letterSpacing: '0.15px',
        textOverflow: 'ellipsis',
        [sm]: {
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
      remain: {
        fontWeight: 500,
        display: 'block',
        fontSize: '14px',
        overflow: 'hidden',
        lineHeight: '32px',
        marginBottom: '6px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      desc: {
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: '0.15px',
      },
      prices: {
        display: 'flex',
        flexDirection: 'column',
        '.price': {
          fontWeight: 500,
          fontSize: '12px',
          overflow: 'hidden',
          lineHeight: '20px',
          letterSpacing: '0.15px',
          textOverflow: 'ellipsis',
          '&:not(.free)': {
            color: nftCardTextColor,
          },
          [sm]: {
            fontSize: '20px',
            lineHeight: '32px',
          },
        },
      },
      shopDetailBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      btnText: {
        minWidth: 0,
        minHeight: 0,
        padding: '4px',
        fontWeight: 500,
        fontSize: '8px',
        [sm]: {
          fontSize: '13px',
          padding: '4px 8px',
          lineHeight: '22px',
          letterSpacing: '0.46px',
        },
        '.MuiButton-endIcon': {
          marginLeft: '4px',
          marginRight: '0',
        },
      },
    };
  }
);

const schema = yup.object({
  caption: yup.string().max(100).required(),
});

interface NFTCaptionValue extends yup.InferType<typeof schema> {}

const ProductCard: FC<ProductCardProps> = ({ data, customName, onSaveEditCaption }) => {
  const { name, nameJa, price, collectionUuid, additionalInfos, networkSymbol } = data;
  const config = useContext(EditShopContext) as EditShopContextValue;
  const myShopData = useShopDetailWrapper();

  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { t: tMain } = useTranslation('translation', { i18n: i18nMain });

  const [showLabelSample, positionLabelSample, nftCardTextColor, nftCardBackgroundColor, collectionNftCardImageMode] =
    useWatch({
      control: config.control,
      name: [
        'showLabelSample',
        'positionLabelSample',
        'style.nftCardTextColor',
        'style.nftCardBackgroundColor',
        'style.collectionNftCardImageMode',
      ],
    });
  const { classes } = useStyles({
    nftCardTextColor,
    positionLabelSample,
    nftCardBackgroundColor,
    collectionNftCardImageMode: collectionNftCardImageMode as OBJECT_FIT,
  });

  const [loading, setLoading] = useState(true);
  const [isEditNFTCaption, setIsEditNFTCaption] = useState(false);
  const [extraInfos, setExtraInfos] = useState<{ url: string; ownerAddress: string }>();

  const isFree = price === 0;
  const isEnglish = i18n.language === 'en';
  const url = data?.url || extraInfos?.url;
  const defaultCaption = customName || (isEnglish ? name : nameJa) || '...';
  const isPreMint = myShopData.data.siteSetting?.category === NFT_SHOP_TYPE.PRE_MINT;

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<NFTCaptionValue>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    defaultValues: {
      caption: '',
    },
    resolver: yupResolver(schema),
  });

  const handleLoadImage = () => {
    setLoading(false);
  };

  const handleEditCaption = async (params: { caption: string }) => {
    if (!!onSaveEditCaption) {
      await onSaveEditCaption(data)(params);
      setIsEditNFTCaption(false);
    }
  };

  useEffect(() => {
    setIsEditNFTCaption(false);
    reset({
      caption: defaultCaption,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, customName, i18n.language]);

  useEffect(() => {
    (async () => {
      try {
        if (!isPreMint) {
          const metadata = await getNFTMetadata(collectionUuid!, additionalInfos);
          setExtraInfos({
            ownerAddress: metadata.ownerAddress,
            url: metadata.metadataContent.image.replace('ipfs://', env.REACT_APP_IPFS_GATEWAY_URL || ''),
          });
        }
      } catch (err: any) {
        enqueueSnackbar(err.message ? t(err.message) : t('my_shop.message.error'), { variant: 'error' });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className={classes.card}>
      <Box className={classes.wrapperImage}>
        {loading ? (
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
            <CircularProgress size="50px" />
          </Box>
        ) : (
          <>
            {showLabelSample && isPreMint && (
              <Box className="sample">
                <Typography variant="subtitle2">{t('sample')}</Typography>
              </Box>
            )}
          </>
        )}
        <CardMedia
          loading="lazy"
          component="img"
          alt="Thumbnails"
          image={url || ''}
          crossOrigin="anonymous"
          className={classes.image}
          onLoad={handleLoadImage}
        />
      </Box>
      <CardContent>
        <Box className={classes.titleBox}>
          {!!defaultCaption && (
            <Box className={classes.title}>
              {isEditNFTCaption && !!onSaveEditCaption ? (
                <>
                  <Controller
                    name="caption"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        variant="standard"
                        error={!!errors.caption?.message}
                        helperText={getErrorText(errors.caption?.message, tMain)}
                        {...field}
                      />
                    )}
                  />
                  <IconButton onClick={handleSubmit(handleEditCaption)}>
                    <CheckCircleIcon color="info" />
                  </IconButton>
                </>
              ) : (
                <>
                  <Tooltip
                    title={defaultCaption}
                    placement="bottom-start"
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -10],
                          },
                        },
                      ],
                    }}
                  >
                    <Typography className={classes.nftName}>{defaultCaption}</Typography>
                  </Tooltip>
                  {!!onSaveEditCaption && (
                    <IconButton onClick={() => setIsEditNFTCaption(true)}>
                      <EditOutlinedIcon />
                    </IconButton>
                  )}
                </>
              )}
            </Box>
          )}
          {!!networkSymbol && <img src={networkSymbol} alt="Network Symbol" className="network" />}
        </Box>
        <Box className={classes.shopDetailBox}>
          {!isNaN(Number(price ?? undefined)) && (
            <Box className={classes.prices}>
              {isFree ? (
                <Typography variant="h6" color="primary" textTransform="uppercase" className="price free">
                  {t('free')}
                </Typography>
              ) : (
                <Typography variant="h6" className="price">
                  {`${CURRENCY_ICONS[myShopData?.data?.paymentMethod?.baseCurrency!]} ${
                    price?.toLocaleString('en-EN') || 0
                  }`}
                </Typography>
              )}
            </Box>
          )}
          <Button variant="outlined" className={classes.btnText}>
            {isFree ? t('get_nft') : t('my_shop.purchase')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default memo(ProductCard);
