import { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { VIEW_MODE } from '~/components/custom-grid-toolbar-search-by-api';
import NoResultsOverlay from '~/components/custom-list-toolbar-filter';
import EditNameDialog, { IEditNFTNameField } from '~/components/dialog/edit-nft-name-dialog';
import { GalleryData } from '~/components/gallery-list';
import ListTable, { ListTablePagination } from '~/components/list-table/v2';
import { API_MEDIA, CHIP_COLOR, CURRENCY_ICONS, ITEMS_PER_PAGE } from '~/constants/common';
import { ShopDetailContext, ShopDetailContextValue } from '~/contexts/ShopDetailWrapper';
import { MyShopCollectionImageList } from '~/enum/tableFields';
import {
  Collection,
  useSetMyShopCollectionImageMutation,
  useUpdateMyShopCollectionMutation,
  MemberMyShopCollectionImagesQueryKey,
  useListMyShopCollectionImagesQuery,
  ListMyShopCollectionImagesDocument,
  GetMyShopDocument,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import MintOnPurchaseDialog from '~/pages/my-shop/shop-detail/components/CollectionDetail/MintOnPurchaseDialog';
import {
  ShopCollectionDetailContext,
  ShopCollectionDetailContextValue,
} from '~/pages/my-shop/shop-detail/components/CollectionDetail/ShopCollectionDetail';
import EditImagePriceDialog from '~/pages/my-shop/shop-detail/components/EditImagePriceDialog';
import {
  collectionStatus,
  renderSelectEditInputCell,
} from '~/pages/my-shop/shop-detail/components/SalesCollection/SalesCollectionTab';
import { NFT_STATUS, STATUS } from '~/types/my-shop';
import { verifyOrderKey, verifySortKey } from '~/utils/common';

interface ImageNFTListProps {
  reset?: any;
  errors?: any;
  control?: any;
  isDirty?: boolean;
  handleSubmit?: any;
  isEditImage?: boolean;
  openImageDialog?: boolean;
  isCollectionScreen?: boolean;
  collectionInfo: Collection | undefined;
  onOpenImageDialog?: () => void;
  setIsEditImage?: Dispatch<SetStateAction<boolean>>;
  setOpenImageListDialog?: Dispatch<SetStateAction<boolean>>;
}

export interface TableImageListShop {
  id: string;
  url: string;
  name: string;
  currentMint: number;
  networkSymbol: string;
  maxMint: string | null;
  createAt?: string;
  originalName?: string;

  order?: number;
  price?: number;
  status?: string;
  collectionImageUuid?: string;

  [MemberMyShopCollectionImagesQueryKey.Name]?: string;
  [MemberMyShopCollectionImagesQueryKey.CurrentMint]: number;
  [MemberMyShopCollectionImagesQueryKey.MaxMint]: number | null;
  [MemberMyShopCollectionImagesQueryKey.CreatedAt]?: string;
  [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageName]?: string;
  [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageNameJa]?: string;
  [MemberMyShopCollectionImagesQueryKey.ShopCollectionImagePrice]?: number;
  [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageStatus]?: string;
  [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageUuid]?: string;
  [MemberMyShopCollectionImagesQueryKey.CollectionUuid]?: string;
}

const useStyles = makeStyles()(() => ({
  imageListBox: {
    width: '100%',
    '.image-list-thumbnail': {
      height: '64px',
      width: '64px',
      borderRadius: '4px',
      objectFit: 'cover',
      cursor: 'pointer',
    },
    '.image-grid': {
      marginBottom: '24px',
    },
    '.MuiTypography-subtitle1': {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
      marginBottom: '16px',
    },
    '.MuiTypography-caption': {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
      marginBottom: '16px',
      display: 'inline-block',
      letterSpacing: '0.17px',
    },
    '.is-not-owner': {
      opacity: '0.5',
    },
    '.MuiSelect-standard': {
      backgroundColor: 'transparent!important',
    },
  },
  noPriceSet: {
    cursor: 'pointer',
    textDecoration: 'underline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const ImageNFTList: FC<ImageNFTListProps> = () => {
  const myShopData = useContext(ShopDetailContext) as ShopDetailContextValue;
  const myShopCollectionData = useContext(ShopCollectionDetailContext) as ShopCollectionDetailContextValue;

  const [openPriceDialog, setOpenPriceDialog] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<TableImageListShop>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [openEditNFTNameDialog, setOpenEditNFTNameDialog] = useState<boolean>(false);
  const [isOpenNFTDialog, setIsOpenNFTDialog] = useState<boolean>(false);
  const [customAnchorElShop, setCustomAnchorElShop] = useState<{
    info: GalleryData;
    anchorEl: null | HTMLElement;
  } | null>(null);

  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showError } = useNotify();
  const { enqueueSnackbar } = useSnackbar();

  const [queryShopColImages, setQueryShopColImages] = useState({
    page: 1,
    limit: ITEMS_PER_PAGE.LIST,
    searchText: '',
    sortBy: verifySortKey(
      MemberMyShopCollectionImagesQueryKey,
      myShopCollectionData?.data?.sortBy,
      MemberMyShopCollectionImagesQueryKey.CreatedAt
    ),
    orderBy: verifyOrderKey(myShopCollectionData?.data?.orderBy),
    where: {
      myShopCollectionUuid: myShopCollectionData?.data?.uuid ?? '',
    },
  });

  const updateQueryShopColImages = (newValue: any) =>
    setQueryShopColImages((value: any) => ({ ...value, ...newValue }));

  const { data: dataShopCollectionImagesData, loading: isLoading } = useListMyShopCollectionImagesQuery({
    fetchPolicy: 'cache-and-network',
    skip: !myShopCollectionData?.data?.uuid,
    variables: queryShopColImages,
  });

  const [updateMyShopCollection] = useUpdateMyShopCollectionMutation({
    refetchQueries: [GetMyShopDocument],
  });

  const [updateMyShopCollectionImage] = useSetMyShopCollectionImageMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionImagesDocument],
  });

  const { items, pagination } = useMemo(() => {
    const items = dataShopCollectionImagesData?.listMyShopCollectionImages?.items || [];
    const pagination: ListTablePagination = dataShopCollectionImagesData?.listMyShopCollectionImages?.pagination || {};

    const _items: TableImageListShop[] = items.map((item) => {
      return {
        id: item.uuid,
        order: item.order,
        price: item.price,
        maxMint: item.maxMint,
        originalName: item.name,
        currentMint: item.currentMint,
        collectionImageUuid: item.uuid,
        url: API_MEDIA + (item.image || ''),
        createAt: item.createdAt,
        name: item.myShopCollectionImageName || item.name,
        status:
          (item.maxMint || 0) <= (item.currentMint || 0)
            ? STATUS.SOLD_OUT
            : !item.price && item.price !== 0
            ? STATUS.SUSPENSION
            : item?.status || STATUS.SUSPENSION,

        [MemberMyShopCollectionImagesQueryKey.Name]: item.name,
        [MemberMyShopCollectionImagesQueryKey.CurrentMint]: item.currentMint,
        [MemberMyShopCollectionImagesQueryKey.MaxMint]: item.maxMint,
        [MemberMyShopCollectionImagesQueryKey.CreatedAt]: item.createdAt,
        [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageName]: item.myShopCollectionImageName || item.name,
        [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageNameJa]: item.myShopCollectionImageNameJa || item.name,
        [MemberMyShopCollectionImagesQueryKey.ShopCollectionImagePrice]: item.price,
        [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageStatus]:
          (item.maxMint || 0) <= (item.currentMint || 0)
            ? STATUS.SOLD_OUT
            : !item.price
            ? STATUS.SUSPENSION
            : item?.status || STATUS.SUSPENSION,
        [MemberMyShopCollectionImagesQueryKey.CollectionUuid]: item.collectionUuid,
        [MemberMyShopCollectionImagesQueryKey.ShopCollectionImageUuid]: item.myShopCollectionImageUuid,
      } as TableImageListShop;
    });
    return { items: _items, pagination };
  }, [dataShopCollectionImagesData]);

  // Handle Edit Collection Name Dialog
  const onOpenEditImageCaption = () => {
    setAnchorEl(null);
    setCustomAnchorElShop(null);
    setOpenEditNFTNameDialog(true);
  };

  const onCloseEditCollectionName = () => {
    setOpenEditNFTNameDialog(false);
  };

  const handleClickMoreMenu = useCallback(
    (row: TableImageListShop): MouseEventHandler<HTMLButtonElement> =>
      (event) => {
        if (row.status !== STATUS.SOLD_OUT) {
          setAnchorEl(event.currentTarget);
          setSelectedItem(row);
        } else {
          enqueueSnackbar(t('my_shop.message.already_sold_out'), { variant: 'error' });
        }
      },
    [t, enqueueSnackbar]
  );

  const onOpenEditPriceDialog = useCallback(
    (galleryItem?: { id: GalleryData['id']; status?: GalleryData['status'] }) => {
      if (galleryItem) {
        setSelectedItem(items.find((row) => row.id === galleryItem.id));
        if (galleryItem.status !== STATUS.SOLD_OUT) {
          setOpenPriceDialog(true);
        } else {
          enqueueSnackbar(t('my_shop.message.already_sold_out'), { variant: 'error' });
        }
      } else {
        setOpenPriceDialog(true);
      }
      setAnchorEl(null);
      setCustomAnchorElShop(null);
    },
    [items, enqueueSnackbar, t]
  );

  const onOpenImageDetail = useCallback(
    (id: string) => {
      setSelectedItem(items.find((row) => row.id === id));
      setAnchorEl(null);
      setIsOpenNFTDialog(true);
    },
    [items]
  );

  const onCloseImageDetailDialog = () => {
    setIsOpenNFTDialog(false);
  };

  const onOpenPriceDialog = () => {
    if (customAnchorElShop?.info.status === STATUS.SOLD_OUT) {
      enqueueSnackbar(t('you_are_not_the_owner_of_this_nft'), { variant: 'error' });
    } else {
      onOpenEditPriceDialog();
    }
  };

  const onClosePriceDialog = useCallback(() => {
    setOpenPriceDialog(false);
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCustomAnchorElShop(null);
  };

  const handleEditNFTName = async (data: IEditNFTNameField) => {
    try {
      await updateMyShopCollectionImage({
        variables: {
          input: {
            name: data.name,
            nameJa: data.nameJa,
            myShopCollectionUuid: myShopCollectionData?.data?.uuid || '',
            collectionImageUuid: selectedItem?.collectionImageUuid || '',
          },
        },
      });
      setSelectedItem((prevState) => {
        if (prevState) {
          prevState[MemberMyShopCollectionImagesQueryKey.ShopCollectionImageName] = data.name;
          prevState[MemberMyShopCollectionImagesQueryKey.ShopCollectionImageNameJa] = data.nameJa;
        }
        return prevState;
      });
      enqueueSnackbar(t('toast_message.update_caption_successfully'), { variant: 'success' });
      setOpenEditNFTNameDialog(false);
    } catch (err: any) {
      showError(err);
    }
  };

  const onSavePrice = useCallback(
    async (newPrice: number) => {
      setIsSubmitting(true);
      try {
        await updateMyShopCollectionImage({
          variables: {
            input: {
              price: newPrice,
              myShopCollectionUuid: myShopCollectionData?.data?.uuid || '',
              collectionImageUuid: selectedItem?.collectionImageUuid || '',
            },
          },
        });
        setSelectedItem((prevState) => {
          if (prevState) {
            prevState.price = newPrice;
          }
          return prevState;
        });
        onClosePriceDialog();
        // await myShopData?.refetch({ myShopUuid: shopId ?? '' });
        setIsSubmitting(false);
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
      } catch (e: any) {
        setIsSubmitting(false);
        showError(e);
      }
    },
    [
      selectedItem,
      myShopCollectionData?.data?.uuid,
      t,
      showError,
      enqueueSnackbar,
      onClosePriceDialog,
      updateMyShopCollectionImage,
    ]
  );

  const handleUpdateStatus = useCallback(
    async (isOnSale: boolean = false, collectionImageUuid?: string) => {
      try {
        await updateMyShopCollectionImage({
          variables: {
            input: {
              status: isOnSale ? STATUS.NOW_ON_SALE : STATUS.SUSPENSION,
              myShopCollectionUuid: myShopCollectionData?.data?.uuid || '',
              collectionImageUuid: collectionImageUuid ? collectionImageUuid : selectedItem?.collectionImageUuid || '',
            },
          },
        });
        setSelectedItem((prevState) => {
          if (prevState) {
            prevState.status = isOnSale ? STATUS.NOW_ON_SALE : STATUS.SUSPENSION;
          }
          return prevState;
        });
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
      } catch (e: any) {
        showError(e);
      }
    },
    [
      myShopCollectionData?.data?.uuid,
      selectedItem?.collectionImageUuid,
      t,
      showError,
      enqueueSnackbar,
      updateMyShopCollectionImage,
    ]
  );

  const onChangeStatusNft = useCallback(
    (item: TableImageListShop) => {
      if (!item.price && item.price !== 0 && item.status === STATUS.SUSPENSION) {
        enqueueSnackbar(t('my_shop.message.need_set_price'), { variant: 'error' });
      } else {
        handleUpdateStatus(item.status !== STATUS.NOW_ON_SALE, item.collectionImageUuid);
      }
    },
    [t, enqueueSnackbar, handleUpdateStatus]
  );

  const handleStatus = () => {
    if ((Number(selectedItem?.maxMint) || 0) <= (selectedItem?.currentMint || 0)) {
      if (selectedItem?.status === STATUS.NOW_ON_SALE) {
        handleUpdateStatus(false);
      } else {
        enqueueSnackbar(t('my_shop.message.already_sold_out'), { variant: 'error' });
      }
    } else if (!isNaN(Number(selectedItem?.price ?? undefined))) {
      handleUpdateStatus(selectedItem?.status === STATUS.SUSPENSION);
    } else if (selectedItem?.status === STATUS.SUSPENSION) {
      enqueueSnackbar(t('my_shop.message.need_set_price'), { variant: 'error' });
    }
    setAnchorEl(null);
    setCustomAnchorElShop(null);
  };

  const handleStatusInDialog = () => {
    if ((Number(selectedItem?.maxMint) || 0) <= (selectedItem?.currentMint || 0)) {
      if (selectedItem?.status === STATUS.NOW_ON_SALE) {
        handleUpdateStatus(false);
      } else {
        enqueueSnackbar(t('my_shop.message.already_sold_out'), { variant: 'error' });
      }
    } else if (!isNaN(Number(selectedItem?.price ?? undefined))) {
      handleUpdateStatus(selectedItem?.status === STATUS.SUSPENSION);
    } else if (selectedItem?.status === STATUS.SUSPENSION) {
      enqueueSnackbar(t('my_shop.message.need_set_price'), { variant: 'error' });
    }
  };

  const handleClickMenu =
    (info: GalleryData): MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      if (info.status !== STATUS.SOLD_OUT) {
        const newAnchorEl = {
          info,
          anchorEl: event.currentTarget,
        };
        setCustomAnchorElShop(newAnchorEl);
        const newSelectedItem = items.find((row) => row.id === info.id);
        setSelectedItem(newSelectedItem);
      } else {
        enqueueSnackbar(t('you_are_not_the_owner_of_this_nft'), { variant: 'error' });
      }
    };

  const checkStatusNft = useCallback(
    (status: string) => {
      if (status === NFT_STATUS.ON_SALE) {
        return <Chip label={t('on_sale')} color="success" />;
      } else {
        return <Chip label={t('unsold')} color="error" />;
      }
    },
    [t]
  );

  const handleProcessRowUpdate = async (newRow: TableImageListShop, oldRow: TableImageListShop) => {
    try {
      if (oldRow.status === STATUS.SOLD_OUT) {
        enqueueSnackbar(t('my_shop.message.already_sold_out'), { variant: 'error' });
        return oldRow;
      }
      if (newRow.order === oldRow.order) {
        return newRow;
      }
      await updateMyShopCollectionImage({
        variables: {
          input: {
            collectionImageUuid: newRow.collectionImageUuid ?? '',
            myShopCollectionUuid: myShopCollectionData.data.uuid ?? '',
            order: newRow.order ? parseInt(newRow.order.toString(), 10) : newRow.order,
          },
        },
      });
    } catch (err: any) {}
    return newRow;
  };

  const imageListColumns: GridColDef<TableImageListShop>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        sortable: false,
        resizable: false,
        field: MyShopCollectionImageList.URL,
        headerName: t('image'),
        renderCell: ({ row }) => (
          <img className="image-list-thumbnail" src={row.url} alt="" onClick={() => onOpenImageDetail(row.id)} />
        ),
      },
      {
        width: 100,
        editable: true,
        sortable: false,
        resizable: false,
        field: MyShopCollectionImageList.ORDER,
        headerName: t('my_shop.order_index'),
        renderEditCell: renderSelectEditInputCell,
      },
      {
        headerName: t('status'),
        width: JSON.parse(columnsSize).status || 125,
        field: MemberMyShopCollectionImagesQueryKey.ShopCollectionImageStatus,
        renderCell: ({ row }) => {
          const status: STATUS = (row.status as STATUS) || STATUS.SUSPENSION;
          return (
            <>
              {row.status === STATUS.SOLD_OUT ? (
                <Chip
                  variant="filled"
                  color={CHIP_COLOR[status]}
                  label={`${collectionStatus(t)[status]?.title}` || '-'}
                />
              ) : (
                <Select
                  variant="standard"
                  disableUnderline
                  fullWidth
                  value={row.status === STATUS.NOW_ON_SALE ? NFT_STATUS.ON_SALE : NFT_STATUS.UNSOLD}
                  onChange={() => onChangeStatusNft(row)}
                >
                  {Object.values(NFT_STATUS).map((status) => (
                    <MenuItem key={status} value={status}>
                      {checkStatusNft(status)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </>
          );
        },
      },
      {
        headerName: t('price'),
        width: JSON.parse(columnsSize).price || 100,
        field: MemberMyShopCollectionImagesQueryKey.ShopCollectionImagePrice,
        renderCell({ row }) {
          const baseCurrency = myShopData?.data.paymentMethod?.baseCurrency;
          const basePrice = row.price;
          return (
            <>
              {!!basePrice ? (
                `${baseCurrency ? CURRENCY_ICONS[baseCurrency] : ''} ${basePrice.toLocaleString('en-EN')}`
              ) : row.price === 0 ? (
                <Chip variant="filled" color="success" label={t('free')} />
              ) : (
                <Typography
                  className={classes.noPriceSet}
                  color="red"
                  onClick={() => {
                    onOpenEditPriceDialog({ id: row.id, status: row.status });
                  }}
                >
                  {t('no_price_set')}
                </Typography>
              )}
            </>
          );
        },
      },
      {
        field: MemberMyShopCollectionImagesQueryKey.ShopCollectionImageName,
        headerName: t('my_shop.image_caption_en'),
        width: JSON.parse(columnsSize).caption || 150,
      },
      {
        field: MemberMyShopCollectionImagesQueryKey.ShopCollectionImageNameJa,
        headerName: t('my_shop.image_caption_ja'),
        width: JSON.parse(columnsSize).caption || 150,
      },
      {
        field: MemberMyShopCollectionImagesQueryKey.Name,
        headerName: t('my_shop.image_name'),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        field: MemberMyShopCollectionImagesQueryKey.MaxMint,
        headerName: t('collection_screen.max_mint'),
        width: JSON.parse(columnsSize).maxMint || 100,
      },
      {
        field: MemberMyShopCollectionImagesQueryKey.CurrentMint,
        headerName: t('collection_screen.current_mint_count'),
        width: JSON.parse(columnsSize).currentMint || 100,
      },
      {
        width: 70,
        headerName: '',
        type: 'actions',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getActions: ({ row }) => [
          <IconButton onClick={handleClickMoreMenu(row)}>
            <MoreVertIcon />
          </IconButton>,
        ],
      },
    ];
  }, [
    t,
    onOpenImageDetail,
    myShopData?.data.paymentMethod?.baseCurrency,
    classes.noPriceSet,
    onOpenEditPriceDialog,
    onChangeStatusNft,
    checkStatusNft,
    handleClickMoreMenu,
  ]);

  const handleUpdateSort = async (value: any) => {
    const sortBy = value.sortBy;
    const orderBy = value.orderBy;
    updateQueryShopColImages({ sortBy, orderBy });
    if (sortBy !== myShopCollectionData?.data?.sortBy || orderBy !== myShopCollectionData?.data?.orderBy) {
      await updateMyShopCollection({
        variables: {
          input: { uuid: myShopCollectionData?.data?.uuid || '', sortBy, orderBy },
        },
      });
    }
  };

  return (
    <Box className={classes.imageListBox}>
      <ListTable
        isMenu
        noBorder
        rows={items}
        editMode="row"
        isLoading={isLoading}
        onlyMode={VIEW_MODE.LIST}
        disableRowSelectionOnClick
        columns={imageListColumns}
        paginationData={pagination}
        searchLabel={t('my_shop.image_name')}
        tableName={'shop_collection_premint'}
        search={queryShopColImages.searchText}
        noRowsMessage={t('my_shop.message.no_image')}
        getRowClassName={(params) => (params.row.status === STATUS.SOLD_OUT ? 'is-not-owner' : '')}
        sort={{
          sortBy: queryShopColImages.sortBy,
          orderBy: queryShopColImages.orderBy,
        }}
        onSort={handleUpdateSort}
        onClickMenu={handleClickMenu}
        onRowUpdate={handleProcessRowUpdate}
        onClickImage={onOpenEditPriceDialog}
        onPagination={updateQueryShopColImages}
        slots={{ noResultsOverlay: NoResultsOverlay }}
        onSearch={(v) => updateQueryShopColImages({ page: 1, searchText: v || '' })}
      />

      <Menu open={!!customAnchorElShop} anchorEl={customAnchorElShop?.anchorEl} onClose={handleCloseMenu}>
        <MenuItem onClick={onOpenEditImageCaption}>{t('my_shop.edit_image_caption')}</MenuItem>
        <MenuItem onClick={() => onOpenPriceDialog()}>{t('edit_price')}</MenuItem>
        <MenuItem onClick={handleStatus}>
          {selectedItem?.status !== STATUS.NOW_ON_SALE ? t('on_sale') : t('unsold')}
        </MenuItem>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem onClick={() => onOpenImageDetail(selectedItem?.id!)}>{t('show_details')}</MenuItem>
        <MenuItem onClick={onOpenEditImageCaption}>{t('my_shop.edit_image_caption')}</MenuItem>
        <MenuItem onClick={() => onOpenEditPriceDialog()}>{t('edit_price')}</MenuItem>
        <MenuItem onClick={handleStatus}>
          {selectedItem?.status !== STATUS.NOW_ON_SALE ? t('on_sale') : t('unsold')}
        </MenuItem>
      </Menu>

      <MintOnPurchaseDialog
        open={isOpenNFTDialog}
        nftInfo={selectedItem!}
        onClose={onCloseImageDetailDialog}
        onEditNFTName={handleEditNFTName}
        onOpenPriceDialog={onOpenPriceDialog}
        actions={[
          <Box sx={{ alignSelf: 'flex-end' }} key="1">
            <Button
              variant="outlined"
              color={selectedItem?.status === STATUS.SUSPENSION ? 'primary' : 'error'}
              disabled={(!selectedItem?.price && selectedItem?.price !== 0) || selectedItem?.status === STATUS.SOLD_OUT}
              onClick={handleStatusInDialog}
            >
              {selectedItem?.status === STATUS.SOLD_OUT
                ? t('my_shop.sold_out')
                : selectedItem?.status === STATUS.SUSPENSION
                ? t('on_sale')
                : t('unsold')}
            </Button>
          </Box>,
        ]}
      />

      <EditImagePriceDialog
        isSubmitting={isSubmitting}
        isDialogOpen={openPriceDialog}
        rowData={selectedItem as TableImageListShop}
        onSavePrice={onSavePrice}
        onCloseDialog={onClosePriceDialog}
      />

      <EditNameDialog
        open={openEditNFTNameDialog}
        title={t('my_shop.edit_image_caption')}
        defaultName={selectedItem?.[MemberMyShopCollectionImagesQueryKey.ShopCollectionImageName]!}
        defaultNameJa={selectedItem?.[MemberMyShopCollectionImagesQueryKey.ShopCollectionImageNameJa]!}
        onEdit={handleEditNFTName}
        onClose={onCloseEditCollectionName}
      />
    </Box>
  );
};

export default ImageNFTList;
