export const CODE = {
  REJECT: 4001,
  INTERNAL_ERROR: -32603,
  NOT_OWNER_COLLECTION: 3,
  NOT_SUPPORT_COLLECTION: -32000,
  CALL_EXCEPTION: 'CALL_EXCEPTION',
  ACTION_REJECTED: 'ACTION_REJECTED',
  NETWORK_ERROR: 'NETWORK_ERROR',
  BAD_DATA: 'BAD_DATA',
  INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
  USER_ALREADY_ADDED: 'User already added',
  CHAIN_NOT_CONFIGURED: 'ChainNotConfigured',
  USER_NEED_REGISTER_FIREBASE: 'User need to register in Firebase Authentication before adding to organization',
};
