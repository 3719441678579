import { ChangeEvent, FC, useCallback, useContext, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import { ShopDetailContext, ShopDetailContextValue } from '~/contexts/ShopDetailWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { useDuplicateMyShopMutation, useRemoveMyShopMutation, useSetOpenShopMutation } from '~/graphql/member/types';

const useStyles = makeStyles()((theme) => ({
  root: {
    gap: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gap: 'unset',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '.MuiFormControlLabel-root': {
      marginLeft: '5px',
      marginRight: '0px',
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '.MuiTypography-root': {
      color: '#111',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menu: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  publishDate: {
    color: '#505050',
    fontSize: '14px',
    fontWeight: 400,
  },
}));

const HeaderAction: FC<{ publishedDate?: string }> = ({ publishedDate }) => {
  const myShopData = useContext(ShopDetailContext) as ShopDetailContextValue;

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const [openRemoveMyShopDialog, setOpenRemoveMyShopDialog] = useState(false);
  const [openCloseMyShopDialog, setOpenCloseMyShopDialog] = useState(false);

  const isOpen = myShopData?.data?.publish || false;

  const [setOpenShop] = useSetOpenShopMutation();
  const [removeMyShop] = useRemoveMyShopMutation();
  const [duplicateMyShop] = useDuplicateMyShopMutation();

  const handleOpen = useCallback(
    async (newValue: boolean) => {
      try {
        await setOpenShop({
          variables: {
            isOpen: newValue,
            myShopUuid: id ?? '',
          },
        });
        await myShopData?.refetch({
          myShopUuid: id ?? '',
        });
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(t('toast_message.open_member_site_unsuccessfully'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, myShopData?.refetch, t, setOpenShop, enqueueSnackbar]
  );

  const handleChangeOpen = async (_: ChangeEvent<HTMLInputElement>, newValue: boolean) => {
    if (newValue) {
      handleOpen(true);
    } else {
      setOpenCloseMyShopDialog(true);
    }
  };

  const onCloseRemoveMyShopDialog = useCallback(async () => {
    setOpenRemoveMyShopDialog(false);
  }, []);

  const handleRemoveShop = useCallback(async () => {
    try {
      await removeMyShop({
        variables: {
          myShopUuid: id || '',
        },
      });
      navigate(AppRouteEnum.MemberSite.replace(/:id/g, ''));
      enqueueSnackbar(t('toast_message.member_site_deleted'), { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(err.message || t('my_shop.message.error'), { variant: 'error' });
    }
  }, [id, t, navigate, removeMyShop, enqueueSnackbar]);

  const onOpenRemoveMyShopDialog = () => {
    setOpenRemoveMyShopDialog(true);
    onCloseMenu();
  };

  const onCloseMenu = () => {
    setMenuAnchorEl(undefined);
  };

  const handleDuplicated = async () => {
    try {
      const duplicatedMyShop = await duplicateMyShop({
        variables: {
          myShopUuid: id || '',
        },
      });
      if (duplicatedMyShop) {
        navigate(AppRouteEnum.MemberSiteDetail.replace(/:id/g, duplicatedMyShop.data?.duplicateMyShop?.uuid || ''));
        enqueueSnackbar(t('toast_message.shop_duplicated'), { variant: 'success' });
      }
    } catch (err: any) {
      enqueueSnackbar(err.message || t('my_shop.message.error'), { variant: 'error' });
    }
    setMenuAnchorEl(undefined);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography>{t('my_shop.basic_site_setting')}</Typography>
        <Box>
          <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Typography className={classes.publishDate}>
          {`${t('my_shop.last_published_date')} : ${
            !!publishedDate ? moment(publishedDate).format(t('date_time_format')) : '-'
          }`}
        </Typography>
        <FormControlLabel
          sx={{ marginRight: 0 }}
          control={<Switch checked={isOpen} onChange={handleChangeOpen} />}
          label={isOpen ? <Chip color="success" label={t('open')} /> : <Chip color="error" label={t('close')} />}
        />
      </Box>
      <Box className={classes.menu}>
        <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
      </Box>
      <Menu anchorEl={menuAnchorEl} open={!!menuAnchorEl} onClose={onCloseMenu}>
        <MenuItem onClick={handleDuplicated}>{t('my_shop.duplicate')}</MenuItem>
        <MenuItem onClick={onOpenRemoveMyShopDialog}>{t('delete')}</MenuItem>
      </Menu>
      <ConfirmationDialog
        open={openRemoveMyShopDialog}
        title={t('member_site.delete_member_site')}
        content={t('member_site.remove_membersite')}
        onConfirm={handleRemoveShop}
        onClose={onCloseRemoveMyShopDialog}
      />
      <ConfirmationDialog
        open={openCloseMyShopDialog}
        title={t('member_site.message.close_shop_message')}
        content={t('member_site.message.confirm_close_shop')}
        onConfirm={() => handleOpen(false)}
        onClose={() => setOpenCloseMyShopDialog(false)}
        confirmTitle={t('close')}
      />
    </Box>
  );
};

export default HeaderAction;
