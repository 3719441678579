import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = { context: { apiName: 'member' } } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
};

export type AcquisitionInformation = {
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type AcquisitionInformationInput = {
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AddOrganizationUserInput = {
  email: Scalars['String'];
  role?: InputMaybe<OrganizationUserRole>;
};

export type AttachMyShopCollectionInput = {
  collections: Array<CreateMyShopCollectionInput>;
  shopUuid: Scalars['String'];
};

export type AttachMyShopCollectionTokensInput = {
  myShopCollectionUuid: Scalars['String'];
  tokens: Array<CreateMyShopCollectionTokenInput>;
};

export type BaseSettingInput = {
  address?: InputMaybe<Scalars['String']>;
  displayPhone?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  prefecture?: InputMaybe<Scalars['String']>;
};

export type ChainNetwork = {
  blockExplorer?: Maybe<Scalars['String']>;
  chainId: Scalars['String'];
  contractAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  icon?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rpcUrl: Scalars['String'];
  subgraphUrl?: Maybe<Scalars['String']>;
  testMode?: Maybe<Scalars['Boolean']>;
  tokenName?: Maybe<Scalars['String']>;
  tokenSymbol?: Maybe<Scalars['String']>;
  tokenSymbols?: Maybe<Array<Scalars['String']>>;
  uuid: Scalars['String'];
  walletAddress?: Maybe<Scalars['String']>;
};

export type Collection = {
  contractAddress: Scalars['String'];
  createdAt: Scalars['Date'];
  desc?: Maybe<Scalars['String']>;
  descJa?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  nameJa?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  organizationUuid: Scalars['String'];
  ownerAddress?: Maybe<Scalars['String']>;
  ownerUid: Scalars['String'];
  symbol: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type CollectionImage = {
  collectionUuid: Scalars['String'];
  createdAt: Scalars['Date'];
  currentMint?: Maybe<Scalars['Float']>;
  image?: Maybe<Scalars['String']>;
  maxMint?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export enum CollectionImagesQueryKey {
  CollectionUuid = 'COLLECTION_UUID',
  CreatedAt = 'CREATED_AT',
  CurrentMint = 'CURRENT_MINT',
  MaxMint = 'MAX_MINT',
  Name = 'NAME',
  NameJa = 'NAME_JA',
  Uuid = 'UUID',
}

export enum CollectionQueryKey {
  ContractAddress = 'CONTRACT_ADDRESS',
  CreatedAt = 'CREATED_AT',
  Desc = 'DESC',
  DescJa = 'DESC_JA',
  Name = 'NAME',
  NameJa = 'NAME_JA',
  Network = 'NETWORK',
  OwnerAddress = 'OWNER_ADDRESS',
  Symbol = 'SYMBOL',
  Type = 'TYPE',
  Uuid = 'UUID',
}

export type CollectionsFilterField = {
  key: CollectionQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export type CollectionsImagesFilterField = {
  key: CollectionImagesQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export type Contact = {
  phoneNumber?: Maybe<Scalars['String']>;
  publish?: Maybe<Scalars['Boolean']>;
};

export type ContactInput = {
  phoneNumber?: InputMaybe<Scalars['String']>;
  publish?: InputMaybe<Scalars['Boolean']>;
};

export type CreateCollectionImageInput = {
  collectionUuid: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  /** File */
  imageFile?: InputMaybe<Scalars['Upload']>;
  maxMint?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  nameJa?: InputMaybe<Scalars['String']>;
};

export type CreateCollectionInput = {
  contractAddress: Scalars['String'];
  desc?: InputMaybe<Scalars['String']>;
  descJa?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  nameJa?: InputMaybe<Scalars['String']>;
  network: Scalars['String'];
  ownerAddress?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateCollectionWithOrgWalletInput = {
  desc?: InputMaybe<Scalars['String']>;
  descJa?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  nameJa?: InputMaybe<Scalars['String']>;
  network: Scalars['String'];
  ownerAddress: Scalars['String'];
  symbol: Scalars['String'];
  type: Scalars['String'];
};

export type CreateMemberInput = {
  address?: InputMaybe<IAddressInput>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  shopUuid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['String']>;
  wallet?: InputMaybe<Scalars['String']>;
};

export type CreateMyShopCollectionImageInput = {
  collectionImageUuid: Scalars['String'];
  myShopCollectionUuid: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  nameJa?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
};

export type CreateMyShopCollectionInput = {
  collectionUuid: Scalars['String'];
  desc?: InputMaybe<Scalars['String']>;
  descJa?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nameJa?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateMyShopCollectionTokenInput = {
  myShopCollectionUuid: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  nameJa?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  tokenDesc?: InputMaybe<Scalars['String']>;
  tokenId: Scalars['String'];
  tokenMintTime?: InputMaybe<Scalars['String']>;
  tokenName?: InputMaybe<Scalars['String']>;
};

export type CreateMyShopInput = {
  /** File */
  bannerFile?: InputMaybe<Scalars['Upload']>;
  domain?: InputMaybe<DomainInput>;
  /** File */
  faviconFile?: InputMaybe<Scalars['Upload']>;
  /** File */
  logoFile?: InputMaybe<Scalars['Upload']>;
  nftActivationSettings?: InputMaybe<NftActivationSettingsInput>;
  /** File */
  ogpFile?: InputMaybe<Scalars['Upload']>;
  operation?: InputMaybe<OperationInput>;
  paymentMethod?: InputMaybe<PaymentMethodInput>;
  /** File */
  ratioBannerFile?: InputMaybe<Scalars['Upload']>;
  shopInformation?: InputMaybe<ShopInformationInput>;
  shopType?: InputMaybe<Scalars['String']>;
  siteSetting?: InputMaybe<SiteSettingInput>;
  users?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateOrganizationInput = {
  /** Organization contact email */
  contactEmail: Scalars['String'];
  /** Organization name */
  name: Scalars['String'];
};

export type CurrencySetting = {
  baseCurrency?: Maybe<Scalars['String']>;
};

export type CurrencySettingInput = {
  baseCurrency: Scalars['String'];
};

export type DetailMyShopCollectionImage = {
  collectionImageUuid?: Maybe<Scalars['String']>;
  collectionUuid: Scalars['String'];
  createdAt: Scalars['Date'];
  currentMint?: Maybe<Scalars['Float']>;
  image?: Maybe<Scalars['String']>;
  maxMint?: Maybe<Scalars['Float']>;
  myShopCollectionImageName?: Maybe<Scalars['String']>;
  myShopCollectionImageNameJa?: Maybe<Scalars['String']>;
  myShopCollectionImageUuid?: Maybe<Scalars['String']>;
  myShopCollectionUuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type Domain = {
  customDomain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subDomain?: Maybe<Scalars['String']>;
};

export type DomainInput = {
  customDomain?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  subDomain?: InputMaybe<Scalars['String']>;
};

export type IAddress = {
  address?: Maybe<Scalars['String']>;
  addressDisplay?: Maybe<Scalars['Boolean']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
};

export type IAddressInput = {
  address?: InputMaybe<Scalars['String']>;
  addressDisplay?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  prefecture?: InputMaybe<Scalars['String']>;
};

export type IpfsStorage = {
  apiKey: Scalars['String'];
  storage: Scalars['String'];
};

export type ListCollectionImagesFilter = {
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<CollectionsImagesFilterField>>;
};

export type ListCollectionImagesResponse = {
  items: Array<CollectionImage>;
  pagination: Pagination;
};

export type ListCollectionsFilter = {
  availableShopUuid?: InputMaybe<Scalars['String']>;
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<CollectionsFilterField>>;
};

export type ListCollectionsResponse = {
  items: Array<Collection>;
  pagination: Pagination;
};

export type ListMemberTokensFilter = {
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<MemberTokensFilterField>>;
  shopUuid?: InputMaybe<Scalars['String']>;
};

export type ListMemberTokensResponse = {
  items: Array<MemberToken>;
  pagination: Pagination;
};

export type ListMembersFilter = {
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<MembersFilterField>>;
};

export type ListMembersResponse = {
  items: Array<Member>;
  pagination: Pagination;
};

export type ListMyShopCollectionImagesResponse = {
  items: Array<DetailMyShopCollectionImage>;
  pagination: Pagination;
};

export type ListMyShopCollectionsFilter = {
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<MyShopCollectionsFilterField>>;
  shopType?: InputMaybe<Scalars['String']>;
};

export type ListMyShopCollectionsResponse = {
  items: Array<MyShopCollectionDetail>;
  pagination: Pagination;
};

export type ListMyShopCollectionsTokensFilter = {
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<MyShopCollectionsTokensFilterField>>;
};

export type ListMyShopCollectionsTokensResponse = {
  items: Array<MyShopCollectionToken>;
  pagination: Pagination;
};

export type ListMyShopResponse = {
  items: Array<MyShop>;
  pagination: Pagination;
};

export type ListMyShopsFilter = {
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<MyShopFilterField>>;
};

export type ListOrdersFilter = {
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<OrdersFilterField>>;
};

export type ListOrdersResponse = {
  items: Array<TokenOrder>;
  pagination: Pagination;
};

export type ListTokensFilter = {
  collectionUuid: Scalars['String'];
  mintTimeEnd?: InputMaybe<Scalars['Date']>;
  mintTimeStart?: InputMaybe<Scalars['Date']>;
  shopUuid?: InputMaybe<Scalars['String']>;
};

export type ListTokensResponse = {
  items: Array<Token>;
  pagination: Pagination;
};

export enum ListTokensSortBy {
  Contract = 'CONTRACT',
  ContractId = 'CONTRACT_ID',
  ContractName = 'CONTRACT_NAME',
  ContractSymbol = 'CONTRACT_SYMBOL',
  Id = 'ID',
  MintTime = 'MINT_TIME',
  Owner = 'OWNER',
  OwnerId = 'OWNER_ID',
  TokenId = 'TOKEN_ID',
  TokenUri = 'TOKEN_URI',
}

export type Member = {
  address?: Maybe<IAddress>;
  createdAt: Scalars['Date'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  ownerUid?: Maybe<Scalars['String']>;
  shopUuid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  verifyEmail?: Maybe<Scalars['Boolean']>;
  wallet?: Maybe<Scalars['String']>;
};

export type MemberListMyShopCollectionImagesFilter = {
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<MemberMyShopCollectionsImagesFilterField>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  myShopCollectionUuid: Scalars['String'];
};

export enum MemberMyShopCollectionImagesQueryKey {
  CollectionUuid = 'COLLECTION_UUID',
  CreatedAt = 'CREATED_AT',
  CurrentMint = 'CURRENT_MINT',
  MaxMint = 'MAX_MINT',
  Name = 'NAME',
  NameJa = 'NAME_JA',
  ShopCollectionImageCreatedAt = 'SHOP_COLLECTION_IMAGE_CREATED_AT',
  ShopCollectionImageName = 'SHOP_COLLECTION_IMAGE_NAME',
  ShopCollectionImageNameJa = 'SHOP_COLLECTION_IMAGE_NAME_JA',
  ShopCollectionImageOrder = 'SHOP_COLLECTION_IMAGE_ORDER',
  ShopCollectionImagePrice = 'SHOP_COLLECTION_IMAGE_PRICE',
  ShopCollectionImageStatus = 'SHOP_COLLECTION_IMAGE_STATUS',
  ShopCollectionImageUuid = 'SHOP_COLLECTION_IMAGE_UUID',
  Uuid = 'UUID',
}

export type MemberMyShopCollectionsImagesFilterField = {
  key: MemberMyShopCollectionImagesQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export enum MemberQueryKey {
  CreatedAt = 'CREATED_AT',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  OwnerUid = 'OWNER_UID',
  ShopUuid = 'SHOP_UUID',
  Status = 'STATUS',
  Uuid = 'UUID',
  Wallet = 'WALLET',
}

export type MemberToken = {
  collectionUuid?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  ownerUid?: Maybe<Scalars['String']>;
  shopUuid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  tokenUuid?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  wallet?: Maybe<Scalars['String']>;
};

export enum MemberTokenQueryKey {
  CollectionUuid = 'COLLECTION_UUID',
  CreatedAt = 'CREATED_AT',
  OwnerUid = 'OWNER_UID',
  TokenId = 'TOKEN_ID',
  Type = 'TYPE',
  Uuid = 'UUID',
  Wallet = 'WALLET',
}

export type MemberTokensFilterField = {
  key: MemberTokenQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export type MembersFilterField = {
  key: MemberQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export type MetadataContent = {
  animation_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image: Scalars['String'];
  name: Scalars['String'];
};

export type MintNftInput = {
  collectionUuid: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['Upload']>;
  mediaAttachment?: InputMaybe<Scalars['Upload']>;
  metadataUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  acceptTermOfUse: User;
  addOrganizationUser: OrganizationUser;
  attachMyShopCollectionTokens: Array<Scalars['String']>;
  attachMyShopCollections: Array<Scalars['String']>;
  cancelPublishShop: MyShop;
  createCollection: Collection;
  createCollectionImage: CollectionImage;
  createCollectionWithOrgWallet: Collection;
  createMyShop: MyShop;
  createOrganization: OrganizationRoleUser;
  duplicateMyShop: MyShop;
  mintNft: Scalars['String'];
  publishShop: MyShop;
  registerMember: Member;
  removeApproveTransfer: Array<RemoveApproveTransferDto>;
  removeCollection: Scalars['Boolean'];
  removeCollectionImage: Scalars['Boolean'];
  removeMyShop: Scalars['Boolean'];
  removeMyShopCollections: Scalars['Boolean'];
  removeOrganizationIpfsStorage: Scalars['Boolean'];
  removeOrganizationUser: Scalars['Boolean'];
  resendEmailVerifyMember: Member;
  setMyShopCollectionImage: MyShopCollectionImage;
  setMyShopCollectionToken: MyShopCollectionToken;
  setOpenShop: MyShop;
  unAttachMyShopCollectionTokens: Array<Scalars['Boolean']>;
  unAttachMyShopCollections: Array<Scalars['Boolean']>;
  updateCollection: Collection;
  updateCollectionImage: CollectionImage;
  updateMemberToken: MemberToken;
  updateMyShop: MyShop;
  updateMyShopCollection: MyShopCollectionDetail;
  updateOrganization: Organization;
  updateOrganizationUser: OrganizationUser;
  uploadFile: Array<Scalars['String']>;
  uploadNFT: UploadNftResponse;
  uploadNFTWithApiKey: UploadNftResponse;
  withdraw: Scalars['Boolean'];
};

export type MutationAddOrganizationUserArgs = {
  input: AddOrganizationUserInput;
};

export type MutationAttachMyShopCollectionTokensArgs = {
  input: AttachMyShopCollectionTokensInput;
};

export type MutationAttachMyShopCollectionsArgs = {
  input: AttachMyShopCollectionInput;
};

export type MutationCancelPublishShopArgs = {
  myShopUuid: Scalars['String'];
};

export type MutationCreateCollectionArgs = {
  input: CreateCollectionInput;
};

export type MutationCreateCollectionImageArgs = {
  input: CreateCollectionImageInput;
};

export type MutationCreateCollectionWithOrgWalletArgs = {
  input: CreateCollectionWithOrgWalletInput;
};

export type MutationCreateMyShopArgs = {
  input: CreateMyShopInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationDuplicateMyShopArgs = {
  myShopUuid: Scalars['String'];
};

export type MutationMintNftArgs = {
  input: MintNftInput;
};

export type MutationPublishShopArgs = {
  myShopUuid: Scalars['String'];
};

export type MutationRegisterMemberArgs = {
  input: CreateMemberInput;
};

export type MutationRemoveApproveTransferArgs = {
  input: RemoveApproveTransferInput;
};

export type MutationRemoveCollectionArgs = {
  collectionUuid: Scalars['String'];
};

export type MutationRemoveCollectionImageArgs = {
  uuid: Scalars['String'];
};

export type MutationRemoveMyShopArgs = {
  myShopUuid: Scalars['String'];
};

export type MutationRemoveMyShopCollectionsArgs = {
  input: RemoveMyShopCollectionInput;
};

export type MutationRemoveOrganizationIpfsStorageArgs = {
  input: RemoveOrganizationIpfsStorageInput;
};

export type MutationRemoveOrganizationUserArgs = {
  input: RemoveOrganizationUserInput;
};

export type MutationResendEmailVerifyMemberArgs = {
  uuid: Scalars['String'];
};

export type MutationSetMyShopCollectionImageArgs = {
  input: CreateMyShopCollectionImageInput;
};

export type MutationSetMyShopCollectionTokenArgs = {
  input: CreateMyShopCollectionTokenInput;
};

export type MutationSetOpenShopArgs = {
  isOpen: Scalars['Boolean'];
  myShopUuid: Scalars['String'];
};

export type MutationUnAttachMyShopCollectionTokensArgs = {
  input: UnAttachMyShopCollectionTokensInput;
};

export type MutationUnAttachMyShopCollectionsArgs = {
  input: UnAttachMyShopCollectionInput;
};

export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionInput;
};

export type MutationUpdateCollectionImageArgs = {
  input: UpdatedCollectionImageInput;
};

export type MutationUpdateMemberTokenArgs = {
  input: UpdateMemberTokenInput;
};

export type MutationUpdateMyShopArgs = {
  input: UpdateMyShopInput;
};

export type MutationUpdateMyShopCollectionArgs = {
  input: UpdateMyShopCollectionInput;
};

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};

export type MutationUpdateOrganizationUserArgs = {
  input: UpdateOrganizationUserInput;
};

export type MutationUploadFileArgs = {
  files: Array<Scalars['Upload']>;
};

export type MutationUploadNftArgs = {
  input: UploadNftInput;
};

export type MutationUploadNftWithApiKeyArgs = {
  input: UploadNftWithApiKeyInput;
};

export type MutationWithdrawArgs = {
  input: WithdrawInput;
};

export type MyShop = {
  createdAt: Scalars['Date'];
  domain?: Maybe<Domain>;
  hasUpdate?: Maybe<Scalars['Boolean']>;
  nftActivationSettings?: Maybe<NftActivationSettings>;
  operation?: Maybe<Operation>;
  organizationUuid: Scalars['String'];
  ownerUid: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethod>;
  publish?: Maybe<Scalars['Boolean']>;
  shopInformation?: Maybe<ShopInformation>;
  shopType?: Maybe<Scalars['String']>;
  siteSetting?: Maybe<SiteSetting>;
  updatedAt?: Maybe<Scalars['Date']>;
  uuid: Scalars['String'];
};

export type MyShopCollectionDetail = {
  collection?: Maybe<Collection>;
  collectionImages?: Maybe<Array<DetailMyShopCollectionImage>>;
  collectionUuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  desc?: Maybe<Scalars['String']>;
  descJa?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  orderBy?: Maybe<Scalars['String']>;
  shopUuid?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
};

export type MyShopCollectionFilter = {
  collectionUuid: Scalars['String'];
  shopUuid: Scalars['String'];
};

export type MyShopCollectionImage = {
  collectionImageUuid?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  myShopCollectionUuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export enum MyShopCollectionQueryKey {
  CollectionContractAddress = 'COLLECTION_CONTRACT_ADDRESS',
  CollectionCreatedAt = 'COLLECTION_CREATED_AT',
  CollectionDesc = 'COLLECTION_DESC',
  CollectionDescJa = 'COLLECTION_DESC_JA',
  CollectionName = 'COLLECTION_NAME',
  CollectionNameJa = 'COLLECTION_NAME_JA',
  CollectionNetwork = 'COLLECTION_NETWORK',
  CollectionOwnerAddress = 'COLLECTION_OWNER_ADDRESS',
  CollectionSymbol = 'COLLECTION_SYMBOL',
  CollectionType = 'COLLECTION_TYPE',
  CollectionUuid = 'COLLECTION_UUID',
  CreatedAt = 'CREATED_AT',
  Desc = 'DESC',
  DescJa = 'DESC_JA',
  Name = 'NAME',
  NameJa = 'NAME_JA',
  ShopUuid = 'SHOP_UUID',
  Status = 'STATUS',
  Type = 'TYPE',
  Uuid = 'UUID',
}

export type MyShopCollectionToken = {
  createdAt: Scalars['Date'];
  myShopCollectionUuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  tokenDesc?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  tokenMintTime?: Maybe<Scalars['String']>;
  tokenName?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type MyShopCollectionsFilterField = {
  key: MyShopCollectionQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export type MyShopCollectionsTokensFilterField = {
  key: MyShopCollectionsTokensQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export enum MyShopCollectionsTokensQueryKey {
  CollectionUuid = 'COLLECTION_UUID',
  CreatedAt = 'CREATED_AT',
  MyshopCollectionUuid = 'MYSHOP_COLLECTION_UUID',
  Name = 'NAME',
  NameJa = 'NAME_JA',
  Order = 'ORDER',
  Price = 'PRICE',
  Status = 'STATUS',
  TokenDesc = 'TOKEN_DESC',
  TokenId = 'TOKEN_ID',
  TokenMintTime = 'TOKEN_MINT_TIME',
  TokenName = 'TOKEN_NAME',
  Uuid = 'UUID',
}

export type MyShopFilterField = {
  key: MyShopQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export enum MyShopQueryKey {
  CreatedAt = 'CREATED_AT',
  Publish = 'PUBLISH',
  ShopType = 'SHOP_TYPE',
  Title = 'TITLE',
  Uuid = 'UUID',
}

export type Navi = {
  description?: Maybe<Scalars['String']>;
  descriptionJa?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  pageTitleJa?: Maybe<Scalars['String']>;
};

export type NaviInput = {
  description?: InputMaybe<Scalars['String']>;
  descriptionJa?: InputMaybe<Scalars['String']>;
  pageTitle?: InputMaybe<Scalars['String']>;
  pageTitleJa?: InputMaybe<Scalars['String']>;
};

export type NftActivationSettings = {
  acquisitionInformation: Array<AcquisitionInformation>;
  expireDate?: Maybe<Scalars['Date']>;
  expireMethod?: Maybe<Scalars['String']>;
  expireUnit?: Maybe<Scalars['String']>;
  expireValue?: Maybe<Scalars['String']>;
};

export type NftActivationSettingsInput = {
  acquisitionInformation?: InputMaybe<Array<AcquisitionInformationInput>>;
  expireDate?: InputMaybe<Scalars['Date']>;
  expireMethod?: InputMaybe<Scalars['String']>;
  expireUnit?: InputMaybe<Scalars['String']>;
  expireValue?: InputMaybe<Scalars['String']>;
};

export type Operation = {
  address?: Maybe<IAddress>;
  contact?: Maybe<Contact>;
  dayOfBirth?: Maybe<Scalars['Date']>;
  detailName?: Maybe<OperatorName>;
  name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  shopName?: Maybe<ShopName>;
  type?: Maybe<Scalars['String']>;
};

export type OperationInput = {
  address?: InputMaybe<IAddressInput>;
  contact?: InputMaybe<ContactInput>;
  dayOfBirth?: InputMaybe<Scalars['Date']>;
  detailName?: InputMaybe<OperatorNameInput>;
  name?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  shopName?: InputMaybe<ShopNameInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type OperatorName = {
  firstName?: Maybe<Scalars['String']>;
  firstNameEn?: Maybe<Scalars['String']>;
  firstNameKana?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNameEn?: Maybe<Scalars['String']>;
  lastNameKana?: Maybe<Scalars['String']>;
};

export type OperatorNameInput = {
  firstName?: InputMaybe<Scalars['String']>;
  firstNameEn?: InputMaybe<Scalars['String']>;
  firstNameKana?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastNameEn?: InputMaybe<Scalars['String']>;
  lastNameKana?: InputMaybe<Scalars['String']>;
};

export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum OrderQueryKey {
  Address = 'ADDRESS',
  CollectionUuid = 'COLLECTION_UUID',
  CreatedAt = 'CREATED_AT',
  MyshopCollectionImageUuid = 'MYSHOP_COLLECTION_IMAGE_UUID',
  ShopUuid = 'SHOP_UUID',
  Status = 'STATUS',
  TokenId = 'TOKEN_ID',
  TokenUuid = 'TOKEN_UUID',
  Uuid = 'UUID',
  Wallet = 'WALLET',
}

export type OrdersFilterField = {
  key: OrderQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export type Organization = {
  contactEmail: Scalars['String'];
  createdAt: Scalars['Date'];
  currencySetting?: Maybe<CurrencySetting>;
  ipfsStorageApiKeys: Array<IpfsStorage>;
  masterWalletAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operation?: Maybe<Operation>;
  paymentSetting?: Maybe<PaymentSetting>;
  status: OrganizationStatus;
  updatedAt?: Maybe<Scalars['Date']>;
  uuid: Scalars['String'];
};

export type OrganizationDetailNameInput = {
  firstName?: InputMaybe<Scalars['String']>;
  firstNameEn?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastNameEn?: InputMaybe<Scalars['String']>;
};

export type OrganizationRoleUser = {
  contactEmail: Scalars['String'];
  createdAt: Scalars['Date'];
  currencySetting?: Maybe<CurrencySetting>;
  ipfsStorageApiKeys: Array<IpfsStorage>;
  masterWalletAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operation?: Maybe<Operation>;
  paymentSetting?: Maybe<PaymentSetting>;
  role: OrganizationUserRole;
  status: OrganizationStatus;
  updatedAt?: Maybe<Scalars['Date']>;
  uuid: Scalars['String'];
};

export enum OrganizationStatus {
  Approved = 'APPROVED',
  Blocked = 'BLOCKED',
  Requested = 'REQUESTED',
}

export type OrganizationUser = {
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role: OrganizationUserRole;
  uid: Scalars['String'];
};

export enum OrganizationUserRole {
  Admin = 'admin',
  Member = 'member',
  Owner = 'owner',
  Viewer = 'viewer',
}

export type Pagination = {
  /** Current page */
  currentPage: Scalars['Int'];
  /** Items per page */
  itemsPerPage: Scalars['Int'];
  /** Total items */
  totalItems: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export type PaymentMethod = {
  baseCurrency?: Maybe<Scalars['String']>;
  enableCard?: Maybe<Scalars['Boolean']>;
  publicKey?: Maybe<Scalars['String']>;
  secretKey?: Maybe<Scalars['String']>;
};

export type PaymentMethodInput = {
  baseCurrency?: InputMaybe<Scalars['String']>;
  enableCard?: InputMaybe<Scalars['Boolean']>;
  publicKey?: InputMaybe<Scalars['String']>;
  secretKey?: InputMaybe<Scalars['String']>;
};

export type PaymentSetting = {
  isLiveMode?: Maybe<Scalars['Boolean']>;
  policyUrl?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  requiredAcceptTerms?: Maybe<Scalars['Boolean']>;
  secretKey?: Maybe<Scalars['String']>;
  termsUrl?: Maybe<Scalars['String']>;
};

export type PaymentSettingInput = {
  policyUrl?: InputMaybe<Scalars['String']>;
  publicKey: Scalars['String'];
  requiredAcceptTerms?: InputMaybe<Scalars['Boolean']>;
  secretKey: Scalars['String'];
  termsUrl?: InputMaybe<Scalars['String']>;
};

export type Query = {
  checkExistDomain: Scalars['Boolean'];
  getAllMyShopCollectionsContainNFT: Array<Scalars['String']>;
  getAllMyShopCollectionsInPreMint: Array<Scalars['String']>;
  getCollection?: Maybe<Collection>;
  getMe?: Maybe<UserOrs>;
  getMyShop: MyShop;
  getMyShopCollection: MyShopCollectionDetail;
  getMyShopCollectionByCollectionId: MyShopCollectionDetail;
  getPublishShop: MyShop;
  listCollectionImages: ListCollectionImagesResponse;
  listCollections: ListCollectionsResponse;
  listMemberTokens: ListMemberTokensResponse;
  listMembers: ListMembersResponse;
  listMyShopCollectionImages: ListMyShopCollectionImagesResponse;
  listMyShopCollectionTokens: ListMyShopCollectionsTokensResponse;
  listMyShopCollections: ListMyShopCollectionsResponse;
  listMyShops: ListMyShopResponse;
  listNetworks: Array<ChainNetwork>;
  listOrders: ListOrdersResponse;
  listOrganizationUsers: Array<OrganizationUser>;
  listTokens: ListTokensResponse;
};

export type QueryCheckExistDomainArgs = {
  domain: Scalars['String'];
  shopType: Scalars['String'];
};

export type QueryGetAllMyShopCollectionsContainNftArgs = {
  collectionUuid: Scalars['String'];
  tokenId: Scalars['String'];
};

export type QueryGetAllMyShopCollectionsInPreMintArgs = {
  collectionUuid: Scalars['String'];
};

export type QueryGetCollectionArgs = {
  collectionUuid: Scalars['String'];
};

export type QueryGetMyShopArgs = {
  myShopUuid: Scalars['String'];
};

export type QueryGetMyShopCollectionArgs = {
  uuid: Scalars['String'];
};

export type QueryGetMyShopCollectionByCollectionIdArgs = {
  input: MyShopCollectionFilter;
};

export type QueryGetPublishShopArgs = {
  myShopUuid: Scalars['String'];
};

export type QueryListCollectionImagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<CollectionImagesQueryKey>;
  where?: InputMaybe<ListCollectionImagesFilter>;
};

export type QueryListCollectionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<CollectionQueryKey>;
  where?: InputMaybe<ListCollectionsFilter>;
};

export type QueryListMemberTokensArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<MemberTokenQueryKey>;
  where?: InputMaybe<ListMemberTokensFilter>;
};

export type QueryListMembersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<MemberQueryKey>;
  where?: InputMaybe<ListMembersFilter>;
};

export type QueryListMyShopCollectionImagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<MemberMyShopCollectionImagesQueryKey>;
  where: MemberListMyShopCollectionImagesFilter;
};

export type QueryListMyShopCollectionTokensArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<MyShopCollectionsTokensQueryKey>;
  where: ListMyShopCollectionsTokensFilter;
};

export type QueryListMyShopCollectionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<MyShopCollectionQueryKey>;
  where: ListMyShopCollectionsFilter;
};

export type QueryListMyShopsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<MyShopQueryKey>;
  where?: InputMaybe<ListMyShopsFilter>;
};

export type QueryListOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<OrderQueryKey>;
  where?: InputMaybe<ListOrdersFilter>;
};

export type QueryListTokensArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ListTokensSortBy>;
  where: ListTokensFilter;
};

export enum QueryMethod {
  And = 'AND',
  Or = 'OR',
}

export enum QueryOperator {
  Anyof = 'ANYOF',
  Contains = 'CONTAINS',
  Empty = 'EMPTY',
  End = 'END',
  Equals = 'EQUALS',
  Exists = 'EXISTS',
  Start = 'START',
}

export type RemoveApproveTransferDto = {
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RemoveApproveTransferInput = {
  collectionUuid: Scalars['String'];
  tokenIds: Array<Scalars['String']>;
};

export type RemoveMyShopCollectionInput = {
  collectionUuid: Scalars['String'];
};

export type RemoveOrganizationIpfsStorageInput = {
  /** Ipfs storage */
  storage: Storage;
};

export type RemoveOrganizationUserInput = {
  uid: Scalars['String'];
};

export type ShopInformation = {
  information?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
};

export type ShopInformationInput = {
  information?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  policy?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  terms?: InputMaybe<Scalars['String']>;
};

export type ShopName = {
  name?: Maybe<Scalars['String']>;
  nameKana?: Maybe<Scalars['String']>;
};

export type ShopNameInput = {
  name?: InputMaybe<Scalars['String']>;
  nameKana?: InputMaybe<Scalars['String']>;
};

export type SiteSetting = {
  banner?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  favicon?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ogp?: Maybe<Scalars['String']>;
  ratioBanner?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  title?: Maybe<Scalars['String']>;
};

export type SiteSettingInput = {
  banner?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  favicon?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ogp?: InputMaybe<Scalars['String']>;
  ratioBanner?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<ThemeInput>;
  title?: InputMaybe<Scalars['String']>;
};

export enum Storage {
  NftStorage = 'NFT_STORAGE',
  Web3Storage = 'WEB3_STORAGE',
}

export type Style = {
  backgroundColor?: Maybe<Scalars['String']>;
  buttonColor?: Maybe<Scalars['String']>;
  collectionBorderColor?: Maybe<Scalars['String']>;
  collectionNftCardImageMode?: Maybe<Scalars['String']>;
  collectionTitleColor?: Maybe<Scalars['String']>;
  descriptionColor?: Maybe<Scalars['String']>;
  font?: Maybe<Scalars['String']>;
  headerColor?: Maybe<Scalars['String']>;
  headerTextColor?: Maybe<Scalars['String']>;
  nftCardBackgroundColor?: Maybe<Scalars['String']>;
  nftCardTextColor?: Maybe<Scalars['String']>;
  spacing?: Maybe<Scalars['Float']>;
  spacingSm?: Maybe<Scalars['Float']>;
  textColor?: Maybe<Scalars['String']>;
  titleColor?: Maybe<Scalars['String']>;
};

export type StyleInput = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  buttonColor?: InputMaybe<Scalars['String']>;
  collectionBorderColor?: InputMaybe<Scalars['String']>;
  collectionNftCardImageMode?: InputMaybe<Scalars['String']>;
  collectionTitleColor?: InputMaybe<Scalars['String']>;
  descriptionColor?: InputMaybe<Scalars['String']>;
  font?: InputMaybe<Scalars['String']>;
  headerColor?: InputMaybe<Scalars['String']>;
  headerTextColor?: InputMaybe<Scalars['String']>;
  nftCardBackgroundColor?: InputMaybe<Scalars['String']>;
  nftCardTextColor?: InputMaybe<Scalars['String']>;
  spacing?: InputMaybe<Scalars['Float']>;
  spacingSm?: InputMaybe<Scalars['Float']>;
  textColor?: InputMaybe<Scalars['String']>;
  titleColor?: InputMaybe<Scalars['String']>;
};

export type Thema = {
  template?: Maybe<Scalars['String']>;
};

export type ThemaInput = {
  template?: InputMaybe<Scalars['String']>;
};

export type Theme = {
  navi?: Maybe<Navi>;
  positionLabelSample?: Maybe<Array<Scalars['Float']>>;
  showLabelSample?: Maybe<Scalars['Boolean']>;
  style?: Maybe<Style>;
  thema?: Maybe<Thema>;
};

export type ThemeInput = {
  navi?: InputMaybe<NaviInput>;
  positionLabelSample?: InputMaybe<Array<Scalars['Float']>>;
  showLabelSample?: InputMaybe<Scalars['Boolean']>;
  style?: InputMaybe<StyleInput>;
  thema?: InputMaybe<ThemaInput>;
};

export type Token = {
  description: Scalars['String'];
  id: Scalars['String'];
  image: Scalars['String'];
  member?: Maybe<Member>;
  memberToken?: Maybe<MemberToken>;
  mintTime: Scalars['String'];
  name: Scalars['String'];
  ownerAddress: Scalars['String'];
  tokenId: Scalars['String'];
  tokenURI: Scalars['String'];
};

export type TokenOrder = {
  accessId?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  collectionUuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  currency?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  myShopCollectionImageUuid?: Maybe<Scalars['String']>;
  payType?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  shopUuid?: Maybe<Scalars['String']>;
  status?: Maybe<TokenOrderStatus>;
  tokenId?: Maybe<Scalars['String']>;
  tokenUuid?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  wallet?: Maybe<Scalars['String']>;
};

export enum TokenOrderStatus {
  Created = 'created',
  NftFailed = 'nft_failed',
  NftSucceeded = 'nft_succeeded',
  Pending = 'pending',
  Processing = 'processing',
}

export type UnAttachMyShopCollectionInput = {
  collectionUuids: Array<Scalars['String']>;
  shopUuid: Scalars['String'];
};

export type UnAttachMyShopCollectionTokensInput = {
  myShopCollectionUuid: Scalars['String'];
  tokenIds: Array<Scalars['String']>;
};

export type UpdateCollectionInput = {
  desc?: InputMaybe<Scalars['String']>;
  descJa?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  nameJa?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type UpdateMemberTokenInput = {
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type UpdateMyShopCollectionInput = {
  desc?: InputMaybe<Scalars['String']>;
  descJa?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nameJa?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  /** id */
  uuid: Scalars['String'];
};

export type UpdateMyShopInput = {
  /** File */
  bannerFile?: InputMaybe<Scalars['Upload']>;
  domain?: InputMaybe<DomainInput>;
  /** File */
  faviconFile?: InputMaybe<Scalars['Upload']>;
  /** File */
  logoFile?: InputMaybe<Scalars['Upload']>;
  nftActivationSettings?: InputMaybe<NftActivationSettingsInput>;
  /** File */
  ogpFile?: InputMaybe<Scalars['Upload']>;
  operation?: InputMaybe<OperationInput>;
  paymentMethod?: InputMaybe<PaymentMethodInput>;
  /** File */
  ratioBannerFile?: InputMaybe<Scalars['Upload']>;
  shopInformation?: InputMaybe<ShopInformationInput>;
  shopType?: InputMaybe<Scalars['String']>;
  siteSetting?: InputMaybe<SiteSettingInput>;
  users?: InputMaybe<Array<Scalars['String']>>;
  uuid: Scalars['String'];
};

export type UpdateOrganizationInput = {
  baseSetting?: InputMaybe<BaseSettingInput>;
  currencySetting?: InputMaybe<CurrencySettingInput>;
  detailName?: InputMaybe<OrganizationDetailNameInput>;
  name?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<OperationInput>;
  paymentSetting?: InputMaybe<PaymentSettingInput>;
};

export type UpdateOrganizationUserInput = {
  role?: InputMaybe<OrganizationUserRole>;
  uid: Scalars['String'];
};

export type UpdatedCollectionImageInput = {
  collectionUuid: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  /** File */
  imageFile?: InputMaybe<Scalars['Upload']>;
  maxMint?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  nameJa?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type UploadNftInput = {
  description: Scalars['String'];
  image: Scalars['Upload'];
  mediaAttachment?: InputMaybe<Scalars['Upload']>;
  name: Scalars['String'];
};

export type UploadNftResponse = {
  /** Description */
  description: Scalars['String'];
  /** Metadata Content */
  metadataContent: MetadataContent;
  /** Metadata Url */
  metadataUrl: Scalars['String'];
  /** Name */
  name: Scalars['String'];
};

export type UploadNftWithApiKeyInput = {
  apiKey: Scalars['String'];
  description: Scalars['String'];
  image: Scalars['Upload'];
  isSave: Scalars['Boolean'];
  mediaAttachment?: InputMaybe<Scalars['Upload']>;
  name: Scalars['String'];
  organizationUuid: Scalars['String'];
  storage: Storage;
};

export type User = {
  createdAt: Scalars['Date'];
  displayName: Scalars['String'];
  email: Scalars['String'];
  /** Firebase uid */
  uid: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  uuid: Scalars['String'];
};

export type UserOrs = {
  createdAt: Scalars['Date'];
  displayName: Scalars['String'];
  email: Scalars['String'];
  organizations: Array<OrganizationRoleUser>;
  /** Firebase uid */
  uid: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  uuid: Scalars['String'];
};

export type WithdrawInput = {
  amount: Scalars['String'];
  network: Scalars['String'];
  walletAddress: Scalars['String'];
};

export type AcceptTermOfUseMutationVariables = Exact<{ [key: string]: never }>;

export type AcceptTermOfUseMutation = {
  acceptTermOfUse: { uuid: string; uid: string; displayName: string; email: string };
};

export type AddOrganizationUserMutationVariables = Exact<{
  input: AddOrganizationUserInput;
}>;

export type AddOrganizationUserMutation = {
  addOrganizationUser: { uid: string; role: OrganizationUserRole; displayName?: string | null; email?: string | null };
};

export type AttachMyShopCollectionTokensMutationVariables = Exact<{
  input: AttachMyShopCollectionTokensInput;
}>;

export type AttachMyShopCollectionTokensMutation = { attachMyShopCollectionTokens: Array<string> };

export type AttachMyShopCollectionsMutationVariables = Exact<{
  input: AttachMyShopCollectionInput;
}>;

export type AttachMyShopCollectionsMutation = { attachMyShopCollections: Array<string> };

export type CreateCollectionImageMutationVariables = Exact<{
  input: CreateCollectionImageInput;
}>;

export type CreateCollectionImageMutation = {
  createCollectionImage: {
    uuid: string;
    collectionUuid: string;
    name?: string | null;
    image?: string | null;
    maxMint?: number | null;
    currentMint?: number | null;
    createdAt: any;
  };
};

export type CreateCollectionWithOrgWalletMutationVariables = Exact<{
  input: CreateCollectionWithOrgWalletInput;
}>;

export type CreateCollectionWithOrgWalletMutation = {
  createCollectionWithOrgWallet: {
    uuid: string;
    ownerUid: string;
    network: string;
    name: string;
    symbol: string;
    organizationUuid: string;
    contractAddress: string;
    ownerAddress?: string | null;
    createdAt: any;
    desc?: string | null;
    descJa?: string | null;
    type?: string | null;
    images?: Array<string> | null;
  };
};

export type CreateCollectionMutationVariables = Exact<{
  input: CreateCollectionInput;
}>;

export type CreateCollectionMutation = {
  createCollection: {
    uuid: string;
    ownerUid: string;
    network: string;
    name: string;
    symbol: string;
    organizationUuid: string;
    contractAddress: string;
    type?: string | null;
    images?: Array<string> | null;
    createdAt: any;
  };
};

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;

export type CreateOrganizationMutation = {
  createOrganization: { uuid: string; name: string; contactEmail: string; role: OrganizationUserRole };
};

export type MintNftMutationVariables = Exact<{
  input: MintNftInput;
}>;

export type MintNftMutation = { mintNft: string };

export type CancelPublishShopMutationVariables = Exact<{
  myShopUuid: Scalars['String'];
}>;

export type CancelPublishShopMutation = { cancelPublishShop: { uuid: string } };

export type CreateMyShopMutationVariables = Exact<{
  input: CreateMyShopInput;
}>;

export type CreateMyShopMutation = {
  createMyShop: {
    uuid: string;
    ownerUid: string;
    createdAt: any;
    updatedAt?: any | null;
    publish?: boolean | null;
    siteSetting?: {
      title?: string | null;
      description?: string | null;
      banner?: string | null;
      favicon?: string | null;
      ogp?: string | null;
      category?: string | null;
      theme?: {
        thema?: { template?: string | null } | null;
        style?: {
          font?: string | null;
          textColor?: string | null;
          backgroundColor?: string | null;
          buttonColor?: string | null;
        } | null;
        navi?: { pageTitle?: string | null; description?: string | null } | null;
      } | null;
    } | null;
    operation?: {
      type?: string | null;
      name?: string | null;
      dayOfBirth?: any | null;
      sex?: string | null;
      detailName?: {
        lastName?: string | null;
        firstName?: string | null;
        lastNameKana?: string | null;
        firstNameKana?: string | null;
        lastNameEn?: string | null;
        firstNameEn?: string | null;
      } | null;
      shopName?: { name?: string | null; nameKana?: string | null } | null;
      address?: {
        postalCode?: string | null;
        prefecture?: string | null;
        address?: string | null;
        addressDisplay?: boolean | null;
      } | null;
      contact?: { phoneNumber?: string | null; publish?: boolean | null } | null;
    } | null;
    shopInformation?: { information?: string | null } | null;
    paymentMethod?: {
      secretKey?: string | null;
      publicKey?: string | null;
      enableCard?: boolean | null;
      baseCurrency?: string | null;
    } | null;
    domain?: { name?: string | null; subDomain?: string | null; customDomain?: string | null } | null;
  };
};

export type DuplicateMyShopMutationVariables = Exact<{
  myShopUuid: Scalars['String'];
}>;

export type DuplicateMyShopMutation = { duplicateMyShop: { uuid: string } };

export type PublishShopMutationVariables = Exact<{
  myShopUuid: Scalars['String'];
}>;

export type PublishShopMutation = { publishShop: { uuid: string } };

export type RemoveMyShopMutationVariables = Exact<{
  myShopUuid: Scalars['String'];
}>;

export type RemoveMyShopMutation = { removeMyShop: boolean };

export type SetOpenShopMutationVariables = Exact<{
  myShopUuid: Scalars['String'];
  isOpen: Scalars['Boolean'];
}>;

export type SetOpenShopMutation = { setOpenShop: { uuid: string; updatedAt?: any | null } };

export type UpdateMyShopMutationVariables = Exact<{
  input: UpdateMyShopInput;
}>;

export type UpdateMyShopMutation = {
  updateMyShop: {
    uuid: string;
    ownerUid: string;
    createdAt: any;
    updatedAt?: any | null;
    publish?: boolean | null;
    siteSetting?: {
      title?: string | null;
      description?: string | null;
      banner?: string | null;
      favicon?: string | null;
      ogp?: string | null;
      category?: string | null;
      theme?: {
        thema?: { template?: string | null } | null;
        style?: {
          font?: string | null;
          textColor?: string | null;
          backgroundColor?: string | null;
          buttonColor?: string | null;
        } | null;
        navi?: { pageTitle?: string | null; description?: string | null } | null;
      } | null;
    } | null;
    operation?: {
      type?: string | null;
      name?: string | null;
      dayOfBirth?: any | null;
      sex?: string | null;
      detailName?: {
        lastName?: string | null;
        firstName?: string | null;
        lastNameKana?: string | null;
        firstNameKana?: string | null;
        lastNameEn?: string | null;
        firstNameEn?: string | null;
      } | null;
      shopName?: { name?: string | null; nameKana?: string | null } | null;
      address?: {
        postalCode?: string | null;
        prefecture?: string | null;
        address?: string | null;
        addressDisplay?: boolean | null;
      } | null;
      contact?: { phoneNumber?: string | null; publish?: boolean | null } | null;
    } | null;
    shopInformation?: { information?: string | null } | null;
    paymentMethod?: {
      secretKey?: string | null;
      publicKey?: string | null;
      enableCard?: boolean | null;
      baseCurrency?: string | null;
    } | null;
    domain?: { name?: string | null; subDomain?: string | null; customDomain?: string | null } | null;
  };
};

export type RemoveApproveTransferMutationVariables = Exact<{
  input: RemoveApproveTransferInput;
}>;

export type RemoveApproveTransferMutation = {
  removeApproveTransfer: Array<{ success: boolean; errorCode?: string | null; errorMessage?: string | null }>;
};

export type RemoveCollectionImageMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type RemoveCollectionImageMutation = { removeCollectionImage: boolean };

export type RemoveCollectionMutationVariables = Exact<{
  collectionUuid: Scalars['String'];
}>;

export type RemoveCollectionMutation = { removeCollection: boolean };

export type RemoveOrganizationUserMutationVariables = Exact<{
  input: RemoveOrganizationUserInput;
}>;

export type RemoveOrganizationUserMutation = { removeOrganizationUser: boolean };

export type RemoveOrganizationIpfsStorageMutationVariables = Exact<{
  input: RemoveOrganizationIpfsStorageInput;
}>;

export type RemoveOrganizationIpfsStorageMutation = { removeOrganizationIpfsStorage: boolean };

export type RemoveMyShopCollectionsMutationVariables = Exact<{
  input: RemoveMyShopCollectionInput;
}>;

export type RemoveMyShopCollectionsMutation = { removeMyShopCollections: boolean };

export type ResendEmailVerifyMemberMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ResendEmailVerifyMemberMutation = { resendEmailVerifyMember: { uuid: string } };

export type SetMyShopCollectionImageMutationVariables = Exact<{
  input: CreateMyShopCollectionImageInput;
}>;

export type SetMyShopCollectionImageMutation = {
  setMyShopCollectionImage: {
    uuid: string;
    name?: string | null;
    status?: string | null;
    collectionImageUuid?: string | null;
    myShopCollectionUuid: string;
    price?: number | null;
    createdAt: any;
  };
};

export type SetMyShopCollectionTokenMutationVariables = Exact<{
  input: CreateMyShopCollectionTokenInput;
}>;

export type SetMyShopCollectionTokenMutation = {
  setMyShopCollectionToken: {
    uuid: string;
    myShopCollectionUuid: string;
    tokenId?: string | null;
    tokenName?: string | null;
    tokenDesc?: string | null;
    tokenMintTime?: string | null;
    status?: string | null;
    name?: string | null;
    nameJa?: string | null;
    price?: number | null;
    order?: number | null;
    createdAt: any;
  };
};

export type UnAttachMyShopCollectionTokensMutationVariables = Exact<{
  input: UnAttachMyShopCollectionTokensInput;
}>;

export type UnAttachMyShopCollectionTokensMutation = { unAttachMyShopCollectionTokens: Array<boolean> };

export type UnAttachMyShopCollectionsMutationVariables = Exact<{
  input: UnAttachMyShopCollectionInput;
}>;

export type UnAttachMyShopCollectionsMutation = { unAttachMyShopCollections: Array<boolean> };

export type UpdateCollectionImageMutationVariables = Exact<{
  input: UpdatedCollectionImageInput;
}>;

export type UpdateCollectionImageMutation = {
  updateCollectionImage: {
    uuid: string;
    collectionUuid: string;
    name?: string | null;
    image?: string | null;
    maxMint?: number | null;
    currentMint?: number | null;
    createdAt: any;
  };
};

export type UpdateCollectionMutationVariables = Exact<{
  input: UpdateCollectionInput;
}>;

export type UpdateCollectionMutation = {
  updateCollection: {
    uuid: string;
    ownerUid: string;
    network: string;
    name: string;
    symbol: string;
    organizationUuid: string;
    contractAddress: string;
    type?: string | null;
    images?: Array<string> | null;
    createdAt: any;
  };
};

export type UpdateMemberTokenMutationVariables = Exact<{
  input: UpdateMemberTokenInput;
}>;

export type UpdateMemberTokenMutation = {
  updateMemberToken: {
    uuid: string;
    type?: string | null;
    status?: string | null;
    wallet?: string | null;
    tokenId?: string | null;
    shopUuid?: string | null;
    ownerUid?: string | null;
    createdAt: any;
    collectionUuid?: string | null;
  };
};

export type UpdateMyShopCollectionMutationVariables = Exact<{
  input: UpdateMyShopCollectionInput;
}>;

export type UpdateMyShopCollectionMutation = {
  updateMyShopCollection: {
    uuid?: string | null;
    type?: string | null;
    name?: string | null;
    status?: string | null;
    shopUuid?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    collection?: {
      uuid: string;
      ownerUid: string;
      network: string;
      name: string;
      symbol: string;
      ownerAddress?: string | null;
      organizationUuid: string;
      contractAddress: string;
      createdAt: any;
      desc?: string | null;
      type?: string | null;
      images?: Array<string> | null;
    } | null;
  };
};

export type UpdateOrganizationUserMutationVariables = Exact<{
  input: UpdateOrganizationUserInput;
}>;

export type UpdateOrganizationUserMutation = {
  updateOrganizationUser: {
    uid: string;
    role: OrganizationUserRole;
    email?: string | null;
    displayName?: string | null;
  };
};

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;

export type UpdateOrganizationMutation = {
  updateOrganization: {
    uuid: string;
    name: string;
    contactEmail: string;
    paymentSetting?: { publicKey?: string | null; secretKey?: string | null } | null;
  };
};

export type UploadFileMutationVariables = Exact<{
  files: Array<Scalars['Upload']> | Scalars['Upload'];
}>;

export type UploadFileMutation = { uploadFile: Array<string> };

export type UploadNftWithApiKeyMutationVariables = Exact<{
  input: UploadNftWithApiKeyInput;
}>;

export type UploadNftWithApiKeyMutation = {
  uploadNFTWithApiKey: {
    name: string;
    description: string;
    metadataUrl: string;
    metadataContent: { name: string; description?: string | null; image: string; animation_url?: string | null };
  };
};

export type UploadNftMutationVariables = Exact<{
  input: UploadNftInput;
}>;

export type UploadNftMutation = {
  uploadNFT: {
    name: string;
    description: string;
    metadataUrl: string;
    metadataContent: { name: string; description?: string | null; image: string; animation_url?: string | null };
  };
};

export type WithdrawMutationVariables = Exact<{
  input: WithdrawInput;
}>;

export type WithdrawMutation = { withdraw: boolean };

export type GetCollectionQueryVariables = Exact<{
  collectionUuid: Scalars['String'];
}>;

export type GetCollectionQuery = {
  getCollection?: {
    uuid: string;
    ownerUid: string;
    network: string;
    name: string;
    nameJa?: string | null;
    symbol: string;
    organizationUuid: string;
    contractAddress: string;
    ownerAddress?: string | null;
    createdAt: any;
    desc?: string | null;
    descJa?: string | null;
    type?: string | null;
    images?: Array<string> | null;
  } | null;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  getMe?: {
    uuid: string;
    uid: string;
    displayName: string;
    email: string;
    createdAt: any;
    organizations: Array<{
      uuid: string;
      name: string;
      contactEmail: string;
      role: OrganizationUserRole;
      status: OrganizationStatus;
      createdAt: any;
      updatedAt?: any | null;
      masterWalletAddress?: string | null;
      paymentSetting?: {
        requiredAcceptTerms?: boolean | null;
        termsUrl?: string | null;
        policyUrl?: string | null;
        secretKey?: string | null;
        publicKey?: string | null;
      } | null;
      currencySetting?: { baseCurrency?: string | null } | null;
      ipfsStorageApiKeys: Array<{ storage: string; apiKey: string }>;
      operation?: {
        type?: string | null;
        name?: string | null;
        dayOfBirth?: any | null;
        sex?: string | null;
        detailName?: {
          lastName?: string | null;
          firstName?: string | null;
          lastNameKana?: string | null;
          firstNameKana?: string | null;
          lastNameEn?: string | null;
          firstNameEn?: string | null;
        } | null;
        shopName?: { name?: string | null; nameKana?: string | null } | null;
        address?: {
          postalCode?: string | null;
          prefecture?: string | null;
          address?: string | null;
          addressDisplay?: boolean | null;
        } | null;
        contact?: { phoneNumber?: string | null; publish?: boolean | null } | null;
      } | null;
    }>;
  } | null;
};

export type GetMyShopCollectionByCollectionIdQueryVariables = Exact<{
  input: MyShopCollectionFilter;
}>;

export type GetMyShopCollectionByCollectionIdQuery = {
  getMyShopCollectionByCollectionId: {
    type?: string | null;
    uuid?: string | null;
    name?: string | null;
    desc?: string | null;
    nameJa?: string | null;
    descJa?: string | null;
    sortBy?: string | null;
    status?: string | null;
    orderBy?: string | null;
    shopUuid?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    collectionUuid?: string | null;
    collectionImages?: Array<{ price?: number | null; status?: string | null }> | null;
    collection?: {
      uuid: string;
      ownerUid: string;
      network: string;
      name: string;
      nameJa?: string | null;
      symbol: string;
      ownerAddress?: string | null;
      organizationUuid: string;
      contractAddress: string;
      createdAt: any;
      desc?: string | null;
      type?: string | null;
      images?: Array<string> | null;
    } | null;
  };
};

export type GetMyShopCollectionQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type GetMyShopCollectionQuery = {
  getMyShopCollection: {
    uuid?: string | null;
    type?: string | null;
    name?: string | null;
    desc?: string | null;
    nameJa?: string | null;
    descJa?: string | null;
    status?: string | null;
    shopUuid?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    collectionUuid?: string | null;
    collectionImages?: Array<{
      uuid: string;
      name?: string | null;
      image?: string | null;
      nameJa?: string | null;
      maxMint?: number | null;
      createdAt: any;
      currentMint?: number | null;
      collectionUuid: string;
      collectionImageUuid?: string | null;
      myShopCollectionUuid?: string | null;
      myShopCollectionImageUuid?: string | null;
      myShopCollectionImageName?: string | null;
      status?: string | null;
      price?: number | null;
    }> | null;
    collection?: {
      uuid: string;
      ownerUid: string;
      network: string;
      name: string;
      nameJa?: string | null;
      symbol: string;
      ownerAddress?: string | null;
      organizationUuid: string;
      contractAddress: string;
      createdAt: any;
      desc?: string | null;
      type?: string | null;
      images?: Array<string> | null;
    } | null;
  };
};

export type ListCollectionIdsInShopQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MyShopCollectionQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where: ListMyShopCollectionsFilter;
}>;

export type ListCollectionIdsInShopQuery = {
  listMyShopCollections: {
    items: Array<{ uuid?: string | null; type?: string | null; collectionUuid?: string | null }>;
  };
};

export type ListCollectionImagesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CollectionImagesQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ListCollectionImagesFilter>;
}>;

export type ListCollectionImagesQuery = {
  listCollectionImages: {
    items: Array<{
      uuid: string;
      collectionUuid: string;
      name?: string | null;
      image?: string | null;
      nameJa?: string | null;
      maxMint?: number | null;
      currentMint?: number | null;
      createdAt: any;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export type ListCollectionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CollectionQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ListCollectionsFilter>;
}>;

export type ListCollectionsQuery = {
  listCollections: {
    items: Array<{
      uuid: string;
      ownerUid: string;
      network: string;
      name: string;
      symbol: string;
      organizationUuid: string;
      contractAddress: string;
      ownerAddress?: string | null;
      createdAt: any;
      desc?: string | null;
      descJa?: string | null;
      type?: string | null;
      images?: Array<string> | null;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export type ListOrganizationUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ListOrganizationUsersQuery = {
  listOrganizationUsers: Array<{
    uid: string;
    role: OrganizationUserRole;
    displayName?: string | null;
    email?: string | null;
  }>;
};

export type ListMemberTokensQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MemberTokenQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ListMemberTokensFilter>;
}>;

export type ListMemberTokensQuery = {
  listMemberTokens: {
    items: Array<{
      type?: string | null;
      uuid: string;
      status?: string | null;
      wallet?: string | null;
      tokenId?: string | null;
      ownerUid?: string | null;
      shopUuid?: string | null;
      createdAt: any;
      collectionUuid?: string | null;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export type ListMembersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MemberQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ListMembersFilter>;
}>;

export type ListMembersQuery = {
  listMembers: {
    items: Array<{
      uuid: string;
      ownerUid?: string | null;
      wallet?: string | null;
      shopUuid?: string | null;
      tokenId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      verifyEmail?: boolean | null;
      email?: string | null;
      status?: string | null;
      createdAt: any;
      address?: {
        postalCode?: string | null;
        prefecture?: string | null;
        address?: string | null;
        addressDisplay?: boolean | null;
      } | null;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export type ListMyShopCollectionImagesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MemberMyShopCollectionImagesQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where: MemberListMyShopCollectionImagesFilter;
}>;

export type ListMyShopCollectionImagesQuery = {
  listMyShopCollectionImages: {
    items: Array<{
      uuid: string;
      collectionUuid: string;
      name?: string | null;
      image?: string | null;
      order?: number | null;
      nameJa?: string | null;
      maxMint?: number | null;
      currentMint?: number | null;
      createdAt: any;
      myShopCollectionUuid?: string | null;
      collectionImageUuid?: string | null;
      myShopCollectionImageUuid?: string | null;
      myShopCollectionImageName?: string | null;
      myShopCollectionImageNameJa?: string | null;
      status?: string | null;
      price?: number | null;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export type ListMyShopCollectionTokensQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MyShopCollectionsTokensQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where: ListMyShopCollectionsTokensFilter;
}>;

export type ListMyShopCollectionTokensQuery = {
  listMyShopCollectionTokens: {
    items: Array<{
      uuid: string;
      myShopCollectionUuid: string;
      tokenId?: string | null;
      tokenName?: string | null;
      tokenDesc?: string | null;
      tokenMintTime?: string | null;
      status?: string | null;
      name?: string | null;
      nameJa?: string | null;
      price?: number | null;
      order?: number | null;
      createdAt: any;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export type ListMyShopCollectionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MyShopCollectionQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where: ListMyShopCollectionsFilter;
}>;

export type ListMyShopCollectionsQuery = {
  listMyShopCollections: {
    items: Array<{
      uuid?: string | null;
      type?: string | null;
      name?: string | null;
      desc?: string | null;
      order?: number | null;
      nameJa?: string | null;
      descJa?: string | null;
      status?: string | null;
      sortBy?: string | null;
      orderBy?: string | null;
      shopUuid?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      collectionUuid?: string | null;
      collection?: {
        uuid: string;
        name: string;
        desc?: string | null;
        type?: string | null;
        nameJa?: string | null;
        descJa?: string | null;
        images?: Array<string> | null;
        symbol: string;
        network: string;
        ownerUid: string;
        createdAt: any;
        ownerAddress?: string | null;
        contractAddress: string;
        organizationUuid: string;
      } | null;
      collectionImages?: Array<{
        uuid: string;
        name?: string | null;
        image?: string | null;
        price?: number | null;
        order?: number | null;
        nameJa?: string | null;
        status?: string | null;
        maxMint?: number | null;
        createdAt: any;
        currentMint?: number | null;
        collectionUuid: string;
        collectionImageUuid?: string | null;
        myShopCollectionUuid?: string | null;
        myShopCollectionImageUuid?: string | null;
        myShopCollectionImageName?: string | null;
      }> | null;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export type ListNetworksQueryVariables = Exact<{ [key: string]: never }>;

export type ListNetworksQuery = {
  listNetworks: Array<{
    uuid: string;
    chainId: string;
    name: string;
    icon?: string | null;
    rpcUrl: string;
    tokenName?: string | null;
    tokenSymbol?: string | null;
    tokenSymbols?: Array<string> | null;
    blockExplorer?: string | null;
    contractAddress?: string | null;
    walletAddress?: string | null;
    subgraphUrl?: string | null;
    testMode?: boolean | null;
    createdAt?: any | null;
  }>;
};

export type ListTokensQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListTokensSortBy>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where: ListTokensFilter;
}>;

export type ListTokensQuery = {
  listTokens: {
    items: Array<{
      id: string;
      ownerAddress: string;
      tokenId: string;
      name: string;
      description: string;
      tokenURI: string;
      image: string;
      mintTime: string;
      memberToken?: {
        type?: string | null;
        uuid: string;
        status?: string | null;
        wallet?: string | null;
        tokenId?: string | null;
        ownerUid?: string | null;
        shopUuid?: string | null;
        createdAt: any;
        collectionUuid?: string | null;
      } | null;
      member?: {
        uuid: string;
        ownerUid?: string | null;
        wallet?: string | null;
        shopUuid?: string | null;
        tokenId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        verifyEmail?: boolean | null;
        email?: string | null;
        status?: string | null;
        createdAt: any;
        address?: {
          postalCode?: string | null;
          prefecture?: string | null;
          address?: string | null;
          addressDisplay?: boolean | null;
        } | null;
      } | null;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export type CheckExistDomainQueryVariables = Exact<{
  domain: Scalars['String'];
  shopType: Scalars['String'];
}>;

export type CheckExistDomainQuery = { checkExistDomain: boolean };

export type GetAllMyShopCollectionsContainNftQueryVariables = Exact<{
  collectionUuid: Scalars['String'];
  tokenId: Scalars['String'];
}>;

export type GetAllMyShopCollectionsContainNftQuery = { getAllMyShopCollectionsContainNFT: Array<string> };

export type GetAllMyShopCollectionsInPreMintQueryVariables = Exact<{
  collectionUuid: Scalars['String'];
}>;

export type GetAllMyShopCollectionsInPreMintQuery = { getAllMyShopCollectionsInPreMint: Array<string> };

export type GetMyShopQueryVariables = Exact<{
  myShopUuid: Scalars['String'];
}>;

export type GetMyShopQuery = {
  getMyShop: {
    uuid: string;
    publish?: boolean | null;
    ownerUid: string;
    createdAt: any;
    updatedAt?: any | null;
    hasUpdate?: boolean | null;
    organizationUuid: string;
    shopType?: string | null;
    nftActivationSettings?: {
      expireMethod?: string | null;
      expireDate?: any | null;
      expireValue?: string | null;
      expireUnit?: string | null;
      acquisitionInformation: Array<{
        id?: string | null;
        type?: string | null;
        displayName?: string | null;
        required?: boolean | null;
      }>;
    } | null;
    siteSetting?: {
      title?: string | null;
      description?: string | null;
      banner?: string | null;
      ratioBanner?: string | null;
      favicon?: string | null;
      ogp?: string | null;
      logo?: string | null;
      meta?: string | null;
      name?: string | null;
      category?: string | null;
      theme?: {
        showLabelSample?: boolean | null;
        positionLabelSample?: Array<number> | null;
        thema?: { template?: string | null } | null;
        style?: {
          spacing?: number | null;
          spacingSm?: number | null;
          font?: string | null;
          textColor?: string | null;
          titleColor?: string | null;
          buttonColor?: string | null;
          headerColor?: string | null;
          headerTextColor?: string | null;
          backgroundColor?: string | null;
          descriptionColor?: string | null;
          collectionTitleColor?: string | null;
          nftCardTextColor?: string | null;
          nftCardBackgroundColor?: string | null;
          collectionBorderColor?: string | null;
          collectionNftCardImageMode?: string | null;
        } | null;
        navi?: {
          pageTitle?: string | null;
          pageTitleJa?: string | null;
          description?: string | null;
          descriptionJa?: string | null;
        } | null;
      } | null;
    } | null;
    operation?: {
      type?: string | null;
      name?: string | null;
      dayOfBirth?: any | null;
      sex?: string | null;
      detailName?: {
        lastName?: string | null;
        firstName?: string | null;
        lastNameKana?: string | null;
        firstNameKana?: string | null;
        lastNameEn?: string | null;
        firstNameEn?: string | null;
      } | null;
      shopName?: { name?: string | null; nameKana?: string | null } | null;
      address?: {
        postalCode?: string | null;
        prefecture?: string | null;
        address?: string | null;
        addressDisplay?: boolean | null;
      } | null;
      contact?: { phoneNumber?: string | null; publish?: boolean | null } | null;
    } | null;
    shopInformation?: {
      sortBy?: string | null;
      policy?: string | null;
      terms?: string | null;
      orderBy?: string | null;
      information?: string | null;
    } | null;
    paymentMethod?: {
      secretKey?: string | null;
      publicKey?: string | null;
      enableCard?: boolean | null;
      baseCurrency?: string | null;
    } | null;
    domain?: { name?: string | null; subDomain?: string | null; customDomain?: string | null } | null;
  };
};

export type GetPublishShopQueryVariables = Exact<{
  myShopUuid: Scalars['String'];
}>;

export type GetPublishShopQuery = { getPublishShop: { uuid: string; updatedAt?: any | null } };

export type ListMyShopsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MyShopQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ListMyShopsFilter>;
}>;

export type ListMyShopsQuery = {
  listMyShops: {
    items: Array<{
      uuid: string;
      ownerUid: string;
      createdAt: any;
      updatedAt?: any | null;
      publish?: boolean | null;
      organizationUuid: string;
      siteSetting?: {
        title?: string | null;
        description?: string | null;
        banner?: string | null;
        favicon?: string | null;
        ogp?: string | null;
        category?: string | null;
        theme?: {
          thema?: { template?: string | null } | null;
          style?: {
            font?: string | null;
            textColor?: string | null;
            backgroundColor?: string | null;
            buttonColor?: string | null;
          } | null;
          navi?: { pageTitle?: string | null; description?: string | null } | null;
        } | null;
      } | null;
      operation?: {
        type?: string | null;
        name?: string | null;
        dayOfBirth?: any | null;
        sex?: string | null;
        detailName?: {
          lastName?: string | null;
          firstName?: string | null;
          lastNameKana?: string | null;
          firstNameKana?: string | null;
          lastNameEn?: string | null;
          firstNameEn?: string | null;
        } | null;
        shopName?: { name?: string | null; nameKana?: string | null } | null;
        address?: {
          postalCode?: string | null;
          prefecture?: string | null;
          address?: string | null;
          addressDisplay?: boolean | null;
        } | null;
        contact?: { phoneNumber?: string | null; publish?: boolean | null } | null;
      } | null;
      shopInformation?: { information?: string | null } | null;
      paymentMethod?: {
        secretKey?: string | null;
        publicKey?: string | null;
        enableCard?: boolean | null;
        baseCurrency?: string | null;
      } | null;
      domain?: { name?: string | null; subDomain?: string | null; customDomain?: string | null } | null;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export const AcceptTermOfUseDocument = gql`
  mutation acceptTermOfUse {
    acceptTermOfUse {
      uuid
      uid
      displayName
      email
    }
  }
`;
export type AcceptTermOfUseMutationFn = Apollo.MutationFunction<
  AcceptTermOfUseMutation,
  AcceptTermOfUseMutationVariables
>;

/**
 * __useAcceptTermOfUseMutation__
 *
 * To run a mutation, you first call `useAcceptTermOfUseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermOfUseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermOfUseMutation, { data, loading, error }] = useAcceptTermOfUseMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptTermOfUseMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptTermOfUseMutation, AcceptTermOfUseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptTermOfUseMutation, AcceptTermOfUseMutationVariables>(
    AcceptTermOfUseDocument,
    options
  );
}
export type AcceptTermOfUseMutationHookResult = ReturnType<typeof useAcceptTermOfUseMutation>;
export type AcceptTermOfUseMutationResult = Apollo.MutationResult<AcceptTermOfUseMutation>;
export type AcceptTermOfUseMutationOptions = Apollo.BaseMutationOptions<
  AcceptTermOfUseMutation,
  AcceptTermOfUseMutationVariables
>;
export const AddOrganizationUserDocument = gql`
  mutation addOrganizationUser($input: AddOrganizationUserInput!) {
    addOrganizationUser(input: $input) {
      uid
      role
      displayName
      email
    }
  }
`;
export type AddOrganizationUserMutationFn = Apollo.MutationFunction<
  AddOrganizationUserMutation,
  AddOrganizationUserMutationVariables
>;

/**
 * __useAddOrganizationUserMutation__
 *
 * To run a mutation, you first call `useAddOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationUserMutation, { data, loading, error }] = useAddOrganizationUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrganizationUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AddOrganizationUserMutation, AddOrganizationUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOrganizationUserMutation, AddOrganizationUserMutationVariables>(
    AddOrganizationUserDocument,
    options
  );
}
export type AddOrganizationUserMutationHookResult = ReturnType<typeof useAddOrganizationUserMutation>;
export type AddOrganizationUserMutationResult = Apollo.MutationResult<AddOrganizationUserMutation>;
export type AddOrganizationUserMutationOptions = Apollo.BaseMutationOptions<
  AddOrganizationUserMutation,
  AddOrganizationUserMutationVariables
>;
export const AttachMyShopCollectionTokensDocument = gql`
  mutation attachMyShopCollectionTokens($input: AttachMyShopCollectionTokensInput!) {
    attachMyShopCollectionTokens(input: $input)
  }
`;
export type AttachMyShopCollectionTokensMutationFn = Apollo.MutationFunction<
  AttachMyShopCollectionTokensMutation,
  AttachMyShopCollectionTokensMutationVariables
>;

/**
 * __useAttachMyShopCollectionTokensMutation__
 *
 * To run a mutation, you first call `useAttachMyShopCollectionTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachMyShopCollectionTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachMyShopCollectionTokensMutation, { data, loading, error }] = useAttachMyShopCollectionTokensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttachMyShopCollectionTokensMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachMyShopCollectionTokensMutation,
    AttachMyShopCollectionTokensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttachMyShopCollectionTokensMutation, AttachMyShopCollectionTokensMutationVariables>(
    AttachMyShopCollectionTokensDocument,
    options
  );
}
export type AttachMyShopCollectionTokensMutationHookResult = ReturnType<typeof useAttachMyShopCollectionTokensMutation>;
export type AttachMyShopCollectionTokensMutationResult = Apollo.MutationResult<AttachMyShopCollectionTokensMutation>;
export type AttachMyShopCollectionTokensMutationOptions = Apollo.BaseMutationOptions<
  AttachMyShopCollectionTokensMutation,
  AttachMyShopCollectionTokensMutationVariables
>;
export const AttachMyShopCollectionsDocument = gql`
  mutation attachMyShopCollections($input: AttachMyShopCollectionInput!) {
    attachMyShopCollections(input: $input)
  }
`;
export type AttachMyShopCollectionsMutationFn = Apollo.MutationFunction<
  AttachMyShopCollectionsMutation,
  AttachMyShopCollectionsMutationVariables
>;

/**
 * __useAttachMyShopCollectionsMutation__
 *
 * To run a mutation, you first call `useAttachMyShopCollectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachMyShopCollectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachMyShopCollectionsMutation, { data, loading, error }] = useAttachMyShopCollectionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttachMyShopCollectionsMutation(
  baseOptions?: Apollo.MutationHookOptions<AttachMyShopCollectionsMutation, AttachMyShopCollectionsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttachMyShopCollectionsMutation, AttachMyShopCollectionsMutationVariables>(
    AttachMyShopCollectionsDocument,
    options
  );
}
export type AttachMyShopCollectionsMutationHookResult = ReturnType<typeof useAttachMyShopCollectionsMutation>;
export type AttachMyShopCollectionsMutationResult = Apollo.MutationResult<AttachMyShopCollectionsMutation>;
export type AttachMyShopCollectionsMutationOptions = Apollo.BaseMutationOptions<
  AttachMyShopCollectionsMutation,
  AttachMyShopCollectionsMutationVariables
>;
export const CreateCollectionImageDocument = gql`
  mutation createCollectionImage($input: CreateCollectionImageInput!) {
    createCollectionImage(input: $input) {
      uuid
      collectionUuid
      name
      image
      maxMint
      currentMint
      createdAt
    }
  }
`;
export type CreateCollectionImageMutationFn = Apollo.MutationFunction<
  CreateCollectionImageMutation,
  CreateCollectionImageMutationVariables
>;

/**
 * __useCreateCollectionImageMutation__
 *
 * To run a mutation, you first call `useCreateCollectionImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionImageMutation, { data, loading, error }] = useCreateCollectionImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollectionImageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCollectionImageMutation, CreateCollectionImageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCollectionImageMutation, CreateCollectionImageMutationVariables>(
    CreateCollectionImageDocument,
    options
  );
}
export type CreateCollectionImageMutationHookResult = ReturnType<typeof useCreateCollectionImageMutation>;
export type CreateCollectionImageMutationResult = Apollo.MutationResult<CreateCollectionImageMutation>;
export type CreateCollectionImageMutationOptions = Apollo.BaseMutationOptions<
  CreateCollectionImageMutation,
  CreateCollectionImageMutationVariables
>;
export const CreateCollectionWithOrgWalletDocument = gql`
  mutation createCollectionWithOrgWallet($input: CreateCollectionWithOrgWalletInput!) {
    createCollectionWithOrgWallet(input: $input) {
      uuid
      ownerUid
      network
      name
      symbol
      organizationUuid
      contractAddress
      ownerAddress
      createdAt
      desc
      descJa
      type
      images
    }
  }
`;
export type CreateCollectionWithOrgWalletMutationFn = Apollo.MutationFunction<
  CreateCollectionWithOrgWalletMutation,
  CreateCollectionWithOrgWalletMutationVariables
>;

/**
 * __useCreateCollectionWithOrgWalletMutation__
 *
 * To run a mutation, you first call `useCreateCollectionWithOrgWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionWithOrgWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionWithOrgWalletMutation, { data, loading, error }] = useCreateCollectionWithOrgWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollectionWithOrgWalletMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCollectionWithOrgWalletMutation,
    CreateCollectionWithOrgWalletMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCollectionWithOrgWalletMutation, CreateCollectionWithOrgWalletMutationVariables>(
    CreateCollectionWithOrgWalletDocument,
    options
  );
}
export type CreateCollectionWithOrgWalletMutationHookResult = ReturnType<
  typeof useCreateCollectionWithOrgWalletMutation
>;
export type CreateCollectionWithOrgWalletMutationResult = Apollo.MutationResult<CreateCollectionWithOrgWalletMutation>;
export type CreateCollectionWithOrgWalletMutationOptions = Apollo.BaseMutationOptions<
  CreateCollectionWithOrgWalletMutation,
  CreateCollectionWithOrgWalletMutationVariables
>;
export const CreateCollectionDocument = gql`
  mutation createCollection($input: CreateCollectionInput!) {
    createCollection(input: $input) {
      uuid
      ownerUid
      network
      name
      symbol
      organizationUuid
      contractAddress
      type
      images
      createdAt
    }
  }
`;
export type CreateCollectionMutationFn = Apollo.MutationFunction<
  CreateCollectionMutation,
  CreateCollectionMutationVariables
>;

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionMutation, { data, loading, error }] = useCreateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCollectionMutation, CreateCollectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCollectionMutation, CreateCollectionMutationVariables>(
    CreateCollectionDocument,
    options
  );
}
export type CreateCollectionMutationHookResult = ReturnType<typeof useCreateCollectionMutation>;
export type CreateCollectionMutationResult = Apollo.MutationResult<CreateCollectionMutation>;
export type CreateCollectionMutationOptions = Apollo.BaseMutationOptions<
  CreateCollectionMutation,
  CreateCollectionMutationVariables
>;
export const CreateOrganizationDocument = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      uuid
      name
      contactEmail
      role
    }
  }
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(
    CreateOrganizationDocument,
    options
  );
}
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const MintNftDocument = gql`
  mutation mintNft($input: MintNFTInput!) {
    mintNft(input: $input)
  }
`;
export type MintNftMutationFn = Apollo.MutationFunction<MintNftMutation, MintNftMutationVariables>;

/**
 * __useMintNftMutation__
 *
 * To run a mutation, you first call `useMintNftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMintNftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mintNftMutation, { data, loading, error }] = useMintNftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMintNftMutation(
  baseOptions?: Apollo.MutationHookOptions<MintNftMutation, MintNftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MintNftMutation, MintNftMutationVariables>(MintNftDocument, options);
}
export type MintNftMutationHookResult = ReturnType<typeof useMintNftMutation>;
export type MintNftMutationResult = Apollo.MutationResult<MintNftMutation>;
export type MintNftMutationOptions = Apollo.BaseMutationOptions<MintNftMutation, MintNftMutationVariables>;
export const CancelPublishShopDocument = gql`
  mutation cancelPublishShop($myShopUuid: String!) {
    cancelPublishShop(myShopUuid: $myShopUuid) {
      uuid
    }
  }
`;
export type CancelPublishShopMutationFn = Apollo.MutationFunction<
  CancelPublishShopMutation,
  CancelPublishShopMutationVariables
>;

/**
 * __useCancelPublishShopMutation__
 *
 * To run a mutation, you first call `useCancelPublishShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPublishShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPublishShopMutation, { data, loading, error }] = useCancelPublishShopMutation({
 *   variables: {
 *      myShopUuid: // value for 'myShopUuid'
 *   },
 * });
 */
export function useCancelPublishShopMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelPublishShopMutation, CancelPublishShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelPublishShopMutation, CancelPublishShopMutationVariables>(
    CancelPublishShopDocument,
    options
  );
}
export type CancelPublishShopMutationHookResult = ReturnType<typeof useCancelPublishShopMutation>;
export type CancelPublishShopMutationResult = Apollo.MutationResult<CancelPublishShopMutation>;
export type CancelPublishShopMutationOptions = Apollo.BaseMutationOptions<
  CancelPublishShopMutation,
  CancelPublishShopMutationVariables
>;
export const CreateMyShopDocument = gql`
  mutation createMyShop($input: CreateMyShopInput!) {
    createMyShop(input: $input) {
      uuid
      ownerUid
      createdAt
      updatedAt
      publish
      siteSetting {
        title
        description
        banner
        favicon
        ogp
        category
        theme {
          thema {
            template
          }
          style {
            font
            textColor
            backgroundColor
            buttonColor
          }
          navi {
            pageTitle
            description
          }
        }
      }
      operation {
        type
        name
        detailName {
          lastName
          firstName
          lastNameKana
          firstNameKana
          lastNameEn
          firstNameEn
        }
        shopName {
          name
          nameKana
        }
        address {
          postalCode
          prefecture
          address
          addressDisplay
        }
        contact {
          phoneNumber
          publish
        }
        dayOfBirth
        sex
      }
      shopInformation {
        information
      }
      paymentMethod {
        secretKey
        publicKey
        enableCard
        baseCurrency
      }
      domain {
        name
        subDomain
        customDomain
      }
    }
  }
`;
export type CreateMyShopMutationFn = Apollo.MutationFunction<CreateMyShopMutation, CreateMyShopMutationVariables>;

/**
 * __useCreateMyShopMutation__
 *
 * To run a mutation, you first call `useCreateMyShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyShopMutation, { data, loading, error }] = useCreateMyShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMyShopMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMyShopMutation, CreateMyShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMyShopMutation, CreateMyShopMutationVariables>(CreateMyShopDocument, options);
}
export type CreateMyShopMutationHookResult = ReturnType<typeof useCreateMyShopMutation>;
export type CreateMyShopMutationResult = Apollo.MutationResult<CreateMyShopMutation>;
export type CreateMyShopMutationOptions = Apollo.BaseMutationOptions<
  CreateMyShopMutation,
  CreateMyShopMutationVariables
>;
export const DuplicateMyShopDocument = gql`
  mutation duplicateMyShop($myShopUuid: String!) {
    duplicateMyShop(myShopUuid: $myShopUuid) {
      uuid
    }
  }
`;
export type DuplicateMyShopMutationFn = Apollo.MutationFunction<
  DuplicateMyShopMutation,
  DuplicateMyShopMutationVariables
>;

/**
 * __useDuplicateMyShopMutation__
 *
 * To run a mutation, you first call `useDuplicateMyShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateMyShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateMyShopMutation, { data, loading, error }] = useDuplicateMyShopMutation({
 *   variables: {
 *      myShopUuid: // value for 'myShopUuid'
 *   },
 * });
 */
export function useDuplicateMyShopMutation(
  baseOptions?: Apollo.MutationHookOptions<DuplicateMyShopMutation, DuplicateMyShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicateMyShopMutation, DuplicateMyShopMutationVariables>(
    DuplicateMyShopDocument,
    options
  );
}
export type DuplicateMyShopMutationHookResult = ReturnType<typeof useDuplicateMyShopMutation>;
export type DuplicateMyShopMutationResult = Apollo.MutationResult<DuplicateMyShopMutation>;
export type DuplicateMyShopMutationOptions = Apollo.BaseMutationOptions<
  DuplicateMyShopMutation,
  DuplicateMyShopMutationVariables
>;
export const PublishShopDocument = gql`
  mutation publishShop($myShopUuid: String!) {
    publishShop(myShopUuid: $myShopUuid) {
      uuid
    }
  }
`;
export type PublishShopMutationFn = Apollo.MutationFunction<PublishShopMutation, PublishShopMutationVariables>;

/**
 * __usePublishShopMutation__
 *
 * To run a mutation, you first call `usePublishShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishShopMutation, { data, loading, error }] = usePublishShopMutation({
 *   variables: {
 *      myShopUuid: // value for 'myShopUuid'
 *   },
 * });
 */
export function usePublishShopMutation(
  baseOptions?: Apollo.MutationHookOptions<PublishShopMutation, PublishShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PublishShopMutation, PublishShopMutationVariables>(PublishShopDocument, options);
}
export type PublishShopMutationHookResult = ReturnType<typeof usePublishShopMutation>;
export type PublishShopMutationResult = Apollo.MutationResult<PublishShopMutation>;
export type PublishShopMutationOptions = Apollo.BaseMutationOptions<PublishShopMutation, PublishShopMutationVariables>;
export const RemoveMyShopDocument = gql`
  mutation removeMyShop($myShopUuid: String!) {
    removeMyShop(myShopUuid: $myShopUuid)
  }
`;
export type RemoveMyShopMutationFn = Apollo.MutationFunction<RemoveMyShopMutation, RemoveMyShopMutationVariables>;

/**
 * __useRemoveMyShopMutation__
 *
 * To run a mutation, you first call `useRemoveMyShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMyShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMyShopMutation, { data, loading, error }] = useRemoveMyShopMutation({
 *   variables: {
 *      myShopUuid: // value for 'myShopUuid'
 *   },
 * });
 */
export function useRemoveMyShopMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveMyShopMutation, RemoveMyShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMyShopMutation, RemoveMyShopMutationVariables>(RemoveMyShopDocument, options);
}
export type RemoveMyShopMutationHookResult = ReturnType<typeof useRemoveMyShopMutation>;
export type RemoveMyShopMutationResult = Apollo.MutationResult<RemoveMyShopMutation>;
export type RemoveMyShopMutationOptions = Apollo.BaseMutationOptions<
  RemoveMyShopMutation,
  RemoveMyShopMutationVariables
>;
export const SetOpenShopDocument = gql`
  mutation setOpenShop($myShopUuid: String!, $isOpen: Boolean!) {
    setOpenShop(myShopUuid: $myShopUuid, isOpen: $isOpen) {
      uuid
      updatedAt
    }
  }
`;
export type SetOpenShopMutationFn = Apollo.MutationFunction<SetOpenShopMutation, SetOpenShopMutationVariables>;

/**
 * __useSetOpenShopMutation__
 *
 * To run a mutation, you first call `useSetOpenShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOpenShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOpenShopMutation, { data, loading, error }] = useSetOpenShopMutation({
 *   variables: {
 *      myShopUuid: // value for 'myShopUuid'
 *      isOpen: // value for 'isOpen'
 *   },
 * });
 */
export function useSetOpenShopMutation(
  baseOptions?: Apollo.MutationHookOptions<SetOpenShopMutation, SetOpenShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetOpenShopMutation, SetOpenShopMutationVariables>(SetOpenShopDocument, options);
}
export type SetOpenShopMutationHookResult = ReturnType<typeof useSetOpenShopMutation>;
export type SetOpenShopMutationResult = Apollo.MutationResult<SetOpenShopMutation>;
export type SetOpenShopMutationOptions = Apollo.BaseMutationOptions<SetOpenShopMutation, SetOpenShopMutationVariables>;
export const UpdateMyShopDocument = gql`
  mutation updateMyShop($input: UpdateMyShopInput!) {
    updateMyShop(input: $input) {
      uuid
      ownerUid
      createdAt
      updatedAt
      publish
      siteSetting {
        title
        description
        banner
        favicon
        ogp
        category
        theme {
          thema {
            template
          }
          style {
            font
            textColor
            backgroundColor
            buttonColor
          }
          navi {
            pageTitle
            description
          }
        }
      }
      operation {
        type
        name
        detailName {
          lastName
          firstName
          lastNameKana
          firstNameKana
          lastNameEn
          firstNameEn
        }
        shopName {
          name
          nameKana
        }
        address {
          postalCode
          prefecture
          address
          addressDisplay
        }
        contact {
          phoneNumber
          publish
        }
        dayOfBirth
        sex
      }
      shopInformation {
        information
      }
      paymentMethod {
        secretKey
        publicKey
        enableCard
        baseCurrency
      }
      domain {
        name
        subDomain
        customDomain
      }
    }
  }
`;
export type UpdateMyShopMutationFn = Apollo.MutationFunction<UpdateMyShopMutation, UpdateMyShopMutationVariables>;

/**
 * __useUpdateMyShopMutation__
 *
 * To run a mutation, you first call `useUpdateMyShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyShopMutation, { data, loading, error }] = useUpdateMyShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyShopMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMyShopMutation, UpdateMyShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMyShopMutation, UpdateMyShopMutationVariables>(UpdateMyShopDocument, options);
}
export type UpdateMyShopMutationHookResult = ReturnType<typeof useUpdateMyShopMutation>;
export type UpdateMyShopMutationResult = Apollo.MutationResult<UpdateMyShopMutation>;
export type UpdateMyShopMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyShopMutation,
  UpdateMyShopMutationVariables
>;
export const RemoveApproveTransferDocument = gql`
  mutation removeApproveTransfer($input: RemoveApproveTransferInput!) {
    removeApproveTransfer(input: $input) {
      success
      errorCode
      errorMessage
    }
  }
`;
export type RemoveApproveTransferMutationFn = Apollo.MutationFunction<
  RemoveApproveTransferMutation,
  RemoveApproveTransferMutationVariables
>;

/**
 * __useRemoveApproveTransferMutation__
 *
 * To run a mutation, you first call `useRemoveApproveTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApproveTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApproveTransferMutation, { data, loading, error }] = useRemoveApproveTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveApproveTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveApproveTransferMutation, RemoveApproveTransferMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveApproveTransferMutation, RemoveApproveTransferMutationVariables>(
    RemoveApproveTransferDocument,
    options
  );
}
export type RemoveApproveTransferMutationHookResult = ReturnType<typeof useRemoveApproveTransferMutation>;
export type RemoveApproveTransferMutationResult = Apollo.MutationResult<RemoveApproveTransferMutation>;
export type RemoveApproveTransferMutationOptions = Apollo.BaseMutationOptions<
  RemoveApproveTransferMutation,
  RemoveApproveTransferMutationVariables
>;
export const RemoveCollectionImageDocument = gql`
  mutation removeCollectionImage($uuid: String!) {
    removeCollectionImage(uuid: $uuid)
  }
`;
export type RemoveCollectionImageMutationFn = Apollo.MutationFunction<
  RemoveCollectionImageMutation,
  RemoveCollectionImageMutationVariables
>;

/**
 * __useRemoveCollectionImageMutation__
 *
 * To run a mutation, you first call `useRemoveCollectionImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCollectionImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCollectionImageMutation, { data, loading, error }] = useRemoveCollectionImageMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveCollectionImageMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCollectionImageMutation, RemoveCollectionImageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveCollectionImageMutation, RemoveCollectionImageMutationVariables>(
    RemoveCollectionImageDocument,
    options
  );
}
export type RemoveCollectionImageMutationHookResult = ReturnType<typeof useRemoveCollectionImageMutation>;
export type RemoveCollectionImageMutationResult = Apollo.MutationResult<RemoveCollectionImageMutation>;
export type RemoveCollectionImageMutationOptions = Apollo.BaseMutationOptions<
  RemoveCollectionImageMutation,
  RemoveCollectionImageMutationVariables
>;
export const RemoveCollectionDocument = gql`
  mutation removeCollection($collectionUuid: String!) {
    removeCollection(collectionUuid: $collectionUuid)
  }
`;
export type RemoveCollectionMutationFn = Apollo.MutationFunction<
  RemoveCollectionMutation,
  RemoveCollectionMutationVariables
>;

/**
 * __useRemoveCollectionMutation__
 *
 * To run a mutation, you first call `useRemoveCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCollectionMutation, { data, loading, error }] = useRemoveCollectionMutation({
 *   variables: {
 *      collectionUuid: // value for 'collectionUuid'
 *   },
 * });
 */
export function useRemoveCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCollectionMutation, RemoveCollectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveCollectionMutation, RemoveCollectionMutationVariables>(
    RemoveCollectionDocument,
    options
  );
}
export type RemoveCollectionMutationHookResult = ReturnType<typeof useRemoveCollectionMutation>;
export type RemoveCollectionMutationResult = Apollo.MutationResult<RemoveCollectionMutation>;
export type RemoveCollectionMutationOptions = Apollo.BaseMutationOptions<
  RemoveCollectionMutation,
  RemoveCollectionMutationVariables
>;
export const RemoveOrganizationUserDocument = gql`
  mutation removeOrganizationUser($input: RemoveOrganizationUserInput!) {
    removeOrganizationUser(input: $input)
  }
`;
export type RemoveOrganizationUserMutationFn = Apollo.MutationFunction<
  RemoveOrganizationUserMutation,
  RemoveOrganizationUserMutationVariables
>;

/**
 * __useRemoveOrganizationUserMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationUserMutation, { data, loading, error }] = useRemoveOrganizationUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOrganizationUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveOrganizationUserMutation, RemoveOrganizationUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveOrganizationUserMutation, RemoveOrganizationUserMutationVariables>(
    RemoveOrganizationUserDocument,
    options
  );
}
export type RemoveOrganizationUserMutationHookResult = ReturnType<typeof useRemoveOrganizationUserMutation>;
export type RemoveOrganizationUserMutationResult = Apollo.MutationResult<RemoveOrganizationUserMutation>;
export type RemoveOrganizationUserMutationOptions = Apollo.BaseMutationOptions<
  RemoveOrganizationUserMutation,
  RemoveOrganizationUserMutationVariables
>;
export const RemoveOrganizationIpfsStorageDocument = gql`
  mutation removeOrganizationIpfsStorage($input: RemoveOrganizationIpfsStorageInput!) {
    removeOrganizationIpfsStorage(input: $input)
  }
`;
export type RemoveOrganizationIpfsStorageMutationFn = Apollo.MutationFunction<
  RemoveOrganizationIpfsStorageMutation,
  RemoveOrganizationIpfsStorageMutationVariables
>;

/**
 * __useRemoveOrganizationIpfsStorageMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationIpfsStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationIpfsStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationIpfsStorageMutation, { data, loading, error }] = useRemoveOrganizationIpfsStorageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOrganizationIpfsStorageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOrganizationIpfsStorageMutation,
    RemoveOrganizationIpfsStorageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveOrganizationIpfsStorageMutation, RemoveOrganizationIpfsStorageMutationVariables>(
    RemoveOrganizationIpfsStorageDocument,
    options
  );
}
export type RemoveOrganizationIpfsStorageMutationHookResult = ReturnType<
  typeof useRemoveOrganizationIpfsStorageMutation
>;
export type RemoveOrganizationIpfsStorageMutationResult = Apollo.MutationResult<RemoveOrganizationIpfsStorageMutation>;
export type RemoveOrganizationIpfsStorageMutationOptions = Apollo.BaseMutationOptions<
  RemoveOrganizationIpfsStorageMutation,
  RemoveOrganizationIpfsStorageMutationVariables
>;
export const RemoveMyShopCollectionsDocument = gql`
  mutation removeMyShopCollections($input: RemoveMyShopCollectionInput!) {
    removeMyShopCollections(input: $input)
  }
`;
export type RemoveMyShopCollectionsMutationFn = Apollo.MutationFunction<
  RemoveMyShopCollectionsMutation,
  RemoveMyShopCollectionsMutationVariables
>;

/**
 * __useRemoveMyShopCollectionsMutation__
 *
 * To run a mutation, you first call `useRemoveMyShopCollectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMyShopCollectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMyShopCollectionsMutation, { data, loading, error }] = useRemoveMyShopCollectionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMyShopCollectionsMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveMyShopCollectionsMutation, RemoveMyShopCollectionsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMyShopCollectionsMutation, RemoveMyShopCollectionsMutationVariables>(
    RemoveMyShopCollectionsDocument,
    options
  );
}
export type RemoveMyShopCollectionsMutationHookResult = ReturnType<typeof useRemoveMyShopCollectionsMutation>;
export type RemoveMyShopCollectionsMutationResult = Apollo.MutationResult<RemoveMyShopCollectionsMutation>;
export type RemoveMyShopCollectionsMutationOptions = Apollo.BaseMutationOptions<
  RemoveMyShopCollectionsMutation,
  RemoveMyShopCollectionsMutationVariables
>;
export const ResendEmailVerifyMemberDocument = gql`
  mutation resendEmailVerifyMember($uuid: String!) {
    resendEmailVerifyMember(uuid: $uuid) {
      uuid
    }
  }
`;
export type ResendEmailVerifyMemberMutationFn = Apollo.MutationFunction<
  ResendEmailVerifyMemberMutation,
  ResendEmailVerifyMemberMutationVariables
>;

/**
 * __useResendEmailVerifyMemberMutation__
 *
 * To run a mutation, you first call `useResendEmailVerifyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailVerifyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailVerifyMemberMutation, { data, loading, error }] = useResendEmailVerifyMemberMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useResendEmailVerifyMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendEmailVerifyMemberMutation, ResendEmailVerifyMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendEmailVerifyMemberMutation, ResendEmailVerifyMemberMutationVariables>(
    ResendEmailVerifyMemberDocument,
    options
  );
}
export type ResendEmailVerifyMemberMutationHookResult = ReturnType<typeof useResendEmailVerifyMemberMutation>;
export type ResendEmailVerifyMemberMutationResult = Apollo.MutationResult<ResendEmailVerifyMemberMutation>;
export type ResendEmailVerifyMemberMutationOptions = Apollo.BaseMutationOptions<
  ResendEmailVerifyMemberMutation,
  ResendEmailVerifyMemberMutationVariables
>;
export const SetMyShopCollectionImageDocument = gql`
  mutation setMyShopCollectionImage($input: CreateMyShopCollectionImageInput!) {
    setMyShopCollectionImage(input: $input) {
      uuid
      name
      status
      collectionImageUuid
      myShopCollectionUuid
      price
      createdAt
    }
  }
`;
export type SetMyShopCollectionImageMutationFn = Apollo.MutationFunction<
  SetMyShopCollectionImageMutation,
  SetMyShopCollectionImageMutationVariables
>;

/**
 * __useSetMyShopCollectionImageMutation__
 *
 * To run a mutation, you first call `useSetMyShopCollectionImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMyShopCollectionImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMyShopCollectionImageMutation, { data, loading, error }] = useSetMyShopCollectionImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMyShopCollectionImageMutation(
  baseOptions?: Apollo.MutationHookOptions<SetMyShopCollectionImageMutation, SetMyShopCollectionImageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetMyShopCollectionImageMutation, SetMyShopCollectionImageMutationVariables>(
    SetMyShopCollectionImageDocument,
    options
  );
}
export type SetMyShopCollectionImageMutationHookResult = ReturnType<typeof useSetMyShopCollectionImageMutation>;
export type SetMyShopCollectionImageMutationResult = Apollo.MutationResult<SetMyShopCollectionImageMutation>;
export type SetMyShopCollectionImageMutationOptions = Apollo.BaseMutationOptions<
  SetMyShopCollectionImageMutation,
  SetMyShopCollectionImageMutationVariables
>;
export const SetMyShopCollectionTokenDocument = gql`
  mutation setMyShopCollectionToken($input: CreateMyShopCollectionTokenInput!) {
    setMyShopCollectionToken(input: $input) {
      uuid
      myShopCollectionUuid
      tokenId
      tokenName
      tokenDesc
      tokenMintTime
      status
      name
      nameJa
      price
      order
      createdAt
    }
  }
`;
export type SetMyShopCollectionTokenMutationFn = Apollo.MutationFunction<
  SetMyShopCollectionTokenMutation,
  SetMyShopCollectionTokenMutationVariables
>;

/**
 * __useSetMyShopCollectionTokenMutation__
 *
 * To run a mutation, you first call `useSetMyShopCollectionTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMyShopCollectionTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMyShopCollectionTokenMutation, { data, loading, error }] = useSetMyShopCollectionTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMyShopCollectionTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<SetMyShopCollectionTokenMutation, SetMyShopCollectionTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetMyShopCollectionTokenMutation, SetMyShopCollectionTokenMutationVariables>(
    SetMyShopCollectionTokenDocument,
    options
  );
}
export type SetMyShopCollectionTokenMutationHookResult = ReturnType<typeof useSetMyShopCollectionTokenMutation>;
export type SetMyShopCollectionTokenMutationResult = Apollo.MutationResult<SetMyShopCollectionTokenMutation>;
export type SetMyShopCollectionTokenMutationOptions = Apollo.BaseMutationOptions<
  SetMyShopCollectionTokenMutation,
  SetMyShopCollectionTokenMutationVariables
>;
export const UnAttachMyShopCollectionTokensDocument = gql`
  mutation unAttachMyShopCollectionTokens($input: UnAttachMyShopCollectionTokensInput!) {
    unAttachMyShopCollectionTokens(input: $input)
  }
`;
export type UnAttachMyShopCollectionTokensMutationFn = Apollo.MutationFunction<
  UnAttachMyShopCollectionTokensMutation,
  UnAttachMyShopCollectionTokensMutationVariables
>;

/**
 * __useUnAttachMyShopCollectionTokensMutation__
 *
 * To run a mutation, you first call `useUnAttachMyShopCollectionTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnAttachMyShopCollectionTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unAttachMyShopCollectionTokensMutation, { data, loading, error }] = useUnAttachMyShopCollectionTokensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnAttachMyShopCollectionTokensMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnAttachMyShopCollectionTokensMutation,
    UnAttachMyShopCollectionTokensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnAttachMyShopCollectionTokensMutation, UnAttachMyShopCollectionTokensMutationVariables>(
    UnAttachMyShopCollectionTokensDocument,
    options
  );
}
export type UnAttachMyShopCollectionTokensMutationHookResult = ReturnType<
  typeof useUnAttachMyShopCollectionTokensMutation
>;
export type UnAttachMyShopCollectionTokensMutationResult =
  Apollo.MutationResult<UnAttachMyShopCollectionTokensMutation>;
export type UnAttachMyShopCollectionTokensMutationOptions = Apollo.BaseMutationOptions<
  UnAttachMyShopCollectionTokensMutation,
  UnAttachMyShopCollectionTokensMutationVariables
>;
export const UnAttachMyShopCollectionsDocument = gql`
  mutation unAttachMyShopCollections($input: UnAttachMyShopCollectionInput!) {
    unAttachMyShopCollections(input: $input)
  }
`;
export type UnAttachMyShopCollectionsMutationFn = Apollo.MutationFunction<
  UnAttachMyShopCollectionsMutation,
  UnAttachMyShopCollectionsMutationVariables
>;

/**
 * __useUnAttachMyShopCollectionsMutation__
 *
 * To run a mutation, you first call `useUnAttachMyShopCollectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnAttachMyShopCollectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unAttachMyShopCollectionsMutation, { data, loading, error }] = useUnAttachMyShopCollectionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnAttachMyShopCollectionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnAttachMyShopCollectionsMutation,
    UnAttachMyShopCollectionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnAttachMyShopCollectionsMutation, UnAttachMyShopCollectionsMutationVariables>(
    UnAttachMyShopCollectionsDocument,
    options
  );
}
export type UnAttachMyShopCollectionsMutationHookResult = ReturnType<typeof useUnAttachMyShopCollectionsMutation>;
export type UnAttachMyShopCollectionsMutationResult = Apollo.MutationResult<UnAttachMyShopCollectionsMutation>;
export type UnAttachMyShopCollectionsMutationOptions = Apollo.BaseMutationOptions<
  UnAttachMyShopCollectionsMutation,
  UnAttachMyShopCollectionsMutationVariables
>;
export const UpdateCollectionImageDocument = gql`
  mutation updateCollectionImage($input: UpdatedCollectionImageInput!) {
    updateCollectionImage(input: $input) {
      uuid
      collectionUuid
      name
      image
      maxMint
      currentMint
      createdAt
    }
  }
`;
export type UpdateCollectionImageMutationFn = Apollo.MutationFunction<
  UpdateCollectionImageMutation,
  UpdateCollectionImageMutationVariables
>;

/**
 * __useUpdateCollectionImageMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionImageMutation, { data, loading, error }] = useUpdateCollectionImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionImageMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCollectionImageMutation, UpdateCollectionImageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCollectionImageMutation, UpdateCollectionImageMutationVariables>(
    UpdateCollectionImageDocument,
    options
  );
}
export type UpdateCollectionImageMutationHookResult = ReturnType<typeof useUpdateCollectionImageMutation>;
export type UpdateCollectionImageMutationResult = Apollo.MutationResult<UpdateCollectionImageMutation>;
export type UpdateCollectionImageMutationOptions = Apollo.BaseMutationOptions<
  UpdateCollectionImageMutation,
  UpdateCollectionImageMutationVariables
>;
export const UpdateCollectionDocument = gql`
  mutation updateCollection($input: UpdateCollectionInput!) {
    updateCollection(input: $input) {
      uuid
      ownerUid
      network
      name
      symbol
      organizationUuid
      contractAddress
      type
      images
      createdAt
    }
  }
`;
export type UpdateCollectionMutationFn = Apollo.MutationFunction<
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables
>;

/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMutation, { data, loading, error }] = useUpdateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCollectionMutation, UpdateCollectionMutationVariables>(
    UpdateCollectionDocument,
    options
  );
}
export type UpdateCollectionMutationHookResult = ReturnType<typeof useUpdateCollectionMutation>;
export type UpdateCollectionMutationResult = Apollo.MutationResult<UpdateCollectionMutation>;
export type UpdateCollectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables
>;
export const UpdateMemberTokenDocument = gql`
  mutation updateMemberToken($input: UpdateMemberTokenInput!) {
    updateMemberToken(input: $input) {
      uuid
      type
      status
      wallet
      tokenId
      shopUuid
      ownerUid
      createdAt
      collectionUuid
    }
  }
`;
export type UpdateMemberTokenMutationFn = Apollo.MutationFunction<
  UpdateMemberTokenMutation,
  UpdateMemberTokenMutationVariables
>;

/**
 * __useUpdateMemberTokenMutation__
 *
 * To run a mutation, you first call `useUpdateMemberTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberTokenMutation, { data, loading, error }] = useUpdateMemberTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMemberTokenMutation, UpdateMemberTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemberTokenMutation, UpdateMemberTokenMutationVariables>(
    UpdateMemberTokenDocument,
    options
  );
}
export type UpdateMemberTokenMutationHookResult = ReturnType<typeof useUpdateMemberTokenMutation>;
export type UpdateMemberTokenMutationResult = Apollo.MutationResult<UpdateMemberTokenMutation>;
export type UpdateMemberTokenMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberTokenMutation,
  UpdateMemberTokenMutationVariables
>;
export const UpdateMyShopCollectionDocument = gql`
  mutation updateMyShopCollection($input: UpdateMyShopCollectionInput!) {
    updateMyShopCollection(input: $input) {
      uuid
      type
      name
      status
      shopUuid
      createdAt
      updatedAt
      collection {
        uuid
        ownerUid
        network
        name
        symbol
        ownerAddress
        organizationUuid
        contractAddress
        createdAt
        desc
        type
        images
      }
    }
  }
`;
export type UpdateMyShopCollectionMutationFn = Apollo.MutationFunction<
  UpdateMyShopCollectionMutation,
  UpdateMyShopCollectionMutationVariables
>;

/**
 * __useUpdateMyShopCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateMyShopCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyShopCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyShopCollectionMutation, { data, loading, error }] = useUpdateMyShopCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyShopCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMyShopCollectionMutation, UpdateMyShopCollectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMyShopCollectionMutation, UpdateMyShopCollectionMutationVariables>(
    UpdateMyShopCollectionDocument,
    options
  );
}
export type UpdateMyShopCollectionMutationHookResult = ReturnType<typeof useUpdateMyShopCollectionMutation>;
export type UpdateMyShopCollectionMutationResult = Apollo.MutationResult<UpdateMyShopCollectionMutation>;
export type UpdateMyShopCollectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyShopCollectionMutation,
  UpdateMyShopCollectionMutationVariables
>;
export const UpdateOrganizationUserDocument = gql`
  mutation updateOrganizationUser($input: UpdateOrganizationUserInput!) {
    updateOrganizationUser(input: $input) {
      uid
      role
      email
      displayName
    }
  }
`;
export type UpdateOrganizationUserMutationFn = Apollo.MutationFunction<
  UpdateOrganizationUserMutation,
  UpdateOrganizationUserMutationVariables
>;

/**
 * __useUpdateOrganizationUserMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationUserMutation, { data, loading, error }] = useUpdateOrganizationUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>(
    UpdateOrganizationUserDocument,
    options
  );
}
export type UpdateOrganizationUserMutationHookResult = ReturnType<typeof useUpdateOrganizationUserMutation>;
export type UpdateOrganizationUserMutationResult = Apollo.MutationResult<UpdateOrganizationUserMutation>;
export type UpdateOrganizationUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationUserMutation,
  UpdateOrganizationUserMutationVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      uuid
      name
      contactEmail
      paymentSetting {
        publicKey
        secretKey
      }
    }
  }
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(
    UpdateOrganizationDocument,
    options
  );
}
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const UploadFileDocument = gql`
  mutation uploadFile($files: [Upload!]!) {
    uploadFile(files: $files)
  }
`;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
}
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const UploadNftWithApiKeyDocument = gql`
  mutation uploadNFTWithApiKey($input: UploadNFTWithApiKeyInput!) {
    uploadNFTWithApiKey(input: $input) {
      name
      description
      metadataUrl
      metadataContent {
        name
        description
        image
        animation_url
      }
    }
  }
`;
export type UploadNftWithApiKeyMutationFn = Apollo.MutationFunction<
  UploadNftWithApiKeyMutation,
  UploadNftWithApiKeyMutationVariables
>;

/**
 * __useUploadNftWithApiKeyMutation__
 *
 * To run a mutation, you first call `useUploadNftWithApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadNftWithApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadNftWithApiKeyMutation, { data, loading, error }] = useUploadNftWithApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadNftWithApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadNftWithApiKeyMutation, UploadNftWithApiKeyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadNftWithApiKeyMutation, UploadNftWithApiKeyMutationVariables>(
    UploadNftWithApiKeyDocument,
    options
  );
}
export type UploadNftWithApiKeyMutationHookResult = ReturnType<typeof useUploadNftWithApiKeyMutation>;
export type UploadNftWithApiKeyMutationResult = Apollo.MutationResult<UploadNftWithApiKeyMutation>;
export type UploadNftWithApiKeyMutationOptions = Apollo.BaseMutationOptions<
  UploadNftWithApiKeyMutation,
  UploadNftWithApiKeyMutationVariables
>;
export const UploadNftDocument = gql`
  mutation uploadNFT($input: UploadNFTInput!) {
    uploadNFT(input: $input) {
      name
      description
      metadataUrl
      metadataContent {
        name
        description
        image
        animation_url
      }
    }
  }
`;
export type UploadNftMutationFn = Apollo.MutationFunction<UploadNftMutation, UploadNftMutationVariables>;

/**
 * __useUploadNftMutation__
 *
 * To run a mutation, you first call `useUploadNftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadNftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadNftMutation, { data, loading, error }] = useUploadNftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadNftMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadNftMutation, UploadNftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadNftMutation, UploadNftMutationVariables>(UploadNftDocument, options);
}
export type UploadNftMutationHookResult = ReturnType<typeof useUploadNftMutation>;
export type UploadNftMutationResult = Apollo.MutationResult<UploadNftMutation>;
export type UploadNftMutationOptions = Apollo.BaseMutationOptions<UploadNftMutation, UploadNftMutationVariables>;
export const WithdrawDocument = gql`
  mutation withdraw($input: WithdrawInput!) {
    withdraw(input: $input)
  }
`;
export type WithdrawMutationFn = Apollo.MutationFunction<WithdrawMutation, WithdrawMutationVariables>;

/**
 * __useWithdrawMutation__
 *
 * To run a mutation, you first call `useWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawMutation, { data, loading, error }] = useWithdrawMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawMutation(
  baseOptions?: Apollo.MutationHookOptions<WithdrawMutation, WithdrawMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WithdrawMutation, WithdrawMutationVariables>(WithdrawDocument, options);
}
export type WithdrawMutationHookResult = ReturnType<typeof useWithdrawMutation>;
export type WithdrawMutationResult = Apollo.MutationResult<WithdrawMutation>;
export type WithdrawMutationOptions = Apollo.BaseMutationOptions<WithdrawMutation, WithdrawMutationVariables>;
export const GetCollectionDocument = gql`
  query getCollection($collectionUuid: String!) {
    getCollection(collectionUuid: $collectionUuid) {
      uuid
      ownerUid
      network
      name
      nameJa
      symbol
      organizationUuid
      contractAddress
      ownerAddress
      createdAt
      desc
      descJa
      type
      images
    }
  }
`;

/**
 * __useGetCollectionQuery__
 *
 * To run a query within a React component, call `useGetCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionQuery({
 *   variables: {
 *      collectionUuid: // value for 'collectionUuid'
 *   },
 * });
 */
export function useGetCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<GetCollectionQuery, GetCollectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCollectionQuery, GetCollectionQueryVariables>(GetCollectionDocument, options);
}
export function useGetCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionQuery, GetCollectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCollectionQuery, GetCollectionQueryVariables>(GetCollectionDocument, options);
}
export type GetCollectionQueryHookResult = ReturnType<typeof useGetCollectionQuery>;
export type GetCollectionLazyQueryHookResult = ReturnType<typeof useGetCollectionLazyQuery>;
export type GetCollectionQueryResult = Apollo.QueryResult<GetCollectionQuery, GetCollectionQueryVariables>;
export const GetMeDocument = gql`
  query getMe {
    getMe {
      uuid
      uid
      displayName
      email
      createdAt
      organizations {
        uuid
        name
        contactEmail
        role
        status
        createdAt
        updatedAt
        masterWalletAddress
        paymentSetting {
          requiredAcceptTerms
          termsUrl
          policyUrl
          secretKey
          publicKey
        }
        currencySetting {
          baseCurrency
        }
        ipfsStorageApiKeys {
          storage
          apiKey
        }
        operation {
          type
          name
          detailName {
            lastName
            firstName
            lastNameKana
            firstNameKana
            lastNameEn
            firstNameEn
          }
          shopName {
            name
            nameKana
          }
          address {
            postalCode
            prefecture
            address
            addressDisplay
          }
          contact {
            phoneNumber
            publish
          }
          dayOfBirth
          sex
        }
      }
    }
  }
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetMyShopCollectionByCollectionIdDocument = gql`
  query getMyShopCollectionByCollectionId($input: MyShopCollectionFilter!) {
    getMyShopCollectionByCollectionId(input: $input) {
      type
      uuid
      name
      desc
      nameJa
      descJa
      sortBy
      status
      orderBy
      shopUuid
      createdAt
      updatedAt
      collectionUuid
      collectionImages {
        price
        status
      }
      collection {
        uuid
        ownerUid
        network
        name
        nameJa
        symbol
        ownerAddress
        organizationUuid
        contractAddress
        createdAt
        desc
        type
        images
      }
    }
  }
`;

/**
 * __useGetMyShopCollectionByCollectionIdQuery__
 *
 * To run a query within a React component, call `useGetMyShopCollectionByCollectionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyShopCollectionByCollectionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyShopCollectionByCollectionIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMyShopCollectionByCollectionIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyShopCollectionByCollectionIdQuery,
    GetMyShopCollectionByCollectionIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyShopCollectionByCollectionIdQuery, GetMyShopCollectionByCollectionIdQueryVariables>(
    GetMyShopCollectionByCollectionIdDocument,
    options
  );
}
export function useGetMyShopCollectionByCollectionIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyShopCollectionByCollectionIdQuery,
    GetMyShopCollectionByCollectionIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyShopCollectionByCollectionIdQuery, GetMyShopCollectionByCollectionIdQueryVariables>(
    GetMyShopCollectionByCollectionIdDocument,
    options
  );
}
export type GetMyShopCollectionByCollectionIdQueryHookResult = ReturnType<
  typeof useGetMyShopCollectionByCollectionIdQuery
>;
export type GetMyShopCollectionByCollectionIdLazyQueryHookResult = ReturnType<
  typeof useGetMyShopCollectionByCollectionIdLazyQuery
>;
export type GetMyShopCollectionByCollectionIdQueryResult = Apollo.QueryResult<
  GetMyShopCollectionByCollectionIdQuery,
  GetMyShopCollectionByCollectionIdQueryVariables
>;
export const GetMyShopCollectionDocument = gql`
  query getMyShopCollection($uuid: String!) {
    getMyShopCollection(uuid: $uuid) {
      uuid
      type
      name
      desc
      nameJa
      descJa
      status
      shopUuid
      createdAt
      updatedAt
      collectionUuid
      collectionImages {
        uuid
        name
        image
        nameJa
        maxMint
        createdAt
        currentMint
        collectionUuid
        collectionImageUuid
        myShopCollectionUuid
        myShopCollectionImageUuid
        myShopCollectionImageName
        status
        price
      }
      collection {
        uuid
        ownerUid
        network
        name
        nameJa
        symbol
        ownerAddress
        organizationUuid
        contractAddress
        createdAt
        desc
        type
        images
      }
    }
  }
`;

/**
 * __useGetMyShopCollectionQuery__
 *
 * To run a query within a React component, call `useGetMyShopCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyShopCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyShopCollectionQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetMyShopCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<GetMyShopCollectionQuery, GetMyShopCollectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyShopCollectionQuery, GetMyShopCollectionQueryVariables>(
    GetMyShopCollectionDocument,
    options
  );
}
export function useGetMyShopCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyShopCollectionQuery, GetMyShopCollectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyShopCollectionQuery, GetMyShopCollectionQueryVariables>(
    GetMyShopCollectionDocument,
    options
  );
}
export type GetMyShopCollectionQueryHookResult = ReturnType<typeof useGetMyShopCollectionQuery>;
export type GetMyShopCollectionLazyQueryHookResult = ReturnType<typeof useGetMyShopCollectionLazyQuery>;
export type GetMyShopCollectionQueryResult = Apollo.QueryResult<
  GetMyShopCollectionQuery,
  GetMyShopCollectionQueryVariables
>;
export const ListCollectionIdsInShopDocument = gql`
  query listCollectionIdsInShop(
    $page: Int
    $limit: Int
    $sortBy: MyShopCollectionQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: ListMyShopCollectionsFilter!
  ) {
    listMyShopCollections(
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      searchText: $searchText
      where: $where
    ) {
      items {
        uuid
        type
        collectionUuid
      }
    }
  }
`;

/**
 * __useListCollectionIdsInShopQuery__
 *
 * To run a query within a React component, call `useListCollectionIdsInShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCollectionIdsInShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCollectionIdsInShopQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListCollectionIdsInShopQuery(
  baseOptions: Apollo.QueryHookOptions<ListCollectionIdsInShopQuery, ListCollectionIdsInShopQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListCollectionIdsInShopQuery, ListCollectionIdsInShopQueryVariables>(
    ListCollectionIdsInShopDocument,
    options
  );
}
export function useListCollectionIdsInShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListCollectionIdsInShopQuery, ListCollectionIdsInShopQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListCollectionIdsInShopQuery, ListCollectionIdsInShopQueryVariables>(
    ListCollectionIdsInShopDocument,
    options
  );
}
export type ListCollectionIdsInShopQueryHookResult = ReturnType<typeof useListCollectionIdsInShopQuery>;
export type ListCollectionIdsInShopLazyQueryHookResult = ReturnType<typeof useListCollectionIdsInShopLazyQuery>;
export type ListCollectionIdsInShopQueryResult = Apollo.QueryResult<
  ListCollectionIdsInShopQuery,
  ListCollectionIdsInShopQueryVariables
>;
export const ListCollectionImagesDocument = gql`
  query listCollectionImages(
    $page: Int
    $limit: Int
    $sortBy: CollectionImagesQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: ListCollectionImagesFilter
  ) {
    listCollectionImages(
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      searchText: $searchText
      where: $where
    ) {
      items {
        uuid
        collectionUuid
        name
        image
        nameJa
        maxMint
        currentMint
        createdAt
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListCollectionImagesQuery__
 *
 * To run a query within a React component, call `useListCollectionImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCollectionImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCollectionImagesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListCollectionImagesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListCollectionImagesQuery, ListCollectionImagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListCollectionImagesQuery, ListCollectionImagesQueryVariables>(
    ListCollectionImagesDocument,
    options
  );
}
export function useListCollectionImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListCollectionImagesQuery, ListCollectionImagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListCollectionImagesQuery, ListCollectionImagesQueryVariables>(
    ListCollectionImagesDocument,
    options
  );
}
export type ListCollectionImagesQueryHookResult = ReturnType<typeof useListCollectionImagesQuery>;
export type ListCollectionImagesLazyQueryHookResult = ReturnType<typeof useListCollectionImagesLazyQuery>;
export type ListCollectionImagesQueryResult = Apollo.QueryResult<
  ListCollectionImagesQuery,
  ListCollectionImagesQueryVariables
>;
export const ListCollectionsDocument = gql`
  query listCollections(
    $page: Int
    $limit: Int
    $sortBy: CollectionQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: ListCollectionsFilter
  ) {
    listCollections(
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      searchText: $searchText
      where: $where
    ) {
      items {
        uuid
        ownerUid
        network
        name
        symbol
        organizationUuid
        contractAddress
        ownerAddress
        createdAt
        desc
        descJa
        type
        images
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListCollectionsQuery__
 *
 * To run a query within a React component, call `useListCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCollectionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListCollectionsQuery, ListCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListCollectionsQuery, ListCollectionsQueryVariables>(ListCollectionsDocument, options);
}
export function useListCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListCollectionsQuery, ListCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListCollectionsQuery, ListCollectionsQueryVariables>(ListCollectionsDocument, options);
}
export type ListCollectionsQueryHookResult = ReturnType<typeof useListCollectionsQuery>;
export type ListCollectionsLazyQueryHookResult = ReturnType<typeof useListCollectionsLazyQuery>;
export type ListCollectionsQueryResult = Apollo.QueryResult<ListCollectionsQuery, ListCollectionsQueryVariables>;
export const ListOrganizationUsersDocument = gql`
  query listOrganizationUsers {
    listOrganizationUsers {
      uid
      role
      displayName
      email
    }
  }
`;

/**
 * __useListOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useListOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListOrganizationUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>(
    ListOrganizationUsersDocument,
    options
  );
}
export function useListOrganizationUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>(
    ListOrganizationUsersDocument,
    options
  );
}
export type ListOrganizationUsersQueryHookResult = ReturnType<typeof useListOrganizationUsersQuery>;
export type ListOrganizationUsersLazyQueryHookResult = ReturnType<typeof useListOrganizationUsersLazyQuery>;
export type ListOrganizationUsersQueryResult = Apollo.QueryResult<
  ListOrganizationUsersQuery,
  ListOrganizationUsersQueryVariables
>;
export const ListMemberTokensDocument = gql`
  query listMemberTokens(
    $page: Int
    $limit: Int
    $sortBy: MemberTokenQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: ListMemberTokensFilter
  ) {
    listMemberTokens(
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      searchText: $searchText
      where: $where
    ) {
      items {
        type
        uuid
        status
        wallet
        tokenId
        ownerUid
        shopUuid
        createdAt
        collectionUuid
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListMemberTokensQuery__
 *
 * To run a query within a React component, call `useListMemberTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMemberTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMemberTokensQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListMemberTokensQuery(
  baseOptions?: Apollo.QueryHookOptions<ListMemberTokensQuery, ListMemberTokensQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMemberTokensQuery, ListMemberTokensQueryVariables>(ListMemberTokensDocument, options);
}
export function useListMemberTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListMemberTokensQuery, ListMemberTokensQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMemberTokensQuery, ListMemberTokensQueryVariables>(ListMemberTokensDocument, options);
}
export type ListMemberTokensQueryHookResult = ReturnType<typeof useListMemberTokensQuery>;
export type ListMemberTokensLazyQueryHookResult = ReturnType<typeof useListMemberTokensLazyQuery>;
export type ListMemberTokensQueryResult = Apollo.QueryResult<ListMemberTokensQuery, ListMemberTokensQueryVariables>;
export const ListMembersDocument = gql`
  query listMembers(
    $page: Int
    $limit: Int
    $sortBy: MemberQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: ListMembersFilter
  ) {
    listMembers(
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      searchText: $searchText
      where: $where
    ) {
      items {
        uuid
        ownerUid
        wallet
        shopUuid
        tokenId
        firstName
        lastName
        verifyEmail
        address {
          postalCode
          prefecture
          address
          addressDisplay
        }
        email
        status
        createdAt
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListMembersQuery__
 *
 * To run a query within a React component, call `useListMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMembersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<ListMembersQuery, ListMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMembersQuery, ListMembersQueryVariables>(ListMembersDocument, options);
}
export function useListMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListMembersQuery, ListMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMembersQuery, ListMembersQueryVariables>(ListMembersDocument, options);
}
export type ListMembersQueryHookResult = ReturnType<typeof useListMembersQuery>;
export type ListMembersLazyQueryHookResult = ReturnType<typeof useListMembersLazyQuery>;
export type ListMembersQueryResult = Apollo.QueryResult<ListMembersQuery, ListMembersQueryVariables>;
export const ListMyShopCollectionImagesDocument = gql`
  query listMyShopCollectionImages(
    $page: Int
    $limit: Int
    $sortBy: MemberMyShopCollectionImagesQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: MemberListMyShopCollectionImagesFilter!
  ) {
    listMyShopCollectionImages(
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      searchText: $searchText
      where: $where
    ) {
      items {
        uuid
        collectionUuid
        name
        image
        order
        nameJa
        maxMint
        currentMint
        createdAt
        myShopCollectionUuid
        collectionImageUuid
        myShopCollectionImageUuid
        myShopCollectionImageName
        myShopCollectionImageNameJa
        status
        price
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListMyShopCollectionImagesQuery__
 *
 * To run a query within a React component, call `useListMyShopCollectionImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyShopCollectionImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyShopCollectionImagesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListMyShopCollectionImagesQuery(
  baseOptions: Apollo.QueryHookOptions<ListMyShopCollectionImagesQuery, ListMyShopCollectionImagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMyShopCollectionImagesQuery, ListMyShopCollectionImagesQueryVariables>(
    ListMyShopCollectionImagesDocument,
    options
  );
}
export function useListMyShopCollectionImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListMyShopCollectionImagesQuery, ListMyShopCollectionImagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMyShopCollectionImagesQuery, ListMyShopCollectionImagesQueryVariables>(
    ListMyShopCollectionImagesDocument,
    options
  );
}
export type ListMyShopCollectionImagesQueryHookResult = ReturnType<typeof useListMyShopCollectionImagesQuery>;
export type ListMyShopCollectionImagesLazyQueryHookResult = ReturnType<typeof useListMyShopCollectionImagesLazyQuery>;
export type ListMyShopCollectionImagesQueryResult = Apollo.QueryResult<
  ListMyShopCollectionImagesQuery,
  ListMyShopCollectionImagesQueryVariables
>;
export const ListMyShopCollectionTokensDocument = gql`
  query listMyShopCollectionTokens(
    $page: Int
    $limit: Int
    $sortBy: MyShopCollectionsTokensQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: ListMyShopCollectionsTokensFilter!
  ) {
    listMyShopCollectionTokens(
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      searchText: $searchText
      where: $where
    ) {
      items {
        uuid
        myShopCollectionUuid
        tokenId
        tokenName
        tokenDesc
        tokenMintTime
        status
        name
        nameJa
        price
        order
        createdAt
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListMyShopCollectionTokensQuery__
 *
 * To run a query within a React component, call `useListMyShopCollectionTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyShopCollectionTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyShopCollectionTokensQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListMyShopCollectionTokensQuery(
  baseOptions: Apollo.QueryHookOptions<ListMyShopCollectionTokensQuery, ListMyShopCollectionTokensQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMyShopCollectionTokensQuery, ListMyShopCollectionTokensQueryVariables>(
    ListMyShopCollectionTokensDocument,
    options
  );
}
export function useListMyShopCollectionTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListMyShopCollectionTokensQuery, ListMyShopCollectionTokensQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMyShopCollectionTokensQuery, ListMyShopCollectionTokensQueryVariables>(
    ListMyShopCollectionTokensDocument,
    options
  );
}
export type ListMyShopCollectionTokensQueryHookResult = ReturnType<typeof useListMyShopCollectionTokensQuery>;
export type ListMyShopCollectionTokensLazyQueryHookResult = ReturnType<typeof useListMyShopCollectionTokensLazyQuery>;
export type ListMyShopCollectionTokensQueryResult = Apollo.QueryResult<
  ListMyShopCollectionTokensQuery,
  ListMyShopCollectionTokensQueryVariables
>;
export const ListMyShopCollectionsDocument = gql`
  query listMyShopCollections(
    $page: Int
    $limit: Int
    $sortBy: MyShopCollectionQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: ListMyShopCollectionsFilter!
  ) {
    listMyShopCollections(
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      searchText: $searchText
      where: $where
    ) {
      items {
        uuid
        type
        name
        desc
        order
        nameJa
        descJa
        status
        sortBy
        orderBy
        shopUuid
        createdAt
        updatedAt
        collectionUuid
        collection {
          uuid
          name
          desc
          type
          nameJa
          descJa
          images
          symbol
          network
          ownerUid
          createdAt
          ownerAddress
          contractAddress
          organizationUuid
        }
        collectionImages {
          uuid
          name
          image
          price
          order
          nameJa
          status
          maxMint
          createdAt
          currentMint
          collectionUuid
          collectionImageUuid
          myShopCollectionUuid
          myShopCollectionImageUuid
          myShopCollectionImageName
        }
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListMyShopCollectionsQuery__
 *
 * To run a query within a React component, call `useListMyShopCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyShopCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyShopCollectionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListMyShopCollectionsQuery(
  baseOptions: Apollo.QueryHookOptions<ListMyShopCollectionsQuery, ListMyShopCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMyShopCollectionsQuery, ListMyShopCollectionsQueryVariables>(
    ListMyShopCollectionsDocument,
    options
  );
}
export function useListMyShopCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListMyShopCollectionsQuery, ListMyShopCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMyShopCollectionsQuery, ListMyShopCollectionsQueryVariables>(
    ListMyShopCollectionsDocument,
    options
  );
}
export type ListMyShopCollectionsQueryHookResult = ReturnType<typeof useListMyShopCollectionsQuery>;
export type ListMyShopCollectionsLazyQueryHookResult = ReturnType<typeof useListMyShopCollectionsLazyQuery>;
export type ListMyShopCollectionsQueryResult = Apollo.QueryResult<
  ListMyShopCollectionsQuery,
  ListMyShopCollectionsQueryVariables
>;
export const ListNetworksDocument = gql`
  query listNetworks {
    listNetworks {
      uuid
      chainId
      name
      icon
      rpcUrl
      tokenName
      tokenSymbol
      tokenSymbols
      blockExplorer
      contractAddress
      walletAddress
      subgraphUrl
      testMode
      createdAt
    }
  }
`;

/**
 * __useListNetworksQuery__
 *
 * To run a query within a React component, call `useListNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNetworksQuery({
 *   variables: {
 *   },
 * });
 */
export function useListNetworksQuery(
  baseOptions?: Apollo.QueryHookOptions<ListNetworksQuery, ListNetworksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListNetworksQuery, ListNetworksQueryVariables>(ListNetworksDocument, options);
}
export function useListNetworksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListNetworksQuery, ListNetworksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListNetworksQuery, ListNetworksQueryVariables>(ListNetworksDocument, options);
}
export type ListNetworksQueryHookResult = ReturnType<typeof useListNetworksQuery>;
export type ListNetworksLazyQueryHookResult = ReturnType<typeof useListNetworksLazyQuery>;
export type ListNetworksQueryResult = Apollo.QueryResult<ListNetworksQuery, ListNetworksQueryVariables>;
export const ListTokensDocument = gql`
  query listTokens(
    $page: Int
    $limit: Int
    $sortBy: ListTokensSortBy
    $orderBy: OrderBy
    $searchText: String
    $where: ListTokensFilter!
  ) {
    listTokens(page: $page, limit: $limit, sortBy: $sortBy, orderBy: $orderBy, searchText: $searchText, where: $where) {
      items {
        id
        ownerAddress
        tokenId
        name
        description
        tokenURI
        image
        mintTime
        memberToken {
          type
          uuid
          status
          wallet
          tokenId
          ownerUid
          shopUuid
          createdAt
          collectionUuid
        }
        member {
          uuid
          ownerUid
          wallet
          shopUuid
          tokenId
          firstName
          lastName
          verifyEmail
          address {
            postalCode
            prefecture
            address
            addressDisplay
          }
          email
          status
          createdAt
        }
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListTokensQuery__
 *
 * To run a query within a React component, call `useListTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTokensQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListTokensQuery(baseOptions: Apollo.QueryHookOptions<ListTokensQuery, ListTokensQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTokensQuery, ListTokensQueryVariables>(ListTokensDocument, options);
}
export function useListTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTokensQuery, ListTokensQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTokensQuery, ListTokensQueryVariables>(ListTokensDocument, options);
}
export type ListTokensQueryHookResult = ReturnType<typeof useListTokensQuery>;
export type ListTokensLazyQueryHookResult = ReturnType<typeof useListTokensLazyQuery>;
export type ListTokensQueryResult = Apollo.QueryResult<ListTokensQuery, ListTokensQueryVariables>;
export const CheckExistDomainDocument = gql`
  query checkExistDomain($domain: String!, $shopType: String!) {
    checkExistDomain(domain: $domain, shopType: $shopType)
  }
`;

/**
 * __useCheckExistDomainQuery__
 *
 * To run a query within a React component, call `useCheckExistDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckExistDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckExistDomainQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *      shopType: // value for 'shopType'
 *   },
 * });
 */
export function useCheckExistDomainQuery(
  baseOptions: Apollo.QueryHookOptions<CheckExistDomainQuery, CheckExistDomainQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckExistDomainQuery, CheckExistDomainQueryVariables>(CheckExistDomainDocument, options);
}
export function useCheckExistDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckExistDomainQuery, CheckExistDomainQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckExistDomainQuery, CheckExistDomainQueryVariables>(CheckExistDomainDocument, options);
}
export type CheckExistDomainQueryHookResult = ReturnType<typeof useCheckExistDomainQuery>;
export type CheckExistDomainLazyQueryHookResult = ReturnType<typeof useCheckExistDomainLazyQuery>;
export type CheckExistDomainQueryResult = Apollo.QueryResult<CheckExistDomainQuery, CheckExistDomainQueryVariables>;
export const GetAllMyShopCollectionsContainNftDocument = gql`
  query getAllMyShopCollectionsContainNFT($collectionUuid: String!, $tokenId: String!) {
    getAllMyShopCollectionsContainNFT(collectionUuid: $collectionUuid, tokenId: $tokenId)
  }
`;

/**
 * __useGetAllMyShopCollectionsContainNftQuery__
 *
 * To run a query within a React component, call `useGetAllMyShopCollectionsContainNftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMyShopCollectionsContainNftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMyShopCollectionsContainNftQuery({
 *   variables: {
 *      collectionUuid: // value for 'collectionUuid'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useGetAllMyShopCollectionsContainNftQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllMyShopCollectionsContainNftQuery,
    GetAllMyShopCollectionsContainNftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllMyShopCollectionsContainNftQuery, GetAllMyShopCollectionsContainNftQueryVariables>(
    GetAllMyShopCollectionsContainNftDocument,
    options
  );
}
export function useGetAllMyShopCollectionsContainNftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllMyShopCollectionsContainNftQuery,
    GetAllMyShopCollectionsContainNftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllMyShopCollectionsContainNftQuery, GetAllMyShopCollectionsContainNftQueryVariables>(
    GetAllMyShopCollectionsContainNftDocument,
    options
  );
}
export type GetAllMyShopCollectionsContainNftQueryHookResult = ReturnType<
  typeof useGetAllMyShopCollectionsContainNftQuery
>;
export type GetAllMyShopCollectionsContainNftLazyQueryHookResult = ReturnType<
  typeof useGetAllMyShopCollectionsContainNftLazyQuery
>;
export type GetAllMyShopCollectionsContainNftQueryResult = Apollo.QueryResult<
  GetAllMyShopCollectionsContainNftQuery,
  GetAllMyShopCollectionsContainNftQueryVariables
>;
export const GetAllMyShopCollectionsInPreMintDocument = gql`
  query getAllMyShopCollectionsInPreMint($collectionUuid: String!) {
    getAllMyShopCollectionsInPreMint(collectionUuid: $collectionUuid)
  }
`;

/**
 * __useGetAllMyShopCollectionsInPreMintQuery__
 *
 * To run a query within a React component, call `useGetAllMyShopCollectionsInPreMintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMyShopCollectionsInPreMintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMyShopCollectionsInPreMintQuery({
 *   variables: {
 *      collectionUuid: // value for 'collectionUuid'
 *   },
 * });
 */
export function useGetAllMyShopCollectionsInPreMintQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllMyShopCollectionsInPreMintQuery,
    GetAllMyShopCollectionsInPreMintQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllMyShopCollectionsInPreMintQuery, GetAllMyShopCollectionsInPreMintQueryVariables>(
    GetAllMyShopCollectionsInPreMintDocument,
    options
  );
}
export function useGetAllMyShopCollectionsInPreMintLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllMyShopCollectionsInPreMintQuery,
    GetAllMyShopCollectionsInPreMintQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllMyShopCollectionsInPreMintQuery, GetAllMyShopCollectionsInPreMintQueryVariables>(
    GetAllMyShopCollectionsInPreMintDocument,
    options
  );
}
export type GetAllMyShopCollectionsInPreMintQueryHookResult = ReturnType<
  typeof useGetAllMyShopCollectionsInPreMintQuery
>;
export type GetAllMyShopCollectionsInPreMintLazyQueryHookResult = ReturnType<
  typeof useGetAllMyShopCollectionsInPreMintLazyQuery
>;
export type GetAllMyShopCollectionsInPreMintQueryResult = Apollo.QueryResult<
  GetAllMyShopCollectionsInPreMintQuery,
  GetAllMyShopCollectionsInPreMintQueryVariables
>;
export const GetMyShopDocument = gql`
  query getMyShop($myShopUuid: String!) {
    getMyShop(myShopUuid: $myShopUuid) {
      uuid
      publish
      ownerUid
      createdAt
      updatedAt
      hasUpdate
      organizationUuid
      nftActivationSettings {
        acquisitionInformation {
          id
          type
          displayName
          required
        }
        expireMethod
        expireDate
        expireValue
        expireUnit
      }
      shopType
      siteSetting {
        title
        description
        banner
        ratioBanner
        favicon
        ogp
        logo
        meta
        name
        category
        theme {
          thema {
            template
          }
          style {
            spacing
            spacingSm
            font
            textColor
            titleColor
            buttonColor
            headerColor
            headerTextColor
            backgroundColor
            descriptionColor
            collectionTitleColor
            nftCardTextColor
            nftCardBackgroundColor
            collectionBorderColor
            collectionNftCardImageMode
          }
          navi {
            pageTitle
            pageTitleJa
            description
            descriptionJa
          }
          showLabelSample
          positionLabelSample
        }
      }
      operation {
        type
        name
        detailName {
          lastName
          firstName
          lastNameKana
          firstNameKana
          lastNameEn
          firstNameEn
        }
        shopName {
          name
          nameKana
        }
        address {
          postalCode
          prefecture
          address
          addressDisplay
        }
        contact {
          phoneNumber
          publish
        }
        dayOfBirth
        sex
      }
      shopInformation {
        sortBy
        policy
        terms
        orderBy
        information
      }
      paymentMethod {
        secretKey
        publicKey
        enableCard
        baseCurrency
      }
      domain {
        name
        subDomain
        customDomain
      }
    }
  }
`;

/**
 * __useGetMyShopQuery__
 *
 * To run a query within a React component, call `useGetMyShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyShopQuery({
 *   variables: {
 *      myShopUuid: // value for 'myShopUuid'
 *   },
 * });
 */
export function useGetMyShopQuery(baseOptions: Apollo.QueryHookOptions<GetMyShopQuery, GetMyShopQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyShopQuery, GetMyShopQueryVariables>(GetMyShopDocument, options);
}
export function useGetMyShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyShopQuery, GetMyShopQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyShopQuery, GetMyShopQueryVariables>(GetMyShopDocument, options);
}
export type GetMyShopQueryHookResult = ReturnType<typeof useGetMyShopQuery>;
export type GetMyShopLazyQueryHookResult = ReturnType<typeof useGetMyShopLazyQuery>;
export type GetMyShopQueryResult = Apollo.QueryResult<GetMyShopQuery, GetMyShopQueryVariables>;
export const GetPublishShopDocument = gql`
  query getPublishShop($myShopUuid: String!) {
    getPublishShop(myShopUuid: $myShopUuid) {
      uuid
      updatedAt
    }
  }
`;

/**
 * __useGetPublishShopQuery__
 *
 * To run a query within a React component, call `useGetPublishShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublishShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublishShopQuery({
 *   variables: {
 *      myShopUuid: // value for 'myShopUuid'
 *   },
 * });
 */
export function useGetPublishShopQuery(
  baseOptions: Apollo.QueryHookOptions<GetPublishShopQuery, GetPublishShopQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPublishShopQuery, GetPublishShopQueryVariables>(GetPublishShopDocument, options);
}
export function useGetPublishShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPublishShopQuery, GetPublishShopQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPublishShopQuery, GetPublishShopQueryVariables>(GetPublishShopDocument, options);
}
export type GetPublishShopQueryHookResult = ReturnType<typeof useGetPublishShopQuery>;
export type GetPublishShopLazyQueryHookResult = ReturnType<typeof useGetPublishShopLazyQuery>;
export type GetPublishShopQueryResult = Apollo.QueryResult<GetPublishShopQuery, GetPublishShopQueryVariables>;
export const ListMyShopsDocument = gql`
  query listMyShops(
    $page: Int
    $limit: Int
    $sortBy: MyShopQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: ListMyShopsFilter
  ) {
    listMyShops(
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      searchText: $searchText
      where: $where
    ) {
      items {
        uuid
        ownerUid
        createdAt
        updatedAt
        publish
        organizationUuid
        siteSetting {
          title
          description
          banner
          favicon
          ogp
          category
          theme {
            thema {
              template
            }
            style {
              font
              textColor
              backgroundColor
              buttonColor
            }
            navi {
              pageTitle
              description
            }
          }
        }
        operation {
          type
          name
          detailName {
            lastName
            firstName
            lastNameKana
            firstNameKana
            lastNameEn
            firstNameEn
          }
          shopName {
            name
            nameKana
          }
          address {
            postalCode
            prefecture
            address
            addressDisplay
          }
          contact {
            phoneNumber
            publish
          }
          dayOfBirth
          sex
        }
        shopInformation {
          information
        }
        paymentMethod {
          secretKey
          publicKey
          enableCard
          baseCurrency
        }
        domain {
          name
          subDomain
          customDomain
        }
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListMyShopsQuery__
 *
 * To run a query within a React component, call `useListMyShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyShopsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListMyShopsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListMyShopsQuery, ListMyShopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMyShopsQuery, ListMyShopsQueryVariables>(ListMyShopsDocument, options);
}
export function useListMyShopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListMyShopsQuery, ListMyShopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMyShopsQuery, ListMyShopsQueryVariables>(ListMyShopsDocument, options);
}
export type ListMyShopsQueryHookResult = ReturnType<typeof useListMyShopsQuery>;
export type ListMyShopsLazyQueryHookResult = ReturnType<typeof useListMyShopsLazyQuery>;
export type ListMyShopsQueryResult = Apollo.QueryResult<ListMyShopsQuery, ListMyShopsQueryVariables>;
