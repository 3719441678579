import { forwardRef, useMemo, useState } from 'react';

import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as CloseIcon } from '../../../../icons/images/close-icon.svg';
import { ReactComponent as NaviIcon } from '../../../../icons/images/navi-icon.svg';
import { ReactComponent as ThemaIcon } from '../../../../icons/images/thema-icon.svg';
import { ReactComponent as ThemeIcon } from '../../../../icons/images/theme-icon.svg';
import EditNavi from '../side-bar-edit-shop/EditNavi';
import EditStyle from '../side-bar-edit-shop/EditStyle';
import EditTheme from '../side-bar-edit-shop/EditTheme';
import MenuList from '../side-bar-edit-shop/MenuList';

enum MENU {
  THEME = 'THEME',
  STYLE = 'STYLE',
  NAVI = 'NAVI',
}

const useStyles = makeStyles()(({ breakpoints, zIndex }) => ({
  bottomBar: {
    height: '66px',
    [breakpoints.up('sm')]: {
      height: '80px',
    },
  },
  root: {
    '.MuiDrawer-paperAnchorBottom': {
      maxHeight: '60%',
      minHeight: '350px',
      height: '60%',
      borderRadius: '16px 16px 0px 0px',
      border: '1px solid #D7D7D7',
      overflow: 'hidden',
    },
    '.MuiBackdrop-root': {
      backgroundColor: 'unset',
    },
  },
  header: {
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    justifyItems: 'center',
    backgroundColor: '#F5F5F5',
    justifyContent: 'space-between',
    borderRadius: '16px 16px 0px 0px',
    borderBottom: '1px solid #D7D7D7',
    '.MuiButtonBase-root': {
      padding: 0,
      minWidth: 'unset',
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
  },
  menuItem: {
    '.MuiMenuItem-root': {
      display: 'flex',
      justifyItems: 'center',
    },
  },
  label: {
    fontSize: 16,
    fontWeight: 400,
    color: '#1C1B1F',
    marginLeft: 16,
  },
  content: {
    overflow: 'auto',
    marginBottom: '80px',
    padding: '12px 16px 10px',
  },

  drawerMainMenu: {
    backgroundColor: '#3E3E3E',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: zIndex.drawer + 1,
    '.MuiList-root': {
      display: 'flex',
      padding: 0,
    },
    '.MuiButtonBase-root': {
      display: 'flex',
      fontWeight: 500,
      color: '#ffffff',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '.MuiListItemIcon-root svg': {
      color: '#ffffff',
    },
    '.MuiListItemIcon-root': {
      minWidth: 'unset',
    },
    '.MuiListItemText-root': {
      flex: 'none',
      marginBottom: 0,
    },
    '.Mui-selected': {
      background: '#0367DF !important',
    },
    '.MuiTypography-root': {
      fontSize: 14,
      fontWeight: 500,
    },
  },
}));

const BottomDrawer = forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [menu, setMenu] = useState<MENU | ''>('');

  const handleDrawerClose = () => {
    setMenu('');
  };

  const listMenuOption = useMemo(
    () => ({
      [MENU.THEME]: {
        value: MENU.THEME,
        label: t('my_shop.edit_design.theme'),
        sublabel: t('my_shop.edit_design.theme'),
        icon: <ThemaIcon />,
        component: <EditTheme isMobile />,
      },
      [MENU.STYLE]: {
        value: MENU.STYLE,
        label: t('my_shop.edit_design.style'),
        sublabel: t('my_shop.edit_design.style'),
        icon: <ThemeIcon />,
        component: <EditStyle ref={ref} />,
      },
      [MENU.NAVI]: {
        value: MENU.NAVI,
        label: t('detail'),
        sublabel: t('my_shop.edit_design.navigation'),
        icon: <NaviIcon />,
        component: <EditNavi />,
      },
    }),
    [t, ref]
  );

  return (
    <div className={classes.bottomBar}>
      <Drawer anchor="bottom" className={classes.root} open={!!menu} onClose={handleDrawerClose}>
        <Box className={classes.header}>
          <Typography className={classes.title}>{menu ? listMenuOption?.[menu]?.sublabel : ''}</Typography>
          <Button onClick={handleDrawerClose}>
            <CloseIcon />
          </Button>
        </Box>
        <div className={classes.content}>{menu ? listMenuOption?.[menu]?.component : ''}</div>
      </Drawer>
      <Box className={classes.drawerMainMenu}>
        <MenuList<MENU> currMenu={menu} menuList={Object.values(listMenuOption)} onChange={setMenu} />
      </Box>
    </div>
  );
});

export default BottomDrawer;
