import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as EmptySvg } from '../icons/images/empty.svg';

import LoaderCenter from './loader-center';
import ProductCard from './shop-landing-page/components/ShopContent/components/Shop/ProductCard';

import { CardData } from '~/types/my-shop';

type CardsListProps = {
  data: CardData[];
  isLoading: boolean;
  newChangedNFTNames: { [key: string]: string } | undefined;
  onSaveEditCaption: (info: CardData) => (data: { caption: string }) => Promise<void>;
};

const GAP = 24;
const MOBILE_GAP = 12;
const MAX_CARD_WIDTH = 288;

const columnNumber = (length: number, maxColumn: number) => {
  return Math.min(length, maxColumn);
};

const getMaxWidth = (length: number, maxColumn: number) => {
  return columnNumber(length, maxColumn) * MAX_CARD_WIDTH + (columnNumber(length, maxColumn) - 1) * GAP;
};

const useStyles = makeStyles<{ length: number }>()(({ breakpoints }, { length }) => {
  const sm = breakpoints.up('sm').replace('@media', '@container');
  const md = breakpoints.up('md').replace('@media', '@container');
  const lg = breakpoints.up('lg').replace('@media', '@container');
  return {
    wrapper: {
      margin: 'auto',
      display: 'grid',
      gap: `${MOBILE_GAP}px`,
      maxWidth: `${getMaxWidth(length, 2)}px`,
      gridTemplateColumns: `repeat(${columnNumber(length, 2)}, 1fr)`,
      [sm]: {
        gap: `${GAP}px`,
      },
      [md]: {
        maxWidth: `${getMaxWidth(length, 3)}px`,
        gridTemplateColumns: `repeat(${columnNumber(length, 3)}, 1fr)`,
      },
      [lg]: {
        maxWidth: `${getMaxWidth(length, 4)}px`,
        gridTemplateColumns: `repeat(${columnNumber(length, 4)}, 1fr)`,
      },
    },
    box: {
      minWidth: 0,
      borderRadius: '8px',
      maxWidth: `${MAX_CARD_WIDTH}px`,
      boxShadow: '0 0 0 1px #0000001A',
    },
  };
});

const CardsList = (props: CardsListProps) => {
  const { isLoading, data, newChangedNFTNames, onSaveEditCaption } = props;

  const { t, i18n } = useTranslation('translation');
  const { classes } = useStyles({ length: data.length });

  return (
    <Box>
      <Box className={classes.wrapper}>
        {!isLoading && data.length === 0 ? (
          <Box className="no-nft">
            <EmptySvg />
            <Typography variant="caption">{t('my_shop.sold_out')}</Typography>
          </Box>
        ) : (
          data?.map((item) =>
            item ? (
              <Box key={item.id} className={classes.box}>
                <ProductCard
                  data={item}
                  customName={newChangedNFTNames?.[`${item?.id}-${i18n.language}`]}
                  onSaveEditCaption={onSaveEditCaption}
                />
              </Box>
            ) : null
          )
        )}
        {isLoading && (
          <Box height="200px">
            <LoaderCenter />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CardsList;
