import React, { useCallback } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import Avatar from '@mui/material/Avatar'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import CircularProgress from '@mui/material/CircularProgress'
import { alpha } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

import DialogCloseButton from '../DialogCloseButton'
import { StyledComponentProps } from '../../types'
import { InjectedConnector, TorusConnector, useConnect, WalletConnectConnector, Web3AuthConnector } from '../../..'
import BrowserWalletRequired from '../BrowserWalletRequired'
import { Connector } from '../../../core/connectors/base'
import WalletConnectIcon from '../icons/wallet-connect.svg'
import TorusWalletIcon from '../icons/torus-wallet.svg'
import InjectedWalletIcon from '../icons/injected-wallet.png'
import JocIcon from '../icons/joc-logo.svg'

const useStyles = makeStyles()((theme) => ({
  wrapLoading: {
    margin: 'auto',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: alpha(theme.palette.background.default, 0.5)
  }
}))

interface Props extends StyledComponentProps<typeof useStyles> {
  open: boolean
  onClose: () => void
  onNext?: () => void
}

const WalletConnectorDialog: React.FC<Props> = (props) => {
  const { open, onClose } = props

  return (
    <Dialog open={open} onClose={onClose} data-testid="wallet-connector-dialog" fullWidth={true} maxWidth={'xs'}>
      <Content {...props} />
    </Dialog>
  )
}

const Content: React.FC<Props> = (props) => {
  const { onClose, onNext } = props
  const { classes } = useStyles(undefined, { props: { classes: props.classes } })
  const { t, i18n } = useTranslation()
  const { connectors, connect, status } = useConnect()

  const { enqueueSnackbar } = useSnackbar()

  const onConnect = useCallback(
    async (connector: Connector) => {
      try {
        if (connector instanceof Web3AuthConnector) {
          await connect(connector, { loginProvider: 'google' })
        } else {
          await connect(connector)
        }
        onClose()
        onNext?.()
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    },
    [connect, connectors, enqueueSnackbar, onClose, onNext]
  )

  const getConfig = useCallback(
    (connector: Connector<any, any>) => {
      if (connector instanceof InjectedConnector) {
        return {
          logo: InjectedWalletIcon,
          name: 'Browser wallet',
          description: t('connect_with_wallet', {
            name: 'Browser wallet'
          })
        }
      }
      if (connector instanceof WalletConnectConnector) {
        return {
          logo: WalletConnectIcon,
          name: 'WalletConnect',
          description: t('connect_with_wallet', { name: 'WalletConnect' })
        }
      }
      if (connector instanceof TorusConnector) {
        return {
          logo: TorusWalletIcon,
          name: 'Torus',
          description: t('connect_with_wallet', { name: 'Torus' })
        }
      }
      if (connector instanceof Web3AuthConnector) {
        return {
          logo: JocIcon,
          name: 'JOC Account',
          description: t('connect_with_wallet', { name: 'JOC Account' })
        }
      }
    },
    [t]
  )

  return (
    <>
      <DialogCloseButton onClick={onClose} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle>
        <Typography variant="h6" color="primary" component="p" fontWeight="bold">
          {t('wallet_connect_dialog.title')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 0.5 }}>
        <List sx={{ position: 'relative' }}>
          {connectors.map((connector, index) => (
            <BrowserWalletRequired key={index} disable={!(connector instanceof InjectedConnector)}>
              <ListItemButton alignItems="flex-start" onClick={() => onConnect(connector)}>
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    imgProps={{ sx: { objectFit: 'contain' } }}
                    src={getConfig(connector)?.logo}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={getConfig(connector)?.name}
                  secondary={
                    <React.Fragment>
                      <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                        {getConfig(connector)?.description}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </BrowserWalletRequired>
          ))}
          {status === 'connecting' && (
            <div className={classes.wrapLoading}>
              <CircularProgress />
            </div>
          )}
        </List>
      </DialogContent>
    </>
  )
}

export default WalletConnectorDialog
