import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import FirebaseAuthLocalized from '~/components/firebase-auth-localized';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  wrapper: {
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
  },
  paper: {
    border: '1px solid #D8D8D8',
    borderRadius: 12,
    boxShadow: 'unset',
    padding: '40px',
    [theme.breakpoints.down('md')]: {
      padding: '15px',
    },
    overflow: 'hidden',
    '.firebaseui-card-actions': {
      display: 'flex',
      flexDirection: 'column',
    },
    '.firebaseui-form-links': {
      marginBottom: '10px',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    padding: theme.spacing(2),
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    img: {
      maxWidth: '350px',
      maxHeight: '56px',
    },
  },
  content: {
    textAlign: 'center',
    marginTop: '48px',
  },
  language: {
    width: 155,
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
  },
  emailConfirmationWaring: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  btnLogout: {
    margin: theme.spacing(0.5, 0, 2, 0),
    textTransform: 'uppercase',
  },
  loginText: {
    marginBottom: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '.title': {
      fontSize: 20,
      fontWeight: 1000,
    },
    '.content': {
      fontSize: 14,
    },
  },
  firebaseBtns: {
    width: '100%',
    '.firebaseui-container': {
      margin: 'unset',
      maxWidth: 'unset',
    },
    '.mdl-shadow--2dp': {
      boxShadow: 'unset',
    },
    '.firebaseui-page-provider-sign-in': {
      '.firebaseui-card-content': {
        padding: 'unset',
        width: '100%',
      },
    },
    '.firebaseui-idp-list': {
      width: '100%',
    },
    '.firebaseui-list-item button': {
      maxWidth: 'unset',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

const SignInPage: React.FC = () => {
  const { classes } = useStyles(undefined, { props: {} });
  const { t, i18n } = useTranslation();
  const firebaseUser = useFirebaseUser();

  useEffect(() => {
    document.title = t('sign_in');
  }, [t]);

  const handleLogout = useCallback(() => {
    firebase.auth().signOut();
  }, []);

  const onLanguageChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const selectedLanguage = event.target.value;
      i18n.changeLanguage(selectedLanguage);
    },
    [i18n]
  );
  const config: firebaseui.auth.Config = useMemo(
    () => ({
      signInSuccessUrl: '#',
    }),
    []
  );

  return (
    <Container className={classes.root}>
      <div className={classes.wrapper}>
        <Paper className={classes.paper}>
          <Box className={classes.logo}>
            <img src="images/gu-logo-text.svg" alt="" />
          </Box>
          <div className={classes.content}>
            <Box className={classes.loginText}>
              <Typography className="title">{t('sign_in_page.connect_by_email.title')}</Typography>
              <Typography className="content">{t('sign_in_page.connect_by_email.content')}</Typography>
            </Box>
            <div>
              {firebaseUser && !firebaseUser.emailVerified ? (
                <>
                  <Typography variant="body1" className={classes.emailConfirmationWaring}>
                    {t('email_confirmation_warning')}
                  </Typography>
                  <Button className={classes.btnLogout} variant="contained" onClick={handleLogout}>
                    {t('logout')}
                  </Button>
                </>
              ) : (
                <FirebaseAuthLocalized language={i18n.language} config={config} className={classes.firebaseBtns} />
              )}
            </div>
          </div>
        </Paper>
        <div className={classes.language}>
          <TextField
            name="language"
            onChange={onLanguageChange}
            value={i18n.language}
            select
            color="primary"
            variant="outlined"
            fullWidth
            hiddenLabel
            size="small"
          >
            {languageOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
    </Container>
  );
};

export default SignInPage;

export const languageOptions = [
  {
    value: 'ja',
    label: 'Japanese',
  },
  {
    value: 'en',
    label: 'English',
  },
];
