import { ReactNode } from 'react';

import Button, { ButtonTypeMap } from '@mui/material/Button';
import { ExtendButtonBase } from '@mui/material/ButtonBase';
import { NavigateOptions, useNavigate } from 'react-router-dom';

interface LinkButtonProps {
  to?: string;
  children?: ReactNode;
  state?: NavigateOptions['state'];
}

const LinkButton: ExtendButtonBase<ButtonTypeMap<LinkButtonProps, 'button'>> = (props: LinkButtonProps) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (props?.to) {
      navigate(props.to, props?.state ? { state: props.state } : {});
    }
  };

  return (
    <Button onClick={handleNavigate} {...props}>
      {props.children}
    </Button>
  );
};

export default LinkButton;
