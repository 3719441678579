import React, { useCallback, useContext } from 'react';

import { ERC721G__factory } from '@gu-corp/gu-token-studio-contracts';
import { useAccount } from '@gusdk/gu-wallet-connector';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { JsonRpcProvider, isAddress } from 'ethers';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import DialogCloseButton from '../dialog/dialog-close-button';

import CreateCollectionForm from './CreateCollectionForm';

import { CODE } from '~/constants/code';
import { MAX_FILE_SIZE } from '~/constants/common';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import {
  Collection,
  ListCollectionsDocument,
  useCreateCollectionMutation,
  useCreateCollectionWithOrgWalletMutation,
  useUploadFileMutation,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { StyledComponentProps } from '~/types/material-ui';
import { COLLECTION_TYPE } from '~/types/my-shop';
import { checkMinter } from '~/utils/checkRole';

const useStyles = makeStyles()(() => ({
  dialog: {
    '.MuiPaper-root': {
      maxWidth: '480px',
      width: '480px',
    },
    '.MuiDialogContent-root': {
      padding: '16px',
      marginTop: '16px',
      lineHeight: '16px',
      '.MuiFormControl-root': {
        margin: 0,
        ':not(:first-of-type)': {
          marginTop: '23px',
        },
        '.MuiFormHelperText-root.Mui-error': {
          whiteSpace: 'pre-wrap',
        },
      },
    },
    '.MuiDivider-root': {
      margin: '0 16px',
      borderColor: '#9E9E9E',
    },
    '.MuiDialogActions-root': {
      padding: '16px',
      '.MuiButtonBase-root:not(:first-of-type)': {
        marginLeft: '16px',
      },
    },
  },
}));

type Style = StyledComponentProps<typeof useStyles>['classes'] & DialogProps['classes'];

interface Props extends DialogProps {
  classes?: Style;
  onClose: (params?: { newCollection?: Collection }) => void;
}

const CreateEditCollectionDialog: React.FC<Props> = (props) => {
  const { open, classes, onClose, ...others } = props;

  const { classes: initClasses } = useStyles();

  return (
    <Dialog open={open} className={`${initClasses.dialog} ${classes}`} {...others}>
      <Content {...props} />
    </Dialog>
  );
};

const schema = yup.object({
  option: yup.mixed<'create' | 'import'>().oneOf(['create', 'import']).required(),
  name: yup
    .string()
    .max(100)
    .when('option', {
      is: 'create',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
  ownerAddress: yup
    .string()
    .max(100)
    .when('option', {
      is: 'create',
      then: (schema) =>
        schema.required().test('isAddress', 'form_validation.isEthAddress', (value) => isAddress(value)),
      otherwise: (schema) => schema.optional(),
    }),
  symbol: yup
    .string()
    .max(100)
    .when('option', {
      is: 'create',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
  desc: yup.string(),
  descJa: yup.string(),
  network: yup.string().required(),
  contractAddress: yup
    .string()
    .ethAddress()
    .when('option', {
      is: 'import',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
  storage: yup.string(),
  images: yup.array().of(yup.string()),
  imageFiles: yup
    .mixed<FileList>()
    .test('required', 'form_validation.isNotEmpty', (value) => (value ? Array.from(value).length !== 0 : false))
    .test('fileSize', 'form_validation.max_file_size', (value) => {
      if (value) {
        const files = Array.from(value);
        return files.every((file) => file.size < MAX_FILE_SIZE);
      }
      return true;
    }),
  imageMock: yup.string().notRequired().nullable(),
});

export interface FormValues extends yup.InferType<typeof schema> {}

const Content: React.FC<Props> = (props) => {
  const { onClose } = props;

  const { t } = useTranslation();
  const { account } = useAccount();
  const { showError } = useNotify();
  const [uploadFile] = useUploadFileMutation();
  const { supportedNetworks } = useSupportedNetworks();
  const [createCollectionWithOrgWallet] = useCreateCollectionWithOrgWalletMutation();
  const [createCollection] = useCreateCollectionMutation({
    refetchQueries: [ListCollectionsDocument],
  });

  const {
    control,
    formState: { errors, isSubmitting },
    watch,
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      desc: '',
      descJa: '',
      symbol: '',
      images: [],
      storage: 'ipfs',
      option: 'create',
      contractAddress: '',
      imageMock: undefined,
      imageFiles: undefined,
      ownerAddress: account,
      network: supportedNetworks ? Object.values(supportedNetworks)?.[0]?.chainId : '',
    },
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const errorMessage = (err: any) => {
    if (err?.error?.code === CODE.INTERNAL_ERROR && err?.error?.data?.code === CODE.NOT_SUPPORT_COLLECTION) {
      return t('contract_is_not_support');
    } else if (err.code === CODE.ACTION_REJECTED) {
      return err.reason;
    } else if (err.code === CODE.CALL_EXCEPTION || err.code === CODE.BAD_DATA) {
      return t('toast_message.network_not_match');
    } else if (err.code === CODE.NETWORK_ERROR) {
      return t('my_shop.message.error');
    } else if (err?.graphQLErrors?.[0]?.extensions?.exception?.code === CODE.INSUFFICIENT_FUNDS) {
      return t('toast_message.insufficient_funds_for_gas');
    }
    return err?.error?.message || err?.message || t('my_shop-message.error');
  };

  const onSubmit = useCallback(
    async (data: FormValues) => {
      try {
        let contractAddress = data.contractAddress || '';
        let params = {};

        const uploadedImagesList = await uploadFile({
          variables: {
            files: data.imageFiles,
          },
        });
        if (uploadedImagesList) {
          params = {
            ...params,
            images: uploadedImagesList.data?.uploadFile ?? [],
          };
        }

        if (data.option === 'create') {
          const newCollectionRes = await createCollectionWithOrgWallet({
            variables: {
              input: {
                desc: data.desc,
                name: data.name!,
                descJa: data.descJa,
                symbol: data.symbol!,
                network: data.network,
                type: COLLECTION_TYPE.DIGITAL_COLLECTIVE,
                ownerAddress: data.ownerAddress || '',
                ...params,
              },
            },
          });
          onClose({ newCollection: newCollectionRes.data?.createCollectionWithOrgWallet });
        } else {
          const provider = new JsonRpcProvider(supportedNetworks?.[data.network]?.rpcUrl);
          const factory = ERC721G__factory.connect(contractAddress, provider);
          const ownerAddress = await factory.owner();
          await checkMinter(factory, ownerAddress);
          const newCollectionRes = await createCollection({
            variables: {
              input: {
                desc: data.desc,
                contractAddress,
                descJa: data.descJa,
                network: data.network!,
                type: COLLECTION_TYPE.DIGITAL_COLLECTIVE,
                ownerAddress,
                ...params,
              },
            },
          });
          onClose({ newCollection: newCollectionRes.data?.createCollection });
        }
      } catch (err: any) {
        showError(err, errorMessage(err));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [supportedNetworks, t, onClose, uploadFile, createCollection, enqueueSnackbar]
  );

  return (
    <>
      <DialogCloseButton onClick={() => onClose()} disabled={isSubmitting} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle>
        <Typography variant="h5" component="p">
          {t('create_collection_dialog.title')}
        </Typography>
      </DialogTitle>
      <Divider sx={{ margin: '0 16px!important' }} />
      <DialogContent>
        <CreateCollectionForm control={control} isSubmitting={isSubmitting} errors={errors} watch={watch} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" disabled={isSubmitting} onClick={() => onClose()}>
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t('create')}
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateEditCollectionDialog;
