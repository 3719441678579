import { FC } from 'react';

import { Outlet } from 'react-router-dom';

import SupportedNetworksProvider from './SupportedNetworksProvider';

import { WithApolloClient } from '~/graphql/client';
import { WithAccount } from '~/hooks/with-account';
import { WithFirebaseUser } from '~/hooks/with-firebase-auth';

const PrivateWrapper: FC = () => (
  <WithFirebaseUser>
    <WithApolloClient>
      <WithAccount>
        <SupportedNetworksProvider>
          <Outlet />
        </SupportedNetworksProvider>
      </WithAccount>
    </WithApolloClient>
  </WithFirebaseUser>
);

export default PrivateWrapper;
