import { FC, PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

import { ButtonTypeMap } from '@mui/material/Button';

import ConfirmationDialog, { ICloseDialog } from '~/components/dialog/confirmation-dialog';

type OpenDialogType = (
  title: string,
  content: string,
  options: {
    confirmTitle?: string;
    colorSubmit?: ButtonTypeMap['props']['color'];
    onOpen?: () => void;
    onClose?: ICloseDialog;
    onConfirm: () => Promise<void>;
  }
) => void;

export interface ConfirmationDialogContextValue {
  openDialog: OpenDialogType;
}

interface IDialogInfo {
  title: string;
  content: string;
  confirmTitle?: string;
  colorSubmit?: ButtonTypeMap['props']['color'];
  onClose?: ICloseDialog;
  onConfirm: () => Promise<void>;
}

// Create a context for the dialog
const ConfirmationDialogContext = createContext<ConfirmationDialogContextValue | null>(null);

const ConfirmationDialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [dialogInfo, setDialogInfo] = useState<IDialogInfo | undefined>();

  const openDialog: OpenDialogType = useCallback((...args) => {
    if (!!args[2]?.onOpen) {
      args[2]?.onOpen();
    }
    setDialogInfo({
      title: args[0],
      content: args[1],
      colorSubmit: args?.[2]?.colorSubmit,
      confirmTitle: args?.[2]?.confirmTitle,
      onClose: args[2].onClose,
      onConfirm: args[2].onConfirm,
    });
    setOpen(true);
  }, []);

  const onCloseDialog: ICloseDialog = useCallback(
    (state) => {
      if (!!dialogInfo?.onClose) {
        dialogInfo?.onClose(state);
      }
      setDialogInfo(undefined);
      setOpen(false);
    },
    [dialogInfo]
  );

  return (
    <ConfirmationDialogContext.Provider
      value={{
        openDialog,
      }}
    >
      {children}
      {dialogInfo && <ConfirmationDialog open={open} {...dialogInfo} onClose={onCloseDialog} />}
    </ConfirmationDialogContext.Provider>
  );
};

// Custom hook for using the dialog context
const useConfirmationDialogContext = () => {
  const context = useContext(ConfirmationDialogContext) as ConfirmationDialogContextValue;
  if (!context) {
    throw new Error('useDialogContext must be used within a DialogProvider');
  }
  return context;
};

export { ConfirmationDialogProvider, useConfirmationDialogContext };
