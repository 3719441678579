import { forwardRef, useCallback, useState } from 'react';

import { makeStyles } from 'tss-react/mui';

import HeaderBarEditDesign from './header-bar-edit-shop';
import SideBarEditDesign from './side-bar-edit-shop';

import BottomDrawer from '~/components/app-layout/edit-shop-layout/custom-bottom-drawer';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.colors.white,
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    overflow: 'hidden',
  },
  rightSide: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    flex: 1,
    overflow: 'auto',
    backgroundColor: '#D7D7D7',
  },
  desktop: {
    flex: 1,
    display: 'flex',
    minHeight: 0,
    flexDirection: 'column',
  },
  mobile: {
    width: '390px',
    margin: '40px auto',
    boxShadow: '0px 10px 18px #888888',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  mobileBar: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export enum DEVICE {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

interface Props extends StyledComponentProps<typeof useStyles> {
  onSave: () => void;
  isSubmitting: boolean;
}

const EditShopLayout = forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>((props, ref) => {
  const { children, isSubmitting, onSave } = props;

  const { classes, cx } = useStyles();
  const [device, setDevice] = useState(DEVICE.DESKTOP);
  const handleDevice = useCallback((newDevice: DEVICE) => {
    setDevice(newDevice);
  }, []);

  return (
    <div className={classes.root} data-testid="layout">
      <HeaderBarEditDesign device={device} isSubmitting={isSubmitting} onSave={onSave} handleDevice={handleDevice} />
      <div className={classes.container}>
        <SideBarEditDesign ref={ref} />
        <div className={classes.rightSide}>
          <div className={classes.contentWrapper}>
            <div className={cx(device === DEVICE.MOBILE ? classes.mobile : classes.desktop)}>{children}</div>
          </div>

          <div className={classes.mobileBar}>
            <BottomDrawer ref={ref} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default EditShopLayout;
