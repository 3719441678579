import { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

export interface NoDataWithCreateButtonProps {
  title?: string;
  buttonTitle: string;
  description: string;
  onClick?: () => void;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    padding: '16px',
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    '.MuiTypography-subtitle2': {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.25px',
    },
    '.MuiTypography-caption': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      textAlign: 'center',
      marginBottom: '24px',
      letterSpacing: '0.1px',
    },
  },
}));

const NoDataWithCreateButton: FC<NoDataWithCreateButtonProps> = ({ title, buttonTitle, description, onClick }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Typography variant="subtitle2">{title || t('get_started')}</Typography>
      <Typography variant="caption">{description}</Typography>
      {onClick && (
        <Button variant="contained" startIcon={<AddIcon />} onClick={onClick}>
          {buttonTitle}
        </Button>
      )}
    </Box>
  );
};

export default NoDataWithCreateButton;
