import { ReactNode, useCallback, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import { initRoleOptions } from '../add-user-dialog';
import DialogCloseButton from '../dialog/dialog-close-button';

import { MEMBER_ACTIONS } from '~/config/roleConfig';
import {
  OrganizationUserRole,
  ListOrganizationUsersDocument,
  useUpdateOrganizationUserMutation,
  OrganizationUser,
} from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()(() => ({
  dialog: {
    '.MuiDialog-paper': {
      '& > .MuiIconButton-root': {
        top: '14px',
      },
      '.dialog-title': {
        padding: '16px',
        fontColor: '#444444',
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '133.4%',
      },
      '.MuiDivider-root': {
        margin: '0 16px',
        borderColor: '#9E9E9E',
      },
      '.MuiDialogContent-root': {
        padding: '16px',
        marginTop: '16px',
        '.MuiFormControl-root': {
          margin: 0,
          ':not(:first-of-type)': {
            marginTop: '23px',
          },
          '.MuiFormHelperText-root.Mui-error': {
            whiteSpace: 'pre-wrap',
          },
        },
      },
      '.MuiDialogActions-root': {
        padding: '16px',
        '.MuiButtonBase-root:not(:first-of-type)': {
          marginLeft: '16px',
        },
      },
    },
  },
}));

interface Props extends DialogProps {
  onClose: () => void;
  userInfo?: OrganizationUser;
  classes?: StyledComponentProps<typeof useStyles>['classes'] & DialogProps['classes'];
}

const ChangeUserRole: React.FC<Props> = (props) => {
  const { open, userInfo, onClose, ...others } = props;
  const { classes } = useStyles();

  return (
    <Dialog open={open} onClose={onClose} {...others} className={classes.dialog} maxWidth="sm" fullWidth>
      <Content userInfo={userInfo} {...props} />
    </Dialog>
  );
};

const schema = yup.object({
  role: yup.mixed<OrganizationUserRole>().oneOf(Object.values(OrganizationUserRole)).required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const Content: React.FC<Props> = (props) => {
  const { userInfo, onClose } = props;

  const { t } = useTranslation();
  const { selectedOrganization } = useAccount();

  const [updateOrganizationUser] = useUpdateOrganizationUserMutation({
    refetchQueries: [ListOrganizationUsersDocument],
  });

  const {
    control,
    resetField,
    handleSubmit,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      role: MEMBER_ACTIONS.EDIT[selectedOrganization.role][0] || OrganizationUserRole.Member,
    },
    resolver: yupResolver(schema),
  });

  const isDirty = !!Object.keys(dirtyFields).length;

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (data: FormValues) => {
      if (!userInfo?.uid) {
        return;
      }
      try {
        await updateOrganizationUser({
          variables: {
            input: {
              role: data.role,
              uid: userInfo.uid || '',
            },
          },
        });
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
        onClose();
        resetField('role', { defaultValue: data.role });
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [userInfo?.uid, t, updateOrganizationUser, resetField, onClose, enqueueSnackbar]
  );

  const roleOptions = useMemo(
    () =>
      initRoleOptions(t).reduce((result, option) => {
        if (MEMBER_ACTIONS.EDIT[selectedOrganization.role]?.includes(option.value)) {
          result.push(
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        }
        return result;
      }, [] as ReactNode[]),
    [selectedOrganization.role, t]
  );

  useEffect(() => {
    resetField('role', { defaultValue: userInfo?.role || OrganizationUserRole.Member });
  }, [userInfo?.role, resetField]);

  return (
    <>
      <DialogCloseButton onClick={onClose} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle className="dialog-title">{t('settings.change_role')}</DialogTitle>
      <Divider />
      <DialogContent>
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              label={t('role')}
              variant="outlined"
              margin="normal"
              error={!!errors.role?.message}
              helperText={t(errors.role?.message as any)}
              {...field}
              select
              disabled={isSubmitting}
            >
              {roleOptions}
            </TextField>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="primary" variant="outlined" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!isDirty || isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};

export default ChangeUserRole;
