import React, { useCallback } from 'react';

import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()(() => ({
  paper: {
    width: 130,
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  open: boolean;
  color?: string;
  onClose: () => void;
  anchorEl: MenuProps['anchorEl'];
}

const LanguageMenu: React.FC<Props> = (props) => {
  const { color, open, onClose, anchorEl } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t, i18n } = useTranslation();
  const onChangeLanguage = useCallback(
    (language: string) => {
      i18n.changeLanguage(language);
      onClose();
    },
    [i18n, onClose]
  );

  const style = color ? { color } : {};

  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      classes={{ paper: classes.paper }}
    >
      <MenuItem selected={i18n.language === 'en'} sx={style} onClick={() => onChangeLanguage('en')}>
        {t('english')}
      </MenuItem>
      <MenuItem selected={i18n.language === 'ja'} sx={style} onClick={() => onChangeLanguage('ja')}>
        {t('japanese')}
      </MenuItem>
    </Menu>
  );
};

export default LanguageMenu;
