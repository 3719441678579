export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  red: '#F93F3F',
  pink: '#FF7297',
  purple: '#A95CCF',
  blue: '#4C8DFF',
  green: '#2E8B63',
  yellow: '#FEF331',
  deepOrange: '#DF6425',
  gray: '#5E5F5F',
  lightGray: '#d9d9d9',
  error: '#DF0303',
  primary: '#0367DF',
};

export const button = {
  hover: {
    primary: {
      contained: '#3A4CB1',
      outlined: 'rgba(83, 109, 254, 0.1)',
    },
  },
};
