import { ChangeEvent, useCallback } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { env } from '~/env';
import { useLogout } from '~/hooks/use-logout';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    width: '100%',
    maxWidth: '650px',
  },
  paper: {
    width: '100%',
    maxWidth: theme.breakpoints.values.md,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.error.main,
    position: 'relative',
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  language: {
    width: 155,
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {}

const AccessDenied = (props: Props) => {
  const logout = useLogout();
  const { t, i18n } = useTranslation();
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });

  const onLanguageChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const selectedLanguage = event.target.value;
      i18n.changeLanguage(selectedLanguage);
    },
    [i18n]
  );

  const isEnglish = i18n.language === 'en';
  const requestUrl = env.REACT_APP_HUBSPOT_URL
    ? isEnglish
      ? env.REACT_APP_HUBSPOT_URL
      : env.REACT_APP_HUBSPOT_URL.replace(/\/contact/g, '/ja/contact')
    : '';

  return (
    <Container className={classes.root}>
      <Box className={classes.wrapper}>
        <Paper className={classes.paper}>
          <Box className={classes.header}>
            <Typography variant="h5" color="white" textAlign="center">
              {t('limited_access')}
            </Typography>
          </Box>
          <Box className={classes.content}>
            <Typography variant="subtitle1" whiteSpace="pre-wrap" textAlign="justify">
              {t('limited_access_desc')}
            </Typography>
          </Box>
          <Box className={classes.actions}>
            <Button
              color="primary"
              variant="outlined"
              href={AppRouteEnum.SignIn}
              LinkComponent={RouterLink}
              startIcon={<ArrowBackIcon />}
              onClick={logout}
            >
              {t('logout')}
            </Button>
            <Button variant="contained" endIcon={<SendIcon />} target="_blank" href={requestUrl}>
              {t('request')}
            </Button>
          </Box>
        </Paper>
        <div className={classes.language}>
          <TextField
            name="language"
            onChange={onLanguageChange}
            value={i18n.language}
            select
            color="primary"
            variant="outlined"
            fullWidth
            hiddenLabel
            size="small"
          >
            {languageOptions(t).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </Box>
    </Container>
  );
};

export const languageOptions = (t: TFunction) => [
  {
    value: 'ja',
    label: t('japanese'),
  },
  {
    value: 'en',
    label: t('english'),
  },
];

export default AccessDenied;
